import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  increaseQuantity,
  decreaseQuantity,
  updateQuantity,
} from 'pdp/pages/ProductPage/actions';
import getProduct from 'pdp/pages/ProductPage/selectors/getProduct';
import Button from 'shared/components/Button/button';
import classnames from 'classnames';
import './productQuantity.scss';

class ProductQuantity extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.quantity !== nextProps.quantity;
  }

  handleChange(e) {
    const { quantity, pdpLayoutTest } = this.props;
    const defaultQuantity = pdpLayoutTest === 'pdp_layout_on' ? 0 : 1;
    const enteredQuantity = parseInt(e.target.value, 10) || defaultQuantity;

    if (quantity !== enteredQuantity || !isNaN(enteredQuantity)) {
      this.props.updateQuantity(enteredQuantity);
    }
  }

  handleBlur() {
    const { quantity } = this.props;
    const defaultValue = 1;

    if (quantity === 0) {
      this.props.updateQuantity(defaultValue);
    }
  }

  buttonQuantity() {
    const { quantity } = this.props;
    return (
      <span className="product-quantity">
        <input
          aria-label="Product Quantity"
          autoComplete="off"
          className="product-quantity__input"
          maxLength="3"
          onChange={this.handleChange}
          onClick={(e) => e.target.select()}
          onFocus={(e) => e.target.select()}
          title="Product Quantity"
          type="tel"
          value={quantity}
        />
        <Button
          className={classnames('product-quantity__minus', {
            'product-quantity__minus--disabled': quantity < 2,
          })}
          title="Less"
          onClick={() => this.props.decreaseQuantity(quantity)}
          disabled={quantity < 2}
        />
        <Button
          className="product-quantity__plus"
          title="More"
          onClick={() => this.props.increaseQuantity(quantity)}
        />
      </span>
    );
  }

  inputQuantity() {
    const { quantity } = this.props;
    return (
      <span className="product-quanity product-quantity-input-only">
        <input
          aria-label="Product Quantity"
          autoComplete="off"
          className="product-quantity__input"
          maxLength="3"
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onClick={(e) => e.target.select()}
          onFocus={(e) => e.target.select()}
          title="Product Quantity"
          type="tel"
          value={quantity === 0 ? '' : quantity}
        />
      </span>
    );
  }

  render() {
    const pdpLayoutTest = this.props.pdpLayoutTest === 'pdp_layout_on';
    return (
      <Fragment>
        {pdpLayoutTest
          ? this.inputQuantity()
          : this.buttonQuantity()
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  const product = getProduct(state, props.productId);
  return {
    quantity: product.quantity,
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  increaseQuantity: (...args) => dispatch(increaseQuantity(productId, ...args)),
  decreaseQuantity: (...args) => dispatch(decreaseQuantity(productId, ...args)),
  updateQuantity: (...args) => dispatch(updateQuantity(productId, ...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductQuantity);
