import {
  RESOLVED_SITEMAP_DATA, REJECTED_SITEMAP_DATA,
} from './sitemap-actions';

const defaultState = {
  siteMapData: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_SITEMAP_DATA: {
      return {
        ...state,
        siteMapData: action.payload,
      };
    }
    case REJECTED_SITEMAP_DATA: {
      return {
        ...state,
        siteMapData: {},
      };
    }
    default: {
      return state;
    }
  }
};
