import React, { Fragment, Component } from 'react';
import { ErrorBoundary } from 'aClientComponents';
import IdleTimer from 'react-idle-timer';
import Auth from '@aws-amplify/auth';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this.onIdle.bind(this);
  }

  onIdle() {
    const kmsi = window.localStorage.getItem('_kmsi');
    if (!kmsi) Auth.signOut();
  }

  render() {
    const { children } = this.props;
    return (
      <Fragment>
        {IS_CLIENT && (
          <IdleTimer
            ref={(ref) => { this.idleTimer = ref; }}
            onIdle={this.onIdle}
            timeout={1000 * 60 * 60} // second -> minute -> hour
          />
        )}
        <ErrorBoundary id="App">
          {children}
        </ErrorBoundary>
      </Fragment>
    );
  }
}
