import React from 'react';
import './errorMessage.scss';

const ErrorMessage = ({
  message,
}) => {
  if (!message) {
    return null;
  }

  return (
    <div className="errorMessage">
      <p>{message}</p>
    </div>
  );
};

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
