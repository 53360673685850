import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import map from 'lodash/map';
import find from 'lodash/find';
import {
  shouldDisplayMonogramHelpLineText,
} from 'pdp/components/Checkout/checkoutOptionRenderer';
import {
  getReviewCount,
  getReviewRating,
} from 'server/components/Utag/reducers/selector/getUtagData';
// eslint-disable-next-line import/no-cycle
import { isZeroDollarProduct } from 'pdp/pages/ProductPage/reducers/productReducer';
import { RTS, YMAL } from 'cms/constants';

const booleanToString = (value) => String(Boolean(value));

const getAdornmentsType = (payload) => (payload.price.adornments ? ['Sale'] : ['Regular']);

const getTotalSkuCount = (payload) => payload.skus.filter((sku) => {
  if ((['Y', 'W']).includes(sku.discontinuedCode)) {
    return sku.sellable;
  }
  return true;
}).length;

const getAvailableSkuCount = (payload) => payload.skus.map((sku) => booleanToString(sku.sellable)).filter((availableSku) => availableSku === 'true').length;

export const productUtag = (payload) => {
  const isSwatchProduct = (options) => {
    const hasSwatch = (options.productOptions || [])
      .some((productOption) => productOption.label === 'color' && !isEmpty(productOption.values[0].url));
    return booleanToString(hasSwatch);
  };

  return {
    product_type: ['non-group'],
    product_brand: payload.designer && payload.designer.name,
    product_id: [payload.id],
    product_name: [payload.name],
    product_cmos_catalog_id: [payload.metadata.cmosCatalogId],
    product_cmos_item: [payload.metadata.cmosItem],
    product_price: [payload.price.promotionalPrice
      ? payload.price.promotionalPrice : payload.price.retailPrice],
    product_pricing_adornment_flag: [booleanToString(payload.price.adornments)],
    product_swatch: isSwatchProduct(payload.options || { productOptions: [] }),
    product_review_count: getReviewCount(payload),
    product_review_rating: getReviewRating(payload),
    product_cmos_sku: payload.skus.map((sku) => sku.metadata.cmosSkuId),
    product_expected_availability: payload.skus.map((sku) => sku.expectedShipDays || ''),
    product_sellable_sku: payload.skus.map(
      (sku) => booleanToString(sku.sellable)
    ),
    product_sale_type: payload.details.clearanceItem ? ['Clearance'] : getAdornmentsType(payload),
    total_sku_count: getTotalSkuCount(payload),
    available_sku_count: getAvailableSkuCount(payload),
  };
};

export const quickLookProductUtagDataKeys = [
  'page_name',
  'event_name',
  'product_available',
  'parent_cmos_item_code',
  'product_showable',
  'product_monogrammable',
  'unsellable_skus',
  'product_configurable',
  'product_inventory_status',
  'product_type',
  'product_brand',
  'product_id',
  'product_name',
  'product_cmos_catalog_id',
  'product_cmos_item',
  'product_price',
  'product_pricing_adornment_flag',
  'product_swatch',
  'product_cmos_sku',
  'product_expected_availability',
  'product_sellable_sku',
  'product_suppress_checkout_flag',
  'product_sale_type',
  'product_dropship_flag',
  'product_nm_exclusive',
  'edw_pricing_adornment',
  'total_sku_count',
  'available_sku_count',
];
const isProductNotSellable = (payload) => (
  payload.details.suppressCheckout
  || isZeroDollarProduct(payload)
  || shouldDisplayMonogramHelpLineText(payload)
);

const getProductDropShip = (skus) => skus.some((sku) => sku.dropShip);

const getEDWPricingType = (payload) => {
  if (!isEmpty(payload.promotions) && payload.price.promotionalPrice && payload.price.adornments) return 'CP';
  if (payload.price.adornments) return 'C';
  if (payload.promotions && payload.price.promotionalPrice) return 'RP';
  return 'R';
};

const inventoryStatus = {
  inStock: 'In Stock',
  preOrder: 'Pre Order',
  backOrder: 'Back Order',
  OOS: 'OOS',
};

export const quickLookProductUtagData = (payload) => ({
  ...productUtag(payload),
  page_name: 'product detail',
  event_name: 'Quicklook',
  product_available: [payload.displayable.toString()],
  parent_cmos_item_code: '',
  product_showable: [
    (payload.displayable && isProductNotSellable(payload)).toString(),
  ],
  product_monogrammable: [payload.isCustomizable.toString()],
  unsellable_skus: payload.skus.some((sku) => !sku.sellable).toString(),
  product_configurable: ['false'],
  product_inventory_status: payload.skus.map(
    (sku) => (
      find(inventoryStatus, (statusValue, statusKey) => sku[statusKey])
      || inventoryStatus.OOS
    )
  ),
  product_dropship_flag: [
    booleanToString(getProductDropShip(payload.skus) || false),
  ],
  product_nm_exclusive: [booleanToString(payload.productFlags.isOnlyAtNM)],
  edw_pricing_adornment: [getEDWPricingType(payload)],
  // eslint-disable-next-line max-len
  ...(payload?.skus && payload?.skus.length > 0 && { df_nmo_sku_id: payload.skus[0]?.id }),
});

export const favoriteUtagData = (payload) => ({
  event_name: payload.utagData.eventId,
  item_type: payload.utagData.itemType,
  favorite_item_status: payload.utagData.favoriteItemStatus,
  product_favorited_catalog: [payload.cmosCatalogId],
  product_favorited_item: [payload.utagData.favoriteItem],
  product_interaction: '',
});

export const productListUtag = (payload) => ({
  category_results: toString(payload.total),
  page_number: toString(payload.page),
  page_template: payload.templateType,
  sort_method: payload.sortBy,
  page_definition_id: payload.templateType,
  previous_view_sort_submit: toString(payload.previousViewSortSubmit),
  product_cmos_catalog_id: payload.cmosCatalog,
  product_cmos_item: payload.cmosItem,
  facet_eligible: toString(payload.facetEligible),
  plp_type: toString(payload.plpType),
  pcs_enabled: toString(payload.pcsEnabled),
});

export const searchListUtag = (payload) => ({
  page_definition_id: toString(payload.page_definition_id),
  page_name: toString(payload.page_name),
  page_type: toString(payload.page_type),
  page_version: toString(payload.page_version),
  search_type: toString(payload.search_type),
  internal_search_term: toString(payload.internal_search_term),
  category_results: toString(payload.total),
  page_number: toString(payload.page),
  sort_method: payload.sortBy,
  previous_view_filter_submit: toString(payload.previousViewFilterSubmit),
  previous_view_sort_submit: toString(payload.previousViewSortSubmit),
  page_template: toString(payload.page_template),
  previous_view_search_submit: toString(payload.previousViewSearchSubmit),
  product_cmos_catalog_id: map(payload.productsMetadata, 'cmosCatalogId'),
  product_cmos_item: map(payload.productsMetadata, 'cmosItem'),
  search_type_ahead: toString(payload.search_type_ahead),
  query_relax: toString(payload.query_relax),
  previous_view_facet_submit: toString(payload.previousViewFacetSubmit),
  search_function: payload.searchFunction,
});

export const categoryTemplatesUtag = (payload) => ({
  page_template: toString(payload.page_template),
  page_definition_id: toString(payload.page_definition_id),
  page_type: toString(payload.page_type),
  page_name: toString(payload.page_name),
});

export const ctpFavoriteDesignerUtag = (payload) => ({
  event_name: toString(payload.event_name),
  item_type: toString(payload.item_type),
  favorite_item_status: toString(payload.favorite_item_status),
  favorite_designer: toString(payload.favorite_designer),
});

export const selectedFilterUtag = (payload) => ({
  filter_selection: payload.filterOptions.filterSelection,
  filter_type: payload.filterOptions.filterType,
});

export const selectedFacetUtag = (payload) => ({
  facet_selection: payload.facetOptions.filterSelection,
  facet_type: payload.facetOptions.filterType,
});

export const recentSizesUtag = (payload) => ({
  elevated_recent_sizes: payload.recentSizes,
});

export const promoTileFlagUtag = (payload) => ({
  promo_tile_flag: payload.promoTileFlag,
});

export const facetFlagUtag = (payload) => (
  payload.facetFlag
    ? {
      facet_flag: toString(payload.facetFlag),
      previous_view_facet_submit: toString(payload.previousViewFacetSubmit),
    }
    : {
      previous_view_filter_submit: toString(payload.previousViewFilterSubmit),
    }
);

export const addToBagDataKeys = [
  'event_name',
  'request_type',
  'ajax_response_id',
  'cart_change_product_cmos_item',
  'cart_change_product_quantity',
  'cart_change_product_cmos_sku',
  'cart_change_product_name',
  'cart_change_bops_flag',
  'cart_change_product_replenish_time',
  'cart_change_nmo_sku_id',
  'cart_change_product_price',
  'cart_change_product_id',
  'cart_change_product_catalog_id',
  'available_sku_count',
  'total_sku_count',
];

export const addToBagUtag = ({
  productIds = [],
  utagData,
  isCartAdapter = false,
}) => {
  const skus = utagData.nmo_sku_id || [];

  return isCartAdapter ? {
    event_name: utagData.ajax_response_id,
    ...utagData,
  } : {
    event_name: utagData.ajax_response_id,
    request_type: utagData.request_type,
    ajax_response_id: utagData.ajax_response_id,
    cart_total_items: utagData.cart_total_items,
    cart_change_product_cmos_item: utagData.product_cmos_item,
    cart_change_product_quantity: utagData.cart_change_product_quantity,
    cart_change_product_cmos_sku: utagData.product_cmos_sku,
    cart_change_product_name: utagData.product_name,
    cart_change_bops_flag: utagData.cart_change_bops_flag,
    cart_change_product_replenish_time: utagData.cart_product_replenish_time,
    cart_change_nmo_sku_id: skus.slice(-1),
    cart_change_product_price: utagData.cart_change_product_price,
    cart_change_product_id: productIds.slice(-1),
    cart_change_product_catalog_id: utagData.cart_change_product_catalog_id,
    ...(typeof utagData?.isYMALProduct === 'boolean' && { panel_type: utagData.isYMALProduct ? YMAL : RTS }),
  };
};

export const productPanelKeys = [
  'product_available',
  'product_type',
  'product_brand',
  'product_id',
  'product_name',
  'product_cmos_catalog_id',
  'product_cmos_item',
  'product_price',
  'product_pricing_adornment_flag',
  'product_swatch',
  'product_review_count',
  'product_review_rating',
  'product_cmos_sku',
  'product_expected_availability',
  'product_sellable_sku',
  'product_suppress_checkout_flag',
  'product_sale_type',
  'product_dropship_flag',
  'product_nm_exclusive',
  'edw_pricing_adornment',
  'total_sku_count',
  'available_sku_count',
  'df_nmo_sku_id',
  ...addToBagDataKeys,
  'previous_view_search_submit',
  'trufit_multiplesize_modal',
  'outfitting_product',
  'facet_selection',
  'facet_type',
  'internal_search_term',
  'previous_view_spr_submit',
  'search_function',
  'search_type_ahead',
  'item_type',
  'favorite_item_status',
  'product_favorited_catalog',
  'product_favorited_item',
  'panel_name',
  'panel_type',
  'panel_description',
];

export const omitProductPanelKeys = [
  'best_Customer',
  'editorial_nav_click',
  'emerging_elite',
  'facebook_token',
  'favorites_enabled_flag',
];
