/* eslint-disable no-unused-expressions */
import { LOCAL_STORAGE_RETRIEVE_KEYS, LOCAL_STORAGE_RETRIEVE_KEYS_WITH_PREFIX } from '../common/constants';

export const clearLocalStorage = () => {
  if (window?.localStorage) {
    // Retrieve LOCAL_STORAGE_RETRIEVE_KEYS and LOCAL_STORAGE_RETRIEVE_KEYS_WITH_PREFIX key values from localstorage before flush.
    const retrievedValues = {};
    for (let i = 0; i < localStorage?.length; i++) {
      const key = localStorage.key(i);
      if (LOCAL_STORAGE_RETRIEVE_KEYS.includes(key)) {
        retrievedValues[key] = localStorage.getItem(key);
        // eslint-disable-next-line no-continue
        continue;
      }
      // eslint-disable-next-line max-len
      if (LOCAL_STORAGE_RETRIEVE_KEYS_WITH_PREFIX.some((prefix) => key.startsWith(prefix))) {
        retrievedValues[key] = localStorage.getItem(key);
      }
    }
    console.log('local storage is full. Clearing local storage not whitelisted');
        window?.localStorage.clear();
        // Add back to localstorage once it is clear
        Object.entries(retrievedValues).forEach(([key, value]) => {
          localStorage.setItem(key, value);
        });
  }
};

export const saveToLocalStorage = (key, value) => {
  if (window?.localStorage) {
    try {
          window?.localStorage.setItem(key, value);
    } catch (error) {
      console.log('Error while storing data to localstorage. Error:', error);
      clearLocalStorage();
    }
  }
};

export const clearIfLocalStorageIsFull = () => {
  if (window?.localStorage) {
    try {
            window?.localStorage.setItem('isLocalStorageFull', false);
            window?.localStorage.removeItem('isLocalStorageFull');
    } catch (error) {
      console.log('Error while storing data to localstorage. Error:', error);
      clearLocalStorage();
    }
  }
};
