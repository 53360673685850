import get from 'lodash/get';
import join from 'lodash/join';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import has from 'lodash/has';
import logger from 'server/utilities/logger';
import { getOptyAssignmentValue } from 'client-utils/utilities-abtest';
import { stringify } from 'query-string';
import { X_ABTEST_INFO, X_DEVICE_TYPE } from 'universal/custom-http-headers';
import httpWithLogging from 'universal/http-client';
import { buildCookieString } from 'universal/utilities-cookies';
import {
  getCountryCodeAndLocaleUrl,
  transformSilosByCountryCode,
  loadNewDesignerIndexSilo,
  types,
} from 'storefront/components/Navigation/actions-navigation';
import { constructInitialContextMenuPath } from 'storefront/components/Navigation/NewMobileNav/ContextMenu';
import { types as NavTypes } from 'storefront/components/LeftNavigation/actions-leftnavigation';
import { types as TemplateActionTypes } from 'templates/dux/templateActions';
import navigation from 'storefront/components/Navigation/fallback.json';
import hcNavigation from 'clientHorchow/storefront/components/Navigation/fallback.json';
import { APP_AB_TEST_SESSION_DATA_RECEIVED } from 'shared/components/App/app-action-types';
import {
  LOADING_BRAND_LINKS,
  RESOLVED_BRAND_LINKS,
  REJECTED_BRAND_LINKS,
} from 'storefront/components/Footer/SisterSites/sisterSites-actions';
import { ECMSlots } from 'client/ecm/constants';
import {
  setPageId,
  logABTestAssignments,
  returnAdobeTargetQuery,
  SET_CANONICAL_URL,
  PAGE_ID_PLP,
  LOADING_ABTEST,
  RESOLVED_ABTEST,
  REJECTED_ABTEST,
  LOADING_ABTESTOPT,
  RESOLVED_ABTESTOPT,
  REJECTED_ABTESTOPT,
  VISUAL_NAVIGATION_TEST,
} from 'shared/actions/actions-page';
import {
  VALID_CATEGORY,
  LOADING_PRODUCT_LIST,
  RESOLVED_PRODUCT_LIST,
  REJECTED_PRODUCT_LIST,
  SET_PRODUCT_LIST_DATA_TO_UTAG,
  getUtagFiltersData,
  types as ProductListType,
  getFetchSize,
  SET_SEO_FACETS_ORDER,
} from 'plp/components/ProductListPage/actions';
import { RESOLVED_CMS_ENTRIES } from 'cms/actions/index';
import { getSelectedGender } from 'client-utils/utilities-gender';

export const LOADING_CRP = 'LOADING_CRP';
export const RESOLVED_CRP = 'RESOLVED_CRP';
export const RESOLVED_CRP_CMS_CONTENT = 'RESOLVED_CRP_CMS_CONTENT';
export const REJECTED_CRP = 'REJECTED_CRP';

export const LOADING_CRP_SPA = 'LOADING_CRP_SPA';
export const RESOLVED_CRP_SPA = 'RESOLVED_CRP_SPA';
export const REJECTED_CRP_SPA = 'REJECTED_CRP_SPA';

export const formatAnalyticsPageName = (templateType, data) => {
  if (templateType === 'emag') {
    return 'Emag';
  }
  if (templateType === 'F') {
    return 'F0';
  }
  if (templateType === 'DesignerIndex') {
    data.push('Designers');
    return data.map((curr) => curr).join(':');
  }
  return data.map((curr) => curr.name).join(':');
};

export const formatSiloBannerEcmForDispatch = (dataArray) => {
  return (isEmpty(dataArray) || isEmpty(dataArray[0])) ? {} : dataArray[0];
};

export const formatSiloMainAssetEcmForDispatch = (dataArray) => {
  return (isEmpty(dataArray) || isEmpty(dataArray[1])) ? {} : dataArray[1];
};

export const isVisualNavigationOn = (toggles) => {
  return get(toggles, 'VISUAL_NAVIGATION', false);
};

export const isVisualNavigationTestOn = (optABTests = {}) => {
  const optAbTestValueForVisualNav = getOptyAssignmentValue(optABTests, VISUAL_NAVIGATION_TEST, '');
  return optAbTestValueForVisualNav === 'b';
};

export function getPlpType(displayAsGroupsFlag) {
  return displayAsGroupsFlag ? 'group' : 'non-group';
}

export const loadingActions = () => (dispatch) => {
  dispatch({ type: LOADING_CRP });
  dispatch({ type: types.LOADING_NAVIGATION });
  dispatch({ type: types.LOADING_NAVIGATION_MOBILE });
  dispatch({ type: types.LOADING_BREADCRUMB_CONTENT });
  dispatch({ type: NavTypes.LOADING_LEFTNAV_CONTENT });
  dispatch({ type: LOADING_BRAND_LINKS });
  dispatch({ type: LOADING_PRODUCT_LIST });
  dispatch({ type: LOADING_ABTEST });
  dispatch({ type: LOADING_ABTESTOPT });
};

export const rejectedActions = (e) => (dispatch, getState) => {
  const state = getState();
  const brand = state.brand_name?.env;
  dispatch({ type: REJECTED_CRP, payload: e });
  logger.info('An error occurred in CRP Service API and static json is being rendered for Navigation.');
  dispatch({ type: types.REJECTED_NAVIGATION, payload: brand === 'HC' ? hcNavigation.silos : navigation.silos });
  dispatch({ type: types.REJECTED_NAVIGATION_MOBILE, payload: brand === 'HC' ? hcNavigation : navigation });
  dispatch({ type: types.REJECTED_BREADCRUMB_CONTENT });
  dispatch({ type: NavTypes.REJECTED_LEFTNAV_CONTENT });
  dispatch({ type: REJECTED_BRAND_LINKS });
  dispatch({ type: REJECTED_PRODUCT_LIST });
  dispatch({ type: REJECTED_ABTEST });
  dispatch({ type: REJECTED_ABTESTOPT });
};

export const loadingActionsForSPA = () => (dispatch) => {
  dispatch({ type: LOADING_CRP_SPA });
  dispatch({ type: types.LOADING_BREADCRUMB_CONTENT });
  dispatch({ type: NavTypes.LOADING_LEFTNAV_CONTENT });
  dispatch({ type: LOADING_PRODUCT_LIST });
  dispatch({ type: LOADING_ABTEST });
  dispatch({ type: LOADING_ABTESTOPT });
};

export const rejectedActionsForSPA = (e) => (dispatch) => {
  dispatch({ type: REJECTED_CRP_SPA, payload: e });
  logger.info('An error occurred in CRP Service API in SPA Flow and static json is being rendered for Navigation.');
  dispatch({ type: types.REJECTED_BREADCRUMB_CONTENT });
  dispatch({ type: NavTypes.REJECTED_LEFTNAV_CONTENT });
  dispatch({ type: REJECTED_PRODUCT_LIST });
  dispatch({ type: REJECTED_ABTEST });
  dispatch({ type: REJECTED_ABTESTOPT });
};

export function fetchCategoryResultsPageMainContent(catId, navpath, requestOptions = {}, facet) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      user, session, api, device,
    } = state;
    const context = state.api && state.api.requestContext;
    const currentCategory = get(state, 'productListPage.currentCategory');
    const currentPage = get(state, 'productListPage.currentPage', 1);
    const {
      page = currentPage,
      sortBy = '',
      filterOptions,
    } = requestOptions;
    const selectedFilters = isEmpty(filterOptions) ? {} : JSON.parse(filterOptions);

    function isSortOptionChanged(sortBy) {
      const previousSortOption = get(state, 'productListPage.products.selectedSortOption');
      return !isEmpty(sortBy)
        && !isEmpty(previousSortOption)
        && previousSortOption !== sortBy;
    }

    function isFilterOptionsChanged(selectedFilters) {
      const previousFilterOptions = get(state, 'productListPage.selectedFilterOptions');
      return !isEmpty(selectedFilters)
        && !isEmpty(previousFilterOptions)
        && !isEqual(previousFilterOptions, selectedFilters);
    }
    dispatch(loadingActionsForSPA());

    let deviceType;

    if (get(session, 'deviceType.isMobile', false) && !get(session, 'deviceType.isTablet', false)) {
      deviceType = 'M';
    } else if (get(session, 'deviceType.isTablet', false)) {
      deviceType = 'T';
    } else {
      deviceType = 'D';
    }

    const additionalHeadersForTarget = returnAdobeTargetQuery({
      ...session,
      ...api.requestContext,
      ...device,
    });

    const { mboxData } = get(state, 'abTestSession', '');
    let mboxValues = [];
    if (mboxData) {
      mboxValues = mboxData.split('|');
    }
    let adobeSessionId = '';
    let adobePCId = {};
    mboxValues.forEach((item) => {
      if ((item || '').includes('session')) adobeSessionId = item.split('#')[1];
      if ((item || '').includes('PC')) adobePCId = { tntId: item.split('#')[1] };
    });
    const daisrobot = get(state, 'device.isBOT', '') === '1' ? { daisrobot: '1' } : {};
    const isCustomerSegmentationToggleOn = get(state, 'toggles.PLP_SRP_CUS_SEG', false);

    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        TLTSID: get(session, 'TLTSID', ''),
        ENABLE_STUB_SERVICE: get(state, 'cookies.enableStubService'),
        ENABLE_PROMO_PREVIEW: get(state, 'cookies.enablePromoPreview'),
        WID: get(state, 'utag.userSession.web_id', ''),
        ucid: get(user, 'ucid', ''),
        SPCR: get(session, 'SPCR', '1'),
        defaultToggles: get(session, 'defaultToggles', '1'),
      }),
      ...(isCustomerSegmentationToggleOn && { securityStatus: get(state.user, 'securityStatus', '') }),
      ...additionalHeadersForTarget,
      [X_ABTEST_INFO]: join(get(state, 'abTests.responses', []).map((i) => `${i.mboxId}:${get(i, 'assignment.value.experienceId', '')}`), '~'),
      [X_DEVICE_TYPE]: deviceType,
      ucid: get(user, 'ucid', ''),
      wid: get(state, 'utag.userSession.web_id', ''),
      'page-url': get(session, 'url', ''),
      adobeSessionId,
      spa: true,
      userAgent: get(api, 'requestContext.USER_AGENT', ''),
      ...daisrobot,
      ...adobePCId,
    };

    if (get(session, '_optuid')) headers.optuid = get(session, '_optuid');
    if (context && context.TRUE_CLIENT_IP) { headers['true-client-ip'] = get(context, 'TRUE_CLIENT_IP'); }
    if (page) headers.page = page;
    if (facet) headers.facet = facet;

    const CRP_SVC_TIMEOUT = get(state, 'apiTimeouts.CRP_SVC_TIMEOUT');

    const requestApi = httpWithLogging(state, CRP_SVC_TIMEOUT);
    return requestApi.get(`${NMConfig.API_CRP}?catId=${catId}&navpath=${navpath}`, { headers })
      .then((res) => {
        const { breadcrumbs } = get(res.data, 'categoryTemplate.templateAttributes.navigation', []);
        const { leftNavigation } = get(res.data, 'categoryTemplate.templateAttributes.navigation', []);
        const { hybridLeftNavigation } = get(res.data, 'categoryTemplate.templateAttributes.navigation', []);
        const { templateType } = get(res.data, 'categoryTemplate', '');
        const { ecmContent } = get(res.data, 'categoryTemplate.templateAttributes', []);
        const { abTests } = get(res.data, 'categoryTemplate', null);
        const { optABTests } = get(res.data, 'categoryTemplate', null);
        if (templateType !== 'P') {
          dispatch({
            type: RESOLVED_CRP_SPA,
            status: 'Received CRP Response',
            payload: res.data.categoryTemplate,
          });
        } else {
          dispatch({
            type: RESOLVED_CRP_SPA,
            status: 'Received CRP Response',
            payload: {
              ...res.data.categoryTemplate,
              abTests: {},
              optABTests: {},
              templateAttributes: {},
            },
          });
        }

        if (get(res.data, 'categoryTemplate.pageAttributes.seoContent', false)) {
          dispatch({
            type: 'SET_META_INFO_CTP',
            payload: {
              ctpMetaInfo: get(res.data, 'categoryTemplate.pageAttributes.seoContent.description', ''),
              metaTitle: res.data.categoryTemplate.pageAttributes.seoContent.title,
              metaDescription: res.data.categoryTemplate.pageAttributes.seoContent.description,
              canonicalUrl: res.data.categoryTemplate.pageAttributes.seoContent.canonicalUrl,
            },
          });
        }

        const pageName = breadcrumbs || [];
        let analyticsPageNameValue = formatAnalyticsPageName(templateType, pageName);
        let pageType = get(res.data, 'categoryTemplate.analyticsAttributes.boutique', false) === false ? 'category' : 'boutique';
        pageType = templateType === 'Silo' || templateType === 'DesignerIndex' ? 'Silo' : pageType;
        const pageTitle = get(res, 'data.categoryTemplate.pageAttributes.seoContent.title', '');
        const excludeFromPCS = get(res.data, 'categoryTemplate.templateAttributes.dynamicContent.categoryFlags.excludeFromPCS', false);
        let pageTemplate = templateType;
        let pageDefintionId = templateType;

        switch (templateType) {
          case 'DesignerIndex':
            pageTemplate = 'Silo';
            break;
          case 'F':
            pageDefintionId = 'F0';
            pageTemplate = 'F0';
            break;
          case 'Entry':
            pageTemplate = 'entry';
            break;
          case 'MagazineIndex':
            analyticsPageNameValue = 'NM Magazine';
            break;
          default:
            break;
        }

        if (pageTemplate === 'F0' || pageTemplate === 'emag') {
          if (pageTitle) {
            dispatch({
              type: TemplateActionTypes.SET_CATEGORY_TEMPLATES_DATA_TO_UTAG,
              payload: {
                page_definition_id: pageDefintionId,
                page_name: analyticsPageNameValue,
                page_template: pageTemplate,
                page_type: pageType,
                page_title: pageTitle,
              },
            });
          }
        } else {
          dispatch({
            type: TemplateActionTypes.SET_CATEGORY_TEMPLATES_DATA_TO_UTAG,
            payload: {
              page_definition_id: pageDefintionId,
              page_name: analyticsPageNameValue,
              page_template: pageTemplate,
              page_type: pageType,
            },
          });
        }

        if (!isEmpty(breadcrumbs)) {
          dispatch({
            type: types.RESOLVED_BREADCRUMB_CONTENT,
            payload: { breadcrumbs },
          });
        }

        if (abTests) {
          if (abTests.status === 'success') {
            if (abTests.assignments.length > 0) {
              dispatch({ type: RESOLVED_ABTEST, payload: abTests.assignments });

              const mboxIds = abTests.assignments
                .map((x) => x.mboxId)
                .join(',');

              logABTestAssignments(getState, mboxIds);
            }
          } else {
            dispatch({ type: REJECTED_ABTEST });
          }

          if (abTests.mboxCookieValue) {
            dispatch({
              type: APP_AB_TEST_SESSION_DATA_RECEIVED,
              mboxCookie: abTests.mboxCookieValue,
            });
          }
        }

        if (optABTests) {
          dispatch({ type: RESOLVED_ABTESTOPT, payload: optABTests });
        }

        if (
          templateType === 'P'
        ) {
          if (!isEmpty(hybridLeftNavigation)) {
            dispatch({
              type: NavTypes.RESOLVED_LEFTNAV_CONTENT,
              payload: hybridLeftNavigation,
            });
          } else if (!isEmpty(leftNavigation)) {
            dispatch({
              type: NavTypes.RESOLVED_LEFTNAV_CONTENT,
              payload: leftNavigation,
            });
          }
        } else if (!isEmpty(leftNavigation)) {
          dispatch({
            type: NavTypes.RESOLVED_LEFTNAV_CONTENT,
            payload: leftNavigation,
          });
        }

        if (!isEmpty(ecmContent)) {
          if (templateType === 'Silo') {
            dispatch({
              type: `RESOLVED_ECM${ECMSlots.PLP_BANNER.contentId}`,
              payload: formatSiloBannerEcmForDispatch(ecmContent),
              contentId: ECMSlots.PLP_BANNER.contentId,
            });
            dispatch({
              type: `RESOLVED_ECM${ECMSlots.SILO_FULL_ASSET.contentId}`,
              payload: formatSiloMainAssetEcmForDispatch(ecmContent),
              contentId: ECMSlots.SILO_FULL_ASSET.contentId,
            });
          } else {
            dispatch({
              type: `RESOLVED_ECM${ECMSlots.PLP_BANNER.contentId}`,
              payload: ecmContent,
              contentId: ECMSlots.PLP_BANNER.contentId,
            });
          }
        }

        if (templateType === 'Silo') {
          const { thumbnails } = get(res.data, 'categoryTemplate.templateAttributes.dynamicContent', []);
          dispatch({
            type: TemplateActionTypes.SET_SILO_THUMBNAIL_DATA,
            payload: thumbnails,
          });
        }

        if (templateType === 'P') {
          dispatch(setPageId(PAGE_ID_PLP));
          const productListing = get(res.data, 'categoryTemplate.templateAttributes.dynamicContent.productListing', {});
          if (!isEmpty(productListing)) {
            const tabsInSizeFacet = get(res.data, 'categoryTemplate.tabsInSizeFacet', false);

            dispatch({
              type: RESOLVED_PRODUCT_LIST,
              payload: {
                ...productListing, tabsInSizeFacet, page,
              },
              ftrTgls: {
                dprToggle: state.toggles.CLOUDINARY_DPR,
              },
            });
            dispatch({
              type: ProductListType.SET_FILTER_OPTIONS,
              selectedFilterOptions: isEmpty(filterOptions)
                ? 'No filter options selected' : JSON.parse(filterOptions),
            });
            const seoFirstParam = state?.facetedLeftNav?.firstParam;
            const seoSecondParam = state?.facetedLeftNav?.secondParam;
            if (isEmpty(seoFirstParam) && isEmpty(seoSecondParam)) {
              dispatch({
                type: SET_CANONICAL_URL,
                canonicalUrl: productListing.seo.canonicalUrl ? productListing.seo.canonicalUrl : '',
              });
            }
            dispatch({
              type: ProductListType.SET_SORT_BY,
              sortBy: productListing.selectedSortOption,
            });
            dispatch({ type: VALID_CATEGORY });

            dispatch({
              type: SET_PRODUCT_LIST_DATA_TO_UTAG,
              payload: {
                total: productListing.total,
                templateType: productListing.templateType,
                category: currentCategory,
                productsMetadata: map(productListing.products, 'metadata'),
                sortBy: productListing.selectedSortOption,
                page,
                facetOptions: getUtagFiltersData(selectedFilters),
                previousViewFacetSubmit: isFilterOptionsChanged(selectedFilters),
                previousViewSortSubmit: isSortOptionChanged(sortBy),
                facetFlag: true,
                facetEligible: true,
                plpType: getPlpType(productListing.displayAsGroups),
                pcsEnabled: !excludeFromPCS,
              },
            });
          } else {
            dispatch({ type: REJECTED_PRODUCT_LIST });
          }
        }
        ['promotiles', 'categoryHeader', 'bottomBanner'].forEach((ecmContentKey) => {
          let contentId = ecmContentKey;
          if (ecmContentKey === 'categoryHeader') {
            contentId = ECMSlots.PLP_BANNER.contentId;
          }
          if (ecmContentKey === 'bottomBanner') {
            contentId = ECMSlots.PLP_BANNER_BOTTOM.contentId;
          }
          const ecmContent = get(res.data, `ecmContent.${ecmContentKey}`);
          if (!isEmpty(ecmContent)) {
            if (ecmContentKey === 'promotiles') {
              if (ecmContent.promotile) {
                dispatch({
                  type: 'LOADING_ecmplpPromoTile',
                });
                dispatch({

                  type: 'RESOLVED_ecmplpPromoTile',
                  payload: {},
                  contentId: ECMSlots.PLP_PROMO_TILES.contentId,
                  headers,
                });
                ecmContent.promotile.forEach((slot, index) => {
                  dispatch({
                    type: `RESOLVED_ECM${ECMSlots[ECMSlots.PLP_PROMO_TILES.slots[index]].contentId}`,
                    payload: slot,
                    contentId: `${ECMSlots[ECMSlots.PLP_PROMO_TILES.slots[index]].contentId}`,
                    headers,
                  });
                });
              }
            } else {
              dispatch({
                type: `RESOLVED_ECM${contentId}`,
                payload: ecmContent,
                contentId,
                headers,
              });
            }
          } else if (has(res.data.ecmContent, ecmContentKey)) {
            dispatch({
              type: `RESOLVED_ECM${contentId}`,
              payload: {},
              contentId,
              headers,
            });
          }
        });
      })
      .catch((e) => {
        dispatch(rejectedActionsForSPA(e));
      });
  };
}

export function fetchCategoryResultsPageContent(catId, navpath, requestOptions = {}, facet) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      user, session, api, device,
    } = state;
    const context = state.api && state.api.requestContext;
    const currentCategory = get(state, 'productListPage.currentCategory');
    const currentPage = get(state, 'productListPage.currentPage', 1);
    const {
      page = currentPage,
      categoryId = get(currentCategory, 'id', '').split('_')[0],
      parentCategoryId = '',
      siloCategoryId,
      sortBy = '',
      filterOptions,
    } = requestOptions;
    const { promos } = JSON.parse(get(session, 'dt_personalize_data', '{}'));
    const sortByParams = sortBy ? `&sortBy=${sortBy}` : '';
    const personalizedPromosParams = promos && promos.length ? `&personalizedPromos=${promos}` : '';
    const selectedFilters = isEmpty(filterOptions) ? {} : JSON.parse(filterOptions);
    const optParam = get(session, 'force-opty') ? `&force-opty=${get(session, 'force-opty')}` : '';
    const enableDynamicNavigation = get(state, 'toggles.ACN_DYNAMIC_NAVIGATION', false) && !state.device.isWebCrawler;

    function isSortOptionChanged(sortBy) {
      const previousSortOption = get(state, 'productListPage.products.selectedSortOption');
      return !isEmpty(sortBy)
        && !isEmpty(previousSortOption)
        && previousSortOption !== sortBy;
    }

    function isFilterOptionsChanged(selectedFilters) {
      const previousFilterOptions = get(state, 'productListPage.selectedFilterOptions');
      return !isEmpty(selectedFilters)
        && !isEmpty(previousFilterOptions)
        && !isEqual(previousFilterOptions, selectedFilters);
    }

    dispatch(loadingActions());

    let deviceType;

    if (get(session, 'deviceType.isMobile', false) && !get(session, 'deviceType.isTablet', false)) {
      deviceType = 'M';
    } else if (get(session, 'deviceType.isTablet', false)) {
      deviceType = 'T';
    } else {
      deviceType = 'D';
    }

    const additionalHeadersForTarget = returnAdobeTargetQuery({
      ...session,
      ...api.requestContext,
      ...device,
    });

    const queryBuilder = (fetchSize) => {
      let params = {};
      if (fetchSize != null) {
        params = { ...params, fetchSize };
      }
      return params;
    };

    const { mboxData } = get(state, 'abTestSession', '');
    let mboxValues = [];
    if (mboxData) {
      mboxValues = mboxData.split('|');
    }
    let adobeSessionId = '';
    let adobePCId = {};
    mboxValues.forEach((item) => {
      if ((item || '').includes('session')) adobeSessionId = item.split('#')[1];
      if ((item || '').includes('PC')) adobePCId = { tntId: item.split('#')[1] };
    });
    const daisrobot = get(state, 'device.isBOT', '') === '1' ? { daisrobot: '1' } : {};
    const isCustomerSegmentationToggleOn = get(state, 'toggles.PLP_SRP_CUS_SEG', false);

    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        TLTSID: get(session, 'TLTSID', ''),
        ENABLE_STUB_SERVICE: get(state, 'cookies.enableStubService'),
        ENABLE_PROMO_PREVIEW: get(state, 'cookies.enablePromoPreview'),
        CMS_Preview: get(context, 'CMS_Preview'),
        WID: get(state, 'utag.userSession.web_id', ''),
        ucid: get(user, 'ucid', ''),
        SPCR: get(session, 'SPCR', '1'),
        defaultToggles: get(session, 'defaultToggles', '1'),
      }),
      ...(isCustomerSegmentationToggleOn && { securityStatus: get(state.user, 'securityStatus', '') }),
      ...additionalHeadersForTarget,
      [X_ABTEST_INFO]: join(get(state, 'abTests.responses', []).map((i) => `${i.mboxId}:${get(i, 'assignment.value.experienceId', '')}`), '~'),
      [X_DEVICE_TYPE]: deviceType,
      ucid: get(user, 'ucid', ''),
      wid: get(state, 'utag.userSession.web_id', ''),
      'page-url': get(session, 'url', ''),
      br_uid: get(context, '_br_uid_2', ''),
      adobeSessionId,
      userAgent: get(api, 'requestContext.USER_AGENT', ''),
      gender: get(state, 'session.dt_gender', getSelectedGender()),
      ...daisrobot,
      ...adobePCId,
    };
    if (get(session, '_optuid')) headers.optuid = get(session, '_optuid');
    if (context && context.TRUE_CLIENT_IP) { headers['true-client-ip'] = get(context, 'TRUE_CLIENT_IP'); }
    if (page) headers.page = page;
    if (facet) headers.facet = facet;

    function getEcmPreviewParamFunc() {
      const context = state.api && state.api.requestContext;
      let ecmPreviewParam;
      if (context) {
        ecmPreviewParam = isEmpty(context.ECMPreview) ? '' : `ECMPreview=${context.ECMPreview}`;
      }
      return `${isEmpty(ecmPreviewParam) ? '' : '&'}${ecmPreviewParam}`;
    }

    const defaultParams = {
      categoryId,
      parentCategoryId,
      siloCategoryId,
      page,
      filterOptions,
    };

    const fetchSize = getFetchSize(state);
    const additionalParams = queryBuilder(fetchSize);
    const ecmPreviewParam = getEcmPreviewParamFunc();
    const urlParam = `${(navpath || '').includes(catId) ? navpath : catId}?${stringify({ ...defaultParams, ...additionalParams })}${sortByParams}${personalizedPromosParams}${optParam}${ecmPreviewParam}`;
    const CRP_SVC_TIMEOUT = get(state, 'apiTimeouts.CRP_SVC_TIMEOUT');
    const requestApi = httpWithLogging(state, CRP_SVC_TIMEOUT);
    const countryCode = get(state, 'locale.countryCode', 'US');

    return requestApi.get(`${NMConfig.API_CRP}/${NMConfig.BRAND_NAME.toLowerCase()}/${countryCode}/categoryResults/${urlParam}`, { headers })
      .then((res) => {
        const { breadcrumbs } = get(res.data, 'categoryTemplate.templateAttributes.navigation', []);
        const { leftNavigation } = get(res.data, 'categoryTemplate.templateAttributes.navigation', []);
        const { hybridLeftNavigation } = get(res.data, 'categoryTemplate.templateAttributes.navigation', []);
        const { visualNav } = get(res.data, 'categoryTemplate.templateAttributes.navigation', []);
        const { templateType } = get(res.data, 'categoryTemplate', '');
        const { ecmContent } = get(res.data, 'categoryTemplate.templateAttributes', []);
        const { abTests } = get(res.data, 'categoryTemplate', null);
        const { optABTests } = get(res.data, 'categoryTemplate', null);

        if (templateType !== 'P') {
          dispatch({
            type: RESOLVED_CRP,
            status: 'Received CRP Response',
            payload: res.data.categoryTemplate,
          });
        } else {
          const cmsContent = get(res.data, 'cmsContent', []);

          dispatch({
            type: RESOLVED_CRP,
            status: 'Received CRP Response',
            payload: {
              ...res.data.categoryTemplate,
              abTests: {},
              optABTests: {},
              templateAttributes: {},
            },
          });

          if (!isEmpty(cmsContent)) {
            const dynamicPromoTileLayout = cmsContent[0]?.fields?.l1Layouts?.find((obj) => obj?.fields?.placement === 'Dynamic Promo');
            if (dynamicPromoTileLayout) {
              const frameTrackingTags = get(cmsContent[0], 'fields.trackingTags', []);
              const layoutTrackingTags = get(dynamicPromoTileLayout, 'fields.trackingTags', []);
              const components = get(dynamicPromoTileLayout, 'fields.components', []);
              for (let i = 0; i < components.length; i++) {
                const promoTileTrackingTags = get(components[i], 'fields.trackingTags', []);
                const content = components[i]?.fields?.content;
                if (content) {
                  content.fields.trackingTags = [...frameTrackingTags, ...layoutTrackingTags, ...promoTileTrackingTags, ...get(content, 'fields.trackingTags', [])];
                }
              }
            }
            dispatch({
              type: RESOLVED_CMS_ENTRIES,
              payload: cmsContent,
            });
          }
        }

        const productListing = get(res.data, 'categoryTemplate.templateAttributes.dynamicContent.productListing', {});
        const seoFacets = get(productListing, 'seoFacets', []);
        if (!isEmpty(seoFacets)) {
          const lowerCaseFacets = seoFacets.map((facet) => facet.toLowerCase());
          dispatch({
            type: SET_SEO_FACETS_ORDER,
            payload: lowerCaseFacets,
          });
        }

        if (get(res.data, 'categoryTemplate.pageAttributes.seoContent', false)) {
          dispatch({
            type: 'SET_META_INFO_CTP',
            payload: {
              ctpMetaInfo: get(res.data, 'categoryTemplate.pageAttributes.seoContent.description', ''),
              metaTitle: res.data.categoryTemplate.pageAttributes.seoContent.title,
              metaDescription: res.data.categoryTemplate.pageAttributes.seoContent.description,
              canonicalUrl: res.data.categoryTemplate.pageAttributes.seoContent.canonicalUrl,
            },
          });
        }

        const pageName = breadcrumbs || [];
        let analyticsPageNameValue = formatAnalyticsPageName(templateType, pageName);
        let pageType = get(res.data, 'categoryTemplate.analyticsAttributes.boutique', false) === false ? 'category' : 'boutique';
        pageType = templateType === 'Silo' || templateType === 'DesignerIndex' ? 'Silo' : pageType;
        const pageTitle = get(res, 'data.categoryTemplate.pageAttributes.seoContent.title', '');
        const excludeFromPCS = get(res.data, 'categoryTemplate.templateAttributes.dynamicContent.categoryFlags.excludeFromPCS', false);
        let pageTemplate = templateType;
        let pageDefintionId = templateType;

        switch (templateType) {
          case 'DesignerIndex':
            pageTemplate = 'Silo';
            break;
          case 'F':
            pageDefintionId = 'F0';
            pageTemplate = 'F0';
            break;
          case 'Entry':
            pageTemplate = 'entry';
            break;
          case 'MagazineIndex':
            analyticsPageNameValue = 'NM Magazine';
            break;
          default:
            break;
        }

        if (pageTemplate === 'F0' || pageTemplate === 'emag') {
          if (pageTitle) {
            dispatch({
              type: TemplateActionTypes.SET_CATEGORY_TEMPLATES_DATA_TO_UTAG,
              payload: {
                page_definition_id: pageDefintionId,
                page_name: analyticsPageNameValue,
                page_template: pageTemplate,
                page_type: pageType,
                page_title: pageTitle,
              },
            });
          }
        } else {
          dispatch({
            type: TemplateActionTypes.SET_CATEGORY_TEMPLATES_DATA_TO_UTAG,
            payload: {
              page_definition_id: pageDefintionId,
              page_name: analyticsPageNameValue,
              page_template: pageTemplate,
              page_type: pageType,
            },
          });
        }

        if (!isEmpty(breadcrumbs)) {
          dispatch({
            type: types.RESOLVED_BREADCRUMB_CONTENT,
            payload: { breadcrumbs },
          });
        }

        if (!isEmpty(visualNav)) {
          dispatch({
            type: types.SET_VISUAL_NAV,
            payload: { visualNav },
          });
        }

        if (abTests) {
          if (abTests.status === 'success') {
            if (abTests.assignments.length > 0) {
              dispatch({ type: RESOLVED_ABTEST, payload: abTests.assignments });

              const mboxIds = abTests.assignments
                .map((x) => x.mboxId)
                .join(',');

              logABTestAssignments(getState, mboxIds);
            }
          } else {
            dispatch({ type: REJECTED_ABTEST });
          }

          if (abTests.mboxCookieValue) {
            dispatch({
              type: APP_AB_TEST_SESSION_DATA_RECEIVED,
              mboxCookie: abTests.mboxCookieValue,
            });
          }
        }

        if (optABTests) {
          dispatch({ type: RESOLVED_ABTESTOPT, payload: optABTests });
        }

        if (
          templateType === 'P'
        ) {
          if (!isEmpty(hybridLeftNavigation)) {
            dispatch({
              type: NavTypes.RESOLVED_LEFTNAV_CONTENT,
              payload: hybridLeftNavigation,
            });
          } else if (!isEmpty(leftNavigation)) {
            dispatch({
              type: NavTypes.RESOLVED_LEFTNAV_CONTENT,
              payload: leftNavigation,
            });
          }
        } else if (!isEmpty(leftNavigation)) {
          dispatch({
            type: NavTypes.RESOLVED_LEFTNAV_CONTENT,
            payload: leftNavigation,
          });
        }

        if (!isEmpty(ecmContent)) {
          if (templateType === 'Silo') {
            dispatch({
              type: `RESOLVED_ECM${ECMSlots.PLP_BANNER.contentId}`,
              payload: formatSiloBannerEcmForDispatch(ecmContent),
              contentId: ECMSlots.PLP_BANNER.contentId,
            });
            dispatch({
              type: `RESOLVED_ECM${ECMSlots.SILO_FULL_ASSET.contentId}`,
              payload: formatSiloMainAssetEcmForDispatch(ecmContent),
              contentId: ECMSlots.SILO_FULL_ASSET.contentId,
            });
          } else {
            dispatch({
              type: `RESOLVED_ECM${ECMSlots.PLP_BANNER.contentId}`,
              payload: ecmContent,
              contentId: ECMSlots.PLP_BANNER.contentId,
            });
          }
        }

        if (templateType === 'Silo') {
          const { thumbnails } = get(res.data, 'categoryTemplate.templateAttributes.dynamicContent', []);
          dispatch({
            type: TemplateActionTypes.SET_SILO_THUMBNAIL_DATA,
            payload: thumbnails,
          });
        }

        if (templateType === 'P') {
          dispatch(setPageId(PAGE_ID_PLP));
          const productListing = get(res.data, 'categoryTemplate.templateAttributes.dynamicContent.productListing', {});
          if (!isEmpty(productListing)) {
            const tabsInSizeFacet = get(res.data, 'categoryTemplate.tabsInSizeFacet', false);

            dispatch({
              type: RESOLVED_PRODUCT_LIST,
              payload: {
                ...productListing, tabsInSizeFacet, page,
              },
              ftrTgls: {
                dprToggle: state.toggles.CLOUDINARY_DPR,
              },
            });
            dispatch({
              type: ProductListType.SET_FILTER_OPTIONS,
              selectedFilterOptions: isEmpty(filterOptions)
                ? 'No filter options selected' : JSON.parse(filterOptions),
            });
            const seoFirstParam = state?.facetedLeftNav?.firstParam;
            const seoSecondParam = state?.facetedLeftNav?.secondParam;
            const blockCanonical = state?.facetedLeftNav?.blockCanonical;
            if (isEmpty(seoFirstParam) && isEmpty(seoSecondParam) && !blockCanonical) {
              dispatch({
                type: SET_CANONICAL_URL,
                canonicalUrl: productListing.seo.canonicalUrl ? productListing.seo.canonicalUrl : '',
              });
            }
            dispatch({
              type: ProductListType.SET_SORT_BY,
              sortBy: productListing.selectedSortOption,
            });
            dispatch({ type: VALID_CATEGORY });
            dispatch({
              type: SET_PRODUCT_LIST_DATA_TO_UTAG,
              payload: {
                total: productListing.total,
                templateType: productListing.templateType,
                category: currentCategory,
                cmosCatalog: map(productListing.products, 'cmosCatalog'),
                cmosItem: map(productListing.products, 'cmosItem'),
                sortBy: productListing.selectedSortOption,
                page,
                facetOptions: getUtagFiltersData(selectedFilters),
                previousViewFacetSubmit: isFilterOptionsChanged(selectedFilters),
                previousViewSortSubmit: isSortOptionChanged(sortBy),
                facetFlag: true,
                facetEligible: true,
                plpType: getPlpType(productListing.displayAsGroups),
                pcsEnabled: !excludeFromPCS,
              },
            });
          } else {
            dispatch({ type: REJECTED_PRODUCT_LIST });
          }
        }

        ['promotiles', 'categoryHeader', ECMSlots.SITE_TICKER.contentId, 'bottomBanner'].forEach((ecmContentKey) => {
          let contentId = ecmContentKey;
          if (ecmContentKey === 'categoryHeader') {
            contentId = ECMSlots.PLP_BANNER.contentId;
          }
          if (ecmContentKey === 'bottomBanner') {
            contentId = ECMSlots.PLP_BANNER_BOTTOM.contentId;
          }
          const ecmContent = get(res.data, `ecmContent.${ecmContentKey}`);
          if (!isEmpty(ecmContent)) {
            if (ecmContentKey === 'promotiles') {
              if (ecmContent.promotile) {
                dispatch({
                  type: 'LOADING_ecmplpPromoTile',
                });
                dispatch({

                  type: 'RESOLVED_ecmplpPromoTile',
                  payload: {},
                  contentId: ECMSlots.PLP_PROMO_TILES.contentId,
                  headers,
                });
                ecmContent.promotile.forEach((slot, index) => {
                  dispatch({
                    type: `RESOLVED_ECM${ECMSlots[ECMSlots.PLP_PROMO_TILES.slots[index]].contentId}`,
                    payload: slot,
                    contentId: `${ECMSlots[ECMSlots.PLP_PROMO_TILES.slots[index]].contentId}`,
                    headers,
                  });
                });
              }
            } else {
              dispatch({
                type: `RESOLVED_ECM${contentId}`,
                payload: ecmContent,
                contentId,
                headers,
              });
            }
          } else if (has(res.data.ecmContent, ecmContentKey)) {
            dispatch({
              type: `RESOLVED_ECM${contentId}`,
              payload: {},
              contentId,
              headers,
            });
          }
        });

        if (isEmpty(get(res.data, 'commonNavigation'))) {
          const brand = state.brand_name?.env;
          dispatch({ type: types.REJECTED_NAVIGATION, payload: brand === 'HC' ? hcNavigation.silos : navigation.silos });
        }
        const { countryCode, localeUrl } = getCountryCodeAndLocaleUrl(state);
        if (!isEmpty(get(res.data, 'commonNavigation.desktopNav.silos'))) {
          dispatch({
            type: types.RESOLVED_NAVIGATION,
            payload: transformSilosByCountryCode(get(res.data, 'commonNavigation.desktopNav.silos'),
              countryCode,
              localeUrl),
          });
        }

        if (!isEmpty(get(res.data, 'commonNavigation.mobileNavInitial.silos'))) {
          const mobileSilos = loadNewDesignerIndexSilo(get(res.data, 'commonNavigation.mobileNavInitial.silos'));

          const transformedSilos = transformSilosByCountryCode(mobileSilos,
            countryCode,
            localeUrl);

          const navPath = get(state, 'page.location.query.navpath', null);
          const defaultPath = get(res, 'data.categoryTemplate.defaultPath', null);
          let initialContextMenuPath;

          if (state.toggles.HOLDING_CONTEXT) {
            initialContextMenuPath = constructInitialContextMenuPath(
              transformedSilos,
              navPath,
              defaultPath
            );
          }

          dispatch({
            type: types.RESOLVED_NAVIGATION_MOBILE,
            payload: {
              silos: transformedSilos,
              serverNavigation: enableDynamicNavigation && state.device.isServer,
              ...initialContextMenuPath && { path: initialContextMenuPath },
            },
          });
        } else if (deviceType !== 'D') {
          const brand = state.brand_name?.env;
          dispatch({ type: types.REJECTED_NAVIGATION_MOBILE, payload: brand === 'HC' ? hcNavigation : navigation });
        }

        dispatch({ type: RESOLVED_BRAND_LINKS, payload: get(res.data, 'commonNavigation.brandLinks') });

        logger.info(`Received CRP-SVC response for the api call from NMO-ui for category id: ${res.data.categoryTemplate.id}. \n JSESSIONID: ${state.session.JSESSIONID} - Date on response headers: ${get(res.headers, 'date', 'No date')}`);
      })
      .catch((e) => {
        dispatch(rejectedActions(e));
      });
  };
}
