export const ADOBE_QUERY_PARAMS = [
  'at_preview_token',
  'at_preview_index',
  'at_preview_listed_activities_only',
  'at_preview_evaluate_as_true_audience_ids',
  'isMobile',
  'isTablet',
  'isMobilePhone',
  'isDesktop',
  'browser',
  'version',
  'referer',
];

export function setAdobeDebugCookies(request, response) {
  ADOBE_QUERY_PARAMS.forEach((param) => {
    if (request.query[param]) {
      request.cookies[param] = request.query[param];
      response.cookie(param, request.query[param], { expires: 0, path: '/' });
    }
  });
}
