import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Button.scss';

const cx = classNames.bind(styles);

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
};

const defaultProps = {
  children: null,
  className: '',
  uiComponent: 'Button',
  testID: undefined,
  variant: 'primary',
  disabled: false,
};

const Button = ({
  children,
  className,
  uiComponent,
  testID,
  variant,
  disabled,
  ...props
}) => {
  const classList = cx({
    Button: true,
    'Button--primary': variant === 'primary' && !disabled,
    'Button--secondary': variant === 'secondary' && !disabled,
    'Button--disabled': disabled === true,
  }, [className].join(' ').trim().replace(/\s+/g, ' '));
  return (
    <button
      type="button"
      className={classList}
      {...props}
      data-ui-component={uiComponent}
      data-tid={testID}
    >
      {children}
    </button>
  );
};

Button.displayName = 'Button';
Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
