import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import { PRECIOUS_JEWELRY_PAYPAL_MESSAGE, REQUIRED_MONOGRAM } from 'pdp/constants';
import ProductOptionSet from 'pdp/components/ProductOptionSet/productOptionSet';
import ProductQuantity from 'pdp/components/ProductQuantity/productQuantity';
import BopsButton from 'pdp/components/BopsButton/bopsButton';
import Appointments from 'pdp/components/Appointments/Appointments';
import GiftNow from 'pdp/components/GiftNow/giftNow';
import BossMessage from 'pdp/components/BossMessage/bossMessage';
import AddToBagButton from 'pdp/components/AddToBagButton/addToBagButton';
import SkuStatusMessages from 'pdp/components/SkuStatusMessages/skuStatusMessages';
import PerishableMessage from 'pdp/components/Perishables/perishableMessage';
import BeautyReplenishment from 'pdp/components/BeautyReplenishment/beautyReplenishment';
import Personalization from 'pdp/components/Monogram/personalization';
import { getSelectedOptionMedia } from 'pdp/pages/ProductPage/selectors/getMedia';
import getSelectedColorIndex from 'pdp/pages/ProductPage/selectors/getSelectedColorIndex';
import getSelectedBuckleFinishIndex from 'pdp/pages/ProductPage/selectors/getSelectedBuckleFinishIndex';
import getColorSkus from 'pdp/pages/ProductPage/selectors/getColorSkus';
import getSizeSkus from 'pdp/pages/ProductPage/selectors/getSizeSkus';
import {
  setHoveredIndex as setHoveredIndexAction,
  setSelectedIndex as setSelectedIndexAction,
  setReplenishInterval as setReplenishIntervalAction,
  openReplenishment as openReplenishmentAction,
} from 'pdp/pages/ProductPage/actions';
import { getColorOptions, getSizeOptions } from 'pdp/pages/ProductPage/selectors/getOptions';
import getSelectedSku from 'pdp/pages/ProductPage/selectors/getSelectedSku';
import Loadable from 'react-loadable';
import PersonalizeItemButton from '../PersonalizeItemButton/PersonalizeItemButton';

const PerishableCalendar = Loadable({
  loader: () => import(/* webpackChunkName: 'perishable-calendar' */ 'pdp/components/Perishables/perishableCalendar'),
  loading: () => false,
});

const CheckoutOptionInfo = ({
  boss,
  giftNowToggle = false,
  product = {},
  setSelectedIndex,
  setHoveredIndex,
  setReplenishInterval,
  openReplenishment,
  suppressBOPSForParentheticalToggle,
  bopsButtonToggle = false,
  showAppointments = false,
  isGroupPDP = false,
  isDomestic,
  ...props
}) => {
  const behaviorCode = get(product, 'customization.behaviorCode');
  const colorOptions = getColorOptions(product);
  const sizeOptions = getSizeOptions(product);
  const selectedColorIndex = getSelectedColorIndex(product);
  const selectedBuckleFinishIndex = getSelectedBuckleFinishIndex(product);
  const selectedOptionMedia = getSelectedOptionMedia(product);
  const selectedSizeIndex = get(product, 'options.selectedSizeIndex', -1);
  const colorSkus = getColorSkus(product, selectedSizeIndex);
  const sizeSkus = getSizeSkus(product, selectedColorIndex);
  const noAvailableSizeSelected = selectedSizeIndex > 0 && isEmpty(colorSkus);
  const appendKey = 'productButtons';

  const selectedSku = getSelectedSku(
    { colorSkus, sizeSkus },
    { colorOptions, sizeOptions },
    { selectedColorIndex, selectedSizeIndex }
  );
  const hoveredColorIndex = get(product, 'options.hoveredColorIndex', -1);
  const hoveredSizeIndex = get(product, 'options.hoveredSizeIndex', -1);
  const hoveredBuckleIndex = get(product, 'options.hoveredBuckleFinishIndex', -1);
  const { details = {} } = product;
  const { parentheticalCharge = {} } = details;

  const isPreciousJewelry = () => get(product, 'details.preciousJewelryItem', false);
  const isRequiredMonogram = () => behaviorCode === REQUIRED_MONOGRAM;
  const isPersonalized = () => product.isPersonalizationSelected;

  const isCustomizationOptionTypePDP = () => {
    let isCustomOptionPDP = false;
    if (product.customization && product.customization.customizationOptions) {
      isCustomOptionPDP = true;
      product.customization.customizationOptions.forEach((element) => {
        if (element.type !== 'PDP') {
          isCustomOptionPDP = false;
        }
      });
    }
    return isCustomOptionPDP;
  };

  const isRequiredMonogramNotCustomized = () => (
    product.customizationSupported
    && isRequiredMonogram()
    && !isPersonalized()
  );

  const shouldShowPersonalizeSection = () => (
    product.customizationSupported
    && !isRequiredMonogramNotCustomized()
  );

  const addToBagButton = (
    <AddToBagButton
      productId={product.id}
      selectedSku={selectedSku}
      customizationOptionTypePDP={isCustomizationOptionTypePDP()}
      skuMediaUrl={get(selectedOptionMedia, 'main.thumbnail.url', '')}
      isGroupPDP={isGroupPDP}
    />
  );

  const giftNowButton = (
    <GiftNow
      product={product}
      selectedSku={selectedSku}
    />
  );

  const getBuckleFinishChoices = () => {
    const customizationOptions = [];
    if (product.customization
      && product.customization.customizationOptions
    ) {
      product.customization.customizationOptions.forEach((element) => {
        customizationOptions.push(element.choices);
      });
    }
    return flatten(customizationOptions);
  };

  const optionType = get(product, 'customization.customizationOptions[0].type', {});

  const disableGiftNow = (
    giftNowToggle
    && !product.isChanel
    && !details.suppressCheckout
    && !product.perishable
    && !product.isZeroDollarProduct
  );

  return (
    <div className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent" {...props}>
      <div className="grid-100 tablet-grid-100 mobile-grid-100 gutter-bottom-half">
        <ProductOptionSet
          productId={product.id}
          labelHeading="Select"
          label="size"
          options={sizeOptions.values}
          selectedIndex={selectedSizeIndex}
          availableSkus={sizeSkus}
          onClick={setSelectedIndex}
          hoveredIndex={hoveredSizeIndex}
          onHover={setHoveredIndex}
          sizeGuide={details.sizeGuide}
        />
        <ProductOptionSet
          productId={product.id}
          labelHeading="Select"
          label="color"
          options={colorOptions.values}
          selectedIndex={selectedColorIndex}
          availableSkus={colorSkus}
          onClick={setSelectedIndex}
          hoveredIndex={hoveredColorIndex}
          onHover={setHoveredIndex}
          noAvailableSizeSelected={noAvailableSizeSelected}
        />
        {isCustomizationOptionTypePDP() && (
          <ProductOptionSet
            productId={product.id}
            labelHeading="Select"
            label="buckle finish"
            options={getBuckleFinishChoices()}
            selectedIndex={selectedBuckleFinishIndex}
            onClick={setSelectedIndex}
            hoveredIndex={hoveredBuckleIndex}
            onHover={setHoveredIndex}
            noAvailableSizeSelected={noAvailableSizeSelected}
            optionType={optionType}
          />
        )}
      </div>
      {product.replenishable && (
        <BeautyReplenishment
          replenishInterval={product.replenishInterval}
          onIntervalSelect={setReplenishInterval}
          openReplenishmentModal={openReplenishment}
        />
      ) }
      {shouldShowPersonalizeSection() && !isCustomizationOptionTypePDP() && (
        <Personalization
          product={product}
          selectedSku={selectedSku}
        />
      )}
      <div className="grid-100 tablet-grid-100 mobile-grid-100">
        <ProductQuantity productId={product.id} />
      </div>
      <div className="messages grid-100 tablet-grid-100 mobile-grid-100">
        <SkuStatusMessages
          selectedSku={selectedSku}
          perishable={product.perishable}
          selectedSkuQuantity={product.quantity}
          preciousJewelryItem={isPreciousJewelry()}
          isChanel={product.isChanel}
          merchandiseType={product.merchandiseType}
        />
      </div>
      {product.perishable && (
        <div className="grid-100 tablet-grid-100 mobile-grid-100">
          <PerishableMessage perishable={product.perishable} />
          <PerishableCalendar
            productId={product.id}
            expectedShipDays={get(selectedSku, 'expectedShipDays', null)}
          />
        </div>
      )}
      <div className="grid-100 tablet-grid-100 mobile-grid-100">
        <BossMessage
          selectedSku={selectedSku}
          startDate={boss.startDate}
          endDate={boss.endDate}
          message={boss.message}
        />
      </div>
      <div
        id={`productButtons_${product.id}`}
        className="tablet-grid-100 mobile-grid-100 gutter-bottom-half"
      >
        {isPreciousJewelry() && (
          <span className="precious-jewelry-message">
            {PRECIOUS_JEWELRY_PAYPAL_MESSAGE}
          </span>
        )}
        {isRequiredMonogramNotCustomized() && !isCustomizationOptionTypePDP()
          ? (
            <PersonalizeItemButton
              product={product}
              selectedSku={selectedSku}
            />
          )
          : addToBagButton
        }
        {!bopsButtonToggle
          && (suppressBOPSForParentheticalToggle
            ? parentheticalCharge <= 0
            : true
          ) && (
            <div id={product.id + appendKey} className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
              <BopsButton
                product={product}
                selectedSku={selectedSku}
                skuMediaUrl={get(selectedOptionMedia, 'main.thumbnail.url', '')}
              />
            </div>
        )}
        {isDomestic && showAppointments && <Appointments />}
        {!isRequiredMonogram() && !disableGiftNow && giftNowButton}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { toggles = {} } = state;
  return {
    suppressBOPSForParentheticalToggle: toggles.SUPPRESS_BOPS_FOR_PARENTHETICAL,
    giftNowToggle: toggles.DISABLE_GIFT_NOW,
    bopsButtonToggle: toggles.DISABLE_BOPS_BUTTON,
    isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
  };
};

const mapDispatchToProps = (dispatch, { product: { id: productId } }) => ({
  setSelectedIndex: (...args) => (
    dispatch(setSelectedIndexAction(productId, ...args))
  ),
  setHoveredIndex: (...args) => (
    dispatch(setHoveredIndexAction(productId, ...args))
  ),
  setReplenishInterval: (...args) => (
    dispatch(setReplenishIntervalAction(productId, ...args))
  ),
  openReplenishment: () => (
    dispatch(openReplenishmentAction())
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutOptionInfo);
