import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Paragraph.scss';

const cx = classNames.bind(styles);

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  className: undefined,
  uiComponent: 'Paragraph',
  testID: undefined,
};

const Paragraph = ({
  children,
  className,
  uiComponent,
  testID,
  ...props
}) => {
  const classList = cx(['Paragraph', className].join(' ').trim().replace(/\s+/g, ' '));
  return (
    <p
      className={classList}
      {...props}
      data-ui-component={uiComponent}
      data-tid={testID}
    >
      {children}
    </p>
  );
};

Paragraph.displayName = 'Paragraph';
Paragraph.propTypes = propTypes;
Paragraph.defaultProps = defaultProps;

export default Paragraph;
