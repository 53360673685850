import {
  HC_SET_TYPEAHEAD_RESULT, HC_REJECTED_SEARCH,
} from './search-actions';

const defaultState = {
  typeaheadPayload: {},
  searchURL: NMConfig.API_ELASTIC_SEARCH,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case HC_SET_TYPEAHEAD_RESULT: {
      return {
        ...state,
        typeaheadPayload: action.payload,
      };
    }
    case HC_REJECTED_SEARCH: {
      return {
        ...state,
        typeaheadPayload: {},
      };
    }
    default: {
      return state;
    }
  }
};
