export const LOADING_BRAND_LINKS = 'LOADING_BRAND_LINKS';
export const RESOLVED_BRAND_LINKS = 'RESOLVED_BRAND_LINKS';
export const REJECTED_BRAND_LINKS = 'REJECTED_BRAND_LINKS';

export function getBrandLinksOptimized() {
  return (dispatch) => {
    dispatch({ type: LOADING_BRAND_LINKS });
    if (global.sistersitesContentObj !== undefined && global.sistersitesContentObj !== '' && Object.keys(global.sistersitesContentObj).length !== 0) {
      return dispatch({ type: RESOLVED_BRAND_LINKS, payload: global.sistersitesContentObj },
        () => {});
    }
    return dispatch({ type: REJECTED_BRAND_LINKS });
  };
}
