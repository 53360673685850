import log from 'server/utilities/logger';
import httpWithLogging from 'universal/http-client';
import { getSiteMapData } from 'server/utilities/decrypter';

export const LOADING_SITEMAP_DATA = 'LOADING_SITEMAP_DATA';
export const RESOLVED_SITEMAP_DATA = 'RESOLVED_SITEMAP_DATA';
export const REJECTED_SITEMAP_DATA = 'REJECTED_SITEMAP_DATA';

const getDataFromS3 = (requestApi) => {
  return IS_CLIENT ? requestApi.get('/dt/sitemap') : getSiteMapData();
};

export function getSiteMapS3Data() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: LOADING_SITEMAP_DATA });
    const requestApi = httpWithLogging(state);
    getDataFromS3(requestApi)
      .then((res) => {
        dispatch({
          type: RESOLVED_SITEMAP_DATA,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: REJECTED_SITEMAP_DATA,
        });
        log.error(`Error getting sitemap data: ${error}`);
      });
  };
}
