import isEmpty from 'lodash/isEmpty';

export default (skus, options, selectedIndexes) => {
  const {
    colorSkus,
    sizeSkus,
  } = skus;

  const {
    colorOptions,
    sizeOptions,
  } = options;

  const {
    selectedColorIndex,
    selectedSizeIndex,
  } = selectedIndexes;

  let selectedSku;
  if (!isEmpty(colorOptions.values) && !isEmpty(colorSkus)) {
    selectedSku = colorSkus[selectedColorIndex];
  } else if (!isEmpty(sizeOptions.values) && !isEmpty(sizeSkus)) {
    selectedSku = sizeSkus[selectedSizeIndex];
  } else if (colorSkus.length === sizeSkus.length) {
    [selectedSku] = colorSkus;
  }

  return selectedSku;
};
