import React from 'react';

const ItemNotAvailable = ({ children }) => (
  <div className="undisplayable grid-100 tablet-grid-100 mobile-grid-100">
    <span className="undisplayable__message">This item is not available.</span>
    {children}
  </div>
);

export default ItemNotAvailable;
