import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { usertimingMiddleware } from './middleware/performance/usertimings';
import errorInterceptorMiddleware from './middleware/middleware-error-interceptor';
import analytics from './middleware/analytics/analytics';
import outfittingMiddleware from './middleware/outfittingMiddleware';
import reducers from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default {
  createStore: (state = {}, history = {}, middlewares = []) => {
    const router = routerMiddleware(history);
    const middleware = composeEnhancers(
      applyMiddleware(
        thunk,
        router,
        errorInterceptorMiddleware(),
        usertimingMiddleware,
        outfittingMiddleware,
        analytics,
        ...middlewares
      ),
    );
    return createStore(reducers, state, middleware);
  },
};
