export const HCDesktopFallback = [
  {
    metadata: {
      tags: [],
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: '2kqx4czmzra1',
        },
      },
      id: '2cTxDniRy1f3ijnOq5QUPy',
      type: 'Entry',
      createdAt: '2022-07-27T15:20:42.688Z',
      updatedAt: '2022-07-27T15:20:42.688Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment',
        },
      },
      revision: 1,
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'frame',
        },
      },
      locale: 'en-US',
    },
    fields: {
      name: 'Horchow Homepage: Fallback',
      metaData: {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: '5Dr2sqqlX4WQ3mEwInHku3',
        },
        contentType: 'metaData',
        fields: {
          name: 'Horchow Homepage: Fallback',
          slugs: [
            '/horchow-evergreen',
            '/editorial/homepage-test',
          ],
        },
      },
      brand: 'HC',
      channel: [
        'Desktop',
      ],
      l1Layouts: [
        {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: '5ZSxISc3Y1LSDV4QV8UtqK',
          },
          contentType: 'l1Layout',
          fields: {
            name: 'Horchow Homepage: Fallback',
            placement: 'Main',
            desktopWidth: '96',
            mobileWidth: '96',
            l2Layout: [
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '2vApyPrwavvyWsxyP14ict',
                },
                contentType: 'l2Layout',
                fields: {
                  name: 'Horchow Homepage: Fallback Row 1',
                  components: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '7zA0JK1uo9t1bfrFcaUSti',
                      },
                      contentType: 'textOverImageContentAsset',
                      fields: {
                        name: 'Horchow Homepage: Fallback Row 1',
                        image: {
                          sys: {
                            type: 'Link',
                            linkType: 'Entry',
                            id: '3zwI11YNFpwFcYRtuWpJBY',
                          },
                          contentType: 'imageContentAsset',
                          fields: {
                            name: 'Horchow Homepage: Fallback Row 1',
                            parallaxShiftZoomBehavior: false,
                            desktopImage: [
                              {
                                url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,h_600,w_1600/f_auto/q_auto:good/v1657217313/content/creative/01B_0722ED_HOME_062822.tiff',
                                tags: [],
                                type: 'upload',
                                bytes: 26663638,
                                width: 5877,
                                format: 'tiff',
                                height: 2939,
                                context: {
                                  custom: {
                                    alt: 'Living Room',
                                    Vendors: 'John-Richard Collection|Four Hands Art Studio|Triever|RFA Fine Art|Exquisite Rugs',
                                    caption: 'JULY EDITORIALS',
                                    'Art Director': 'Jay Williams',
                                    'Digital Tech': 'Karl Ludwig',
                                    Photographer: 'Matt Savins',
                                  },
                                },
                                version: 1657217313,
                                duration: null,
                                metadata: {
                                  brands: [
                                    'o6ubpongsfhteehpxwlf',
                                    'tapl0bs3opp8ukcvf95s',
                                  ],
                                  asset_type: 'uymo684qvl8awfg52vni',
                                  categories: [
                                    'bwipmsmf95wk7stu76dc',
                                  ],
                                  shoot_date: '2022-06-28',
                                  composition: 'jkvyc553vnyzofslenmd',
                                  star_rating: 'wkkermich0rjr7exzmar',
                                  campaign_types: [
                                    '0001',
                                  ],
                                  retouch_status: 'bn1hqe9a728lqnw9tplt',
                                  expiration_date: '2023-06-28',
                                },
                                public_id: 'content/creative/01B_0722ED_HOME_062822',
                                created_at: '2022-07-07T18:08:33Z',
                                secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,h_600,w_1600/f_auto/q_auto:good/v1657217313/content/creative/01B_0722ED_HOME_062822.tiff',
                                original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1657217313/content/creative/01B_0722ED_HOME_062822.tiff',
                                resource_type: 'image',
                                raw_transformation: 'c_fill,h_600,w_1600/f_auto/q_auto:good',
                                original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1657217313/content/creative/01B_0722ED_HOME_062822.tiff',
                              },
                            ],
                            mobileImage: [
                              {
                                url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_425,w_414/f_auto/q_auto:good/v1657217221/content/creative/01A_0722ED_HOME_062822.tiff',
                                tags: [],
                                type: 'upload',
                                bytes: 30873582,
                                width: 4143,
                                format: 'tiff',
                                height: 5179,
                                context: {
                                  custom: {
                                    alt: 'Living Room',
                                    Vendors: 'John-Richard Collection',
                                    caption: 'JULY EDITORIALS',
                                    'Art Director': 'Jay Williams',
                                    'Digital Tech': 'Daniel Mendenhall',
                                    Photographer: 'Karl Ludwig',
                                  },
                                },
                                version: 1657217221,
                                duration: null,
                                metadata: {
                                  brands: [
                                    'o6ubpongsfhteehpxwlf',
                                    'tapl0bs3opp8ukcvf95s',
                                  ],
                                  asset_type: 'uymo684qvl8awfg52vni',
                                  categories: [
                                    'bwipmsmf95wk7stu76dc',
                                  ],
                                  shoot_date: '2022-06-28',
                                  composition: 'jkvyc553vnyzofslenmd',
                                  star_rating: 'wkkermich0rjr7exzmar',
                                  campaign_types: [
                                    '0001',
                                  ],
                                  retouch_status: 'bn1hqe9a728lqnw9tplt',
                                  expiration_date: '2023-06-28',
                                },
                                public_id: 'content/creative/01A_0722ED_HOME_062822',
                                created_at: '2022-07-07T18:07:01Z',
                                secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,h_425,w_414/f_auto/q_auto:good/v1657217221/content/creative/01A_0722ED_HOME_062822.tiff',
                                original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1657217221/content/creative/01A_0722ED_HOME_062822.tiff',
                                resource_type: 'image',
                                raw_transformation: 'c_fill,dpr_2.0,h_425,w_414/f_auto/q_auto:good',
                                original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1657217221/content/creative/01A_0722ED_HOME_062822.tiff',
                              },
                            ],
                            linkTo: 'https://www.horchow.com/c/Furniture/All-Furniture-cat20260733',
                          },
                        },
                        text: {
                          sys: {
                            type: 'Link',
                            linkType: 'Entry',
                            id: '18dNCIv7v0jpdwUHhogObz',
                          },
                          contentType: 'richTextContentAsset',
                          fields: {
                            name: 'Horchow Homepage: Fallback Row 1',
                            desktopText: {
                              data: {},
                              content: [
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: 'BIG IDEAS',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'heading-4',
                                },
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: '',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'paragraph',
                                },
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: 'Whether you’re looking for a statement-making anchor piece or a handy side table, we have the furniture solutions—and inspiration—your rooms need',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'paragraph',
                                },
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: '',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'paragraph',
                                },
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: '',
                                      nodeType: 'text',
                                    },
                                    {
                                      data: {
                                        uri: 'https://www.horchow.com/c/Furniture/All-Furniture-cat20260733',
                                      },
                                      content: [
                                        {
                                          data: {},
                                          marks: [],
                                          value: 'SHOP FURNITURE',
                                          nodeType: 'text',
                                        },
                                      ],
                                      nodeType: 'hyperlink',
                                    },
                                    {
                                      data: {},
                                      marks: [],
                                      value: '',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'paragraph',
                                },
                              ],
                              nodeType: 'document',
                            },
                            mobileText: {
                              data: {},
                              content: [
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: 'BIG IDEAS',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'heading-4',
                                },
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: '',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'paragraph',
                                },
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: 'Whether you’re looking for a statement-making anchor piece or a handy side table, we have the furniture solutions—and inspiration—your rooms need',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'paragraph',
                                },
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: '',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'paragraph',
                                },
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: '',
                                      nodeType: 'text',
                                    },
                                    {
                                      data: {
                                        uri: 'https://www.horchow.com/c/Furniture/All-Furniture-cat20260733',
                                      },
                                      content: [
                                        {
                                          data: {},
                                          marks: [],
                                          value: 'SHOP FURNITURE',
                                          nodeType: 'text',
                                        },
                                      ],
                                      nodeType: 'hyperlink',
                                    },
                                    {
                                      data: {},
                                      marks: [],
                                      value: '',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'paragraph',
                                },
                              ],
                              nodeType: 'document',
                            },
                            textJustification: 'Center',
                            backgroundColor: 'rgba(255,255,255,.9)',
                            textPadding: 15,
                            buttonStyle: 'underlined',
                          },
                        },
                        desktopTextPosition: 'Middle Center',
                        desktopTextWidth: 45,
                      },
                    },
                  ],
                },
              },
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '52K0thPyhN470ajhiTCDTa',
                },
                contentType: 'l2Layout',
                fields: {
                  name: 'Horchow: Homepage Row 2',
                  desktopWidth: '80',
                  l3Layout: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '6NZ8iCYUzbXwfEJTFLGqLD',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Horchow: Homepage Row 2 Header',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '2xQsyOPObKC7d28zD1iXKX',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: 'Horchow: Homepage Row 2 Header',
                              desktopText: {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: 'SHOP FEATURED CATEGORIES',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'heading-5',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                ],
                                nodeType: 'document',
                              },
                              textJustification: 'Center',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '68E7cKGVYcoAZRngLvZa0X',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'HC Homepage Row 2: Living Room',
                        desktopWidth: '20',
                        mobileWidth: '40',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3LQXznufHlJmLIub8sHniX',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'HC Homepage Row 2: Living Room',
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_190,w_190/f_auto/q_auto:good/v1696867522/content/marketing/Quicklinks_LivingRoom_101023.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 20291,
                                  width: 243,
                                  format: 'jpg',
                                  height: 243,
                                  version: 1696867522,
                                  duration: null,
                                  metadata: {
                                    asset_type: '0001',
                                    retouch_status: '0001',
                                    star_rating: '0001',
                                    brands: [
                                      '0001',
                                    ],
                                  },
                                  public_id: 'content/marketing/Quicklinks_LivingRoom_101023',
                                  created_at: '2023-10-09T16:05:22Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_190,w_190/f_auto/q_auto:good/v1696867522/content/marketing/Quicklinks_LivingRoom_101023.jpg',
                                  resource_type: 'image',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1696867522/content/marketing/Quicklinks_LivingRoom_101023.jpg',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1696867522/content/marketing/Quicklinks_LivingRoom_101023.jpg',
                                  raw_transformation: 'c_scale,h_190,w_190/f_auto/q_auto:good',
                                },
                              ],
                              linkTo: 'https://www.horchow.com/c/furniture-living-room-cat16060742',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: 'W78iG79LKSqH8MUxWjkN5',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: 'HC Homepage Row 2: Living Room',
                              desktopText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [
                                          {
                                            type: 'bold',
                                          },
                                        ],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.horchow.com/c/furniture-living-room-cat16060742',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'LIVING ROOM',
                                            marks: [
                                              {
                                                type: 'bold',
                                              },
                                            ],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              buttonStyle: 'underlined',
                            },
                          },
                        ],
                        horizontalSpacingDesktop: '64',
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4HJoGZW2MRUwpI7KTpND3W',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Living Room',
                              code: 'LR',
                              type: 'Category',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '1QWPQjkqV4EIq4pOO8SZdO',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'HC Homepage Row 2: WALL ART',
                        desktopWidth: '20',
                        mobileWidth: '40',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '39VbbYEUu9HQ3baAnXaJDF',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'HC Homepage Row 2: WALL ART',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_190,w_190/f_auto/q_auto:good/v1696867522/content/marketing/Quicklinks_WallArt_101023.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 38957,
                                  width: 243,
                                  format: 'jpg',
                                  height: 243,
                                  version: 1696867522,
                                  duration: null,
                                  metadata: {
                                    asset_type: '0001',
                                    brands: [
                                      '0001',
                                    ],
                                    retouch_status: '0001',
                                    star_rating: '0001',
                                  },
                                  public_id: 'content/marketing/Quicklinks_WallArt_101023',
                                  created_at: '2023-10-09T16:05:22Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_190,w_190/f_auto/q_auto:good/v1696867522/content/marketing/Quicklinks_WallArt_101023.jpg',
                                  resource_type: 'image',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1696867522/content/marketing/Quicklinks_WallArt_101023.jpg',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1696867522/content/marketing/Quicklinks_WallArt_101023.jpg',
                                  raw_transformation: 'c_scale,h_190,w_190/f_auto/q_auto:good',
                                },
                              ],
                              linkTo: 'https://www.horchow.com/c/decor-wall-accents-wall-art-cat000094',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3Tdfq3nrDHAGzvlYcuZ7LR',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: 'HC Homepage Row 2: WALL ART',
                              desktopText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [
                                          {
                                            type: 'bold',
                                          },
                                        ],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.horchow.com/c/decor-wall-accents-wall-art-cat000094',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'WALL ART',
                                            marks: [
                                              {
                                                type: 'bold',
                                              },
                                            ],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              buttonStyle: 'underlined',
                            },
                          },
                        ],
                        horizontalSpacingDesktop: '64',
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '1Goi6U0mANmpsWXD31DTyZ',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Wall Art',
                              code: 'AR',
                              type: 'Category',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '15VBRfNeOxmAsi7bhjmYy7',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'HC Homepage Row 2: MIRRORS ',
                        desktopWidth: '20',
                        mobileWidth: '40',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5hmV1i4uTd2eVr9c7b1LIa',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'HC Homepage Row 2: MIRRORS ',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_190,w_190/f_auto/q_auto:good/v1696867522/content/marketing/Quciklinks_Mirros_101023.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 22848,
                                  width: 243,
                                  format: 'jpg',
                                  height: 243,
                                  version: 1696867522,
                                  duration: null,
                                  metadata: {
                                    asset_type: '0001',
                                    brands: [
                                      '0001',
                                    ],
                                    retouch_status: '0001',
                                    star_rating: '0001',
                                  },
                                  public_id: 'content/marketing/Quciklinks_Mirros_101023',
                                  created_at: '2023-10-09T16:05:22Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_190,w_190/f_auto/q_auto:good/v1696867522/content/marketing/Quciklinks_Mirros_101023.jpg',
                                  resource_type: 'image',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1696867522/content/marketing/Quciklinks_Mirros_101023.jpg',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1696867522/content/marketing/Quciklinks_Mirros_101023.jpg',
                                  raw_transformation: 'c_scale,h_190,w_190/f_auto/q_auto:good',
                                },
                              ],
                              linkTo: 'https://www.horchow.com/c/decor-wall-accents-mirrors-cat000092',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '39UT5g5x65rxbB7c1V6vJt',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: 'HC Homepage Row 2: MIRRORS',
                              desktopText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [
                                          {
                                            type: 'bold',
                                          },
                                        ],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.horchow.com/c/decor-wall-accents-mirrors-cat000092',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'MIRRORS',
                                            marks: [
                                              {
                                                type: 'bold',
                                              },
                                            ],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              buttonStyle: 'underlined',
                            },
                          },
                        ],
                        horizontalSpacingDesktop: '64',
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '63KBEz0OL736CzNDo0XyBr',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Mirrors',
                              code: 'MI',
                              type: 'Category',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '4rH2YqP2ab5QSr6AMnah7y',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'HC Homepage Row 2: Home Accents',
                        desktopWidth: '20',
                        mobileWidth: '40',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '012eYqJ4KveGBdizhFAz4x',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'HC Homepage Row 2: Home Accents',
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_190,w_190/f_auto/q_auto:good/v1696867522/content/marketing/Quicklinks_HomeAccents_101023.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 20444,
                                  width: 243,
                                  format: 'jpg',
                                  height: 243,
                                  version: 1696867522,
                                  duration: null,
                                  metadata: {
                                    asset_type: '0001',
                                    brands: [
                                      '0001',
                                    ],
                                    retouch_status: '0001',
                                    star_rating: '0001',
                                  },
                                  public_id: 'content/marketing/Quicklinks_HomeAccents_101023',
                                  created_at: '2023-10-09T16:05:22Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_190,w_190/f_auto/q_auto:good/v1696867522/content/marketing/Quicklinks_HomeAccents_101023.jpg',
                                  resource_type: 'image',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1696867522/content/marketing/Quicklinks_HomeAccents_101023.jpg',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1696867522/content/marketing/Quicklinks_HomeAccents_101023.jpg',
                                  raw_transformation: 'c_scale,h_190,w_190/f_auto/q_auto:good',
                                },
                              ],
                              linkTo: 'https://www.horchow.com/c/decor-home-accents-cat29200732',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4EDI9NCCJ2HBxh0bCXkBOj',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: 'HC Homepage Row 2: Home Accents',
                              desktopText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [
                                          {
                                            type: 'bold',
                                          },
                                        ],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.horchow.com/c/decor-home-accents-cat29200732',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'HOME ACCENTS',
                                            marks: [
                                              {
                                                type: 'bold',
                                              },
                                            ],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              buttonStyle: 'underlined',
                            },
                          },
                        ],
                        horizontalSpacingDesktop: '64',
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4N5el3OGA4t6vLqcutbjkn',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Home Accents',
                              code: 'HA',
                              type: 'Category',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: 'VsawBpnWoQJR4PWjqMv5t',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'HC Homepage Row 2: Christmas',
                        desktopWidth: '20',
                        mobileWidth: '40',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5bCwMRHnTPppTOSVucer7Z',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'HC Homepage Row 2: Christmas',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_190,w_190/f_auto/q_auto:good/v1696867522/content/marketing/Quicklinks_Christmas_101023.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 43722,
                                  width: 243,
                                  format: 'jpg',
                                  height: 243,
                                  version: 1696867522,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      '0001',
                                    ],
                                    asset_type: '0001',
                                    star_rating: '0001',
                                    retouch_status: '0001',
                                  },
                                  public_id: 'content/marketing/Quicklinks_Christmas_101023',
                                  created_at: '2023-10-09T16:05:22Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_190,w_190/f_auto/q_auto:good/v1696867522/content/marketing/Quicklinks_Christmas_101023.jpg',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1696867522/content/marketing/Quicklinks_Christmas_101023.jpg',
                                  resource_type: 'image',
                                  raw_transformation: 'c_scale,h_190,w_190/f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1696867522/content/marketing/Quicklinks_Christmas_101023.jpg',
                                },
                              ],
                              linkTo: 'https://www.horchow.com/c/holiday-gifts-christmas-cat39750739',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5eV6n2W0Czuo5cFSAe2yxi',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: 'HC Homepage Row 2: Christmas',
                              desktopText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.horchow.com/c/holiday-gifts-christmas-cat39750739',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'CHRISTMAS',
                                            marks: [
                                              {
                                                type: 'bold',
                                              },
                                            ],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              buttonStyle: 'underlined',
                            },
                          },
                        ],
                        horizontalSpacingDesktop: '64',
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4tv74QsUtxzU1FhnNgChaK',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Christmas',
                              code: 'CH',
                              type: 'Category',
                            },
                          },
                        ],
                      },
                    },
                  ],
                  verticalSpacingDesktop: '64',
                  trackingTags: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '5HYioWDSDjw15FRNIHvMma',
                      },
                      contentType: 'trackingTags',
                      fields: {
                        name: 'Row 2',
                        code: 'R2',
                        type: '3rd Group',
                      },
                    },
                  ],
                },
              },
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: 'LSaDYa5JKtoXncKf0iv63',
                },
                contentType: 'l2Layout',
                fields: {
                  name: 'Horchow: Homepage Row 4',
                  mobileWidth: '96',
                  l3Layout: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '6DVthV7ixyF38WlCRxxYIS',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Horchow: Homepage Row 4 New Arrivals',
                        desktopWidth: '50',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '1OQmOpCVzDSJcyq1bMA6Sf',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'Horchow: Homepage Row 4 New Arrivals',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,g_xy_center,h_617,w_772/f_auto/q_auto:good/v1692629135/content/creative/20A_0923ED_CHRISTIANLACROIX.tiff',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 55367124,
                                  width: 4399,
                                  format: 'tiff',
                                  height: 5498,
                                  version: 1692629135,
                                  duration: null,
                                  metadata: {
                                    expiration_date: '2024-08-17',
                                    shoot_date: '2023-08-17',
                                    asset_type: 'uymo684qvl8awfg52vni',
                                    composition: 'jkvyc553vnyzofslenmd',
                                    retouch_status: '0001',
                                    star_rating: 'wkkermich0rjr7exzmar',
                                    brands: [
                                      'tapl0bs3opp8ukcvf95s',
                                    ],
                                    campaign_types: [
                                      '0001',
                                    ],
                                    categories: [
                                      'bwipmsmf95wk7stu76dc',
                                      'sk8cgkh4uvxtqfsial2h',
                                    ],
                                  },
                                  context: {
                                    custom: {
                                      alt: '20A_0923ED_CHRISTIANLACROIX.tif',
                                      caption: 'SEPTEMBER EDITORIALS',
                                      'Digital Tech': 'Daniel Mendenhall',
                                      Photographer: 'Lori Bandi',
                                      Vendors: 'Christian Lacroix',
                                    },
                                  },
                                  public_id: 'content/creative/20A_0923ED_CHRISTIANLACROIX',
                                  created_at: '2023-08-21T14:45:35Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,g_xy_center,h_617,w_772/f_auto/q_auto:good/v1692629135/content/creative/20A_0923ED_CHRISTIANLACROIX.tiff',
                                  resource_type: 'image',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1692629135/content/creative/20A_0923ED_CHRISTIANLACROIX.tiff',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1692629135/content/creative/20A_0923ED_CHRISTIANLACROIX.tiff',
                                  raw_transformation: 'c_fill,g_xy_center,h_617,w_772/f_auto/q_auto:good',
                                },
                              ],
                              mobileImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,h_465,w_372/f_auto/q_auto:good/v1692629135/content/creative/20A_0923ED_CHRISTIANLACROIX.tiff',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 55367124,
                                  width: 4399,
                                  format: 'tiff',
                                  height: 5498,
                                  context: {
                                    custom: {
                                      alt: '20A_0923ED_CHRISTIANLACROIX.tif',
                                      Vendors: 'Christian Lacroix',
                                      caption: 'SEPTEMBER EDITORIALS',
                                      'Digital Tech': 'Daniel Mendenhall',
                                      Photographer: 'Lori Bandi',
                                    },
                                  },
                                  version: 1692629135,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      'tapl0bs3opp8ukcvf95s',
                                    ],
                                    asset_type: 'uymo684qvl8awfg52vni',
                                    categories: [
                                      'bwipmsmf95wk7stu76dc',
                                      'sk8cgkh4uvxtqfsial2h',
                                    ],
                                    shoot_date: '2023-08-17',
                                    composition: 'jkvyc553vnyzofslenmd',
                                    star_rating: 'wkkermich0rjr7exzmar',
                                    campaign_types: [
                                      '0001',
                                    ],
                                    retouch_status: '0001',
                                    expiration_date: '2024-08-17',
                                  },
                                  public_id: 'content/creative/20A_0923ED_CHRISTIANLACROIX',
                                  created_at: '2023-08-21T14:45:35Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,h_465,w_372/f_auto/q_auto:good/v1692629135/content/creative/20A_0923ED_CHRISTIANLACROIX.tiff',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1692629135/content/creative/20A_0923ED_CHRISTIANLACROIX.tiff',
                                  resource_type: 'image',
                                  raw_transformation: 'c_fill,h_465,w_372/f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1692629135/content/creative/20A_0923ED_CHRISTIANLACROIX.tiff',
                                },
                              ],
                              linkTo: 'https://www.horchow.com/c/All-New-Arrivals-cat24450732',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3RUrXhfX1lJnd4oRFZ5mgR',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: 'Horchow: Homepage Row 4 New Arrivals',
                              desktopText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'heading-5',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'NEW ARRIVALS',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'Explore the latest finds from your favorite designers.',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [
                                          {
                                            type: 'bold',
                                          },
                                        ],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.horchow.com/c/All-New-Arrivals-cat24450732',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'SHOP NOW',
                                            marks: [
                                              {
                                                type: 'bold',
                                              },
                                            ],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              mobileText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'heading-4',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'NEW ARRIVALS',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [
                                          {
                                            type: 'bold',
                                          },
                                        ],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.horchow.com/c/All-New-Arrivals-cat24450732',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'SHOP NOW',
                                            marks: [
                                              {
                                                type: 'bold',
                                              },
                                            ],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              buttonStyle: 'underlined',
                            },
                          },
                        ],
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '1K6PzWcZsmI2HqZQ8MtyX3',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'New Arrivals',
                              code: 'NA',
                              type: 'Category',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '6K6pGFU69ijFtW77ZG3T7h',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage: Horchow Row 4 Free Shipping',
                        desktopWidth: '50',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4Fht6cF9G68boQ7qlFGnoD',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'Homepage: Horchow Row 4 Free Shipping Every Day',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,h_617,w_775/f_auto/q_auto:good/v1692192079/product/hc_680193_100000_a.tiff',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 3447280,
                                  width: 1200,
                                  format: 'tiff',
                                  height: 1500,
                                  context: {
                                    custom: {
                                      alt: 'Country Estate Dinnerware',
                                      Vendors: 'Juliska',
                                      'PIM Style': '680193',
                                      Depictions: 'HB6T8',
                                    },
                                  },
                                  version: 1692192079,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      'o6ubpongsfhteehpxwlf',
                                    ],
                                    asset_type: 'gwuatnwnfqupszpus2bb',
                                    categories: [
                                      'bwipmsmf95wk7stu76dc',
                                    ],
                                    star_rating: '0001',
                                    retouch_status: '0001',
                                  },
                                  public_id: 'product/hc_680193_100000_a',
                                  created_at: '2023-08-16T13:21:19Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,h_617,w_775/f_auto/q_auto:good/v1692192079/product/hc_680193_100000_a.tiff',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1692192079/product/hc_680193_100000_a.tiff',
                                  resource_type: 'image',
                                  raw_transformation: 'c_fill,h_617,w_775/f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1692192079/product/hc_680193_100000_a.tiff',
                                },
                              ],
                              mobileImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_465,w_372/f_auto/q_auto:good/v1692192079/product/hc_680193_100000_a.tiff',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 3447280,
                                  width: 1200,
                                  format: 'tiff',
                                  height: 1500,
                                  version: 1692192079,
                                  duration: null,
                                  metadata: {
                                    asset_type: 'gwuatnwnfqupszpus2bb',
                                    retouch_status: '0001',
                                    star_rating: '0001',
                                    brands: [
                                      'o6ubpongsfhteehpxwlf',
                                    ],
                                    categories: [
                                      'bwipmsmf95wk7stu76dc',
                                    ],
                                  },
                                  context: {
                                    custom: {
                                      Depictions: 'HB6T8',
                                      'PIM Style': '680193',
                                      Vendors: 'Juliska',
                                      alt: 'Country Estate Dinnerware',
                                    },
                                  },
                                  public_id: 'product/hc_680193_100000_a',
                                  created_at: '2023-08-16T13:21:19Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,dpr_2.0,h_465,w_372/f_auto/q_auto:good/v1692192079/product/hc_680193_100000_a.tiff',
                                  resource_type: 'image',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1692192079/product/hc_680193_100000_a.tiff',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1692192079/product/hc_680193_100000_a.tiff',
                                  raw_transformation: 'c_scale,dpr_2.0,h_465,w_372/f_auto/q_auto:good',
                                },
                              ],
                              linkTo: 'https://www.horchow.com/c/sale-shop-by-free-shipping-every-day-cat30560737?filterOptions=%7B%22Designer%22%3A%5B%5D%2C%22level2%22%3A%5B%22Bakeware%22%2C%22Bar%20Accessories%22%2C%22Beverage%20Servers%22%2C%22Cookware%22%2C%22Dinnerware%22%2C%22Drinkware%22%2C%22Flatware%22%2C%22Kitchen%20Accessories%22%2C%22Serveware%22%2C%22Stemware%22%2C%22Tabletop%22%5D%2C%22Color%22%3A%5B%5D%2C%22priceBandUpper%22%3A%5B%5D%7D&navpath=cat000000_cat000140_cat30560733_cat30560737',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '56K7xLB0Ky26GCdlJC3h2j',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: 'Homepage: Horchow Row 4 Free Shipping Every Day',
                              desktopText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'heading-5',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'FREE SHIPPING ON TABLETOP',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'Dinnerware, flatware, table linens, serving pieces, and more always ship free. Exclusions apply.',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [
                                          {
                                            type: 'bold',
                                          },
                                        ],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.horchow.com/c/sale-shop-by-free-shipping-every-day-cat30560737?filterOptions=%7B%22Designer%22%3A%5B%5D%2C%22level2%22%3A%5B%22Bakeware%22%2C%22Bar%20Accessories%22%2C%22Beverage%20Servers%22%2C%22Cookware%22%2C%22Dinnerware%22%2C%22Drinkware%22%2C%22Flatware%22%2C%22Kitchen%20Accessories%22%2C%22Serveware%22%2C%22Stemware%22%2C%22Tabletop%22%5D%2C%22Color%22%3A%5B%5D%2C%22priceBandUpper%22%3A%5B%5D%7D&navpath=cat000000_cat000140_cat30560733_cat30560737',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'SHOP NOW',
                                            marks: [
                                              {
                                                type: 'bold',
                                              },
                                            ],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              mobileText: {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: 'FREE SHIPPING EVERY DAY',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'heading-4',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [
                                          {
                                            type: 'bold',
                                          },
                                        ],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                      {
                                        data: {
                                          uri: 'https://www.horchow.com/c/sale-shop-by-free-shipping-every-day-cat30560737',
                                        },
                                        content: [
                                          {
                                            data: {},
                                            marks: [
                                              {
                                                type: 'bold',
                                              },
                                            ],
                                            value: 'SHOP NOW',
                                            nodeType: 'text',
                                          },
                                        ],
                                        nodeType: 'hyperlink',
                                      },
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                ],
                                nodeType: 'document',
                              },
                              textJustification: 'Center',
                              buttonStyle: 'underlined',
                            },
                          },
                        ],
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '71bDNGfRQ0eA5EIt4Qv3l3',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'Tabletop',
                              code: 'TB',
                              type: 'Category',
                            },
                          },
                        ],
                      },
                    },
                  ],
                  verticalSpacingDesktop: '96',
                  trackingTags: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '6N4j6GC5wxlKxVMmSzJdYT',
                      },
                      contentType: 'trackingTags',
                      fields: {
                        name: 'Row 4',
                        code: 'R4',
                        type: '3rd Group',
                      },
                    },
                  ],
                },
              },
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '1mt9nCzuefHLssi2Xa1ckd',
                },
                contentType: 'l2Layout',
                fields: {
                  name: 'Horchow: Homepage Row 5 Fallback',
                  components: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '6iuldSizp2GenPzisqZu08',
                      },
                      contentType: 'textOverImageContentAsset',
                      fields: {
                        name: 'HC Homepage: Email Sign-Up Fallback',
                        image: {
                          sys: {
                            type: 'Link',
                            linkType: 'Entry',
                            id: '1IesK9H9HMWqzI0gAzRZea',
                          },
                          contentType: 'imageContentAsset',
                          fields: {
                            name: 'HC Homepage: Email Sign-Up',
                            desktopImage: [
                              {
                                url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_235,w_1600/c_fill,h_208,l_v1608744989:content:marketing:m_HP_Placeholder_062419.png,w_1567/f_auto/q_auto:good/v1642788180/content/stock/r_HC_HP_EVERGREEN_EmailSignup_012122.jpg',
                                tags: [],
                                type: 'upload',
                                bytes: 88691,
                                width: 1600,
                                format: 'jpg',
                                height: 235,
                                context: {
                                  custom: {
                                    caption: 'Email Sign Up',
                                  },
                                },
                                version: 1642788180,
                                duration: null,
                                metadata: {
                                  brands: [
                                    'o6ubpongsfhteehpxwlf',
                                    'tapl0bs3opp8ukcvf95s',
                                  ],
                                  channels: [
                                    'zux26btuerj5pm8cd76l',
                                  ],
                                  asset_type: 'gnwvisghth8lacvzdjkm',
                                  categories: [
                                    'jt5nfdbcrdgngrbfnnh1',
                                  ],
                                  composition: 'jkvyc553vnyzofslenmd',
                                  launch_date: '2022-01-21',
                                  campaign_types: [
                                    '0001',
                                  ],
                                },
                                public_id: 'content/stock/r_HC_HP_EVERGREEN_EmailSignup_012122',
                                created_at: '2022-01-21T18:03:00Z',
                                secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,h_235,w_1600/c_fill,h_208,l_v1608744989:content:marketing:m_HP_Placeholder_062419.png,w_1567/f_auto/q_auto:good/v1642788180/content/stock/r_HC_HP_EVERGREEN_EmailSignup_012122.jpg',
                                original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1642788180/content/stock/r_HC_HP_EVERGREEN_EmailSignup_012122.jpg',
                                resource_type: 'image',
                                raw_transformation: 'c_scale,h_235,w_1600/c_fill,h_208,l_v1608744989:content:marketing:m_HP_Placeholder_062419.png,w_1567/f_auto/q_auto:good',
                                original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1642788180/content/stock/r_HC_HP_EVERGREEN_EmailSignup_012122.jpg',
                              },
                            ],
                            mobileImage: [
                              {
                                url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,h_275,w_414/c_fill,h_250,l_v1608744989:content:marketing:m_HP_Placeholder_062419.png,w_390/f_auto/q_auto:good/v1642788180/content/stock/m_HC_HP_EVERGREEN_EmailSignup_012122.jpg',
                                tags: [],
                                type: 'upload',
                                bytes: 46448,
                                width: 414,
                                format: 'jpg',
                                height: 275,
                                version: 1642788180,
                                duration: null,
                                metadata: {
                                  asset_type: 'gnwvisghth8lacvzdjkm',
                                  brands: [
                                    'o6ubpongsfhteehpxwlf',
                                    'tapl0bs3opp8ukcvf95s',
                                  ],
                                  campaign_types: [
                                    '0001',
                                  ],
                                  categories: [
                                    'jt5nfdbcrdgngrbfnnh1',
                                  ],
                                  channels: [
                                    'zux26btuerj5pm8cd76l',
                                  ],
                                  composition: 'jkvyc553vnyzofslenmd',
                                  launch_date: '2022-01-21',
                                },
                                context: {
                                  custom: {
                                    caption: 'Email Sign Up',
                                  },
                                },
                                public_id: 'content/stock/m_HC_HP_EVERGREEN_EmailSignup_012122',
                                created_at: '2022-01-21T18:03:00Z',
                                secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,h_275,w_414/c_fill,h_250,l_v1608744989:content:marketing:m_HP_Placeholder_062419.png,w_390/f_auto/q_auto:good/v1642788180/content/stock/m_HC_HP_EVERGREEN_EmailSignup_012122.jpg',
                                resource_type: 'image',
                                original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1642788180/content/stock/m_HC_HP_EVERGREEN_EmailSignup_012122.jpg',
                                original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1642788180/content/stock/m_HC_HP_EVERGREEN_EmailSignup_012122.jpg',
                                raw_transformation: 'c_fill,h_275,w_414/c_fill,h_250,l_v1608744989:content:marketing:m_HP_Placeholder_062419.png,w_390/f_auto/q_auto:good',
                              },
                            ],
                          },
                        },
                        text: {
                          sys: {
                            type: 'Link',
                            linkType: 'Entry',
                            id: '2P2PrfNgP7iyyBA89kPpFE',
                          },
                          contentType: 'richTextContentAsset',
                          fields: {
                            name: 'HC Homepage: Email Sign-Up',
                            desktopText: {
                              data: {},
                              content: [
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: 'NEW SUBSCRIBERS SAVE 20%',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'heading-5',
                                },
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: '',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'paragraph',
                                },
                                {
                                  data: {},
                                  content: [
                                    {
                                      data: {},
                                      marks: [],
                                      value: '',
                                      nodeType: 'text',
                                    },
                                    {
                                      data: {
                                        uri: 'https://www.horchow.com/services/SpecialOffer',
                                      },
                                      content: [
                                        {
                                          data: {},
                                          marks: [],
                                          value: 'SIGN UP FOR HORCHOW EMAILS',
                                          nodeType: 'text',
                                        },
                                      ],
                                      nodeType: 'hyperlink',
                                    },
                                    {
                                      data: {},
                                      marks: [],
                                      value: '',
                                      nodeType: 'text',
                                    },
                                  ],
                                  nodeType: 'paragraph',
                                },
                              ],
                              nodeType: 'document',
                            },
                            mobileText: {
                              nodeType: 'document',
                              data: {},
                              content: [
                                {
                                  nodeType: 'heading-4',
                                  data: {},
                                  content: [
                                    {
                                      nodeType: 'text',
                                      value: 'NEW SUBSCRIBERS SAVE 20%',
                                      marks: [],
                                      data: {},
                                    },
                                  ],
                                },
                                {
                                  nodeType: 'paragraph',
                                  data: {},
                                  content: [
                                    {
                                      nodeType: 'text',
                                      value: '',
                                      marks: [],
                                      data: {},
                                    },
                                  ],
                                },
                                {
                                  nodeType: 'paragraph',
                                  data: {},
                                  content: [
                                    {
                                      nodeType: 'text',
                                      value: '',
                                      marks: [],
                                      data: {},
                                    },
                                    {
                                      nodeType: 'hyperlink',
                                      data: {
                                        uri: 'https://www.horchow.com/services/SpecialOffer',
                                      },
                                      content: [
                                        {
                                          nodeType: 'text',
                                          value: 'SIGN UP FOR HORCHOW EMAILS',
                                          marks: [],
                                          data: {},
                                        },
                                      ],
                                    },
                                    {
                                      nodeType: 'text',
                                      value: '',
                                      marks: [],
                                      data: {},
                                    },
                                  ],
                                },
                              ],
                            },
                            textJustification: 'Center',
                            buttonStyle: 'underlined',
                          },
                        },
                        desktopTextPosition: 'Middle Center',
                        desktopTextWidth: 70,
                        mobileTextPosition: 'Middle Center',
                        mobileTextWidth: 90,
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '3TRp9fzYPGIt74zJbjIOxc',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'HC Email Sign-Up',
                              code: 'SignUp_061923',
                              type: 'Campaign Name',
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '1Isal4HIRkU4SItPkJwxs8',
                },
                contentType: 'l2Layout',
                fields: {
                  name: 'Horchow: Homepage Footer',
                  l3Layout: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '2EoVoPkHppMAblX6fbWVuM',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage: Horchow Free Shipping',
                        desktopWidth: '33',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5xVXSQTBJE3Zj1ok9iUA0d',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'Homepage: Horchow Free Shipping',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,w_1600/c_crop,g_west,h_344,w_533/f_auto/q_auto:good/v1662568031/content/marketing/r_HP_HC_About_090722.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 67018,
                                  width: 1670,
                                  format: 'jpg',
                                  height: 344,
                                  context: {
                                    custom: {
                                      alt: 'Who We Are',
                                      caption: 'Horchow About',
                                    },
                                  },
                                  version: 1662568031,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      'tapl0bs3opp8ukcvf95s',
                                    ],
                                    channels: [
                                      'zux26btuerj5pm8cd76l',
                                    ],
                                    asset_type: 'jgjeeyocn9sjhgzofsvg',
                                    launch_date: '2022-09-07',
                                    star_rating: '0001',
                                    campaign_types: [
                                      '0001',
                                    ],
                                    retouch_status: '0001',
                                  },
                                  public_id: 'content/marketing/r_HP_HC_About_090722',
                                  created_at: '2022-09-07T16:27:11Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,w_1600/c_crop,g_west,h_344,w_533/f_auto/q_auto:good/v1662568031/content/marketing/r_HP_HC_About_090722.jpg',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1662568031/content/marketing/r_HP_HC_About_090722.jpg',
                                  resource_type: 'image',
                                  raw_transformation: 'c_scale,w_1600/c_crop,g_west,h_344,w_533/f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1662568031/content/marketing/r_HP_HC_About_090722.jpg',
                                },
                              ],
                              mobileImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1663795552/content/marketing/m_HP_HC_FreeShipping_090722.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 11052,
                                  width: 414,
                                  format: 'jpg',
                                  height: 242,
                                  version: 1663795552,
                                  duration: null,
                                  metadata: {
                                    asset_type: '0001',
                                    retouch_status: '0001',
                                    star_rating: '0001',
                                    brands: [
                                      '0001',
                                    ],
                                  },
                                  public_id: 'content/marketing/m_HP_HC_FreeShipping_090722',
                                  created_at: '2022-09-21T21:25:52Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1663795552/content/marketing/m_HP_HC_FreeShipping_090722.jpg',
                                  resource_type: 'image',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1663795552/content/marketing/m_HP_HC_FreeShipping_090722.jpg',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1663795552/content/marketing/m_HP_HC_FreeShipping_090722.jpg',
                                  raw_transformation: 'f_auto/q_auto:good',
                                },
                              ],
                              linkTo: 'https://www.horchow.com/Sale/Shop-By/Free-Shipping-Everyday/cat30560737/c.cat',
                            },
                          },
                        ],
                        horizontalSpacingDesktop: '0',
                        verticalSpacingDesktop: '0',
                        horizontalSpacingTablet: '0',
                        verticalSpacingTablet: '0',
                        verticalSpacingMobile: '0',
                        verticallyAlign: 'Middle',
                        trackingTags: [],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '3XmJDghSt95IdMQRJ9bcvZ',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage: Horchow Who We Are',
                        desktopWidth: '33',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '53q8MRxEAozvH46hkDdBBt',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'Homepage: Horchow Who We Are',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,w_1600/c_crop,h_344,w_533/f_auto/q_auto:good/v1662568031/content/marketing/r_HP_HC_About_090722.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 67018,
                                  width: 1670,
                                  format: 'jpg',
                                  height: 344,
                                  context: {
                                    custom: {
                                      alt: 'Who We Are',
                                      caption: 'Horchow About',
                                    },
                                  },
                                  version: 1662568031,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      'tapl0bs3opp8ukcvf95s',
                                    ],
                                    channels: [
                                      'zux26btuerj5pm8cd76l',
                                    ],
                                    asset_type: 'jgjeeyocn9sjhgzofsvg',
                                    launch_date: '2022-09-07',
                                    star_rating: '0001',
                                    campaign_types: [
                                      '0001',
                                    ],
                                    retouch_status: '0001',
                                  },
                                  public_id: 'content/marketing/r_HP_HC_About_090722',
                                  created_at: '2022-09-07T16:27:11Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,w_1600/c_crop,h_344,w_533/f_auto/q_auto:good/v1662568031/content/marketing/r_HP_HC_About_090722.jpg',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1662568031/content/marketing/r_HP_HC_About_090722.jpg',
                                  resource_type: 'image',
                                  raw_transformation: 'c_scale,w_1600/c_crop,h_344,w_533/f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1662568031/content/marketing/r_HP_HC_About_090722.jpg',
                                },
                              ],
                              mobileImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1663795552/content/marketing/m_HP_HC_About_090722.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 9443,
                                  width: 414,
                                  format: 'jpg',
                                  height: 241,
                                  version: 1663795552,
                                  duration: null,
                                  metadata: {
                                    asset_type: '0001',
                                    retouch_status: '0001',
                                    star_rating: '0001',
                                    brands: [
                                      '0001',
                                    ],
                                  },
                                  public_id: 'content/marketing/m_HP_HC_About_090722',
                                  created_at: '2022-09-21T21:25:52Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1663795552/content/marketing/m_HP_HC_About_090722.jpg',
                                  resource_type: 'image',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1663795552/content/marketing/m_HP_HC_About_090722.jpg',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1663795552/content/marketing/m_HP_HC_About_090722.jpg',
                                  raw_transformation: 'f_auto/q_auto:good',
                                },
                              ],
                              linkTo: 'https://www.horchow.com/About-Horchow/cat7980731/c.cat',
                            },
                          },
                        ],
                        horizontalSpacingDesktop: '0',
                        verticalSpacingDesktop: '0',
                        horizontalSpacingTablet: '0',
                        topSpacingMobile: '0',
                        verticalSpacingMobile: '0',
                        verticallyAlign: 'Middle',
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: 'cXqzj2YM8r1GRkJoX5f38',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'HC Who We Are',
                              code: 'WhoWeAre_012722',
                              type: 'Campaign Name',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '2eG1VDthrL4rAA0kN0HtYR',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage: Horchow Delivery',
                        desktopWidth: '33',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '7pQw7KG4kGeifnm1U8gvjH',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'Homepage: Horchow Delivery',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_scale,w_1600/c_crop,g_east,h_344,w_533/f_auto/q_auto:good/v1662568031/content/marketing/r_HP_HC_About_090722.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 67018,
                                  width: 1670,
                                  format: 'jpg',
                                  height: 344,
                                  context: {
                                    custom: {
                                      alt: 'Who We Are',
                                      caption: 'Horchow About',
                                    },
                                  },
                                  version: 1662568031,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      'tapl0bs3opp8ukcvf95s',
                                    ],
                                    channels: [
                                      'zux26btuerj5pm8cd76l',
                                    ],
                                    asset_type: 'jgjeeyocn9sjhgzofsvg',
                                    launch_date: '2022-09-07',
                                    star_rating: '0001',
                                    campaign_types: [
                                      '0001',
                                    ],
                                    retouch_status: '0001',
                                  },
                                  public_id: 'content/marketing/r_HP_HC_About_090722',
                                  created_at: '2022-09-07T16:27:11Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_scale,w_1600/c_crop,g_east,h_344,w_533/f_auto/q_auto:good/v1662568031/content/marketing/r_HP_HC_About_090722.jpg',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1662568031/content/marketing/r_HP_HC_About_090722.jpg',
                                  resource_type: 'image',
                                  raw_transformation: 'c_scale,w_1600/c_crop,g_east,h_344,w_533/f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1662568031/content/marketing/r_HP_HC_About_090722.jpg',
                                },
                              ],
                              mobileImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1663795552/content/marketing/m_HP_HC_Delivery_090722.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 11263,
                                  width: 414,
                                  format: 'jpg',
                                  height: 242,
                                  version: 1663795552,
                                  duration: null,
                                  metadata: {
                                    asset_type: '0001',
                                    retouch_status: '0001',
                                    star_rating: '0001',
                                    brands: [
                                      '0001',
                                    ],
                                  },
                                  public_id: 'content/marketing/m_HP_HC_Delivery_090722',
                                  created_at: '2022-09-21T21:25:52Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1663795552/content/marketing/m_HP_HC_Delivery_090722.jpg',
                                  resource_type: 'image',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1663795552/content/marketing/m_HP_HC_Delivery_090722.jpg',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1663795552/content/marketing/m_HP_HC_Delivery_090722.jpg',
                                  raw_transformation: 'f_auto/q_auto:good',
                                },
                              ],
                              linkTo: 'https://www.horchow.com/assistance/assistance.jsp?itemId=cat16870735&navid=assist:shipping&eVar6=shipping',
                            },
                          },
                        ],
                        horizontalSpacingDesktop: '0',
                        horizontalSpacingTablet: '0',
                        topSpacingMobile: '0',
                        horizontalSpacingMobile: '0',
                        trackingTags: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4P5nBYTJkajf6gptiZFw4q',
                            },
                            contentType: 'trackingTags',
                            fields: {
                              name: 'HC Delivery',
                              code: 'Delivery_012722',
                              type: 'Campaign Name',
                            },
                          },
                        ],
                      },
                    },
                  ],
                  padding: '20',
                  backgroundColor: '#F1F1F1',
                },
              },
            ],
          },
        },
      ],
    },
    contentType: 'frame',
  },
];
