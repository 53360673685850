export const TOGGLE_MOBILE_SEARCH_BAR = 'TOGGLE_MOBILE_SEARCH_BAR';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const UPDATE_TYPEAHEAD_SUGGESTIONS = 'UPDATE_TYPEAHEAD_SUGGESTIONS';
export const SET_RECENTLY_SEARCHED_ANALYTICS = 'SET_RECENTLY_SEARCHED_ANALYTICS';
export const LOAD_TYPEAHEAD_UI_CONFIG = 'LOAD_TYPEAHEAD_UI_CONFIG';
export const TOGGLE_IS_TYPEAHEAD_SUGGESTIONS_EMPTY = 'TOGGLE_IS_TYPEAHEAD_SUGGESTIONS_EMPTY';
export const SET_COLLAPSIBLE_SEARCH_MODAL = 'SET_COLLAPSIBLE_SEARCH_MODAL';

export const types = {
  SET_PLACEHOLDER_VALUE: 'SET_PLACEHOLDER_VALUE',
  SET_MOBILE_SEARCH_TERM: 'SET_MOBILE_SEARCH_TERM',
};

export function toggleMobileSearchBar() {
  return (dispatch) => dispatch({ type: TOGGLE_MOBILE_SEARCH_BAR });
}

export function updateSearchTerm(searchTerm) {
  return (dispatch) => dispatch({ type: UPDATE_SEARCH, searchTerm });
}

export function setSearchTermToEmpty() {
  return (dispatch) => updateSearchTerm('')(dispatch);
}

export function setSearchTermValueFromPlaceholder(currentPlaceholder = '', currentValue = '') {
  return (dispatch) => {
    if (currentPlaceholder && !currentValue) {
      updateSearchTerm(currentPlaceholder)(dispatch);
    }
  };
}

export function setPlaceholderValue(placeholderValue) {
  return (dispatch) => dispatch({ type: types.SET_PLACEHOLDER_VALUE, placeholderValue });
}

export function setCollapsibleSearchModal(payload) {
  return (dispatch) => dispatch({ type: SET_COLLAPSIBLE_SEARCH_MODAL, payload });
}

export function setPlaceHolderValueToEmpty() {
  return (dispatch) => setPlaceholderValue('')(dispatch);
}

export function setMobileTextBoxValue(searchTerm) {
  if (typeof window !== 'undefined' && window.$ && window.BRAutosuggest) {
    window.BRAutosuggest.search(searchTerm);
  }
  return (dispatch) => (
    dispatch({
      type: types.SET_MOBILE_SEARCH_TERM,
      mobileSearchTerm: searchTerm,
    })
  );
}

export function setRecentlySearchedAnalytics(value) {
  if (typeof window !== 'undefined' && window.utag_data_dt) {
    if (value) {
      window.utag_data_dt = {
        ...window.utag_data_dt,
        recently_searched: `${value}`,
      };
    } else {
      delete window.utag_data_dt.recently_searched;
    }
    return (dispatch) => (
      dispatch({
        type: SET_RECENTLY_SEARCHED_ANALYTICS,
        value,
      })
    );
  }
  return (dispatch) => (
    dispatch({
      type: SET_RECENTLY_SEARCHED_ANALYTICS,
      value,
    })
  );
}

export function genderToggleAnalyticsReset() {
  if (!window.utag_data_dt) return;

  window.utag_data_dt = {
    ...window.utag_data_dt,
    previous_view_search_submit: 'false',
    search_type_ahead: 'false',
  };
}

export function loadTypeaheadUIConfig() {
  return (dispatch, getState) => {
    if (getState().toggles.TYPEAHEAD || getState().toggles.ABTEST_TYPEAHEAD) {
      dispatch({ type: LOAD_TYPEAHEAD_UI_CONFIG, payload: global.typeaheadDataObj });
    }
  };
}
