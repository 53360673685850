window.jQuery = require('jquery');
require('jquery.cookie');

window.RWD = { on() {} };

window.popUp = (url, width, height, scrolls, windowName) => {
  let winOptions;
  if (windowName === 'disableAddToBag') {
    winOptions = 'toolbar=yes,location=yes,directories=yes,status=yes,'
      + `menubar=yes,scrollbars=${scrolls},resizable=yes,copyhistory=no,`
      + `width=${width},height=${height},top=0,left=0`;
  } else {
    winOptions = 'toolbar=no,location=no,directories=no,status=no,'
      + `menubar=no,scrollbars=${scrolls},resizable=yes,copyhistory=no,`
      + `width=${width},height=${height},screenX=20,screenY=20`;
  }
  const win = window.open(url, windowName, winOptions);
  if (!win.opener) win.opener = window.self;
  if (win.focus)win.focus();
};
