import { types } from 'templates/dux/templateActions';

function constructObjectWithNotNullProperties(state, action) {
  return {
    ...state,
    page_template: action.payload.page_template,
    page_definition_id: action.payload.page_definition_id,
    page_type: action.payload.page_type,
    page_name: action.payload.page_name,
    page_title: action.payload.page_title,
  };
}

export default (state = {}, action) => {
  switch (action.type) {
    case types.SET_CATEGORY_TEMPLATES_DATA_TO_UTAG:
      return constructObjectWithNotNullProperties(state, action);
    case types.SET_FAVORITE_DESIGNER_INDEX_TO_UTAG:
      return {
        ...state,
        event_name: action.payload.event_name,
        item_type: action.payload.item_type,
        favorite_item_status: action.payload.favorite_item_status,
        favorite_designer: action.payload.favorite_designer,
      };
    default:
      return state;
  }
};
