import { RESOLVED_REFRESHABLE_CONTENT, REJECTED_REFRESHABLE_CONTENT } from './refreshableContent-actions';

const initialState = {
  refreshableHtmlContent: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESOLVED_REFRESHABLE_CONTENT:
      return {
        refreshableHtmlContent: {
          ...state.refreshableHtmlContent,
          [action.categoryId]: action.payload,
        },
      };
    case REJECTED_REFRESHABLE_CONTENT:
      return {
        ...state,
      };
    default:
      return state;
  }
};
