import React from 'react';
import find from 'lodash/find';

const MAX_WORD_LENGTH = 12;
const OFF_SET = 1;
const wordBreakCharacters = [' ', '-', '/', '_'];

const containsWordBreakCharacters = (text) => wordBreakCharacters.some(
  (character) => text.indexOf(character) >= 0
);

const appendEllipsis = (content) => `${content.substring(0, 9)}...`;

const splitIntoTwoLines = (text) => {
  const breakCriteria = find(
    wordBreakCharacters,
    (character) => text.indexOf(character) >= 0,
  );
  const breakPoint = text.indexOf(breakCriteria) + OFF_SET;
  const firstRow = text.substring(0, breakPoint);
  let secondRow = text.substring(breakPoint, text.length);
  if (secondRow.length > MAX_WORD_LENGTH) {
    secondRow = appendEllipsis(secondRow);
  }
  return (
    <span>
      {firstRow.trim()}
      <br />
      {secondRow}
    </span>
  );
};

const WordWrap = ({ content = '' }) => {
  if (content.length <= MAX_WORD_LENGTH) {
    return <span>{content}</span>;
  }
  if (!containsWordBreakCharacters(content)) {
    return <span>{appendEllipsis(content)}</span>;
  }
  return splitIntoTwoLines(content);
};

export default WordWrap;
