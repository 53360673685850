import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import includes from 'lodash/includes';
import mapValues from 'lodash/mapValues';
import {
  RESOLVED_PRODUCT_LIST,
  SET_SEO_FACETS_ORDER,
} from 'plp/components/ProductListPage/actions';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  SELECT_FILTER,
  EXPAND_FACET,
  SELECT_FILTER_OPTION,
  CLEAR_ALL_FILTER_OPTION,
  FILTER_DESIGNER_BY_NAME,
  OPEN_FACETED_MOBILE_FILTER,
  CLOSE_FACETED_MOBILE_FILTER,
  SELECT_FILTER_BY_ROUTE_PARAM,
  UPDATE_STORE_SEARCH_ADDRESS,
  UPDATE_STORE_SEARCH_MILES,
  SUBMIT_SEARCH_BY_STORE,
  CANCEL_SEARCH_BY_STORE,
  IN_STORE,
  RESOLVED_ALL_STORES_BY_ADDRESS,
  RESOLVED_ALL_STORES_BY_GEOLOCATION,
  REJECTED_ALL_STORES,
  SHOW_ERROR_MESSAGE,
  NO_LAT_LONG_FAVSTORE,
  TOGGLE_CHANGE_YOUR_LOCATION,
  SELECT_YOUR_STORE,
  UPDATE_IN_STORE_SEARCH_ADDRESS,
  UPDATE_IN_STORE_SEARCH_MILES,
  SUBMIT_SEARCH_STORE_BY_LOCATION,
  CANCEL_SEARCH_STORE_BY_LOCATION,
  TOGGLE_CATEGORY_SELECTOR,
  CLEAR_STORE_SELECTION,
  FORCE_SEO_FIRST_PARAM,
  FORCE_SEO_SECOND_PARAM,
  CLEAR_SAVED_SEO_PARAMS,
  CLEAR_SEO_FIRST_PARAM,
  CLEAR_SEO_SECOND_PARAM,
  BLOCK_CANON_FACET_UPDATES,
  NO_INDEX_FROM_SELECTION,
} from '../../FacetedLeftNav/actions';

export const defaultState = {
  selectedFilterKey: '',
  expandedFilters: [0, 1],
  facetedFiltersList: {},
  designerSearchText: '',
  facetedMobileFilterOpened: false,
  address: '',
  radius: 100,
  inStoreFilterError: false,
  stores: [],
  showChangeYourLocation: false,
  selectedStoreNumber: '',
  selectedStore: {},
  addressField: '',
  radiusField: 100,
  noStoresAvailable: false,
  availableStoreHash: {},
  storeListPayload: [],
  facetDeafultExpandList: {},
  hybridFacetsCategorySelector: true,
  tabsInSizeFacet: false,
  storeChange: false,
  seoFacets: [],
  firstParam: {},
  secondParam: {},
  blockCanonical: false,
  noIndexFromSelection: false,
};

function addStoreIdToStoresByPayload(payload) {
  const filteredStore = payload.filter((store) => {
    return get(store, 'storeServices.inStoreShopping.isOpen', false);
  }).map((store) => ({ ...store, storeId: store.id }));
  return filteredStore;
}

const filterActiveStores = (storesData) => {
  const { storeList } = storesData;
  if (storeList?.length > 0) {
    return storeList.filter((str) => get(str, 'storeServices.inStoreShopping.isOpen', false))
      .sort((s1, s2) => s1.distance - s2.distance);
  }
  return storeList || [];
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_PRODUCT_LIST: {
      const { tabsInSizeFacet } = action.payload;
      if (!action.payload.applicableFilters) return state;
      const facetedFiltersListInit = {};
      let availableStoreHash = {};

      action.payload.applicableFilters.forEach((facetOption) => {
        const { facetsOrderList, facets } = facetOption;

        if (facetOption.filterKey === IN_STORE) {
          availableStoreHash = { ...facets };
        }

        facetedFiltersListInit[facetOption.filterKey] = {};
        const validFacetsList = filter(facetsOrderList, (facetKey) => !isNil(facets[facetKey]));

        validFacetsList.forEach((facet) => {
          facetedFiltersListInit[facetOption.filterKey][facet] = false;

          if (state.facetedFiltersList[facetOption.filterKey]
            && state.facetedFiltersList[facetOption.filterKey][facet] === true) {
            facetedFiltersListInit[facetOption.filterKey][facet] = true;
          }
        });

        return true;
      });

      if (state.storeListPayload
        && state.storeListPayload.length > 0) {
        const filteredStore = addStoreIdToStoresByPayload(state.storeListPayload);
        if (filteredStore && filteredStore.length > 0) {
          return {
            ...state,
            stores: [...filteredStore],
            designerSearchText: '',
            facetedFiltersList: facetedFiltersListInit,
            showChangeYourLocation: false,
            availableStoreHash,
          };
        }
      }

      return {
        ...state,
        designerSearchText: '',
        facetedFiltersList: facetedFiltersListInit,
        availableStoreHash,
        ...tabsInSizeFacet && { tabsInSizeFacet },
      };
    }

    case SELECT_FILTER_OPTION: {
      if (!state.facetedFiltersList) return state;
      const { selectedFilterKey, selectedFilterOptionKey } = action.payload;

      if (selectedFilterKey === IN_STORE) {
        if (selectedFilterOptionKey === 'csp') {
          const facetedFilterClone = {
            ...state.facetedFiltersList[IN_STORE],
            sdd: false,
            ndd: false,
            csp: !state.facetedFiltersList[IN_STORE].csp,
          };

          return {
            ...state,
            storeChange: false,
            facetedFiltersList: {
              ...state.facetedFiltersList,
              [IN_STORE]: facetedFilterClone,
            },
          };
        }

        if (selectedFilterOptionKey === 'sdd') {
          const facetedFilterClone = {
            ...state.facetedFiltersList[IN_STORE],
            sdd: !state.facetedFiltersList[IN_STORE].sdd,
            csp: false,
          };

          return {
            ...state,
            storeChange: false,
            facetedFiltersList: {
              ...state.facetedFiltersList,
              [IN_STORE]: facetedFilterClone,
            },
          };
        }

        if (selectedFilterOptionKey === 'ndd') {
          const facetedFilterClone = {
            ...state.facetedFiltersList[IN_STORE],
            ndd: !state.facetedFiltersList[IN_STORE].ndd,
            csp: false,
          };

          return {
            ...state,
            storeChange: false,
            facetedFiltersList: {
              ...state.facetedFiltersList,
              [IN_STORE]: facetedFilterClone,
            },
          };
        }

        if (selectedFilterOptionKey === 'zipChange') {
          const facetedFilterClone = {
            ...state.facetedFiltersList[IN_STORE],
            ndd: false,
            sdd: false,
            csp: false,
          };

          return {
            ...state,
            storeChange: false,
            facetedFiltersList: {
              ...state.facetedFiltersList,
              [IN_STORE]: facetedFilterClone,
            },
          };
        }

        if (selectedFilterOptionKey === 'storeChange') {
          const facetedFilterClone = {
            ...state.facetedFiltersList[IN_STORE],
            ndd: false,
            sdd: false,
            csp: true,
          };

          return {
            ...state,
            storeChange: true,
            facetedFiltersList: {
              ...state.facetedFiltersList,
              [IN_STORE]: facetedFilterClone,
            },
          };
        }
      }

      const facetedFilterClone = {
        ...state.facetedFiltersList[selectedFilterKey],
        [selectedFilterOptionKey]:
        !state.facetedFiltersList[selectedFilterKey][selectedFilterOptionKey],
      };

      const facetedFiltersListClone = {
        ...state.facetedFiltersList,
        [selectedFilterKey]: facetedFilterClone,
      };

      return {
        ...state,
        designerSearchText: '',
        facetedFiltersList: facetedFiltersListClone,
      };
    }

    case SELECT_FILTER: {
      const selectedFilterKey = (action.payload.selectedFilterKey !== state.selectedFilterKey)
        ? action.payload.selectedFilterKey : defaultState.selectedFilterKey;

      if (action.payload.selectedFilterKey === IN_STORE
        && state.storeListPayload
        && state.storeListPayload.length > 0) {
        const filteredStore = addStoreIdToStoresByPayload(state.storeListPayload);

        if (filteredStore && filteredStore.length === 0) {
          return {
            ...state,
            selectedFilterKey,
            designerSearchText: '',
            stores: [],
            showChangeYourLocation: true,
            showError: true,
            noStoresAvailable: true,
            hybridFacetsCategorySelector: false,
          };
        }

        return {
          ...state,
          selectedFilterKey,
          designerSearchText: '',
          stores: [...filteredStore],
          hybridFacetsCategorySelector: false,
        };
      }

      return {
        ...state,
        designerSearchText: '',
        selectedFilterKey,
        hybridFacetsCategorySelector: false,
      };
    }

    case EXPAND_FACET: {
      const selectedFilterKey = (action.payload.selectedFilterKey !== state.selectedFilterKey)
        ? action.payload.selectedFilterKey : defaultState.selectedFilterKey;

      if (
        action.payload.selectedFilterKey === IN_STORE
        && state.storeListPayload
        && state.storeListPayload.length > 0
      ) {
        const filteredStore = addStoreIdToStoresByPayload(state.storeListPayload);

        if (filteredStore && filteredStore.length === 0) {
          return {
            ...state,
            selectedFilterKey,
            designerSearchText: '',
            stores: [],
            showChangeYourLocation: true,
            showError: true,
            noStoresAvailable: true,
          };
        }

        return {
          ...state,
          selectedFilterKey,
          designerSearchText: '',
          stores: [...filteredStore],
        };
      }

      if (!includes(state.expandedFilters, action.payload.filterIndex)) {
        return {
          ...state,
          designerSearchText: '',
          expandedFilters: [...state.expandedFilters, action.payload.filterIndex],
        };
      }

      const filteredList = state.expandedFilters.filter(
        (item) => item !== action.payload.filterIndex
      );

      return {
        ...state,
        designerSearchText: '',
        expandedFilters: filteredList,
      };
    }

    case CLEAR_ALL_FILTER_OPTION: {
      if (!state.facetedFiltersList) return state;
      const facetedFiltersListClone = {};

      Object.keys(state.facetedFiltersList).forEach((facetKey) => {
        if (facetKey === IN_STORE) {
          facetedFiltersListClone[facetKey] = mapValues(
            facetedFiltersListClone[facetKey], () => false
          );
        } else {
          facetedFiltersListClone[facetKey] = mapValues(
            facetedFiltersListClone[facetKey], () => false
          );
        }
      });

      return {
        ...state,
        selectedFilterKey: '',
        designerSearchText: '',
        address: '',
        radius: 100,
        selectedStoreNumber: '',
        selectedStore: {},
        facetedFiltersList: facetedFiltersListClone,
      };
    }
    case FILTER_DESIGNER_BY_NAME:
      return {
        ...state,
        designerSearchText: action.designerSearchText,
      };
    case OPEN_FACETED_MOBILE_FILTER:
      return {
        ...state,
        facetedMobileFilterOpened: true,
      };
    case CLOSE_FACETED_MOBILE_FILTER:
      return {
        ...state,
        facetedMobileFilterOpened: false,
      };
    case TOGGLE_CATEGORY_SELECTOR:
      return {
        ...state,
        hybridFacetsCategorySelector: !state.hybridFacetsCategorySelector,
        selectedFilterKey: '',
      };
    case LOCATION_CHANGE:
    case SELECT_FILTER_BY_ROUTE_PARAM: {
      if (!state.facetedFiltersList) return state;
      let filterOptions = {};

      if (action.routeParam && action.routeParam.filterOptions) {
        filterOptions = JSON.parse(action.routeParam.filterOptions);
      } else if (action.payload) {
        filterOptions = get(action, 'payload.query.filterOptions', '');
        if (filterOptions !== '') {
          filterOptions = JSON.parse(filterOptions);
        } else {
          const query = get(action, 'payload.query');
          if (isEmpty(query)) {
            filterOptions = {};
          } else {
            const capitalize = (s) => {
              if (typeof s !== 'string') return '';
              return s.charAt(0).toUpperCase() + s.slice(1);
            };
            const queryKeys = Object.keys(query);
            const seoFacets = state.seoFacets;
            filterOptions = {};
            queryKeys.forEach((key) => {
              const capKey = capitalize(key);
              if (key === seoFacets[0]) {
                const queryKey = query[key];
                filterOptions[capKey] = [queryKey];
                state.firstParam = { [key]: queryKey };
              }
              if (key === seoFacets[1]) {
                const queryKey = query[key];
                filterOptions[capKey] = [queryKey];
                state.secondParam = { [key]: queryKey };
              }
            });
          }
        }
      }

      if (isEmpty(filterOptions)) {
        return {
          ...state,
          facetedFiltersList: {
            ...state.facetedFiltersList,
          },
        };
      }

      const facetedFilterClone = { ...state.facetedFiltersList };
      const filterClone = {};
      const address = state.address;
      const radius = state.radius;

      Object.keys(filterOptions).forEach((key) => {
        if (filterOptions[key].length > 0) {
          const selectedFilters = {};
          filterOptions[key].forEach((filterKey) => {
            if (facetedFilterClone[key]
                && facetedFilterClone[key][filterKey] !== undefined) {
              selectedFilters[filterKey] = true;
            }
          });
          filterClone[key] = { ...facetedFilterClone[key], ...selectedFilters };
        }
      });

      return {
        ...state,
        facetedFiltersList: { ...state.facetedFiltersList, ...filterClone },
        address: (state.inStoreFilterError) ? state.address : address,
        radius: (state.inStoreFilterError) ? state.radius : radius,
      };
    }

    case UPDATE_STORE_SEARCH_ADDRESS: {
      return {
        ...state,
        address: action.address,
      };
    }

    case UPDATE_STORE_SEARCH_MILES: {
      return {
        ...state,
        radius: action.radius,
      };
    }

    case SUBMIT_SEARCH_BY_STORE: {
      if (state.address === '') {
        return state;
      }
      return {
        ...state,
        showError: false,
        facetedFiltersList: {
          ...state.facetedFiltersList,
          [IN_STORE]: { address: state.address, radius: state.radius },
        },
      };
    }

    case CANCEL_SEARCH_BY_STORE: {
      return {
        ...state,
        address: '',
        radius: 100,
      };
    }

    case RESOLVED_ALL_STORES_BY_GEOLOCATION: {
      const storeList = filterActiveStores(action.payload);
      const filteredStore = addStoreIdToStoresByPayload(storeList);

      if (storeList.length === 0 || filteredStore.length === 0) {
        return {
          ...state,
          noStoresAvailable: true,
          showChangeYourLocation: true,
          showError: true,
        };
      }

      let preSelectedStore;

      if (state.address !== '') {
        preSelectedStore = find(filteredStore,
          (store) => { return store.name === state.address; });
      }

      return {
        ...state,
        storeListPayload: filteredStore,
        noStoresAvailable: false,
        showChangeYourLocation: false,
        showError: false,
        stores: [...filteredStore],
        ...(preSelectedStore && { selectedStoreNumber: preSelectedStore.storeNumber }),
        ...(preSelectedStore && { selectedStore: preSelectedStore }),
      };
    }

    case RESOLVED_ALL_STORES_BY_ADDRESS: {
      const storeList = filterActiveStores(action.payload);
      const { pageId } = action.payload;
      const filteredStore = addStoreIdToStoresByPayload(storeList);

      if (pageId !== 'PAGE_ID_SRP' && pageId !== 'PAGE_ID_PLA' && pageId !== 'PAGE_ID_PDP' && (storeList.length === 0 || filteredStore.length === 0)) {
        return {
          ...state,
          noStoresAvailable: true,
          showError: true,
        };
      }

      let preSelectedStore;

      if (state.address !== '') {
        preSelectedStore = find(filteredStore,
          (store) => { return store.name === state.address; });
      }

      const stores = (pageId === 'PAGE_ID_SRP' || pageId === 'PAGE_ID_PLA' || pageId === 'PAGE_ID_PDP') ? storeList : [...filteredStore];

      return {
        ...state,
        storeListPayload: filteredStore,
        noStoresAvailable: false,
        showChangeYourLocation: false,
        showError: false,
        stores,
        ...(preSelectedStore && { selectedStoreNumber: preSelectedStore.storeNumber }),
        ...(preSelectedStore && { selectedStore: preSelectedStore }),
      };
    }

    case REJECTED_ALL_STORES:
      return {
        ...state,
        storeListPayload: [],
        stores: [],
        noStoresAvailable: true,
        showError: false,
      };

    case SHOW_ERROR_MESSAGE:
      return {
        ...state,
        showError: true,
      };

    case NO_LAT_LONG_FAVSTORE: {
      return {
        ...state,
        stores: [],
        storeListPayload: [],
        noStoresAvailable: true,
      };
    }

    case TOGGLE_CHANGE_YOUR_LOCATION:
      return {
        ...state,
        showChangeYourLocation: !state.showChangeYourLocation,
      };

    case SELECT_YOUR_STORE:
      return {
        ...state,
        selectedStoreNumber: action.selectedStore.storeNumber,
        selectedStore: action.selectedStore,
        noStoresAvailable: false,
      };

    case CLEAR_STORE_SELECTION:
      return {
        ...state,
        selectedStoreNumber: '',
        selectedStore: {},
      };

    case UPDATE_IN_STORE_SEARCH_ADDRESS:
      return {
        ...state,
        addressField: action.address,
      };

    case UPDATE_IN_STORE_SEARCH_MILES:
      return {
        ...state,
        radiusField: action.radius,
      };

    case SUBMIT_SEARCH_STORE_BY_LOCATION:
      return {
        ...state,
      };

    case CANCEL_SEARCH_STORE_BY_LOCATION:
      return {
        ...state,
        addressField: '',
        radiusField: 100,
        noStoresAvailable: false,
      };

    case SET_SEO_FACETS_ORDER:
      return {
        ...state,
        seoFacets: action.payload,
      };

    case FORCE_SEO_FIRST_PARAM:
      return {
        ...state,
        firstParam: action.payload,
      };

    case FORCE_SEO_SECOND_PARAM:
      return {
        ...state,
        secondParam: action.payload,
      };

    case CLEAR_SAVED_SEO_PARAMS:
      return {
        ...state,
        firstParam: {},
        secondParam: {},
      };

    case CLEAR_SEO_FIRST_PARAM:
      return {
        ...state,
        firstParam: {},
      };

    case CLEAR_SEO_SECOND_PARAM:
      return {
        ...state,
        secondParam: {},
      };

    case BLOCK_CANON_FACET_UPDATES:
      return {
        ...state,
        blockCanonical: action.payload,
      };

    case NO_INDEX_FROM_SELECTION:
      return {
        ...state,
        noIndexFromSelection: action.payload,
      };

    default:
      return state;
  }
};
