import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link as RouterLink } from 'react-router';
import styles from './Link.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
  variant: PropTypes.oneOf(['text', 'cta', 'menu']),
};

const defaultProps = {
  className: '',
  children: null,
  uiComponent: 'Link',
  testID: undefined,
  to: undefined,
  variant: 'text',
};

const Link = ({
  className,
  children,
  uiComponent,
  testID,
  to,
  variant,
  ...props
}) => {
  const classList = cx({
    Link: true,
    'Link--cta': variant === 'cta',
    'Link--menu': variant === 'menu',
  }, [className].join(' ').trim().replace(/\s+/g, ' '));
  if (to) {
    return (
      <RouterLink
        className={classList}
        to={to}
        {...props}
        data-ui-component={uiComponent}
        data-tid={testID}
      >
        {children}
      </RouterLink>
    );
  }
  return (
    <a
      className={classList}
      {...props}
      data-ui-component={uiComponent}
      data-tid={testID}
    >
      {children}
    </a>
  );
};

Link.displayName = 'Link';
Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
