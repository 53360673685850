export const ECMSlots = {
  PDP_BANNER: {
    serviceUrl: '/pages/pdp/banner',
    className: 'pdp_banner',
    contentId: 'pdpBanner',
    fallback: '',
  },
  PDP_BANNER_BOTTOM: {
    serviceUrl: '/contentservice/pages/pdp/bottomBanner',
    className: 'pdp_banner_bottom',
    contentId: 'pdpBannerBottom',
    fallback: '',
  },
  PLP_BANNER_BOTTOM: {
    serviceUrl: '/contentservice/pages/plp/bottomBanner',
    className: 'plp_banner_bottom',
    contentId: 'plpBannerBottom',
    fallback: '',
  },
  HOME_TOP: {
    serviceUrl: '/pages/home',
    className: 'home_top',
    contentId: 'homeTop',
    fallback: '/category/cat000000/fallback/main.html',
    personalize: true,
    cache: true,
    prefetch: true,
  },
  SITE_TICKER: {
    serviceUrl: '/fragments/siteticker',
    className: 'site_ticker',
    contentId: 'siteTicker',
    fallback: '',
    cache: true,
  },
  READY_LAUNCHES: {
    serviceUrl: '/preview/launchDetails',
    className: 'ready_launches',
    contentId: 'readyLaunches',
    fallback: '',
  },
  PLP_BANNER: {
    serviceUrl: '/fragments/category/banner',
    className: 'plp_banner',
    contentId: 'plpBanner',
    fallback: '',
  },
  PLP_BANNER_F0: {
    serviceUrl: '/fragments/category/collections/',
    className: 'plp_banner',
    contentId: 'plpBannerF0',
    fallback: '',
  },
  MAGAZINE_INDEX: {
    serviceUrl: '/magazine/index',
    className: 'magazine_index',
    contentId: 'plpBanner',
    fallback: '',
  },
  SILO_FULL_ASSET: {
    serviceUrl: '',
    className: 'silo_asset',
    contentId: 'siloAsset',
    fallback: '',
  },
  PLP_PROMO_TILE_1: {
    serviceUrl: '/fragments/category/promotile1',
    className: 'plp_promoTile1',
    contentId: 'plpPromoTile1',
    fallback: '',
  },
  PLP_PROMO_TILE_2: {
    serviceUrl: '/fragments/category/promotile2',
    className: 'plp_promoTile2',
    contentId: 'plpPromoTile2',
    fallback: '',
  },
  PLP_PROMO_TILE_3: {
    serviceUrl: '/fragments/category/promotile3',
    className: 'plp_promoTile3',
    contentId: 'plpPromoTile3',
    fallback: '',
  },
  PLP_PROMO_TILE_4: {
    serviceUrl: '/fragments/category/promotile4',
    className: 'plp_promoTile4',
    contentId: 'plpPromoTile4',
    fallback: '',
  },
  PLP_PROMO_TILES: {
    serviceUrl: '/fragments/category/promotile',
    contentId: 'plpPromoTile',
    elements: 'promotile',
    slots: [
      'PLP_PROMO_TILE_1',
      'PLP_PROMO_TILE_2',
      'PLP_PROMO_TILE_3',
      'PLP_PROMO_TILE_4',
    ],
  },
  SRP_PROMO_TILES: {
    serviceUrl: '/fragments/category/promotile',
    contentId: 'srp_ecm_content',
    elements: 'promotile',
    slots: [
      'SRP_PROMO_TILE_0',
      'SRP_PROMO_TILE_1',
      'SRP_PROMO_TILE_2',
      'SRP_PROMO_TILE_3',
    ],
  },
  SRP_PROMO_TILE_0: {
    serviceUrl: '/fragments/category/promotile0',
    contentId: 'srppromotile0',
    className: 'srpPromotile0',
    fallback: '',
  },
  SRP_PROMO_TILE_1: {
    serviceUrl: '/fragments/category/promotile1',
    contentId: 'srppromotile1',
    className: 'srpPromotile1',
    fallback: '',
  },
  SRP_PROMO_TILE_2: {
    serviceUrl: '/fragments/category/promotile2',
    contentId: 'srppromotile2',
    className: 'srpPromotile2',
    fallback: '',
  },
  SRP_PROMO_TILE_3: {
    serviceUrl: '/fragments/category/promotile3',
    contentId: 'srppromotile3',
    className: 'srpPromotile3',
    fallback: '',
  },
  DRAWER_AEM_ASSET: {
    serviceUrl: '/fragments/silos/drawers',
    className: 'aem_drawer_asset',
    contentId: 'aemDrawerAsset',
    fallback: '',
  },
  SRP_BOTTOM_BANNER: {
    serviceUrl: '/contentservice/pages/srp/bottomBanner',
    className: 'srp_banner_bottom',
    contentId: 'srpBannerBottom',
    fallback: '',
  },
  SRP_BANNER: {
    serviceUrl: '/fragments/srp/banner',
    className: 'srp_banner',
    contentId: 'srpBanner',
    fallback: '',
  },
};

export const ECMParams = {
  PARAM_LAUNCH: 'ECMPreview',
  PARAM_PERSONALIZE: 'personalize',
};

export const ECMCookies = [
  'ECMPreview',
];

export const ECMLayouts = {
  A: [
    'grid-100 tablet-grid-100 mobile-grid-100 grid-parent',
  ],
  B: [
    'grid-50 tablet-grid-50 mobile-grid-100 grid-parent',
    'grid-50 tablet-grid-50 mobile-grid-100 grid-parent',
  ],
  BINMOBILE: [
    'grid-50 tablet-grid-50 mobile-grid-50 grid-parent',
    'grid-50 tablet-grid-50 mobile-grid-50 grid-parent',
  ],
  C: [
    'grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
    'grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
    'grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
  ],
  CINMOBILE: [
    'grid-33 tablet-grid-33 mobile-grid-33 grid-parent',
    'grid-33 tablet-grid-33 mobile-grid-33 grid-parent',
    'grid-33 tablet-grid-33 mobile-grid-33 grid-parent',
  ],
  D: [
    'grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
    'grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
    'grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
    'grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
  ],
  DINMOBILE: [
    'grid-25 tablet-grid-25 mobile-grid-25 grid-parent',
    'grid-25 tablet-grid-25 mobile-grid-25 grid-parent',
    'grid-25 tablet-grid-25 mobile-grid-25 grid-parent',
    'grid-25 tablet-grid-25 mobile-grid-25 grid-parent',
  ],
  E: [
    'grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
    'grid-66 tablet-grid-66 mobile-grid-100 grid-parent',
  ],
  EINMOBILE: [
    'grid-33 tablet-grid-33 mobile-grid-33 grid-parent',
    'grid-66 tablet-grid-66 mobile-grid-66 grid-parent',
  ],
  F: [
    'grid-66 tablet-grid-66 mobile-grid-100 grid-parent',
    'grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
  ],
  FINMOBILE: [
    'grid-66 tablet-grid-66 mobile-grid-66 grid-parent',
    'grid-33 tablet-grid-33 mobile-grid-33 grid-parent',
  ],
  G: [
    'grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'grid-20 tablet-grid-20 mobile-grid-100 grid-parent',
  ],
};

export const ECMHOMEMETATITLE = `${process.env.BRAND_NAME === 'HC' ? 'Designer Furniture and Home Decor' : 'Designer Clothing, Shoes, Handbags, & Beauty'}`;
export const ECMHOMEMETADESCRIPTION = `${process.env.BRAND_NAME === 'HC' ? 'Shop designer furniture and home decor at Horchow. Discover luxury accessories and exclusive home details you won&rsquo;t find anywhere else.'
  : 'Free Shipping & Free Returns at Neiman Marcus. Shop the latest styles from top designers including Michael Kors, Tory Burch, Burberry, Christian Louboutin, kate spade & more.'}`;
export const ECMHOMEMETAKEYWORDS = `${process.env.BRAND_NAME === 'HC' ? '' : 'Michael Kors, Tory Burch, Burberry, Cole Haan, kate spade'}`;
export const ECMHOMEMETATAGS = [{ name: 'google-site-verification', content: '4TSe1Ap-Tkkl6XOqW_137eADB0NQK1AyerqncN7nqj4' }, { name: 'y_key', content: 'c5788d1871f19431' }, { name: 'msvalidate.01', content: 'BE59176161C6983857E2CD5A2DA1B55A' }, { name: 'google-site-verification', content: 'ckCkElZN3t_2zXORocQoiFP0zyMfgf5ppvcmVKkmzdg' }, { name: 'google-site-verification', content: 'N5hbhQZqJqg78QE_12oIVPEK5oS1NoSf8HNevd-SwHw' }, { name: 'google-site-verification', content: 'SUSYQVXGIsEi61vuks-uF_jH7BpWV5Z_GnrkjrpMs18' }, { name: 'google-site-verification', content: 'gdMX1YZmRAx5cq35GMOhNlXc2ZaQJKEffTXFv2DjapE' }];
export const ECMHOMERELLINKS = [{ rel: 'apple-touch-icon', href: '/category/mobile/NM-icon-129.png' }];
export const ECMHOMECANONICALURL = 'https://www.neimanmarcus.com';
export const ECMHOMEJSONLD = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Neiman Marcus',
  legalName: 'Neiman Marcus',
  url: 'https://www.neimanmarcus.com',
  description: 'Free Shipping & Free Returns at Neiman Marcus. Shop the latest styles from top designers including Michael Kors, Tory Burch, Burberry, Christian Louboutin, kate spade & more.',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://www.neimanmarcus.com/search.jsp?from=brSearch&responsive=true&request_type=search&search_type=keyword&q={search_term_string}&l={search_term_string}&fl=&refpg=SL',
    'query-input': 'required name=search_term_string',
  },
  logo: 'https://www.neimanmarcus.com/category/images/svg/neiman-marcus-logo.svg',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+1-888-888-4757',
    contactType: 'customer service',
  },
  sameAs: [
    'https://www.facebook.com/neimanmarcus',
    'https://twitter.com/neimanmarcus',
    'https://www.pinterest.com/neimanmarcus/',
    'https://www.instagram.com/neimanmarcus/',
  ],
};
export const ECMHOMEJSONLD_NEWSEARCH = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Neiman Marcus',
  legalName: 'Neiman Marcus',
  url: 'https://www.neimanmarcus.com',
  description: 'Free Shipping & Free Returns at Neiman Marcus. Shop the latest styles from top designers including Michael Kors, Tory Burch, Burberry, Christian Louboutin, kate spade & more.',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://www.neimanmarcus.com/s/?from=brSearch&responsive=true&request_type=search&search_type=keyword&q={search_term_string}&l={search_term_string}&fl=&refpg=SL',
    'query-input': 'required name=search_term_string',
  },
  logo: 'https://www.neimanmarcus.com/category/images/svg/neiman-marcus-logo.svg',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+1-888-888-4757',
    contactType: 'customer service',
  },
  sameAs: [
    'https://www.facebook.com/neimanmarcus',
    'https://twitter.com/neimanmarcus',
    'https://www.pinterest.com/neimanmarcus/',
    'https://www.instagram.com/neimanmarcus/',
  ],
};
