import get from 'lodash/get';
import { getCloudinaryImageSetIfEnabled } from 'client-utils/utilities-cloudinary';

/* eslint-disable max-len */
const findEntryById = (id, entries) => entries.find((entry) => entry.sys.id === id);

const addFields = (entry, entries) => {
  const matchedEntry = findEntryById(entry.sys.id, entries);
  /* eslint-disable no-param-reassign */
  if (matchedEntry) {
    entry.contentType = matchedEntry.sys.contentType.sys.id;
    entry.fields = matchedEntry.fields;
  }
};

const normalizer = (item, entries) => {
  const { fields } = item;

  if (fields) {
    /* eslint-disable array-callback-return */
    const fieldsKeys = Object.keys(fields);

    fieldsKeys.map((key) => {
      if (Array.isArray(fields[key])) {
        fields[key].map((entry) => {
          if (get(entry, 'sys.id')) {
            addFields(entry, entries);
            normalizer(entry, entries);
          }
        });
      } else if (typeof fields[key] === 'object') {
        const entry = fields[key];
        if (get(entry, 'sys.id')) {
          addFields(entry, entries);
          normalizer(entry, entries);
        }
      }
    });
  }
};

const normalizeJSON = (rawObject) => {
  const entries = rawObject.includes.Entry;

  rawObject.items.map((item) => {
    item.contentType = item.sys.contentType.sys.id;
    normalizer(item, entries);
  });

  return rawObject.items;
};

const normalizeProductJSON = (productsJSON) => {
  const normalized = productsJSON.map((product) => {
    const imageSet = getCloudinaryImageSetIfEnabled(product?.media?.main);
    return ({
      id: product.id,
      designerName: product.designer.name,
      name: product.name,
      price: product.price,
      url: product.details.canonicalUrl,
      promotions: product.promotions,
      imageUrl: imageSet.medium.url,
      isGroup: product.isGroup,
      childProductIds: product.childProductIds,
      displayable: product.displayable,
    });
  });
  return normalized;
};

export {
  normalizeJSON,
  normalizeProductJSON,
};
