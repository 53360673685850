import find from 'lodash/find';
import findKey from 'lodash/findKey';
import uniqBy from 'lodash/uniqBy';

// Remove duplicate indexes from option matrix and rebuild option Matrix with available skus
export const rebuildOptionMatrix = (product, sizeName, indexes) => {
  const availableSkus = product?.skus
    .filter((item) => item?.sellable && sizeName === item?.size?.name)
    .map((item) => (
      {
        id: item.id,
        colorName: item?.color?.name,
        sizeName: item?.size?.name,
      }
    ));

  const firstElement = indexes.shift();

  const newOptionMatrix = product?.options?.optionMatrix.filter(
    (item, index) => !indexes.includes(index),
  );

  const newOptions = [];

  const getNewOptions = (skuId, index) => {
    if (!skuId) {
      indexes.forEach((item) => {
        if (product.options.optionMatrix[item][index]) {
          // eslint-disable-next-line no-param-reassign
          skuId = product.options.optionMatrix[item][index];
        }
      });
    }

    const sku = find(product?.skus, { id: skuId });

    if (sku?.sellable) {
      newOptions.push(sku.id);
    } else {
      // find an available sku for the same size and color
      const availableSku = find(availableSkus, {
        colorName: sku?.color.name,
        sizeName: sku?.size.name,
      });
      if (availableSku?.id) {
        newOptions.push(availableSku.id);
      } else {
        newOptions.push(skuId);
      }
    }
  };

  newOptionMatrix[firstElement]
    .find((skuId, index) => getNewOptions(skuId, index));

  newOptionMatrix[firstElement] = newOptions;

  return newOptionMatrix;
};

export const checkForDuplicateSizes = (product) => {
  const sizes = find(product?.options?.productOptions, { label: 'size' });
  const sizeKey = findKey(product?.options?.productOptions, { label: 'size' });

  if (!sizes || sizes.length === 0) {
    return product;
  }

  const uniqueSizes = uniqBy(sizes?.values, 'name');

  if (uniqueSizes.length === sizes?.values.length) {
    return product;
  }

  // find duplicate size indexes in optionMatrix
  uniqueSizes.forEach((item) => {
    const indexes = [];

    const updatedIndexes = sizes?.values;
    updatedIndexes.forEach((value, index) => {
      if (value.name === item.name) {
        indexes.push(index);
      }
    });

    if (indexes.length > 1) {
      product.options.optionMatrix = rebuildOptionMatrix(// eslint-disable-line no-param-reassign
        product,
        sizes?.values[indexes[0]]?.name,
        indexes,
      );
      // Keep first item and remove duplicate indexes from product options
      product.options.productOptions[sizeKey].values = sizes?.values.filter(// eslint-disable-line no-param-reassign
        (element, index) => !indexes.includes(index),
      );
    }
  });

  return product;
};
