import { combineReducers } from 'redux';
import ecmContentReducers from 'ecm/reducers/reducers-ecmcontent';
import ecmRecommendationsReducers from 'ecm/reducers/reducers-ecmrecommendations';
import ecmStoreEventsReducers from 'ecm/reducers/reducers-ecmstoreevents';
import ecmQuickLookProductReducer from 'ecm/reducers/reducers-ecmproductinfo';
import ecmP13nReducer from 'ecm/reducers/reducers-ecmP13n';

export default combineReducers({
  ecmContent: ecmContentReducers,
  ecmRecommendations: ecmRecommendationsReducers,
  ecmStoreEvents: ecmStoreEventsReducers,
  ecmDynamicProductInfo: ecmQuickLookProductReducer,
  ecmP13n: ecmP13nReducer,
});
