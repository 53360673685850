import { GET_APPOINTMENTS } from '../actions';

const defaultState = {
  stores: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_APPOINTMENTS:
      return {
        stores: action.payload,
      };
    default:
      return state;
  }
};
