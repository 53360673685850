import React from 'react';
import { REQUIRED_MONOGRAM } from 'pdp/constants';
import './personalizationCTA.scss';

const PersonalizationCTA = ({
  editCustomizations,
  removeCustomizations,
  behaviorCode,
}) => {
  if (behaviorCode === REQUIRED_MONOGRAM) {
    return (
      <div className="PersonalizationCTA PersonalizationCTA__inline">
        <button
          type="button"
          aria-label="Edit Monogram"
          className="PersonalizationCTA__edit-button__require"
          id="personalizationEdit"
          onClick={editCustomizations}
        >
          Edit
        </button>
      </div>
    );
  }
  return (
    <div className="PersonalizationCTA">
      <button
        type="button"
        aria-label="Edit Monogram"
        className="PersonalizationCTA__edit-button__optional"
        onClick={editCustomizations}
      >
        Edit
      </button>
      <button
        type="button"
        aria-label="Remove Monogram"
        className="PersonalizationCTA__remove-button"
        onClick={removeCustomizations}
      >
        Remove
      </button>
    </div>
  );
};

export default PersonalizationCTA;
