/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import queryString from 'query-string';
import {
  updateParams,
  updateParamsWithSEO,
  clearAllParams,
  clearParamsForMultiSelect,
  updateParamsWithStoreName,
} from 'client-utils/utilities-router';
import httpWithLogging from 'universal/http-client';
import reactCookie from 'react-cookie';
import { isMobile } from 'client-utils/utilities-page';
import { SELECT_FILTER_OPTION_SRP } from 'srp/actions/actions';
import { PAGE_ID_SRP } from 'shared/actions/actions-page';

export const SELECT_FILTER = 'SELECT_FILTER';
export const EXPAND_FACET = 'EXPAND_FACET';
export const SELECT_FILTER_OPTION = 'SELECT_FILTER_OPTION';
export const CLEAR_ALL_FILTER_OPTION = 'CLEAR_ALL_FILTER_OPTION';
export const UPDATE_URL_SEARCH_PARAM = 'UPDATE_URL_SEARCH_PARAM';
export const FILTER_DESIGNER_BY_NAME = 'FILTER_DESIGNER_BY_NAME';
export const OPEN_FACETED_MOBILE_FILTER = 'OPEN_FACETED_MOBILE_FILTER';
export const CLOSE_FACETED_MOBILE_FILTER = 'CLOSE_FACETED_MOBILE_FILTER';
export const SELECT_FILTER_BY_ROUTE_PARAM = 'SELECT_FILTER_BY_ROUTE_PARAM';
export const UPDATE_STORE_SEARCH_ADDRESS = 'UPDATE_STORE_SEARCH_ADDRESS';
export const UPDATE_STORE_SEARCH_MILES = 'UPDATE_STORE_SEARCH_MILES';
export const SUBMIT_SEARCH_BY_STORE = 'SUBMIT_SEARCH_BY_STORE';
export const CANCEL_SEARCH_BY_STORE = 'CANCEL_SEARCH_BY_STORE';
export const IN_STORE = 'In Store';
export const SIZE_FACET = 'Size';
export const LOADING_ALL_STORES = 'LOADING_ALL_STORES';
export const RESOLVED_ALL_STORES_BY_ADDRESS = 'RESOLVED_ALL_STORES_BY_ADDRESS';
export const RESOLVED_ALL_STORES_BY_GEOLOCATION = 'RESOLVED_ALL_STORES_BY_GEOLOCATION';
export const REJECTED_ALL_STORES = 'REJECTED_ALL_STORES';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const NO_LAT_LONG_FAVSTORE = 'NO_LAT_LONG_FAVSTORE';
export const TOGGLE_CHANGE_YOUR_LOCATION = 'TOGGLE_CHANGE_YOUR_LOCATION';
export const SELECT_YOUR_STORE = 'SELECT_YOUR_STORE';
export const CLEAR_STORE_SELECTION = 'CLEAR_STORE_SELECTION';
export const UPDATE_IN_STORE_SEARCH_ADDRESS = 'UPDATE_IN_STORE_SEARCH_ADDRESS';
export const UPDATE_IN_STORE_SEARCH_MILES = 'UPDATE_IN_STORE_SEARCH_MILES';
export const SUBMIT_SEARCH_STORE_BY_LOCATION = 'SUBMIT_SEARCH_STORE_BY_LOCATION';
export const CANCEL_SEARCH_STORE_BY_LOCATION = 'CANCEL_SEARCH_STORE_BY_LOCATION';
export const TOGGLE_CATEGORY_SELECTOR = 'TOGGLE_CATEGORY_SELECTOR';
export const SET_CANONICAL_URL = 'SET_CANONICAL_URL';
export const FORCE_SEO_FIRST_PARAM = 'FORCE_SEO_FIRST_PARAM';
export const FORCE_SEO_SECOND_PARAM = 'FORCE_SEO_SECOND_PARAM';
export const SAVE_UNFORCED_PARAMS = 'SAVE_UNFORCED_PARAMS';
export const CLEAR_SAVED_SEO_PARAMS = 'CLEAR_SAVED_SEO_PARAMS';
export const CLEAR_SEO_FIRST_PARAM = 'CLEAR_SEO_FIRST_PARAM';
export const CLEAR_SEO_SECOND_PARAM = 'CLEAR_SEO_SECOND_PARAM';
export const BLOCK_CANON_FACET_UPDATES = 'BLOCK_CANON_FACET_UPDATES';
export const NO_INDEX_FROM_SELECTION = 'NO_INDEX_FROM_SELECTION';


export function handleFilterSelection(selectedFilterKey) {
  return (dispatch) => {
    dispatch({
      type: SELECT_FILTER,
      payload: {
        selectedFilterKey,
      },
    });
  };
}

export function handleFilterOptionSelection(
  selectedFilterKey,
  selectedFilterOptionKey,
  pageId = null
) {
  return {
    type: pageId === PAGE_ID_SRP ? SELECT_FILTER_OPTION_SRP : SELECT_FILTER_OPTION,
    payload: {
      selectedFilterOptionKey,
      selectedFilterKey,
    },
  };
}

export function handleClearAllFilters() {
  return { type: CLEAR_ALL_FILTER_OPTION };
}

export function toggleCategorySelector() {
  return { type: TOGGLE_CATEGORY_SELECTOR };
}

export function handleURLUpdate(router = {}, isClearAll = false, selectedKey = '', filterOptionValue = '', isMultiSelect) {
  return (dispatch, getState) => {
    const state = getState();

    const geolocatedStore = get(state, 'facetedLeftNav.storeListPayload[0].storeId', null);

    const seoToggle = state?.toggles?.SEO_BUCKET_ONE;
    const seoFacets = state?.facetedLeftNav?.seoFacets;
    const pathname = router?.location?.pathname;

    if (isClearAll && isEmpty(router) === false) {
      if (seoToggle) {
        dispatch({
          type: SET_CANONICAL_URL,
          canonicalUrl: `https://www.neimanmarcus.com${pathname}`,
        });
        dispatch({
          type: CLEAR_SAVED_SEO_PARAMS,
        });
        dispatch({
          type: BLOCK_CANON_FACET_UPDATES,
          payload: false,
        });
        dispatch({
          type: NO_INDEX_FROM_SELECTION,
          payload: false,
        });
        clearAllParams(router, seoFacets[0], seoFacets[1]);
      } else {
        updateParamsWithStoreName(router, { filterOptions: null, page: 1 }, '');
      }
      dispatch({ type: UPDATE_URL_SEARCH_PARAM, param: null });
      return true;
    }

    const pageId = get(state, 'page.pageId', null);
    let facetedFiltersList = [];

    switch (pageId) {
      case 'PAGE_ID_PLP':
        facetedFiltersList = get(state, 'facetedLeftNav.facetedFiltersList', null);
        break;
      case 'PAGE_ID_SRP':
        facetedFiltersList = get(state, 'srp.search.facetedFiltersList', null);
        break;
      default:
        facetedFiltersList = get(state, 'facetedLeftNav.facetedFiltersList', null);
    }

    const storeChange = get(state, 'facetedLeftNav.storeChange', false);

    const selectedZip = reactCookie.load('dt_selected_zipcode') && reactCookie.load('dt_selected_zipcode').slice(1, -1);
    let zipCode = '';

    const storeNoFromCookie = reactCookie.load('dt_selected_store') !== undefined ? reactCookie.load('dt_selected_store').split('/')[0] : null;
    const geolocatedStoreNo = geolocatedStore !== null ? geolocatedStore.split('/')[0] : null;

    let storeNo = storeNoFromCookie !== null ? storeNoFromCookie : geolocatedStoreNo;

    if (facetedFiltersList !== null && isEmpty(router) === false) {
      const results = {};
      Object.keys(facetedFiltersList).forEach((facetFilterKey) => {
        if (!results[facetFilterKey]) {
          results[facetFilterKey] = [];
        }

        if (facetFilterKey === IN_STORE) {
          zipCode = selectedZip || get(state, 'api.requestContext.akamaiEdgescape.zip', '');
          let sdd = '';
          let ndd = '';

          Object.keys(facetedFiltersList[facetFilterKey]).forEach((facetKey) => {
            if (facetKey === 'csp') {
              if (facetedFiltersList[facetFilterKey][facetKey] === true) {
                sdd = '';
                ndd = '';
                zipCode = '';
              }

              if (facetedFiltersList[facetFilterKey][facetKey] === false) {
                storeNo = '';
              }
            }

            if (facetKey === 'sdd' && facetedFiltersList[facetFilterKey][facetKey] === true) {
              sdd = 'sdd';
              storeNo = '';
            }

            if (facetKey === 'ndd' && facetedFiltersList[facetFilterKey][facetKey] === true) {
              ndd = 'ndd';
              storeNo = '';
            }

            if (facetKey === 'zipChange') {
              storeNo = '';
              sdd = '';
              ndd = '';
            }

            if (facetKey === 'storeChange') {
              zipCode = '';
              sdd = '';
              ndd = '';
            }
          });

          results[IN_STORE] = [zipCode, storeNo, sdd, ndd];
        }


        Object.keys(facetedFiltersList[facetFilterKey]).forEach(
          (facetKey) => {
            if (
              facetedFiltersList[facetFilterKey][facetKey]
              && facetFilterKey !== IN_STORE
              && facetFilterKey !== 'Category'
            ) {
              results[facetFilterKey].push(facetKey);
            }
          }
        );

        if (facetFilterKey === 'Category') {
          if (pageId === 'PAGE_ID_SRP') {
            results[facetFilterKey] = values(facetedFiltersList[facetFilterKey]);
          } else {
            results[facetFilterKey].push(...facetedFiltersList[facetFilterKey]);
          }
        }
      });

      if (storeChange) {
        results[IN_STORE] = ['', storeNo, '', ''];
      }

      const blockCanonical = get(state, 'facetedLeftNav.blockCanonical', false);

      if (seoToggle && pageId === 'PAGE_ID_PLP') {
        if (isMultiSelect || blockCanonical) {
          const params = { filterOptions: JSON.stringify(results), page: 1 };
          const lowerCaseKey = selectedKey.toLowerCase();
          clearParamsForMultiSelect(router, lowerCaseKey, params);
          dispatch({
            type: SET_CANONICAL_URL,
            canonicalUrl: `https://www.neimanmarcus.com${pathname}`,
          });
          dispatch({
            type: CLEAR_SAVED_SEO_PARAMS,
          });
        } else {
          selectedKey = selectedKey.toLowerCase();

          const firstParam = {};
          const secondParam = {};
          let savedFirstParam = state.facetedLeftNav.firstParam;
          let savedSecondParam = state.facetedLeftNav.secondParam;

          if (selectedKey === seoFacets[0]) {
            firstParam[selectedKey] = filterOptionValue;
            if (isEmpty(savedFirstParam)) {
              dispatch({
                type: FORCE_SEO_FIRST_PARAM,
                payload: firstParam,
              });
            }
          } else if (selectedKey === seoFacets[1]) {
            secondParam[selectedKey] = filterOptionValue;
            if (isEmpty(savedSecondParam)) {
              dispatch({
                type: FORCE_SEO_SECOND_PARAM,
                payload: secondParam,
              });
            }
          }

          const query = router?.location?.query;
          const queryKeys = Object.keys(query);

          let isDeselectFirst = false;
          if (!isEmpty(savedFirstParam)) {
            const firstRankFacets = query[seoFacets[0]];
            const selectedValueIndex = firstRankFacets && firstRankFacets.indexOf(filterOptionValue);
            isDeselectFirst = selectedValueIndex !== -1;
            if (isDeselectFirst) {
              savedSecondParam = {};
            }
          }
          let isDeselectSecond = false;
          if (!isEmpty(savedSecondParam)) {
            const secondRankFacets = query[seoFacets[1]];
            const selectedValueIndex = secondRankFacets
              && secondRankFacets.indexOf(filterOptionValue);
            isDeselectSecond = selectedValueIndex !== -1;
            if (isDeselectSecond) {
              savedFirstParam = {};
            }
          }

          let firstQueryParam = '';
          if (!isEmpty(firstParam)) {
            const firstParamKey = Object.keys(firstParam);
            const firstKey = firstParamKey;
            const firstValue = firstParam[firstParamKey];
            if (isDeselectFirst) {
              if (savedFirstParam[selectedKey] === filterOptionValue) {
                firstQueryParam = '';
              }
              dispatch({
                type: CLEAR_SEO_FIRST_PARAM,
              });
              dispatch({
                type: BLOCK_CANON_FACET_UPDATES,
                payload: true,
              });
            } else if (isEmpty(savedFirstParam)) {
              const nextParam = { [firstKey]: firstValue };
              firstQueryParam = `${queryString.stringify({ ...nextParam })}`;
            } else {
              firstQueryParam = `${queryString.stringify({ ...savedFirstParam })}`;
            }
          } else if (queryKeys.indexOf(seoFacets[0]) !== -1) {
            const nextQueryParam = { [seoFacets[0]]: query[seoFacets[0]] };
            firstQueryParam = `${queryString.stringify({ ...nextQueryParam })}`;
          }

          let secondQueryParam = '';
          if (!isEmpty(secondParam)) {
            const secondParamKey = Object.keys(secondParam);
            const secondKey = secondParamKey;
            const secondValue = secondParam[secondParamKey];
            if (isDeselectSecond) {
              if (savedSecondParam[selectedKey] === filterOptionValue) {
                secondQueryParam = '';
              }
              dispatch({
                type: CLEAR_SEO_SECOND_PARAM,
              });
              dispatch({
                type: BLOCK_CANON_FACET_UPDATES,
                payload: true,
              });
            } else if (isEmpty(savedSecondParam)) {
              const nextParam = { [secondKey]: secondValue };
              secondQueryParam = `${queryString.stringify({ ...nextParam })}`;
            } else {
              secondQueryParam = `${queryString.stringify({ ...savedSecondParam })}`;
            }
          } else if (queryKeys.indexOf(seoFacets[1]) !== -1) {
            const nextQueryParam = { [seoFacets[1]]: query[seoFacets[1]] };
            secondQueryParam = `${queryString.stringify({ ...nextQueryParam })}`;
          }

          if (isEmpty(firstQueryParam) && isEmpty(secondQueryParam)) {
            dispatch({
              type: SET_CANONICAL_URL,
              canonicalUrl: `https://www.neimanmarcus.com${pathname}`,
            });
          } else if (isEmpty(firstQueryParam) && !isEmpty(secondQueryParam)) {
            dispatch({
              type: SET_CANONICAL_URL,
              canonicalUrl: `https://www.neimanmarcus.com${pathname}?${secondQueryParam}`,
            });
          } else if (!isEmpty(firstQueryParam) && isEmpty(secondQueryParam)) {
            dispatch({
              type: SET_CANONICAL_URL,
              canonicalUrl: `https://www.neimanmarcus.com${pathname}?${firstQueryParam}`,
            });
          } else {
            dispatch({
              type: SET_CANONICAL_URL,
              canonicalUrl: `https://www.neimanmarcus.com${pathname}?${firstQueryParam}&${secondQueryParam}`,
            });
          }
          const extraFirstParamSelection = !isEmpty(savedFirstParam) && !isEmpty(firstParam);
          const extraSecondParamSelection = !isEmpty(savedSecondParam) && !isEmpty(secondParam);
          const noMatchSelection = isEmpty(firstParam) && isEmpty(secondParam);
          if (extraFirstParamSelection || extraSecondParamSelection || noMatchSelection) {
            dispatch({
              type: CLEAR_SAVED_SEO_PARAMS,
            });
            dispatch({
              type: SET_CANONICAL_URL,
              canonicalUrl: false,
            });
            dispatch({
              type: BLOCK_CANON_FACET_UPDATES,
              payload: true,
            });
            dispatch({
              type: NO_INDEX_FROM_SELECTION,
              payload: true,
            });
            updateParams(router, { filterOptions: JSON.stringify(results), page: 1 });
          } else {
            updateParamsWithSEO(router, firstParam, secondParam,
              { filterOptions: JSON.stringify(results), page: 1 },
              savedFirstParam, savedSecondParam,
              isDeselectFirst, isDeselectSecond);
          }
        }
      } else {
        updateParams(router, {
          filterOptions: JSON.stringify(results),
          page: 1,
        });
      }
      dispatch({ type: UPDATE_URL_SEARCH_PARAM, param: results });
    } else {
      dispatch({ type: UPDATE_URL_SEARCH_PARAM, param: 'Error Occured' });
    }
    return true;
  };
}

export function handleDesignerFilterChange(searchText) {
  return { type: FILTER_DESIGNER_BY_NAME, designerSearchText: searchText };
}

export function openFacetedMobileFilter() {
  return { type: OPEN_FACETED_MOBILE_FILTER };
}

export function closeFacetedMobileFilter() {
  return { type: CLOSE_FACETED_MOBILE_FILTER };
}

export function handleSelectFilterByRouteParam(filterOptions, storeName) {
  return {
    type: SELECT_FILTER_BY_ROUTE_PARAM,
    routeParam: {
      filterOptions,
      storeName,
    },
  };
}

export function handleFreeFormAddressChange(address) {
  return { type: UPDATE_STORE_SEARCH_ADDRESS, address };
}

export function handleRadiusChange(radius) {
  return { type: UPDATE_STORE_SEARCH_MILES, radius };
}

export function handleInStoreSubmit() {
  return { type: SUBMIT_SEARCH_BY_STORE };
}

export function handleInStoreCancel() {
  return { type: CANCEL_SEARCH_BY_STORE };
}

export function fetchListOfStoreByAddress(address, mileRadius) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_ALL_STORES });
    const state = getState();
    const BRAND = get(state, 'brand_name', 'NM').env;
    const qs = `storesByAddressOrCoordinates?brand=${BRAND}&freeFormAddress=${address}&mileRadius=${mileRadius}`;
    const STORE_LAMBA_API_TIMEOUT = get(state, 'apiTimeouts.STORE_LAMBA_API_TIMEOUT');
    const requestApi = httpWithLogging(state, STORE_LAMBA_API_TIMEOUT);
    const pageId = get(state, 'page.pageId');

    return requestApi.get(`${NMConfig.API_STORE_LIST}/${qs}`)
      .then((successResponse) => dispatch({
        type: RESOLVED_ALL_STORES_BY_ADDRESS,
        payload: {
          storeList: successResponse.data,
          pageId,
        },
      }))
      .catch(() => {
        dispatch({ type: REJECTED_ALL_STORES });
        dispatch({ type: SHOW_ERROR_MESSAGE });
      });
  };
}

export function fetchListOfStore() {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_ALL_STORES });
    const state = getState();
    const context = state.api && state.api.requestContext;
    const lat = get(context, 'akamaiEdgescape.lat', '');// '32.8140'
    const long = get(context, 'akamaiEdgescape.long', '');// '-96.9489'
    const dtFavoriteStore = get(context, 'dtFavoriteStore', '');
    let storeNumber;
    let filterOptions = get(state, 'routing.locationBeforeTransitions.query.filterOptions', null);
    if (filterOptions !== '') {
      filterOptions = JSON.parse(filterOptions);
      const storeFilter = get(filterOptions, 'In Store', []);
      if (storeFilter.length === 1) {
        storeNumber = storeFilter[0];
      }
    }
    let qs;
    const headers = {};
    if (storeNumber) {
      qs = `storeById?storeId=${storeNumber}`;
    } else if (dtFavoriteStore.length > 0 && isMobile() === false) {
      qs = `storeById?storeId=${dtFavoriteStore}`;
    } else if (lat.length > 0 && long.length > 0) {
      const jSessionId = get(state, 'session.JSESSIONID', '');
      headers['x-cache-key'] = `${jSessionId}|100`;
      qs = `storesByAddressOrCoordinates?brand=${NMConfig.BRAND_NAME}&latitude=${lat}&longitude=${long}&mileRadius=100`;
    }
    if (qs) {
      const STORE_LAMBA_API_TIMEOUT = get(state, 'apiTimeouts.STORE_LAMBA_API_TIMEOUT');
      const requestApi = httpWithLogging(state, STORE_LAMBA_API_TIMEOUT);
      return requestApi.get(`${NMConfig.API_STORE_LIST}/${qs}`, { headers })
        .then((successResponse) => {
          dispatch({
            type: RESOLVED_ALL_STORES_BY_GEOLOCATION,
            payload: {
              storeList: successResponse.data,
            },
          });
        })
        .catch((err) => dispatch({ type: REJECTED_ALL_STORES, error: err }));
    }
    dispatch({ type: NO_LAT_LONG_FAVSTORE });

    return true;
  };
}

export function handleToggleChangeyourLocation() {
  return { type: TOGGLE_CHANGE_YOUR_LOCATION };
}

export function handleStoreSelection(selectedStore) {
  return (dispatch) => {
    reactCookie.save('dt_selected_store', selectedStore.id, { path: '/' });
    dispatch({ type: SELECT_YOUR_STORE, selectedStore });
  };
}

export function clearStoreSelection() {
  return { type: CLEAR_STORE_SELECTION };
}

export function handleFreeFormAddressFieldChange(address) {
  return { type: UPDATE_IN_STORE_SEARCH_ADDRESS, address };
}

export function handleRadiusFieldChange(radius) {
  return { type: UPDATE_IN_STORE_SEARCH_MILES, radius };
}

export function handleSearchForStoreSubmit() {
  return { type: SUBMIT_SEARCH_STORE_BY_LOCATION };
}

export function handleSearchForStoreCancel() {
  reactCookie.remove('dt_selected_store', { path: '/' });
  return { type: CANCEL_SEARCH_STORE_BY_LOCATION };
}
