import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { unescape } from 'he';
import ListGroup from '../../lib/ListGroup';
import ListGroupItem from '../../lib/ListGroupItem';
import Link from '../../lib/Link';
import styles from './QuickLinks.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
  pageID: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired,
};

const defaultProps = {
  className: '',
  uiComponent: 'QuickLinks',
  testID: undefined,
};

const QuickLinks = ({
  className,
  uiComponent,
  testID,
  pageID,
  product,
  onClick = () => {},
  ...props
}) => {
  const classList = cx(['QuickLinks', className].join(' ').trim().replace(/\s+/g, ' '));
  const {
    designer = {},
    hierarchy = [],
  } = product;
  const { name: designerName } = designer;
  const [firstHierarchy = {}] = hierarchy;
  const { level1, level2 } = firstHierarchy;
  const hasDesignerName = designerName && designerName.length > 0;
  const decodedDesignerName = unescape(designerName || '');

  const quickLinks = [];
  if (hasDesignerName) {
    quickLinks.push({
      key: 'qlDesigner',
      icid: `${pageID}_QLinks1`,
      title: decodedDesignerName,
    });
  }
  if (level1) {
    quickLinks.push({
      key: 'qlHierarchyLevel1',
      icid: `${pageID}_QLinks2`,
      title: level1,
    });
    if (hasDesignerName && !level2) {
      quickLinks.push({
        key: 'qlDesignerHierarchyLevel1',
        icid: `${pageID}_QLinks3`,
        title: `${decodedDesignerName} ${level1}`,
      });
    }
  }
  if (level2) {
    if (hasDesignerName) {
      quickLinks.push({
        key: 'qlDesignerHierarchyLevel2',
        icid: `${pageID}_QLinks3`,
        title: `${decodedDesignerName} ${level2}`,
      });
    }
    quickLinks.push({
      key: 'qlHierarchyLevel2',
      icid: `${pageID}_QLinks4`,
      title: level2,
    });
  }

  if (quickLinks.length === 0) {
    return null;
  }

  return (
    <div
      className={classList}
      data-ui-component={uiComponent}
      data-tid={testID}
      {...props}
    >
      <ListGroup horizontal="md">
        <ListGroupItem as="h2" className="QuickLinks__heading">
          Shop More
        </ListGroupItem>
        {quickLinks.map((quickLink) => (
          <ListGroupItem key={quickLink.key}>
            <Link
              aria-label={`Shop more: ${quickLink.title}`}
              target="_self"
              onClick={onClick}
              to={{
                pathname: `${'/s/'}`,
                query: {
                  from: 'brSearch',
                  responsive: 'true',
                  request_type: 'search',
                  search_type: 'keyword',
                  q: `${quickLink.title}`,
                  l: `${quickLink.title}`,
                  fl: '',
                  icid: `${quickLink.icid}`,
                },
              }}
            >
              {quickLink.title}
            </Link>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

QuickLinks.displayName = 'QuickLinks';
QuickLinks.propTypes = propTypes;
QuickLinks.defaultProps = defaultProps;

export default QuickLinks;
