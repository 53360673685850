import axios from 'axios';
import Auth from '@aws-amplify/auth';
import { getRequestParams } from 'profile/components/MyFavorites/utilities';

export const markProductsIsFavorites = (products, isFavArray) => {
  const favIds = isFavArray.map((i) => i.id);

  return products.map((product) => ({
    ...product,
    isFavorite: favIds.includes(product.id),
  }));
};

export const markProductsAsYMAL = (products = []) => {
  return products.map((p) => ({
    ...p,
    isYMALProduct: true,
  }));
};

export const getFavoriteList = () => {
  const getFavorites = async (isGuest) => {
    try {
      const { id, headers } = await getRequestParams(isGuest);
      const { data: { products } } = await axios.get(
        `${NMConfig.FAVORITE_ITEMS}/${(isGuest ? 'guests' : 'accounts')}/${id}/products`,
        { headers },
      );
      return products;
    } catch (e) {
      return [];
    }
  };
  return Auth.currentAuthenticatedUser()
    .then(() => getFavorites(false))
    .catch(() => getFavorites(true));
};
