import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { TOGGLE_FAVORITE } from 'pdp/pages/ProductPage/actions';
import { sortSelectedValuesForDesigner } from 'plp/components/ProductListPage/components/ProductList/components/Filters/filterSorter';
import { getCloudinaryImageWithFixedWidth } from 'client-utils/utilities-cloudinary';
import {
  types as PlpTypes,
  RESOLVED_PRODUCT_LIST,
  REJECTED_PRODUCT_LIST,
  RESOLVED_PRODUCT_LIST_METADATA,
  RESOLVED_FAVORITE,
  RESOLVED_CATEGORY_NO_RESULTS_CONTENT,
  INVALID_CATEGORY,
  VALID_CATEGORY,
  SOURCE_QUICK_LOOK,
  INIT_SWATCH_VALUES,
  UPDATE_SWATCH_VALUE,
  RESOLVED_FAVORITE_FLATTENED,
} from '../actions';

const STATUS_FAVORITE = 'favorite';
export const defaultState = {
  list: [],
  total: 0,
  sortOptions: [],
  selectedSortOption: '',
  colorOptionsList: {},
  currentPage: 1,
};

export const getSwatches = (products) => {
  if (!products) {
    return {};
  }
  const colorOptionsListClone = {};
  products.forEach((product) => {
    colorOptionsListClone[product.id] = product.swatches;
  });
  return colorOptionsListClone;
};

const PLP_IMAGE_WIDTH = 380;

function updateProductMediaToCloudinaryIfRequired(products, toggles) {
  const dprToggle = toggles?.dprToggle;
  if (toggles && products) {
    const updatedProducts = products.map((product) => ({
      ...product,
      main: getCloudinaryImageWithFixedWidth(product.main, PLP_IMAGE_WIDTH, '', dprToggle),
      alt: getCloudinaryImageWithFixedWidth(product.alt, PLP_IMAGE_WIDTH, '', dprToggle),
      swatches: product.swatches?.map((swatch) => ({
        ...swatch,
        main: getCloudinaryImageWithFixedWidth(swatch.main, PLP_IMAGE_WIDTH, '', dprToggle),
        alt: getCloudinaryImageWithFixedWidth(swatch.alt, PLP_IMAGE_WIDTH, '', dprToggle),
      })),
    }), []);
    return updatedProducts;
  }
  return products;
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_PRODUCT_LIST:
    {
      const payload = {
        ...state,
        title: action.payload.title,
        list: updateProductMediaToCloudinaryIfRequired(action.payload.products, action.ftrTgls),
        templateType: action.payload.templateType,
        total: action.payload.total,
        sortOptions: action.payload.sortOptions,
        selectedSortOption: action.payload.selectedSortOption,
        isNewArrivalCategory: action.payload.isNewArrivalCategory,
        applicableFilters: action.payload.applicableFilters,
        isDisplayAsGroups: action.payload.displayAsGroups,
        currentPage: (parseInt(action.payload.page, 10) || 1),
        driveToGroups: action.payload.driveToGroups,
        boutiqueChild: action.payload.boutiqueChild,
      };

      let colorOptionsListClone = {};
      colorOptionsListClone = getSwatches(payload.list);

      action.payload.applicableFilters = action.payload.applicableFilters.map((filter) => {
        if (filter.filterKey === 'Designer') {
          return {
            ...filter,
            facetsOrderList: sortSelectedValuesForDesigner(filter.facetsOrderList),
          };
        }
        return filter;
      });
      return {
        ...payload,
        applicableFilters: action.payload.applicableFilters,
        colorOptionsList: colorOptionsListClone,
      };
    }
    case REJECTED_PRODUCT_LIST:
      return defaultState;
    case TOGGLE_FAVORITE: {
      const index = findIndex(state.list, { id: action.productId });
      const productList = state.list;
      const favoriteProduct = productList[index];
      productList[index] = {
        ...favoriteProduct,
        isFavorite: action.payload === STATUS_FAVORITE,
      };
      return {
        ...state,
        list: productList,
      };
    }
    case RESOLVED_FAVORITE: {
      const productList = state.list.map((product) => {
        if (product.id === action.data.productId) {
          return {
            ...product,
            isFavorite: action.data.utagData.favoriteItemStatus === STATUS_FAVORITE,
          };
        }
        return product;
      });
      return {
        ...state,
        list: productList,
      };
    }
    case RESOLVED_FAVORITE_FLATTENED: {
      const productList = state.list.map((product) => {
        if (product.id === action.data.productId) {
          return {
            ...product,
            fav: action.data.utagData.favoriteItemStatus === STATUS_FAVORITE,
          };
        }
        return product;
      });
      return {
        ...state,
        list: productList,
      };
    }
    case SOURCE_QUICK_LOOK: {
      return {
        ...state,
        sourceQuickLook: action.payload,
      };
    }
    case INIT_SWATCH_VALUES: {
      if (!state.list) {
        return state;
      }
      const listClone = state.list.map((product) => {
        if (!product.options || !product.options.productOptions) {
          return product;
        }
        const productOptionsClone = product.options.productOptions.map((option) => {
          if (option.label === 'color') {
            const valuesClone = option.values.map((value) => {
              return { ...value, isVisible: true };
            });
            return { ...option, values: valuesClone };
          }
          return option;
        });
        const optionsClone = { ...product.options, productOptions: productOptionsClone };
        return { ...product, options: optionsClone };
      });
      return {
        ...state,
        list: listClone,
      };
    }
    case UPDATE_SWATCH_VALUE: {
      const { swatchIndex, productId } = action.payload;
      if (!state.list) {
        return state;
      }
      const listClone = state.list.map((product) => {
        if (product.id !== productId || !product.options.productOptions) {
          return product;
        }
        const productOptionsClone = product.options.productOptions.map((option) => {
          if (option.label === 'color') {
            const valuesClone = option.values.map((value, index) => {
              if (index !== swatchIndex) return value;
              return { ...value, isVisible: false };
            });
            return { ...option, values: valuesClone };
          }
          return option;
        });
        const optionsClone = { ...product.options, productOptions: productOptionsClone };
        return { ...product, options: optionsClone };
      });
      return {
        ...state,
        list: listClone,
      };
    }
    default:
      return state;
  }
};

export const currentCategory = (selectedCategory = {}, { type, category }) => {
  return type === PlpTypes.SET_CATEGORY ? category : selectedCategory;
};

export const selectedSortBy = (selectedSortBy = '', { type, sortBy = '' }) => {
  return type === PlpTypes.SET_SORT_BY ? sortBy : selectedSortBy;
};

export const categoryNoResultsContent = (noResults = '', action) => {
  return action.type === RESOLVED_CATEGORY_NO_RESULTS_CONTENT ? action.payload : noResults;
};

const defaultSeo = {
  title: '',
  content: '',
  titleOverride: '',
  metaInformation: '',
  relatedContent: '',
};
export const seo = (seo = defaultSeo, { type, payload }) => {
  if (type === RESOLVED_PRODUCT_LIST) {
    const data = get(payload, 'seo');
    const relatedContent = get(data, 'relatedContent');
    const encodedRelatedContent = !isEmpty(relatedContent) ? encodeURIComponent(relatedContent) : '';
    return Object.assign({}, defaultSeo, data, { relatedContent: encodedRelatedContent });
  }
  return seo;
};

export const isValidCategory = (state = true, { type }) => {
  if (type === INVALID_CATEGORY || type === VALID_CATEGORY) { return type === VALID_CATEGORY; }
  return state;
};

export const selectedFilterOptions = (selectedFilterOptions = {}, action) => {
  return action.type === PlpTypes.SET_FILTER_OPTIONS
    ? action.selectedFilterOptions
    : selectedFilterOptions;
};

export const productListMetadata = (productListMetadata = {}, action) => {
  return action.type === RESOLVED_PRODUCT_LIST_METADATA
    ? action.payload.data.productListMetaData
    : productListMetadata;
};
