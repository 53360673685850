import curry from 'lodash/curry';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { DESIGNER_CAT_ID } from 'storefront/components/constants';

export const sanitizeInput = (input) => (
  input.trim().toLowerCase().replace(/\s+/g, ' ')
);

export const indexOfSearchTerm = (searchTerm, name) => (
  name.toLowerCase().indexOf(searchTerm)
);

const findDesigners = (silos) => (
  find(silos, { catmanId: DESIGNER_CAT_ID })
);

const buildMatch = (silo, matchIndex) => (
  { silo: { ...silo, categories: [] }, matchIndex, sortName: silo.name.toLowerCase() }
);

const findMatches = curry((searchTerm, designerSilo) => (
  map(designerSilo.categories, (letterCategory) => (
    reduce(letterCategory.categories, (results, cat) => {
      const matchIndex = indexOfSearchTerm(searchTerm, cat.name);
      return matchIndex >= 0 ? [...results, buildMatch(cat, matchIndex)] : results;
    }, [])
  ))));

const sortMatches = (matches) => (
  sortBy(matches, ['matchIndex', 'sortName']).splice(0, 10)
);

const pluckSilo = (matches) => (
  map(matches, 'silo')
);

const searchDesigners = (searchInput, silos) => {
  const searchTerm = sanitizeInput(searchInput);
  return flow(
    findDesigners,
    findMatches(searchTerm),
    flatten,
    sortMatches,
    pluckSilo
  )(silos);
};

export default searchDesigners;
