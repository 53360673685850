import Auth from '@aws-amplify/auth';
import axios from 'axios';

export const checkInitialHit = (disableATGLogin = false) => {
  if (!window?.sessionStorage?.atLeastSecondHit) {
    window.sessionStorage.setItem('atLeastSecondHit', true);
    if (!window?.localStorage?.getItem('_kmsi')) {
      if (!disableATGLogin) {
        axios.get('/dt/api/logoutAtg');
      }

      Auth.signOut();
    }
  }
};
