import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { themeGet } from 'client/theming/theme-utilities';
import styles from './ListGroup.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  as: PropTypes.string,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
  horizontal: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

const defaultProps = {
  className: '',
  children: null,
  as: 'div',
  uiComponent: 'ListGroup',
  testID: undefined,
  horizontal: false,
};

const ListGroup = ({
  className,
  children,
  as: Element,
  uiComponent,
  testID,
  horizontal,
  ...props
}) => {
  const classList = cx({
    ListGroup: true,
    'ListGroup--horizontal': horizontal === true || horizontal === 'sm',
    [`ListGroup--horizontal-${horizontal}`]: (
      horizontal
      && Object.keys(themeGet('breakpoints')).includes(horizontal)
    ),
  }, [className].join(' ').trim().replace(/\s+/g, ' '));
  return (
    <Element
      className={classList}
      data-ui-component={uiComponent}
      data-tid={testID}
      {...props}
    >
      {children}
    </Element>
  );
};

ListGroup.displayName = 'ListGroup';
ListGroup.propTypes = propTypes;
ListGroup.defaultProps = defaultProps;

export default ListGroup;
