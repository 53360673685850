import React from 'react';

export const CALL_FOR_AVAILABILITY_STORES = 'Available in select Neiman Marcus stores. Call';
export const CALL_FOR_AVAILABILITY_MESSAGE = 'For product availability, call';

const CallForAvailability = () => (
  <div className="call-for-availability grid-100 tablet-grid-100 mobile-grid-100">
    <p className="hide-on-mobile">
      {`${CALL_FOR_AVAILABILITY_MESSAGE} 1.800.824.5440 for details.`}
    </p>
    <p className="hide-on-tablet hide-on-desktop">
      {`${CALL_FOR_AVAILABILITY_MESSAGE}`}
      <a href="tel:+18008245440"> 1.800.824.5440</a>
      {' for details.'}
    </p>
  </div>
);

export default CallForAvailability;
