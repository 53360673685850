import React from 'react';
import Button from 'shared/components/Button/button';
import classnames from 'classnames';
import './filterButtons.scss';

const filterButtons = ({
  onSubmit, onClear, filterKey, selectedValues,
}) => {
  const selected = selectedValues && selectedValues.length > 0;
  return (
    <div className="facet-buttons">
      <div className="facet-column-buttons">
        <div className="facet-column__submit hide-on-mobile">
          <Button
            value="Submit"
            onClick={onSubmit}
          />
        </div>
        <div className={classnames('facet-column__clear', { 'sr-only': !selected })}>
          <Button
            ariaHidden={selected ? 'false' : 'true'}
            id={`${filterKey}_clearButton`}
            onClick={() => onClear(filterKey)}
            secondary
            tabIndex={selected ? '0' : '-1'}
            value="Clear"
          />
        </div>
      </div>
    </div>
  );
};

export default filterButtons;
