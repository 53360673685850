import window from 'window-or-global';
import get from 'lodash/get';
import {
  RESOLVED_MINIFIED_PRODUCT,
  SET_ACTIVE_STYLE_BOARD,
  OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG,
  RESOLVED_QUICK_LOOK_PRODUCT_PDP,
  REJECTED_MINIFIED_PRODUCT,
  REJECTED_STYLYZE_RECOMMENDATIONS,
  RESOLVED_STYLYZE_RECOMMENDATIONS,
} from 'pdp/pages/ProductPage/actions';

const acceptableActions = [
  RESOLVED_MINIFIED_PRODUCT,
  SET_ACTIVE_STYLE_BOARD,
  OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG,
  RESOLVED_QUICK_LOOK_PRODUCT_PDP,
  REJECTED_MINIFIED_PRODUCT,
  RESOLVED_STYLYZE_RECOMMENDATIONS,
  REJECTED_STYLYZE_RECOMMENDATIONS,
];

const errorTypes = {
  NO_STYLEBOARDS_FOUND: 'NO_STYLEBOARDS_FOUND',
  REQUEST_TIMEOUT: 'REQUEST_TIMEOUT',
  PRODUCT_COUNTS_DID_NOT_MATCH: 'PRODUCT_COUNTS_DID_NOT_MATCH',
  DEFAULT: 'DEFAULT',
};

const hasStyEvents = () => typeof window !== 'undefined' && 'StyEvents' in window;

const sendStylizedEvent = ({ type, data } = {}) => {
  if (hasStyEvents && typeof window.StyEvents.emit === 'function') {
    window.StyEvents.emit(type, data);
  }
};

export default (store) => (next) => (action) => {
  const stateData = store.getState();
  const pdpOutfittingToggle = get(stateData, 'toggles.PDP_OUTFITTING', false);

  if (!pdpOutfittingToggle) {
    return next(action);
  }
  if (!hasStyEvents) {
    return next(action);
  }
  const isAcceptableActions = acceptableActions.includes(action.type);

  if (!isAcceptableActions) {
    return next(action);
  }
  const webId = get(stateData, 'session.WID', '');
  const csbList = get(stateData, 'productCatalog.outfitting.outfittingRecs.csbList', []);
  const minifiedProductResponse = get(stateData, 'productCatalog.outfitting.minifiedProductResponse', []);
  let csbIndex = get(stateData, 'productCatalog.outfitting.activeStyleBoard', 0);
  let error = get(minifiedProductResponse[csbIndex], 'error', false);

  const getStyleboardIdByIndex = (i = csbIndex) => (
    csbList[i]
      ? get(csbList[i], 'id', '')
      : ''
  );
  let csbId = getStyleboardIdByIndex(csbIndex);

  switch (action.type) {
    case RESOLVED_MINIFIED_PRODUCT:
      if (action.payload && typeof action.payload.styleBoard === 'number' && action.payload.styleBoard >= 0) {
        csbIndex = action.payload.styleBoard;
        csbId = getStyleboardIdByIndex(csbIndex);
        error = get(action.payload.data[csbIndex], 'error', false);
      }
    // fall through to SET_ACTIVE_STYLE_BOARD
    case SET_ACTIVE_STYLE_BOARD:
      if (typeof action.payload === 'number' && action.payload >= 0) {
        csbIndex = action.payload;
        csbId = getStyleboardIdByIndex(csbIndex);
        error = get(minifiedProductResponse[csbIndex], 'error', false);
      }
      if (error) {
        sendStylizedEvent({
          type: 'analytics:error',
          data: {
            webProfileId: webId,
            productId: get(stateData, 'productCatalog.product.id', ''),
            errorType: errorTypes.PRODUCT_COUNTS_DID_NOT_MATCH,
          },
        });
      } else {
        sendStylizedEvent({
          type: 'analytics:styleboardView',
          data: {
            webProfileId: webId,
            csbId,
            csbIndex,
          },
        });
      }
      break;
    case OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG:
      sendStylizedEvent({
        type: 'analytics:addToBag',
        data: {
          webProfileId: webId,
          csbId,
          csbIndex,
          productId: action.productId,
          color: action.payload.color || '',
          size: action.payload.size || '',
          skuId: action.payload.skuId,
          quantity: action.payload.quantity,
        },
      });
      break;
    case RESOLVED_QUICK_LOOK_PRODUCT_PDP:
      sendStylizedEvent({
        type: 'analytics:quickLook',
        data: {
          webProfileId: webId,
          csbId,
          csbIndex,
          productId: action.payload.id,
        },
      });
      break;
    case REJECTED_MINIFIED_PRODUCT:
    case REJECTED_STYLYZE_RECOMMENDATIONS:
      sendStylizedEvent({
        type: 'analytics:error',
        data: {
          webProfileId: webId,
          productId: get(stateData, 'productCatalog.product.id', ''),
          errorType: errorTypes.REQUEST_TIMEOUT,
        },
      });
      break;
    case RESOLVED_STYLYZE_RECOMMENDATIONS:
      if (action.payload && typeof (action.payload.csbList) === 'object' && action.payload.csbList.length === 0) {
        sendStylizedEvent({
          type: 'analytics:error',
          data: {
            webProfileId: webId,
            productId: get(stateData, 'productCatalog.product.id', ''),
            errorType: errorTypes.NO_STYLEBOARDS_FOUND,
          },
        });
      }
      break;
    default:
      break;
  }
  return next(action);
};
