import { getActiveMediaIndex } from 'pdp/pages/ProductPage/selectors/getMedia';
import {
  RESOLVED_STYLYZE_RECOMMENDATIONS,
  RESOLVED_RECOMMENDATIONS_MINIFIED_PRODUCT,
  RESOLVED_MINIFIED_PRODUCT,
  RESOLVED_QUICK_LOOK_PRODUCT_PDP,
  RESET_QUICK_LOOK_PRODUCT_PDP,
  SET_ACTIVE_STYLE_BOARD,
  SHOW_LOADING,
  HIDE_LOADING,
  SET_QL_SELECTED_SIZE_INDEX,
  SET_QL_SELECTED_COLOR_INDEX,
  SET_QL_HOVERED_COLOR_INDEX,
  REJECTED_MINIFIED_PRODUCT,
  INCREASE_PRODUCT_QL_QUANTITY,
  DECREASE_PRODUCT_QL_QUANTITY,
  UPDATE_PRODUCT_QL_QUANTITY,
  ADD_TO_OUTFITTING_QL_BAG_ERROR,
  SOURCE_OUTFITTING_QUICK_LOOK,
  CLEAR_OUTFITTING_QL_ERROR,
  REJECTED_PRODUCT_RECOMMENDATIONS,
  REJECTED_RECOMMENDATIONS_MINIFIED_PRODUCT,
  SET_QL_IS_COLOR_CHANGED,
} from '../actions';

export const defaultState = {
  outfittingRecs: [],
  similarStyleRecs: [],
  isSimilarStyleRecsRejected: false,
  activeStyleBoard: 0,
  minifiedProductRejected: false,
  isColorChanged: false,
  hoveredColorIndex: 0,
};

export default (state = defaultState, action) => {
  let quantity;
  switch (action.type) {
    case RESOLVED_STYLYZE_RECOMMENDATIONS:
      return { ...state, outfittingRecs: action.payload };
    case RESOLVED_RECOMMENDATIONS_MINIFIED_PRODUCT:
      return {
        ...state,
        similarStyleRecs: action.payload,
      };
    case REJECTED_RECOMMENDATIONS_MINIFIED_PRODUCT:
    case REJECTED_PRODUCT_RECOMMENDATIONS:
      return {
        ...state,
        isSimilarStyleRecsRejected: true,
      };
    case RESOLVED_MINIFIED_PRODUCT:
      return {
        ...state,
        minifiedProductResponse: action.payload.data,
        activeStyleBoard: action.payload.styleBoard,
        loading: !action.payload.loaded,
      };
    case RESOLVED_QUICK_LOOK_PRODUCT_PDP:
      return {
        ...state,
        QLSummary: {
          ...action.payload,
          quantity: 1,
        },
      };
    case RESET_QUICK_LOOK_PRODUCT_PDP:
      return {
        ...state,
        QLSummary: [],
      };
    case SET_QL_SELECTED_SIZE_INDEX:
      return {
        ...state,
        QLSummary: {
          ...state.QLSummary,
          options: {
            ...state.QLSummary.options,
            selectedSizeIndex: action.payload,
          },
        },
      };
    case SET_QL_HOVERED_COLOR_INDEX: {
      return {
        ...state,
        QLSummary: {
          ...state.QLSummary,
          options: {
            ...state.QLSummary.options,
            hoveredColorIndex: action.payload,
          },
        },
      };
    }
    case SET_QL_SELECTED_COLOR_INDEX: {
      const newState = {
        ...state,
        QLSummary: {
          ...state.QLSummary,
          options: {
            ...state.QLSummary.options,
            selectedColorIndex: action.payload,
          },
        },
      };

      return {
        ...newState,
        activeMediaIndex: getActiveMediaIndex(newState.QLSummary, true),
      };
    }
    case SET_QL_IS_COLOR_CHANGED:
      return {
        ...state,
        QLSummary: {
          ...state.QLSummary,
          isColorChanged: action.payload,
        },
      };
    case SET_ACTIVE_STYLE_BOARD:
      return {
        ...state,
        activeStyleBoard: action.payload,
        QLSummary: [],
      };
    case REJECTED_MINIFIED_PRODUCT:
      return {
        ...state,
        minifiedProductRejected: true,
        loading: false,
        QLSummary: [],
      };
    case SHOW_LOADING:
      return {
        ...state,
        loading: true,
        QLSummary: [],
      };
    case HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };
    case INCREASE_PRODUCT_QL_QUANTITY:
      quantity = state.QLSummary.quantity < 999 ? action.payload + 1 : 999;
      return {
        ...state,
        QLSummary: {
          ...state.QLSummary,
          quantity,
        },
      };
    case DECREASE_PRODUCT_QL_QUANTITY:
      quantity = state.QLSummary.quantity > 0 ? action.payload - 1 : 0;
      return {
        ...state,
        QLSummary: {
          ...state.QLSummary,
          quantity,
        },
      };
    case UPDATE_PRODUCT_QL_QUANTITY:
      quantity = parseInt(action.payload.toString().substr(0, 3), 10);
      return {
        ...state,
        QLSummary: {
          ...state.QLSummary,
          quantity,
        },
      };
    case ADD_TO_OUTFITTING_QL_BAG_ERROR:
      return {
        ...state,
        QLSummary: {
          ...state.QLSummary,
          addToBagError: action.payload.errorMessage,
        },
      };
    case CLEAR_OUTFITTING_QL_ERROR:
      return {
        ...state,
        QLSummary: {
          ...state.QLSummary,
          addToBagError: '',
        },
      };
    case SOURCE_OUTFITTING_QUICK_LOOK:
      return {
        ...state,
        QLSummary: {
          ...state.QLSummary,
          sourceQuickLook: action.payload,
        },
      };
    default:
      return state;
  }
};
