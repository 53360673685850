import { SET_PICKUP_STORE } from '../actions';

const defaultState = {
  storeNumber: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_PICKUP_STORE:
      return {
        storeNumber: action.payload,
      };
    default:
      return state;
  }
};
