import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  testID: PropTypes.string,
};

const defaultProps = {
  testID: 'ShopRunnerScript',
};

class ShopRunnerScript extends Component {
  componentDidMount() {
    const { showPdpRedesign } = this.props;
    if (!showPdpRedesign) {
      try {
        if (window && window.sr_updateMessages) {
          window.sr_updateMessages();
        }
      } catch (ignoreError) {
        /* Do Nothing */
      }
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      testID,
    } = this.props;
    return (
      <script
        data-tid={testID}
        src="/assets/scripts/shoprunner_init.js"
        defer
      />
    );
  }
}

ShopRunnerScript.displayName = 'ShopRunnerScript';
ShopRunnerScript.propTypes = propTypes;
ShopRunnerScript.defaultProps = defaultProps;

export default ShopRunnerScript;
