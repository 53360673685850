import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Heading.scss';

const cx = classNames.bind(styles);

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
};

const defaultProps = {
  children: undefined,
  className: undefined,
  uiComponent: 'Heading',
  testID: undefined,
  level: 1,
};

const Heading = ({
  children,
  className,
  level,
  uiComponent,
  testID,
  ...props
}) => {
  const Element = level > 6 ? 'h6' : `h${level}`;
  const classList = cx(['Heading', className].join(' ').trim().replace(/\s+/g, ' '));
  return (
    <Element
      className={classList}
      {...props}
      data-ui-component={uiComponent}
      data-tid={testID}
    >
      {children}
    </Element>
  );
};

Heading.displayName = 'Heading';
Heading.propTypes = propTypes;
Heading.defaultProps = defaultProps;

export default Heading;
