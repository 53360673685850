import { getGroupLinkData } from 'client-utils/utilities-linked-data';
import {
  updateProductState,
  defaultState as defaultProductState,
  productInteractionReducer,
} from './productReducer';
import { RESOLVED_GROUP, REJECTED_PRODUCT, REJECTED_PRODUCT_PLA } from '../actions';

const defaultGroupState = {
  ...defaultProductState,
  childProducts: {
    productIds: [],
  },
  linkedData: [],
  linkedDataWithAllProdsAndSKUs: [],
  isGroup: true,
};

const getProducts = (products = []) => {
  const returnObj = {};
  const productIds = [];
  const linkedData = [];
  const linkedDataWithAllProdsAndSKUs = [];
  const childProducts = {};
  products.forEach((product) => {
    const productState = updateProductState({}, product);
    childProducts[product.id] = productState;
    linkedData.push(productState.linkedData);
    linkedDataWithAllProdsAndSKUs.push(
      productState.linkedDataWithAllProdsAndSKUs
    );
    productIds.push(product.id);
  });
  returnObj.productIds = productIds;
  returnObj.linkedData = linkedData;
  returnObj.linkedDataWithAllProdsAndSKUs = linkedDataWithAllProdsAndSKUs;
  returnObj.childProducts = childProducts;
  return returnObj;
};

const setProdutState = (state, action) => {
  const { productId } = action;
  const { childProducts } = state;
  if (productId && childProducts[productId]) {
    return {
      ...state,
      childProducts: {
        ...childProducts,
        [productId]: productInteractionReducer(
          childProducts[action.productId],
          action,
        ),
      },
    };
  }
  return state;
};

const isChanel = (childProducts = {}) => {
  const keys = Object.keys(childProducts);
  if (keys.length > 0) return childProducts[keys[0]].isChanel;
  return false;
};

export default (groupState = defaultGroupState, action) => {
  const { isGroup } = groupState;
  switch (action.type) {
    case RESOLVED_GROUP: {
      const { childProducts } = action.payload;
      const normalizedProducts = getProducts(childProducts);
      return {
        ...groupState,
        ...action.payload,
        childProducts: {
          ...normalizedProducts.childProducts,
          productIds: [...normalizedProducts.productIds],
        },
        isChanel: isChanel(normalizedProducts.childProducts),
        linkedData: getGroupLinkData(normalizedProducts.linkedData),
        linkedDataWithAllProdsAndSKUs: getGroupLinkData(
          normalizedProducts.linkedDataWithAllProdsAndSKUs
        ),
      };
    }
    case REJECTED_PRODUCT_PLA:
    case REJECTED_PRODUCT:
      return groupState;
    default:
      return isGroup ? setProdutState(groupState, action) : groupState;
  }
};
