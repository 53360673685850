import {
  getPDPPageTitle,
  getMetaTitle,
} from 'client-utils/utilities-page';

export default (state = {}, action) => {
  switch (action.type) {
    case 'RESOLVED_PRODUCT_PLA':
    case 'RESOLVED_PRODUCT': {
      const { payload } = action;
      return {
        ...state,
        product_name: [payload.name],
        product_brand: payload.designer && payload.designer.name,
        product_depiction: `${payload.metadata.cmosCatalogId}_${payload.metadata.cmosItem}`,
        product_price: [payload.price.promotionalPrice
          ? payload.price.promotionalPrice : payload.price.retailPrice],
        product_image: `https:${payload.media.main.medium.url}`,
        title: getPDPPageTitle(payload),
      };
    }
    case 'PAGE_VISITED':
      return {
        ...state,
        type: action.page_data.page_type,
      };
    case 'RESOLVED_BREADCRUMB_CONTENT':
      return {
        ...state,
        breadcrumbs:
          action.payload.breadcrumbs.map((breadcrumb) => breadcrumb.name),
      };
    case 'SET_META_INFO':
      return {
        ...state,
        title: getMetaTitle(action.payload),
      };
    default:
      return state;
  }
};
