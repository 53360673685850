require('matchmedia-polyfill');
require('matchmedia-polyfill/matchMedia.addListener');
require('picturefill');
require('viewport-units-buggyfill').init();
require('@babel/polyfill');
require('whatwg-fetch');
require('promise-polyfill/src/polyfill');
require('core-js/es/promise/all-settled');

// new CustomEvent pollyfill - ie9+
if (typeof window.CustomEvent !== 'function') {
  const cE = function CustomEvent(event, params) {
    const newParams = params || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, newParams.bubbles, newParams.cancelable, newParams.detail);
    return evt;
  };
  cE.prototype = window.Event.prototype;
  window.CustomEvent = cE;
}

// Event propagation path pollyfill
Event.prototype.propagationPath = propagationPath;

function propagationPath() {
  const getPropagation = () => {
    let element = this.target;
    const pathArr = [element];
    if (element === null || element.parentElement === null) {
      return [];
    }
    while (element.parentElement !== null) {
      element = element.parentElement;
      pathArr.unshift(element);
    }
    return pathArr;
  };
  return this.path || (this.composedPath && this.composedPath()) || getPropagation();
}
