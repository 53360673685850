import httpWithLogging from 'universal/http-client';
import { getAuthApiData } from '../../../../../utilities/amplifyUtils';

export const statusMap = {
  BO: 'Backordered',
  CB: 'In Process',
  CX: 'Cancelled',
  DS: 'In Process',
  MI: 'In Process',
  PD: 'In Process',
  PL: 'In Process',
  PT: 'In Process',
  QU: 'In Process',
  RB: 'In Process',
  RD: 'In Process',
  RI: 'In Process',
  RN: 'Returned',
  RP: 'In Process',
  RT: 'Returned',
  RX: 'Returned',
  VS: 'Shipped',
  A: 'In Process',
  X: 'Cancellation in Progress',
  PX: 'Cancellation in Progress',
  RW: 'Returned',
  TAKE: 'Purchased',
  SEND: 'Purchased',
  SALE: 'Purchased',
  RETURN: 'Returned',
  CANCELLED: 'Cancelled',
  3500: 'In Process',
  8500: 'Returned',
  8000: 'Return in Process',
  1500: 'Backordered',
  7000: 'Shipped',
  7500: 'Delivered',
  9000: 'Cancelled',
  19000: 'Return Cancelled',
};

export const narvarStatusMap = {
  SHPD: 'Awaiting carrier pickup',
  INTR: 'On its way',
  DLPP: 'Ready for pick up',
  ITPP: 'In transit to pickup point',
  OFDL: 'Out for delivery',
  MCHO: 'On its way',
  EXCP: 'Exception',
  UNKN: 'Exception',
  UNDL: 'Undeliverable',
  ITTS: 'On its way back to sender',
  DLTS: 'Returned to sender',
  DLVD: 'Delivered',
  PUBC: 'Picked Up',
};

export const serviceLevelCodeMap = {
  SL0: 'Same Day Delivery',
  SL1: 'Next Business Day',
  SL2: 'Second Business Day',
  SL3: 'Standard Shipping',
  SL3_PROMO: 'Promotional Shipping',
  SL4: 'Free Shipping',
};

export const narvarDeliveredStatus = ['DLVD', 'PUBC'];

export const cancelStatus = ['X', 'PX', 'CX', '9000'];

export function isValidDate(datestring) {
  if (!datestring) return false;
  const d = new Date(datestring);
  return d instanceof Date && !isNaN(d);
}

export const getOrderStatus = (status, deliveredDate = '', cancelCode = '') => {
  if ((status === 'VS' || status === 'CT') && (deliveredDate != null && deliveredDate !== '')) {
    return 'Delivered';
  }

  if (status === 'CX' && cancelCode === 'CXI') {
    return 'Ships from store';
  }
  return statusMap[status] || 'n/a';
};

export const getCancellableStatus = (status, systemOrderStatus) => {
  if (systemOrderStatus) {
    return ['RP', 'MI', 'RD', 'BO', 'QU', 'RI', 'FT', '1000', '1500', '2000'].includes(systemOrderStatus);
  }
  return ['RP', 'MI', 'RD', 'BO', 'QU', 'RI', 'FT', '1000', '1500', '2000'].includes(status);
};

export const getCancellableOrderType = (orderId) => !orderId?.includes('CM');

export async function cancelRegisteredOrderItem(state, payload) {
  const { orderId } = payload;

  const requestApi = httpWithLogging(state, 5000, true);

  const body = { ...payload };

  const { headers, ucaId } = await getAuthApiData();

  requestApi
    .post(`${
      NMConfig.API_DT_ORDER_HISTORY_V3
    }/profiles/${ucaId}/orders/${orderId}/cancel`, body, {
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    })
    .catch((err) => err);
}

export const cancelGuestOrderItem = (state, body) => {
  const requestApi = httpWithLogging(state, 3000, true);

  const headers = {
    'Content-Type': 'application/json',
  };

  const isV2ToggleOn = state?.toggles?.ORDER_HISTORY_CANCEL_V2;
  const isV3ToggleOn = state?.toggles?.ORDER_HISTORY_CANCEL_V3;

  const url = () => {
    if (isV3ToggleOn) {
      return `${NMConfig.API_DT_ORDER_HISTORY_V3}/guest/cancel`;
    }
    if (isV2ToggleOn) {
      return `${NMConfig.API_DT_GUEST_ORDER_HISTORY_V2}/cancel`;
    }

    return `${NMConfig.API_DT_ORDER_HISTORY}/guest/cancel`;
  };

  requestApi.post(url, body, { headers }).catch((err) => err);
};

export const normalizeString = (str = '') => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const formatSizeText = (size) => {
  if (!size || typeof size !== 'string') return '';
  const isSizeOnlyLetters = /^[a-z ]+$/i.test(size);
  return isSizeOnlyLetters
    ? size.split(' ').map(normalizeString).join(' ')
    : size;
};

export const formatColorText = (colorText, description = '') => {
  if (!colorText) return null;
  if (colorText.indexOf('/') === -1) return normalizeString(colorText);

  const colorIndex = description.toLowerCase().indexOf(colorText.toLowerCase());
  if (colorIndex > -1) {
    return description.slice(colorIndex, colorIndex + colorText.length);
  }
  return colorText;
};

export const normalizeShipping = (shippingAddress) => {
  const shippingKeys = Object.keys(shippingAddress);
  const normalizedAddress = shippingKeys.map((key) => {
    if (key !== 'stateCode') return [key, shippingAddress[key].split(' ').map(normalizeString).join(' ')];
    return [key, shippingAddress[key]];
  }).reduce((acc, curr) => {
    acc[curr[0]] = curr[1];
    return acc;
  }, {});

  return normalizedAddress;
};
