import remove from 'lodash/remove';
import { getOrderStatus } from '../components/OrderStatusLineItem/orderStatusUtils';
// function calculateOrderPrices(orderDetails) {
//   const multiItemTotal = (total, shipping, tax) => {
//     const result = [];
//     orderDetails.forEach((item) => {
//       if (total) {
//         result.push(item.items.reduce((accu, curr) => {
//           const quantity = curr.quantity ? curr.quantity : 0;
//           return accu + (Number(quantity) * (curr.price));
//         }, 0));
//       }

//       if (shipping) {
//         result.push(item.items.reduce((accu, curr) => accu + curr.freight, 0));
//       }

//       if (tax) {
//         result.push(
//           item.items.reduce((accu, curr) => accu + curr.tax, 0).toFixed(2)
//         );
//       }
//     });
//     return result.reduce((a, c) => (Number(a) + Number(c)).toFixed(2));
//   };

//   const itemsSubtotal = orderDetails.length > 1
//     ? multiItemTotal(true, false, false)
//     : orderDetails[0].items.reduce((accu, curr) => {
//       const quantity = curr.quantity ? curr.quantity : 0;
//       return accu + (Number(quantity) * (curr.price));
//     }, 0).toFixed(2);

//   const shipping = orderDetails.length > 1
//     ? multiItemTotal(false, true, false)
//     : orderDetails[0].items
//       .reduce((accu, curr) => accu + curr.freight, 0).toFixed(2);

//   const tax = orderDetails.length > 1
//     ? multiItemTotal(false, false, true)
//     : orderDetails[0].items
//       .reduce((accu, curr) => accu + curr.tax, 0).toFixed(2);

//   const grandTotal = (
//     (Number(itemsSubtotal))
//     + (Number(shipping))
//     + (Number(tax))
//   ).toFixed(2);

//   return {
//     itemsSubtotal,
//     shipping,
//     tax,
//     grandTotal,
//   };
// }
// TODO commenting out to pass linter, this might be needed when new api is ready

export function splitHistoryDataByTypes(allOrders) {
  return {
    allOrders,
    cancelledOrders: allOrders.filter((item) => item.orderStatus === 'CX' || item.orderStatus === 'X' || item.orderStatus === 'PX'),
    replenishableOrders: allOrders,
  };
}

const isMergeConditionMet = (mergeReturnsToggle, curr, length) => (
  mergeReturnsToggle
  && curr.channel === 'CMOS'
  && curr.orderStatus === 'RT'
  && length > 0
);

export function formatOrderHistoryData(data, mergeReturnsToggle) {
  return data.reduce((acc, curr) => {
    const {
      customerId,
      email,
      orderId,
      orderDate,
      itemTotal,
      cmosSkuId,
      orderStatus,
      product,
      lineItemId,
      brandLine,
      firstName,
      lastName,
      itemSKU,
      subStatus,
      isService,
      isReplenishment,
      orderSourceSystem,
      externalOrderLineId,
      externalReturnOrderLineId,
      sellingChannelId,
      deliveredDate,
      quantity,
      narvarTrackingLink,
      narvarReturnsLink,
      trackingLink,
      trackingNumber,
      subChannel,
      orderTotal,
      formattedOrderId,
      pickupStoreNumber,
      associates,
      channel,
      cancelCode,
      systemOrderStatus,
    } = curr;
    const currMap = {
      customerId,
      email,
      orderId,
      orderDate,
      itemTotal,
      cmosSkuId,
      orderStatus,
      lineItemId,
      brandLine,
      firstName,
      lastName,
      itemSKU,
      subStatus,
      isService,
      isReplenishment,
      orderSourceSystem,
      externalOrderLineId,
      externalReturnOrderLineId,
      sellingChannelId,
      deliveredDate,
      quantity,
      trackingLink,
      narvarTrackingLink,
      narvarReturnsLink,
      trackingNumber,
      formattedOrderId,
      subChannel,
      pickupStoreNumber,
      associates,
      color: product.color,
      size: product.size,
      item: product,
      totalPrice: orderTotal,
      statusText: getOrderStatus(orderStatus, deliveredDate, cancelCode),
      channel,
      cancelCode,
      systemOrderStatus,
    };
    if (acc.length) {
      for (let i = 0; i < acc.length; i++) {
        if (acc[i][0].orderId === curr.orderId) {
          // NMO-8750: Update to filter out duplicate items when returned.
          if (isMergeConditionMet(mergeReturnsToggle, curr, acc[i].length)) {
            remove(acc[i], (n) => n.orderStatus === 'VS'
                && n.item.pimSkuId === curr.product.pimSkuId
                && n.quantity === curr.quantity
                && n.itemTotal === curr.itemTotal);
            if (i === acc.length - 1) {
              if (acc[i].length === 0) {
                const filteredAcc = acc.filter((o) => o.length !== 0);
                return [...filteredAcc, [currMap]];
              }
              acc[i].push(currMap);
              return acc;
            }
          }
          acc[i].push(currMap);
          return acc;
        }
        if (i === acc.length - 1) {
          return [...acc, [currMap]];
        }
      }
    }
    acc.push([currMap]);
    return acc;
  }, []);
}
