import find from 'lodash/find';
import get from 'lodash/get';

const getOptions = (productState, type) => (
  find(
    get(productState, 'options.productOptions', []),
    ['label', type],
  ) || { values: [] }
);

export const getColorOptions = (productState) => getOptions(productState, 'color');

export const getSizeOptions = (productState) => getOptions(productState, 'size');
