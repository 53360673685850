import { types as NavTypes } from 'storefront/components/Navigation/actions-navigation';
import isEmpty from 'lodash/isEmpty';

export default (state = null, action) => {
  switch (action.type) {
    case NavTypes.RESOLVED_EDITORIAL_CLASS:
      return {
        editorial_class: !isEmpty(action.payload.editorialclass)
          ? action.payload.editorialclass
          : null,
      };
    default:
      return state;
  }
};
