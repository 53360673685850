import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Tab from './Tab';
import styles from './Tabs.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
};

const defaultProps = {
  className: '',
  uiComponent: 'Tabs',
  testID: undefined,
};

class Tabs extends Component {
  constructor(props) {
    super(props);

    const { children } = this.props;

    this.state = {
      activeTab: children[0].props.label,
    };

    this.onClickTabItem = this.onClickTabItem.bind(this);
  }

  onClickTabItem(tab) {
    this.setState({ activeTab: tab });
  }

  render() {
    const {
      className,
      children,
      uiComponent,
      testID,
      scrollablePanel,
    } = this.props;
    const {
      activeTab,
    } = this.state;

    let scrollableModifier = '';

    if (scrollablePanel) {
      scrollableModifier = '--scrollable';
    }

    const classList = cx(['Tabs', className].join(' ').trim().replace(/\s+/g, ' '));

    return (
      <div
        className={classList}
        data-ui-component={uiComponent}
        data-tid={testID}
      >
        <div className="Tabs__TabList" role="tablist">
          {children.map((child) => {
            const { label } = child.props;
            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={this.onClickTabItem}
              >
                <span>{label}</span>
              </Tab>
            );
          })}
        </div>
        <div className={`Tabs__TabPanels${scrollableModifier}`}>
          {children.map((child) => {
            if (child.props.label !== activeTab) {
              return null;
            }
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

Tabs.displayName = 'Tabs';
Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;
