import {
  RESOLVED_PRODUCT,
  REJECTED_PRODUCT,
  RESOLVED_PRODUCT_PLA,
  REJECTED_PRODUCT_PLA,
  RESOLVED_GROUP,
} from 'pdp/pages/ProductPage/actions';
import {
  RESOLVED_QUICK_LOOK_PRODUCT,
  RESET_QUICK_LOOK_PRODUCT,
} from 'plp/components/ProductListPage/actions';
import utagData from './selector/getUtagData';
import groupUtagData from './selector/getGroupUtagData';
import productAnalytics from './selector/getProductAnalytics';

export default (state = {}, action) => {
  switch (action.type) {
    case RESOLVED_PRODUCT_PLA:
    case RESOLVED_PRODUCT: {
      const { payload } = action;
      return {
        productInfo: utagData(payload),
        productAnalytics: productAnalytics(payload),
      };
    }
    case RESOLVED_GROUP: {
      const { payload } = action;
      return {
        productInfo: groupUtagData(payload),
        productAnalytics: productAnalytics(payload),
      };
    }
    case RESOLVED_QUICK_LOOK_PRODUCT: {
      return {
        productAnalytics: productAnalytics(action.payload),
      };
    }
    case RESET_QUICK_LOOK_PRODUCT: {
      return {};
    }
    case REJECTED_PRODUCT_PLA:
    case REJECTED_PRODUCT:
      return {};
    default:
      return state;
  }
};
