import { unescape } from 'he';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import get from 'lodash/get';
import { buildCookieString } from 'universal/utilities-cookies';
import httpWithLogging from 'universal/http-client';
import logger, { X_NMO_TRACE_ID_NAME } from 'server/utilities/logger';
import {
  getCache,
  putCache,
} from 'client-utils/utilities-mcache';
import { ECMSlots } from '../constants';

export const SET_P13N_PXL = 'SET_P13N_PXL';
export const SET_HIDE_ON_DESKTOP = 'SET_HIDE_ON_DESKTOP';
export const SET_HIDE_ON_TABLET = 'SET_HIDE_ON_TABLET';
export const SET_HIDE_ON_MOBILE = 'SET_HIDE_ON_MOBILE';
export const ADD_PROMO_TILES_FLAG_TO_UTAG_DATA = 'ADD_PROMO_TILES_FLAG_TO_UTAG_DATA';
export const SET_SELECTED_FACET = 'SET_SELECTED_FACET';
export const SET_ACTIVE_FACET = 'SET_ACTIVE_FACET';
export const RESOLVED_SRP_ECM_CONTENT = 'RESOLVED_SRP_ECM_CONTENT';
export const REJECT_SRP_ECM_CONTENT = 'REJECT_SRP_ECM_CONTENT';

const getConfig = (session = {}, context = {}, device) => {
  return {
    Cookie: buildCookieString({
      JSESSIONID: session.JSESSIONID,
      DYN_USER_ID: session.DYN_USER_ID,
      TLTSID: session.TLTSID,
      [X_NMO_TRACE_ID_NAME]: context[X_NMO_TRACE_ID_NAME],
      TRUE_CLIENT_IP: context.TRUE_CLIENT_IP,
      W2A: session.W2A,
    }),
    device: device !== undefined ? device : '',
  };
};

function trimSpaces(str) {
  return str.trim().replace(/\s\s+/g, ' ');
}
function stripHtml(html) {
  return html.replace(/<[^>]+>/g, '');
}
export function getTitle(product) {
  const isGroup = product.isGroup;
  const cmosItemNum = get(product, 'metadata.cmosItem', null);
  const designerName = unescape(get(product, 'designer.name', ' '));
  let productName = stripHtml(unescape(product.name));
  if (isGroup) {
    const childProducts = get(product, 'childProducts', []);
    productName = stripHtml(unescape(childProducts.map((product) => { return product.name; }).join(' ')));
  }
  return trimSpaces(`${cmosItemNum} ${designerName} ${productName}`);
}

const getPromoTileFlag = (successResponse, ecmSlot) => {
  return !!(get(successResponse, `data[${ecmSlot.elements}][0]`, false)
    || get(successResponse, `data[${ecmSlot.elements}][1]`, false)
    || get(successResponse, `data[${ecmSlot.elements}][2]`, false));
};

export function getEcmQLProductInfo(prodId) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_ECM_QL_PRODUCT_INFO_${prodId}`,
      prodId,
    });
    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 6000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    const prodCallString = `${NMConfig.API_PRODUCT}?productIds=${prodId}`;
    return requestApi.get(prodCallString, { headers })
      .then((res) => {
        if (get(res.data, 'total', '') !== 0) {
          dispatch({
            type: `RESOLVED_ECM_QL_PRODUCT_INFO_${prodId}`,
            payload: res.data,
            prodId,
          });
        } else {
          dispatch({
            type: `REJECTED_ECM_QL_PRODUCT_INFO_${prodId}`,
            prodId,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: `REJECTED_ECM_QL_PRODUCT_INFO_${prodId}`,
          prodId,
        });
      });
  };
}

export function getCurratedProductInfo(catID = '', noOfProducts = 10) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_ECM_CURRATED_PRODUCTS_${catID}`,
      catID,
    });
    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 6000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const apiConfig = NMConfig.API_CURATED_PRODUCT_LIST;
    const prodCallString = `${apiConfig}?categoryId=${catID}&fetchSize=${noOfProducts}`;

    let cacheKey;
    let cacheResponse;

    if (prodCallString) {
      cacheKey = prodCallString;
      const cacheData = getCache(cacheKey, state);
      cacheResponse = cacheData ? { data: cacheData } : null;
    }
    const curatedProductResponse = (catID, curratedProductList) => {
      dispatch({
        type: `RESOLVED_ECM_CURRATED_PRODUCTS_${catID}`,
        payload: curratedProductList,
        catID,
      });
    };

    if (cacheResponse) {
      return new Promise((resolve) => {
        curatedProductResponse(catID, cacheResponse.data);
        return resolve();
      });
    }
    return requestApi.get(prodCallString, { headers })
      .then((res) => {
        if (res.data && res.data.products) {
          if (res.data.products.length !== 0) {
            const curratedProductList = [];
            for (const product of res.data.products) {
              const returnImage = () => {
                if (get(product, 'media.main.medium.url', 'mnull') !== 'mnull') {
                  return get(product, 'media.main.medium.url', '/assets/images/no-image.c9a49578722aabed021ab4821bf0e705.jpeg');
                }
                if (get(product, 'media.alternate.medium.url', 'anull') !== 'anull') {
                  return get(product, 'media.alternate.medium.url', '/assets/images/no-image.c9a49578722aabed021ab4821bf0e705.jpeg');
                }
                return '/assets/images/no-image.c9a49578722aabed021ab4821bf0e705.jpeg';
              };
              const formatPrice = () => {
                if (get(product, 'price.currencyCode', false)
                  && get(product, 'price.retailPrice', false)
                ) {
                  return `${get(product, 'price.currencyCode', ' ')}${get(product, 'price.retailPrice', ' ')}`;
                }
                return '';
              };

              const designerName = get(product, 'designer.name', ' ');
              const name = product.name || '';
              const isGroup = product.isGroup;
              const childProducts = isGroup ? get(product, 'childProducts', []) : [];

              curratedProductList.push({
                productId: product.id,
                designerName,
                name,
                driveToUrl: get(product, 'details.canonicalUrl', ''),
                price: formatPrice(),
                imageUrl: returnImage(),
                title: getTitle(product),
                isGroup,
                childProducts,
              });
            }
            if (cacheKey) {
              const mcacheTimeout = get(state, 'mcacheTimeouts.cproducts', 900000);
              putCache(cacheKey, curratedProductList, mcacheTimeout, state);
            }
            curatedProductResponse(catID, curratedProductList);
          } else {
            dispatch({
              type: `REJECTED_ECM_CURRATED_PRODUCTS_${catID}`,
              catID,
            });
          }
        } else {
          dispatch({
            type: `REJECTED_ECM_CURRATED_PRODUCTS_${catID}`,
            catID,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: `REJECTED_ECM_CURRATED_PRODUCTS_${catID}`,
          catID,
        });
      });
  };
}

export function setHideOnDesktop(ecmSlot) {
  return (dispatch) => {
    dispatch({ type: SET_HIDE_ON_DESKTOP, contentId: ecmSlot.contentId });
  };
}

export function setHideOnTablet(ecmSlot) {
  return (dispatch) => {
    dispatch({ type: SET_HIDE_ON_TABLET, contentId: ecmSlot.contentId });
  };
}

export function setHideOnMobile(ecmSlot) {
  return (dispatch) => {
    dispatch({ type: SET_HIDE_ON_MOBILE, contentId: ecmSlot.contentId });
  };
}

export function getECMRequest(ecmSlot, catId = '', categoryIds = '', productId = '') {
  return (dispatch, getState) => {
    dispatch({ type: `LOADING_ECM${ecmSlot.contentId}` });
    const state = getState();
    const { locale, session = {}, abTestsOpt = {} } = state;
    const context = state.api && state.api.requestContext;
    const device = get(state, 'device.isMobilePhone', false) ? 'mobile' : 'desktop';
    const CONTENT_SERVICE_TIMEOUT = get(state, 'apiTimeouts.CONTENT_SERVICE_TIMEOUT');
    const requestApi = httpWithLogging(state, CONTENT_SERVICE_TIMEOUT);
    const headers = getConfig(session, context, device);
    const aemOptId = get(state, 'aemOptId', '');
    if (catId.length > 0) {
      headers.catId = catId;
    }
    if (productId.length > 0) {
      headers.productId = productId;
    }
    if (categoryIds.length > 0) {
      headers.categoryIds = categoryIds;
    }

    let ecmPreviewParam = '';
    if (context) {
      ecmPreviewParam = isEmpty(context.ECMPreview) ? '' : `ECMPreview=${context.ECMPreview}`;
    }
    const href = `${NMConfig.API_ECM}${ecmSlot.serviceUrl}${!isEmpty(ecmPreviewParam) ? '?' : ''}${ecmPreviewParam}`;
    if (session.personalize && session.dt_personalize_preview) {
      const dtPersonalizePreview = JSON.parse(session.dt_personalize_preview);
      if (!isEmpty(dtPersonalizePreview.aemAbTestUsergroup)) {
        headers.abTestUserGroup = dtPersonalizePreview.aemAbTestUsergroup;
      }
      if (!isEmpty(dtPersonalizePreview.countryCode)) {
        headers.countryCode = dtPersonalizePreview.countryCode;
      }
      if (!isEmpty(dtPersonalizePreview.bestCustomer)) {
        headers.bc = dtPersonalizePreview.bestCustomer;
      }
      if (!isEmpty(dtPersonalizePreview.customerScore)) {
        headers.customerScore = dtPersonalizePreview.customerScore;
      }
      if (!isEmpty(dtPersonalizePreview.customerJourneySegment)) {
        headers.cjs = dtPersonalizePreview.customerJourneySegment;
      }
      if (!isEmpty(dtPersonalizePreview.emailSubscriber)) {
        headers.emailSub = dtPersonalizePreview.emailSubscriber;
      }
      if (!isEmpty(dtPersonalizePreview.plcc)) {
        headers.plcc = dtPersonalizePreview.plcc;
      }
      if (!isEmpty(dtPersonalizePreview.inCircleLevel)) {
        headers.inCircleLevel = dtPersonalizePreview.inCircleLevel;
      }
    } else {
      if (locale) {
        if (!isEmpty(locale.countryCode)) {
          headers.countryCode = locale.countryCode;
        }
      }
      if (abTestsOpt) {
        if (!isEmpty(get(abTestsOpt, `${aemOptId}`))) {
          headers.abTestUserGroup = get(abTestsOpt, `${aemOptId}.variation`, '');
        }
      }
      if (context) {
        if (!isEmpty(context.dtPersonalizeData.bestCustomer)) {
          headers.bc = context.dtPersonalizeData.bestCustomer;
        }
        if (!isEmpty(context.dtPersonalizeData.customerScore)) {
          headers.customerScore = context.dtPersonalizeData.customerScore;
        }
        if (!isEmpty(context.dtPersonalizeData.customerJourneySegment)) {
          headers.cjs = context.dtPersonalizeData.customerJourneySegment;
        }
        if (!isEmpty(context.dtPersonalizeData.emailSubscriber)) {
          headers.emailSub = context.dtPersonalizeData.emailSubscriber;
        }
        if (!isEmpty(context.dtPersonalizeData.plcc)) {
          headers.plcc = context.dtPersonalizeData.plcc;
        }
        if (!isEmpty(context.dtPersonalizeData.inCircleLevel)) {
          headers.inCircleLevel = context.dtPersonalizeData.inCircleLevel;
        }
      }
    }

    if (get(state, 'toggles.AEM_MOBILE_HP', false)) {
      headers.countryCode = get(state, 'device.isMobilePhone', false) ? 'MOBILE' : 'DESKTOP';
    }

    let cacheKey;
    let cacheResponse;

    const shouldPutCache = !IS_CLIENT && isEmpty(ecmPreviewParam);
    const shouldGetCache = !IS_CLIENT && isEmpty(ecmPreviewParam);

    if (shouldPutCache || shouldGetCache) {
      if (ecmSlot.cache) {
        let cacheData;
        const keyObj = omitBy({
          href: ecmSlot.serviceUrl,
          catId: headers.catId ? headers.catId : '',
          categoryIds: headers.categoryIds ? headers.categoryIds : '',
          productId: headers.productId ? headers.productId : '',
          countryCode: headers.countryCode ? headers.countryCode : '',
          abTestUserGroup: headers.abTestUserGroup ? headers.abTestUserGroup : '',
          bc: headers.bc ? headers.bc : '',
          cjs: headers.cjs ? headers.cjs : '',
          emailSub: headers.emailSub ? headers.emailSub : '',
          plcc: headers.plcc ? headers.plcc : '',
          inCircleLevel: headers.inCircleLevel ? headers.inCircleLevel : '',
          device,
        }, isEmpty);
        cacheKey = JSON.stringify(keyObj);
        if (shouldGetCache) {
          cacheData = getCache(cacheKey, state);
          cacheResponse = cacheData ? { data: cacheData } : null;
        }
      }

      const doResponse = (successResponse) => {
        if (ecmSlot.slots) {
          dispatch({
            type: `RESOLVED_ECM${ecmSlot.contentId}`,
            payload: {},
            contentId: ecmSlot.contentId,
            headers,
          });
          dispatch({
            type: `RESOLVED_ECM${ECMSlots[ecmSlot.slots[0]].contentId}`,
            payload: get(successResponse, `data[${ecmSlot.elements}][0]`, null),
            contentId: ECMSlots[ecmSlot.slots[0]].contentId,
            headers,
          });
          dispatch({
            type: `RESOLVED_ECM${ECMSlots[ecmSlot.slots[1]].contentId}`,
            payload: get(successResponse, `data[${ecmSlot.elements}][1]`, null),
            contentId: ECMSlots[ecmSlot.slots[1]].contentId,
            headers,
          });
          dispatch({
            type: `RESOLVED_ECM${ECMSlots[ecmSlot.slots[2]].contentId}`,
            payload: get(successResponse, `data[${ecmSlot.elements}][2]`, null),
            contentId: ECMSlots[ecmSlot.slots[2]].contentId,
            headers,
          });
          dispatch({
            type: ADD_PROMO_TILES_FLAG_TO_UTAG_DATA,
            payload: { promoTileFlag: getPromoTileFlag(successResponse, ecmSlot) },
          });
        } else {
          dispatch({
            type: `RESOLVED_ECM${ecmSlot.contentId}`,
            payload: successResponse.data,
            contentId: ecmSlot.contentId,
            headers,
          });
        }
      };

      if (cacheResponse) {
        return new Promise((resolve) => {
          doResponse(cacheResponse);
          return resolve();
        });
      }
      return requestApi.get(href, { headers })
        .then((successResponse) => {
          if (cacheKey) {
            const mcacheTimeout = get(state, 'mcacheTimeouts.ecm', 600000);
            putCache(cacheKey, successResponse.data, mcacheTimeout, state);
          }
          doResponse(successResponse);
        })
        .catch(() => dispatch({ type: `REJECTED_ECM${ecmSlot.contentId}` }));
    }
    return requestApi.get(href, { headers })
      .then((successResponse) => {
        if (ecmSlot.slots) {
          dispatch({
            type: `RESOLVED_ECM${ecmSlot.contentId}`,
            payload: {},
            contentId: ecmSlot.contentId,
            headers,
          });
          dispatch({
            type: `RESOLVED_ECM${ECMSlots[ecmSlot.slots[0]].contentId}`,
            payload: get(successResponse, `data[${ecmSlot.elements}][0]`, null),
            contentId: ECMSlots[ecmSlot.slots[0]].contentId,
            headers,
          });
          dispatch({
            type: `RESOLVED_ECM${ECMSlots[ecmSlot.slots[1]].contentId}`,
            payload: get(successResponse, `data[${ecmSlot.elements}][1]`, null),
            contentId: ECMSlots[ecmSlot.slots[1]].contentId,
            headers,
          });
          dispatch({
            type: `RESOLVED_ECM${ECMSlots[ecmSlot.slots[2]].contentId}`,
            payload: get(successResponse, `data[${ecmSlot.elements}][2]`, null),
            contentId: ECMSlots[ecmSlot.slots[2]].contentId,
            headers,
          });
          dispatch({
            type: ADD_PROMO_TILES_FLAG_TO_UTAG_DATA,
            payload: { promoTileFlag: getPromoTileFlag(successResponse, ecmSlot) },
          });
        } else {
          dispatch({
            type: `RESOLVED_ECM${ecmSlot.contentId}`,
            payload: successResponse.data,
            contentId: ecmSlot.contentId,
            headers,
          });
        }
      })
      .catch(() => dispatch({ type: `REJECTED_ECM${ecmSlot.contentId}` }));
  };
}

export function preloadedECMRequest(ecmSlot, data) {
  return (dispatch) => {
    dispatch({
      type: `RESOLVED_ECM${ecmSlot.contentId}`,
      payload: data,
      contentId: ecmSlot.contentId,
    });
  };
}

export function getECMSimpleRequest(ecmSlot) {
  return (dispatch, getState) => {
    dispatch({ type: `LOADING_ECM${ecmSlot.contentId}` });
    const state = getState();
    const { session = {} } = state;
    const context = state.api && state.api.requestContext;
    const CONTENT_SERVICE_TIMEOUT = get(state, 'apiTimeouts.CONTENT_SERVICE_TIMEOUT');
    const requestApi = httpWithLogging(state, CONTENT_SERVICE_TIMEOUT);
    const headers = getConfig(session, context);
    return requestApi.get(`${NMConfig.API_ECM}${ecmSlot.serviceUrl}`, { headers })
      .then((successResponse) => dispatch({
        type: `RESOLVED_ECM${ecmSlot.contentId}`,
        payload: successResponse.data,
        contentId: ecmSlot.contentId,
        headers,
      }))
      .catch(() => dispatch({ type: `REJECTED_ECM${ecmSlot.contentId}` }));
  };
}

export function getPersonalization(custId) {
  return (dispatch, getState) => {
    const state = getState();
    const CP_PERSONALIZATION_API_TIMEOUT = get(state, 'apiTimeouts.CP_PERSONALIZATION_API_TIMEOUT', 500);
    const requestApi = httpWithLogging(state, CP_PERSONALIZATION_API_TIMEOUT);
    const cpClientId = get(state, 'coherentPath.clientId', 'neimanmarcus');
    const queryString = `client_id=${cpClientId}&cust_id=${custId}`;
    return requestApi.get(`${NMConfig.API_COHERENT_PATH_RECS}?${queryString}`)
      .then((successResponse) => {
        logger.info('Successful Coherent Path Recommendations Request', {
          client_id: 'neimanmarcus',
          cust_id: custId,
          WID: get(state, 'session.WID'),
          JSESSIONID: get(state, 'session.JSESSIONID'),
        });
        return (successResponse.data);
      }).catch((e) => {
        logger.error('Exception in Coherent Path Recommendations Request:', e);
      });
  };
}

export function getECMRequestWithCPPersonalization(ecmSlot, catId = '') {
  return (dispatch, getState) => {
    let state = getState();
    const ignoreCPRecs = get(state, 'toggles.IGNORE_COHERENT_PATH_RECS', false);
    const cpToggle = get(state, 'toggles.CP_NEW', false);
    const cpBannerToggle = get(state, 'toggles.CP_NEW_BANNER', false);
    const custId = get(state, 'session._cplid', new Date().getTime());
    Promise.all([
      dispatch(getECMRequest(ecmSlot, catId)),
      dispatch(getPersonalization(custId)),
    ])
      .then((responses) => {
        state = getState();
        const hasContent = !isEmpty(get(state, 'ecm.ecmContent', {})[ecmSlot.contentId]);
        const cpFailed = responses[1] === undefined;
        const cpClientId = cpToggle ? get(state, 'coherentPath.clientId', 'neimanmarcus') : 'neimanmarcus';
        const hasCPContent = !isEmpty(responses[1] || {});
        !ignoreCPRecs && !cpFailed && hasContent && hasCPContent
          && dispatch({
            type: 'RESOLVED_CP_PERSONALIZATION',
            payload: responses[1],
            contentId: ecmSlot.contentId,
            cpToggle,
            cpBannerToggle,
          });

        const reason = [];
        if (!hasContent) reason.push('Content Service Failed');
        if (cpFailed) reason.push('Coherent Path Failed');
        if (!cpFailed && !hasCPContent) reason.push('Coherent Path Empty Response');
        if (ignoreCPRecs) reason.push('Ignore Coherent Path Recs');

        const cpFlag = hasContent && hasCPContent && !cpFailed && !ignoreCPRecs ? 1 : 0;

        logger.info('Coherent Path Pixel Request', {
          client_id: cpClientId,
          cust_id: custId,
          WID: get(state, 'session.WID'),
          JSESSIONID: get(state, 'session.JSESSIONID'),
          is_control: cpFlag,
          reason: reason.join(','),
        });
        const queryString = `clientId=${cpClientId}&cplid=${custId}&action=is_control&is_control=${cpFlag}`;
        dispatch({ type: 'SET_P13N_PXL', payload: `${queryString}` });
      })
      .catch(() => {
        dispatch({ type: 'REJECTED_CP_PERSONALIZATION' });
      });
  };
}

export function setSelectedFacet(facetId, ecmSlot) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_FACET,
      payload: facetId,
      contentId: ecmSlot.contentId,
    });
  };
}

export function loadMoreMagazines(ecmSlot) {
  return (dispatch, getState) => {
    dispatch({ type: `LOADING_ECM${ecmSlot.contentId}_MAGAZINE_LOAD_MORE` });
    const state = getState();
    const { session = {} } = state;
    const context = state.api && state.api.requestContext;
    const CONTENT_SERVICE_TIMEOUT = get(state, 'apiTimeouts.MAGAZINE_INDEX_TIMEOUT');
    const requestApi = httpWithLogging(state, CONTENT_SERVICE_TIMEOUT);
    const slotInState = state.ecm.ecmContent[ecmSlot.contentId] || {};
    const selectedFacet = state.ecm.ecmContent[ecmSlot.contentId].selectedFacet || '';
    const lastServedStoryId = get(slotInState, 'storyConfig.lastServedStoryId', '');
    const categoryIds = get(slotInState, 'storyConfig.storyIdList', []).join(',');
    const headers = getConfig(session, context);
    headers.categoryIds = categoryIds;
    headers.lastServedStoryId = lastServedStoryId;
    const params = [];
    if (selectedFacet !== '' && selectedFacet.toLowerCase() !== 'featured') params.push(`facet=${selectedFacet}`);
    if (context && !isEmpty(context.ECMPreview)) params.push(`ECMPreview=${context.ECMPreview}`);
    params.push(new Date().getTime());
    const href = `${NMConfig.API_ECM}${ecmSlot.serviceUrl}${params.length > 0 ? '?' : ''}${params.join('&')}`;
    return requestApi.get(href, { headers })
      .then((successResponse) => {
        return dispatch({
          type: `RESOLVED_ECM${ecmSlot.contentId}_MAGAZINE_LOAD_MORE`,
          payload: successResponse.data,
          contentId: ecmSlot.contentId,
          headers,
        });
      })
      .catch(() => dispatch({ type: `REJECTED_ECM${ecmSlot.contentId}_MAGAZINE_LOAD_MORE` }));
  };
}

export function selectFacet(facet, ecmSlot) {
  return (dispatch, getState) => {
    dispatch({ type: `LOADING_ECM${ecmSlot.contentId}_MAGAZINE_FACET` });
    const state = getState();
    const { session = {} } = state;
    const context = state.api && state.api.requestContext;
    const CONTENT_SERVICE_TIMEOUT = get(state, 'apiTimeouts.MAGAZINE_INDEX_TIMEOUT');
    const requestApi = httpWithLogging(state, CONTENT_SERVICE_TIMEOUT);
    const slotInState = state.ecm.ecmContent[ecmSlot.contentId] || {};
    const categoryIds = get(slotInState, 'storyConfig.storyIdList', []).join(',');
    const headers = getConfig(session, context);
    headers.categoryIds = categoryIds;
    const params = [];
    if (!isEmpty(facet)) params.push(`facet=${facet}`);
    if (context && !isEmpty(context.ECMPreview)) params.push(`ECMPreview=${context.ECMPreview}`);
    params.push(new Date().getTime());
    const href = `${NMConfig.API_ECM}${ecmSlot.serviceUrl}${params.length > 0 ? '?' : ''}${params.join('&')}`;
    return requestApi.get(href, { headers })
      .then((successResponse) => {
        return dispatch({
          type: `RESOLVED_ECM${ecmSlot.contentId}_MAGAZINE_FACET`,
          payload: successResponse.data,
          contentId: ecmSlot.contentId,
          headers,
        });
      })
      .catch(() => dispatch({ type: `REJECTED_ECM${ecmSlot.contentId}_MAGAZINE_FACET` }));
  };
}
