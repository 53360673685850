/* eslint-disable no-await-in-loop, no-nested-ternary */
import get from 'lodash/get';
import httpWithLogging from 'universal/http-client';
import Auth from '@aws-amplify/auth';
import { parse } from 'query-string';
import { getAuthApiData } from '../../../utilities/amplifyUtils';
import {
  onlineTab as onlineIndex, instoreTab as inStoreIndex,
} from '../../components/OrderHistoryDT/V3/OrderHistoryUtils';
import { narvarDeliveredStatus } from '../../components/OrderHistoryDT/V3/OrderStatusLineItem/orderStatusUtils';

export const SUBMIT_CANCEL_ORDER = 'SUBMIT_CANCEL_ORDER_V3';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS_V3';
export const CANCEL_ORDER_FAILED = 'CANCEL_ORDER_FAILED_V3';
export const NO_ORDERHISTORY_DATA_AVAILABLE = 'NO_ORDERHISTORY_DATA_AVAILABLE_V3';
export const REJECTED_ONLINE_ORDER_HISTORY = 'REJECTED_ONLINE_ORDER_HISTORY_V3';
export const REJECTED_STORE_ORDER_HISTORY = 'REJECTED_STORE_ORDER_HISTORY_V3';
export const LOADING_ORDER_HISTORY_DATA = 'LOADING_ORDER_HISTORY_DATA_V3';
export const RESOLVED_COMBINED_ORDER_HISTORY = 'RESOLVED_COMBINED_ORDER_HISTORY_V3';
export const LOADING_GUEST_ORDERS = 'LOADING_GUEST_ORDERS_V3';
export const RESOLVED_GUEST_ORDERS = 'RESOLVED_GUEST_ORDERS_V3';
export const REJECTED_GUEST_ORDERS = 'REJECTED_GUEST_ORDERS_V3';
export const GUEST_DETAILS_UTAG = 'GUEST_DETAILS_UTAG';
export const ORDER_HISTORY_PROFILE_UTAG = 'ORDER_HISTORY_PROFILE_UTAG';
export const MAP_OH_ITEM_ADDRESSES = 'MAP_OH_ITEM_ADDRESSES_V3';
export const ORDER_HISTORY_UTAG = 'SET_RAW_OH_DATA';
export const SET_RAW_OH_DATA = 'SET_RAW_OH_DATA_V3';
export const SET_FETCHED_ALL_OH = 'SET_FETCHED_ALL_OH_V3';
export const SET_FETCHED_ALL_ONLINE = 'SET_FETCHED_ALL_ONLINE_V3';
export const SET_FETCHED_ALL_STORE = 'SET_FETCHED_ALL_STORE_V3';
export const LOADING_CANCELLED_ORDERS = 'LOADING_CANCELLED_ORDERS_V3';
export const LOADING_ONLINE_ORDERS = 'LOADING_ONLINE_ORDERS_V3';
export const LOADING_STORE_ORDERS = 'LOADING_STORE_ORDERS_V3';
export const SET_CANCELLED_ORDER_HISTORY = 'SET_CANCELLED_ORDER_HISTORY_V3';
export const SET_ONLINE_ORDER_HISTORY = 'SET_ONLINE_ORDER_HISTORY_V3';
export const SET_STORE_ORDER_HISTORY = 'SET_STORE_ORDER_HISTORY_V3';
export const REJECTED_CANCELLED_ORDER_HISTORY = 'REJECTED_CANCELLED_ORDER_HISTORY_V3';
export const REJECTED_ORDER_HISTORY = 'REJECTED_ORDER_HISTORY_V3';
export const SET_OH_SEARCH_TEXT = 'SET_OH_SEARCH_TEXT_V3';
export const START_SEARCHING_OH = 'START_SEARCHING_OH_V3';
export const RESOLVED_STYLED_FOR_YOU_DATA = 'RESOLVED_STYLED_FOR_YOU_DATA_V3';
export const REJECTED_STYLED_FOR_YOU_DATA = 'REJECTED_STYLED_FOR_YOU_DATA_V3';
export const CLICKED_STYLED_FOR_YOU_UTAG = 'CLICKED_STYLED_FOR_YOU_UTAG';

const size = 5;
const CMOS_CANCEL_INIT = 'PX';
const MAO_CANCEL_INIT = 9000;
const SHIPMENT_INFO_WINDOW = 30;

const updateGuestCancelState = (dispatch, cancelData) => {
  const {
    message, ohState, prods, orderId,
  } = cancelData;
  const guestorders = ohState.guest.orders.map((order) => {
    order.consignments = order.consignments.map((cons) => {
      cons.items = cons.items.map((item) => {
        item = {//eslint-disable-line
          ...item,
        };
        if (prods.includes(item.externalLineItemId)) {
          item.status = item.orderSourceSystem === 'MA'
            ? MAO_CANCEL_INIT
            : CMOS_CANCEL_INIT;
          item.orderStatus = item.orderSourceSystem === 'MA'
            ? MAO_CANCEL_INIT
            : CMOS_CANCEL_INIT;
        }
        return item;
      });
      return cons;
    });

    return order;
  });
  dispatch({
    type: CANCEL_ORDER_SUCCESS,
    payload: { [orderId]: { message, loading: false } },
    orders: { guest: { ...ohState.guest, ...{ orders: guestorders } } },
  });
};

const updateRegisteredCancelState = (dispatch, cancelData) => {
  const {
    message, ohState, tabKey, prods, orderId,
  } = cancelData;
  let orders = (tabKey === onlineIndex)
    ? ohState.onlineOrders : ohState.combinedOrderHistory.allOrders;
  orders = orders.map((order) => {
    if (order.cmosOrderId === orderId) {
      order.consignments = order.consignments.map((cons) => {
        cons.items = cons.items.map((item) => {
          if (prods.includes(item.product.id)) {
            item.orderStatus = item.orderSourceSystem === 'MA' ? MAO_CANCEL_INIT : CMOS_CANCEL_INIT;
          }
          return item;
        });
        return cons;
      });
    }
    return order;
  });
  dispatch({
    type: CANCEL_ORDER_SUCCESS,
    payload: { [orderId]: { message, loading: false } },
    orders: (
      (tabKey === onlineIndex) ? { onlineOrders: orders }
        : { combinedOrderHistory: { allOrders: orders } }
    ),
  });
};

export const cancelOrderItems = (payload, tabKey, prods) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { orderId } = payload;
    const requestApi = httpWithLogging(state, 5000, true);

    const body = { ...payload };
    let headers = { 'Content-Type': 'application/json' }; let url = ''; let
      ucaId;

    if (tabKey !== 'guest') {
      const authData = await getAuthApiData();
      headers = { ...headers, ...authData.headers };
      ucaId = authData.ucaId;
      url = `${
        NMConfig.API_DT_ORDER_HISTORY_V3
      }/profiles/${ucaId}/orders/${orderId}/cancel`;
    } else {
      url = `${NMConfig.API_DT_ORDER_HISTORY_V3}/guest/cancel`;
    }

    dispatch({
      type: SUBMIT_CANCEL_ORDER,
      payload: {
        [orderId]: {
          loading: true,
        },
      },
    });
    try {
      const { data: { message } } = await requestApi.post(url, body, { headers });
      const ohState = state.profile.orderHistoryV3;
      const cancelData = {
        message, ohState, tabKey, prods, orderId,
      };
      if (tabKey !== 'guest') {
        updateRegisteredCancelState(dispatch, cancelData);
      } else {
        updateGuestCancelState(dispatch, cancelData);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: CANCEL_ORDER_SUCCESS,
        payload: { [orderId]: { message: error?.response?.data?.message || '', loading: false } },
      });
    } finally {
      dispatch({
        type: SUBMIT_CANCEL_ORDER,
        payload: {
          [orderId]: {
            loading: false,
          },
        },
      });
    }
  };
};

async function sortConsignments(order) {
  return new Promise((resolve) => {
    order.consignments.sort((a, b) => {
      if (['null', 'undefined'].includes(a.narvarTrackingLink)) return 1;
      if (['null', 'undefined'].includes(b.narvarTrackingLink)) return -1;
      const d = (a.items[0]?.eddRaw || 0) - (b.items[0]?.eddRaw || 0);
      return d;
    });
    resolve(order);
  });
}

async function getShippingInformation(state, orders, dispatch) {
  const requestApi = httpWithLogging(state, 3000, true);
  const consignmentMap = {};
  if (!orders.length) {
    dispatch();
    return;
  }

  for (const order of orders) {
    // eslint-disable-next-line
    const narvarresp = await Promise.allSettled(order.consignments.reduce((prev, consignment) => {
      if (!['null', 'undefined'].includes(consignment.narvarTrackingLink)) {
        const lineItem = consignment.items.length ? consignment.items[0] : {};
        const orderDate = lineItem?.shipDate ? lineItem?.shipDate : lineItem?.orderDate;
        const status = lineItem?.orderStatus || lineItem?.status;
        const diffDays = Math.ceil(
          Math.abs(new Date() - new Date(orderDate))
          / (1000 * 60 * 60 * 24)
        );
        if (diffDays <= SHIPMENT_INFO_WINDOW && ['VS', '7000'].includes(`${status}`)) {
          const url = `${NMConfig.API_DT_ORDER_HISTORY}/tracking`;
          const narvarURL = new URL(consignment.narvarTrackingLink);
          const trackingNumber = parse(narvarURL.search)?.tracking_numbers || ''; // eslint-disable-line
          consignmentMap[trackingNumber] = consignment.narvarTrackingLink;
          const body = {
            carrier: narvarURL.pathname.split('/').pop() || '',
            trackingNumber,
          };
          prev.push(requestApi.post(url, body));
        }
      }
      return prev;
    }, []));
    // eslint-disable-next-line
    narvarresp.forEach((resp) => {
      if (resp.status === 'fulfilled' && resp.value.status === 200) {
        const { current_status, delivery, carrier } = resp.value?.data || {}; // eslint-disable-line
        const narvarUrl = consignmentMap[carrier?.tracking_number]; // eslint-disable-line
        const consInd = order.consignments.findIndex((
          (cons) => cons.narvarTrackingLink === narvarUrl));
        if (consInd !== -1) {
          order.consignments[consInd].items = order.consignments[consInd].items.map((item) => {
            const delDate = delivery?.date && new Date(delivery.date);
            const eddBegin = delivery?.edd_begin && new Date(delivery.edd_begin); // eslint-disable-line
            const format = { weekday: 'short', month: 'short', day: 'numeric' };
            const narvarStatus = current_status?.status_code; // eslint-disable-line
            return {
              ...item,
              ...{
                edd: `${(narvarStatus !== 'DLVD' && eddBegin) ? 'Arrives by ' : ''}${(delDate || eddBegin)
                  ? (delDate || eddBegin)?.toLocaleString('en-US', format) : ''}`,
                eddRaw: delDate || eddBegin,
                deliveredDate: delivery?.date || item?.deliveredDate,
                narvarStatus,
                narvarStatusDescription: current_status?.status_description, // eslint-disable-line
                ...(narvarDeliveredStatus.includes(current_status?.status_code) // eslint-disable-line
                  ? { orderStatus: '7500', systemOrderStatus: '7500' } : {}),
              },
            };
          });
        }
      }
    });
    const sorted = await sortConsignments(order);
    dispatch(sorted);
  }
}

const mapOrderDetailItem = (item, data) => {
  item = {  //eslint-disable-line
    //eslint-disable-line
    ...item,
    cmosOrderId: data.cmosOrderNumber,
    product: {
      productId: item.productId?.id,
      name: item.productName,
      thumbnailUrl: item.imageUrl || item.thumbnailUrl,
      size: item.size,
      designerName: item.brand,
    },
    systemOrderStatus: item.systemStatus,
    orderStatus: item.status || item.transactionType || data.orderStatus,
    subChannel: item.transactionType || item.subChannel,
    formattedOrderId: data.formattedOrderId,
    orderDate: data.orderDate,
    totalPrice: data.totalOrderAmount,
    orderTotal: data.totalOrderAmount,
    itemTotal: item.extendedPrice,
    orderId: data?.orderId,
    // shippingAddress:order.shippingAddress
  };

  return item;
};


export const getGuestOrderDetails = (body, isStoreOrder = false) => {
  return async (dispatch, getState) => {
    const url = isStoreOrder
      ? `${NMConfig.API_DT_ORDER_HISTORY_V2}/lookup-store-orders`
      : `${NMConfig.API_DT_ORDER_HISTORY_V3}/guest`;
    try {
      const state = getState();
      const requestApi = httpWithLogging(state, 5000, true);
      dispatch({ type: LOADING_GUEST_ORDERS });
      const { data } = await requestApi.post(url, body);

      let orders = data.orderDetails;
      orders = orders.map((order) => {
        order.consignments = order.consignments.map((cons) => {
          cons.items = cons.items.map((item) => mapOrderDetailItem(item, data));
          return cons;
        });
        return order;
      });
      getShippingInformation(state, orders, (order) => {
        dispatch({
          type: RESOLVED_GUEST_ORDERS,
          payload: {
            orders: order ? [order] : [],
            details: data,
          },
        });
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: REJECTED_GUEST_ORDERS });
    }
  };
};

function handleOrderHistory(response) {
  return (dispatch, getState) => {
    const state = getState();
    const dataObj = response?.data?.orderItems;
    if (dataObj.length < size) {
      dispatch({
        type: SET_FETCHED_ALL_OH,
        payload: true,
      });
    }
    getShippingInformation(state, dataObj, (order) => {
      dispatch({
        type: RESOLVED_COMBINED_ORDER_HISTORY,
        payload: {
          orders: order ? [order] : [],
          pagination: {
            tabs: {
              all: response?.data?.pagination,
            },
          },
        },
      });
    });
  };
}

function handleOnlineOrders(response) {
  return (dispatch, getState) => {
    const state = getState();
    const dataObj = response?.data?.orderItems;
    if (dataObj.length < size) {
      dispatch({
        type: SET_FETCHED_ALL_ONLINE,
        payload: true,
      });
    }

    getShippingInformation(state, dataObj, (order) => {
      dispatch({
        type: SET_ONLINE_ORDER_HISTORY,
        payload: {
          orders: order ? [order] : [],
          pagination: {
            tabs: {
              online: response?.data?.pagination,
            },
          },
        },
      });
    });
  };
}

function handleStoreOrders(response) {
  return (dispatch, getState) => {
    const state = getState();
    const dataObj = response?.data?.orderItems;
    if (dataObj.length < size) {
      dispatch({
        type: SET_FETCHED_ALL_STORE,
        payload: true,
      });
    }

    getShippingInformation(state, dataObj, (order) => {
      dispatch({
        type: SET_STORE_ORDER_HISTORY,
        payload: {
          orders: order ? [order] : [],
          pagination: {
            tabs: {
              store: response?.data?.pagination,
            },
          },
        },
      });
    });
  };
}

const loadingAction = (isCancelled, isOnlineTab, isStoreTab) => {
  if (isCancelled) {
    return { type: LOADING_CANCELLED_ORDERS, payload: true };
  }
  if (isOnlineTab) {
    return { type: LOADING_ONLINE_ORDERS, payload: true };
  }

  if (isStoreTab) {
    return { type: LOADING_STORE_ORDERS, payload: true };
  }
  return { type: LOADING_ORDER_HISTORY_DATA, payload: true };
};

export function getOrderHistoryData({
  sort = {}, filter = {}, search = '',
} = {},
size = 0, isOnlineTab = false, isStoreTab = false) {
  return (dispatch, getState) => {
    const state = getState();
    const canExtendSearch = get(state, 'toggles.OH_EXTENDED_SEARCH', false);
    const searchTimeout = canExtendSearch ? 10000 : 6000;
    const requestApi = httpWithLogging(state, searchTimeout, true);
    const ohState = state.profile.orderHistoryV3;
    // eslint-disable-next-line
    const tab = isOnlineTab ? 'online': (isStoreTab? 'store': 'all');

    Auth.currentAuthenticatedUser().then((user) => {
      // eslint-disable-next-line
      const userId = user?.attributes.sub;
      const jwtTokenId = user?.signInUserSession.idToken.jwtToken;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtTokenId}`,
      };

      const togglesObj = {
        connect: true,
        'in-store': get(state, 'toggles.OH_STORE_ORDERS', false),
      };
      const filterArr = ['connect', 'in-store'].filter(
        (filter) => togglesObj[filter]
      );
      const isCancelled = filter.status === 'CX';
      if (filterArr.length !== 2 && (!isOnlineTab && !isStoreTab && !isCancelled)) {
        filter.channels = [...filterArr, 'online'];
      }

      const pagination = ohState.pagination;
      const body = {
        sort: {
          sortType: 'desc',
          ...sort,
        },
        pagination: {
          from: pagination?.tabs[tab]?.next || {},
          size,
        },
        filter: {
          ...filter,
        },
      };

      if (search) body.searchText = search;

      dispatch(loadingAction(isCancelled, isOnlineTab, isStoreTab));

      const url = `${NMConfig.API_DT_ORDER_HISTORY_V3}/profiles/${userId}/orders`;

      return requestApi
        .post(url, body, {
          headers,
        })
        .then((res) => {
          dispatch({
            type: SET_OH_SEARCH_TEXT,
            payload: search,
          });
          if (isCancelled) {
            dispatch({
              type: SET_CANCELLED_ORDER_HISTORY,
              payload: res?.data?.orderItems,
              pagination: res?.data?.pagination,
            });
          }
          if (isOnlineTab) {
            return dispatch(handleOnlineOrders(res));
          }

          if (isStoreTab) {
            return dispatch(handleStoreOrders(res));
          }

          return dispatch(handleOrderHistory(res));
        })
        .catch(() => {
          const type = isCancelled
            ? REJECTED_CANCELLED_ORDER_HISTORY
            : REJECTED_ORDER_HISTORY;
          dispatch({ type });
        });
    }).catch(() => {
    });
  };
}

export function mapAddressesToItems(data, tabKey, orderId, formattedOrderId) {
  return (dispatch, getState) => {
    const state = getState();
    const defGroup = ['null', 'undefined'];
    const ohState = { ...state.profile.orderHistoryV3 };
    const mappedOrders = data.orderDetails[0];

    const orders = (tabKey === onlineIndex)
      ? ohState.onlineOrders : (
        (tabKey === inStoreIndex) ? ohState.storeOrders : ohState.combinedOrderHistory.allOrders);

    for (let index = 0; index < orders.length; index++) {
      const order = orders[index];
      if ([orderId, formattedOrderId].includes(order.cmosOrderId)) {
        order.consignments = order.consignments.map((cons) => {
          cons.items = cons.items.map((item) => {
            const detailConsInd = mappedOrders.consignments.findIndex(
              (c) => (c?.narvarTrackingLink === cons?.narvarTrackingLink
                || (defGroup.includes(c?.narvarTrackingLink)
                && defGroup.includes(cons?.narvarTrackingLink)))
            );
            if (detailConsInd !== -1) {
              const mcons = mappedOrders.consignments[detailConsInd];
              for (let k = 0; k < mcons.items.length; k++) {
                const element = mcons.items[k];
                let updated = false;
                if (element.pimSkuId === item.itemSKU) {
                  item.shippingAddress = element.shippingAddress;
                  updated = true;
                  break;
                }
                if (updated) break;
              }
            }
            return item;
          });
          return cons;
        });
        break;
      }
    }
    let ohKey = 'combinedOrderHistory';
    ohKey = (tabKey === onlineIndex) ? 'onlineOrders'
      : (tabKey === inStoreIndex) ? 'storeOrders' : ohKey;
    const payload = {
      [ohKey]: (ohKey === 'combinedOrderHistory' ? { allOrders: orders } : orders),
    };
    dispatch({
      type: MAP_OH_ITEM_ADDRESSES,
      payload,
    });
  };
}

export function fetchStyledForYouData(variantId = '', productId, callback) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state, 6000, true);
    const stylyzeUrl = `${NMConfig.API_PRODUCT_OUTFITTING_CLIENT}?accId=59ba93a4e4b0e46ea98f0759&variantIds=${variantId}&page=0&pageSize=3&minimal=true`;

    return requestApi
      .get(stylyzeUrl)
      .then((response) => {
        const data = response?.data?.csbList;
        const stylyzeData = Array.isArray(data) ? data : [];

        if (stylyzeData?.length >= 1) {
          callback();
          dispatch({
            type: RESOLVED_STYLED_FOR_YOU_DATA,
            payload: { [productId]: stylyzeData },
          });
        } else {
          dispatch({
            type: RESOLVED_STYLED_FOR_YOU_DATA,
            payload: { [productId]: 'Not Available' },
          });
        }
      })
      .catch(() => dispatch({
        type: REJECTED_STYLED_FOR_YOU_DATA,
        payload: { [productId]: 'Not Available' },
      }));
  };
}

export const loadGuestOrderDetailsUtagData = (orderType) => ({
  type: GUEST_DETAILS_UTAG,
  payload: { orderType },
});
export const loadOrderHistoryPageUtagData = () => ({
  type: ORDER_HISTORY_UTAG,
});
export const loadOrderHistoryProfileUtagData = (payload) => ({
  type: ORDER_HISTORY_PROFILE_UTAG,
  payload,
});
export const clickedStyledForYouUtagData = () => ({ type: CLICKED_STYLED_FOR_YOU_UTAG });

export function startSearching() {
  return {
    type: START_SEARCHING_OH,
  };
}
