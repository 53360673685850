import { types as NavTypes } from 'storefront/components/Navigation/actions-navigation';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

export const breadcrumbsUtag = (breadcrumbs) => (isEmpty(breadcrumbs) ? null : {
  bread_crumb: map(breadcrumbs, 'name'),
  cat_id: map(breadcrumbs, 'id'),
});
export const editorialBreadcrumbsUtag = (breadcrumbs) => (isEmpty(breadcrumbs)
  ? null
  : { bread_crumb: map(breadcrumbs, 'name') });

export default (state = {}, action) => {
  switch (action.type) {
    case NavTypes.RESOLVED_BREADCRUMB_CONTENT:
      return breadcrumbsUtag(action.payload.breadcrumbs);
    case NavTypes.REJECTED_BREADCRUMB_CONTENT:
      return {};
    case NavTypes.RESOLVED_EDITORIAL_BREADCRUMB_CONTENT:
      return editorialBreadcrumbsUtag(action.payload.breadcrumbs);
    default:
      return state;
  }
};
