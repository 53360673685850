import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Button from 'shared/components/Button/button';
import { US_COUNTRY_CODE, REQUIRED_MONOGRAM } from 'pdp/constants';
import BopsErrorForReplenishment from 'pdp/components/BopsErrorForReplenishment/bopsErrorForReplenishment';
import getProduct from 'pdp/pages/ProductPage/selectors/getProduct';
import {
  getPerformanceLogLevel,
  markTTI,
  markStartTTD,
} from 'client-utils/utilities-performance';
import validatePDP, { BOPS } from '../../pages/ProductPage/pdpPageValidator';
import ErrorMessage from '../ErrorMessage/errorMessage';
import {
  clearError as clearErrorAction,
  clearStores as clearStoresAction,
  setBopsErrorForReplenishment as setBopsErrorForReplenishmentAction,
  showBopsError as showBopsErrorAction,
  addToBag as addToBagAction,
} from '../../pages/ProductPage/actions';

export class DumbBopsButton extends Component {
  constructor() {
    super();
    this.onClickOpenBops = this.onClickOpenBops.bind(this);
  }

  onClickOpenBops() {
    const {
      clearError,
      clearStores,
      excludeBackOrderBOPSToggle,
      product,
      selectedSku,
      setBopsErrorForReplenishment,
      showBopsError,
      skuMediaUrl,
      vendorQtyRestrictions,
      addToBag,
    } = this.props;

    if (getPerformanceLogLevel() > 3) {
      markTTI('PDP_FindInStoreButton');
    }
    markStartTTD('PDP_FindInStoreModal');
    clearError();

    if (product.replenishable && product.replenishInterval) {
      setBopsErrorForReplenishment();
      return;
    }

    const errorMessage = validatePDP(
      product,
      selectedSku,
      vendorQtyRestrictions,
      BOPS,
      false,
      false,
      excludeBackOrderBOPSToggle,
    );
    if (errorMessage) {
      showBopsError(errorMessage);
      return;
    }

    clearStores();
    window.YourNeimans.setData({
      pageStack: ['bopsPage'],
      isOpen: true,
      callback: (storeNumber) => (
        addToBag(product, selectedSku, skuMediaUrl, storeNumber)
      ),
      bopsSku: selectedSku.id,
    });
  }

  isEligibleForPickupInStore() {
    const { product, countryCode } = this.props;
    const behaviorCode = get(product, 'customization.behaviorCode');
    const pickupInStoreEligible = get(product, 'details.pickupInStoreEligible');
    if (behaviorCode === REQUIRED_MONOGRAM) {
      return false;
    }
    return (pickupInStoreEligible === true && countryCode === US_COUNTRY_CODE);
  }

  render() {
    const {
      errorMessage,
      product,
    } = this.props;

    const bopsButton = (
      <div className="grid-100 tablet-grid-100 mobile-grid-100 bops-button grid-parent">
        <ErrorMessage message={errorMessage} />
        <BopsErrorForReplenishment
          isError={product.bopsErrorForReplenishment}
        />
        <div className="grid-50 tablet-grid-65 mobile-grid-100 grid-parent">
          <Button secondary onClick={this.onClickOpenBops} value="FIND IN STORE" />
        </div>
      </div>
    );

    return this.isEligibleForPickupInStore() && bopsButton;
  }
}

const mapStateToProps = (state, { product: { id: productId } }) => {
  const product = getProduct(state, productId);

  return {
    errorMessage: product.bopsError,
    vendorQtyRestrictions: state.productCatalog.vendorQtyRestrictions,
    countryCode: state.locale.countryCode,
    excludeBackOrderBOPSToggle: state.toggles.EXCLUDE_BACKORDER_FOR_BOPS,
  };
};

const mapDispatchToProps = (dispatch, { product: { id: productId } }) => ({
  addToBag: (...args) => dispatch(addToBagAction(...args)),
  showBopsError: (...args) => dispatch(showBopsErrorAction(productId, ...args)),
  clearError: () => dispatch(clearErrorAction(productId)),
  clearStores: () => dispatch(clearStoresAction()),
  setBopsErrorForReplenishment: () => (
    dispatch(setBopsErrorForReplenishmentAction(productId))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DumbBopsButton);
