import { types } from './promoCarousel-actions';

const splitDivs = (content) => content.match(/(<div)[\s\S]+?(<\/div>)/gm);

const defaultState = {
  carouselDivs: ['<div></div>'],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.RESOLVED_PROMO_CAROUSEL_CONTENT: {
      return {
        ...state,
        carouselDivs: splitDivs(action.payload),
      };
    }
    default: {
      return state;
    }
  }
};
