import { stripHtml } from 'client-utils/utilities-linked-data';
import { RESOLVED_PLA_RECOMMENDATIONS, REJECTED_PLA_RECOMMENDATIONS } from '../../actions';

export const defaultState = { pla: [] };

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_PLA_RECOMMENDATIONS:
      return {
        ...state,
        pla: action.payload.map(
          (product) => ({ ...product, name: stripHtml(product.name) })
        ),
      };
    case REJECTED_PLA_RECOMMENDATIONS:
      return { ...state, pla: [] };
    default:
      return state;
  }
};
