import capitalize from 'lodash/capitalize';

export function resolveEdtiorialBreadcrumbs(urlPathArr) {
  return urlPathArr
    .map(
      (dir) => dir.split('-').map(capitalize).join(' ')
    )
    .map((path) => ({
      name: path.toLowerCase() === 'editorial' ? 'Magazine' : path,
    }));
}
