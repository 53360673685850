const convertArrayScaleToObject = ({ scale } = {}) => {
  if (typeof scale !== 'object' || !Array.isArray(scale)) {
    throw new Error('arrayScale must be an array.');
  }
  return (Object.entries(scale).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {}));
};

const createScaleObject = ({
  scale = [],
  defaultCSSUnit = 'px',
  includeUnitWithZero = false,
  unitless = false,
} = {}) => {
  const CSS_UNITS = [
    'rem',
    'em',
    'vh',
    'vw',
    'vmin',
    'vmax',
    'ex',
    '%',
    'px',
    'cm',
    'mm',
    'in',
    'pt',
    'pc',
    'ch',
  ];
  if (!Array.isArray(scale)) {
    throw new Error('scale must be an array.');
  }
  if (typeof defaultCSSUnit !== 'string' || !CSS_UNITS.includes(defaultCSSUnit)) {
    throw new Error(`defaultCSSUnit must be one of the following strings ["${CSS_UNITS.join('", "')}"].`);
  }
  if (typeof includeUnitWithZero !== 'boolean') {
    throw new Error('includeUnitWithZero must be a boolean.');
  }
  if (typeof unitless !== 'boolean') {
    throw new Error('unitless must be a boolean.');
  }
  return convertArrayScaleToObject({
    scale: scale.map((dimension) => {
      let value;
      let unit;
      if (typeof dimension === 'string') {
        const parts = dimension.match(/[a-zA-Z]+|[0-9]+|[%]/g);
        [value] = parts;
        unit = parts[parts.length - 1];
        if (!CSS_UNITS.includes(unit) && !unitless) {
          unit = defaultCSSUnit;
        }
      } else {
        value = dimension;
      }
      if ((value === 0 && !includeUnitWithZero) || unitless) {
        return `${value}`;
      }
      if (unit) {
        return `${value}${unit}`;
      }
      return `${value}${defaultCSSUnit}`;
    }),
  });
};

const theme = {};
theme.base = 'nm';

/*
  Media Queries
*/
const breakpoints = createScaleObject({ scale: [0, 768, 1024, 1600], defaultCSSUnit: 'px' });

// aliases
breakpoints.sm = breakpoints['0'];
breakpoints.md = breakpoints['1'];
breakpoints.lg = breakpoints['2'];
breakpoints.xl = breakpoints['3'];

theme.breakpoints = breakpoints;

const mediaQueries = {
  sm: `@media screen and (min-width: ${breakpoints.sm})`,
  md: `@media screen and (min-width: ${breakpoints.md})`,
  lg: `@media screen and (min-width: ${breakpoints.lg})`,
  xl: `@media screen and (min-width: ${breakpoints.xl})`,
  landscape: '@media (orientation: landscape)',
  portrait: '@media (orientation: portrait)',
  print: '@media print',
};

theme.mediaQueries = mediaQueries;

/*
  Theme Key: space
  CSS Properties:
    margin, margin-top, margin-right, margin-bottom, margin-left,
    padding, padding-top, padding-right, padding-bottom, padding-left,
    grid-gap, grid-column-gap, grid-row-gap
*/
const space = createScaleObject({ scale: [0, 4, 8, 16, 32, 64, 128, 256], defaultCSSUnit: 'px' });

// aliases
space.small = space['1'];
space.medium = space['2'];
space.large = space['3'];

theme.space = space;

/*
  Theme Key: fontSizes
  CSS Properties:
    font-size
*/
const fontSizes = createScaleObject({ scale: [12, 14, 16, 18, 20, 24, 32, 48], defaultCSSUnit: 'px' });

// aliases
fontSizes.base = fontSizes['2'];
fontSizes.body = fontSizes['2'];
fontSizes.heading = fontSizes['3'];

theme.fontSizes = fontSizes;

/*
  Theme Key: colors
  CSS Properties:
    color, background-color, border-color
*/
const colors = {
  black: '#000',
  white: '#fff',
  creamLight: '#f8f5f0',
  grayLight: '#ededf0',
  grayMed: '#d3d3d3',
  grayDark: '#757575',
  red: '#b00000',
  green: '#1e561f',
  greenEmerald: '#157a61',
  blue: '#3474ad',
  periwinkle: '#cbccfe',
  yellow: '#f0b744',
  transparent: 'transparent',
};

// aliases
colors.text = colors.black;
colors.background = colors.white;
colors.primary = colors.black;
colors.secondary = colors.greenEmerald;
colors.highlight = colors.periwinkle;
colors.gray = colors.grayMed;
colors.lightgray = colors.grayLight;
colors.darkgray = colors.grayDark;
colors.state = {
  active: colors.blue,
  success: colors.green,
  warning: colors.yellow,
  error: colors.red,
};

theme.colors = colors;

/*
  Theme Key: fonts
  CSS Properties:
    font-family
*/
const fonts = {
  bigCaslon: 'big-caslon-fb, "Times New Roman", Georgia, "Droid Serif", serif',
  neuzeitGrotesk: 'neuzeit-grotesk, Futura, "Century Gothic", Roboto, sans-serif',
  helveticaNeue: '"Helvetica Neue", Arial, "Liberation Sans", FreeSans, sans-serif',
  helvetica: 'Helvetica, Arial, Roboto, sans-serif',
  futura: 'futura-pt, Futura, "Century Gothic", Roboto, sans-serif',
  mono: 'Menlo, Courier, monospace',
};
fonts.primary = fonts.neuzeitGrotesk;
fonts.body = fonts.futura;
fonts.heading = fonts.futura;
fonts.aem = fonts.bigCaslon;

theme.fonts = fonts;

/*
  Theme Key: fontWeights
  CSS Properties:
    font-weight
*/
const fontWeights = createScaleObject({
  scale: [200, 300, 400, 600, 700, 900],
  unitless: true,
});

// aliases
fontWeights.light = fontWeights['1'];
fontWeights.regular = fontWeights['2'];
fontWeights.bold = fontWeights['4'];
fontWeights.black = fontWeights['5'];

theme.fontWeights = fontWeights;

/*
  Theme Key: lineHeights
  CSS Properties:
    line-height
*/
const lineHeights = createScaleObject({
  scale: [1, 1.25, 1.5],
  unitless: true,
});

// aliases
lineHeights.default = lineHeights['0'];
lineHeights.heading = lineHeights['1'];
lineHeights.body = lineHeights['2'];

theme.lineHeights = lineHeights;

/*
  Theme Key: letterSpacings
  CSS Properties:
    letter-spacing
*/
const letterSpacings = {
  normal: 'normal',
  default: '0.16px',
  sm: '-0.05em',
  lg: '0.25em',
};
theme.letterSpacings = letterSpacings;

/*
  Theme Key: sizes
  CSS Properties:
    width, height, min-width, max-width, min-height, max-height
*/
const sizes = createScaleObject({ scale: [16, 32, 64, 128, 256, 512, 768, 1024, 1536], defaultCSSUnit: 'px' });

theme.sizes = sizes;

/*
  Theme Key: borders
  CSS Properties:
    border, border-top, border-right, border-bottom, border-left
*/
const borders = convertArrayScaleToObject({
  scale: [
    0,
    '1px solid',
    '2px solid',
    '4px solid',
    '8px solid',
    '16px solid',
    '32px solid',
  ],
});
theme.borders = borders;

/*
  Theme Key: borderWidths
  CSS Properties:
    border-width
*/
const borderWidths = createScaleObject({ scale: [0, 1, 2, 4, 6], defaultCSSUnit: 'px' });
theme.borderWidths = borderWidths;

/*
  Theme Key: borderStyles
  CSS Properties:
    border-style
*/
// theme.borderStyles = {};

/*
  Theme Key: radii
  CSS Properties:
    border-radius
*/
const radii = createScaleObject({ scale: [0, 2, 4, 16, 9999, '100%'], defaultCSSUnit: 'px' });
theme.radii = radii;

/*
  Theme Key: shadows
  CSS Properties:
    box-shadow, text-shadow
*/
theme.shadows = {
  sm: '0 0 4px rgba(0, 0, 0, .125)',
  lg: '0 0 24px rgba(0, 0, 0, .125)',
};

/*
  Theme Key: zIndices
  CSS Properties:
    z-index
*/
const zIndices = createScaleObject({
  scale: [1000, 1020, 1030, 1040, 1050, 1060, 1070],
  unitless: true,
});

// aliases
zIndices.min = zIndices['0'];
zIndices.max = zIndices['6'];
zIndices.dropdown = zIndices['0'];
zIndices.sticky = zIndices['1'];
zIndices.fixed = zIndices['2'];
zIndices['modal-backdrop'] = zIndices['3'];
zIndices.modal = zIndices['4'];
zIndices.popover = zIndices['5'];
zIndices.tooltip = zIndices['6'];

theme.zIndices = zIndices;

/*
  Component Theme Keys
*/

theme.contentContainer = {
  minWidth: '320px',
  maxWidth: '1600px',
};

theme.buttons = {
  primary: {
    color: colors.white,
    backgroundColor: colors.primary,
    hover: {
      backgroundColor: colors.secondary,
    },
    active: {
      backgroundColor: colors.secondary,
    },
  },
  secondary: {
    color: colors.black,
    backgroundColor: colors.white,
    border: `${borders['2']} ${colors.primary}`,
    hover: {
      color: colors.secondary,
      borderColor: colors.secondary,
    },
    active: {
      color: colors.secondary,
      borderColor: colors.secondary,
    },
  },
  disabled: {
    color: colors.grayDark,
    backgroundColor: colors.grayLight,
  },
};

module.exports = theme;
