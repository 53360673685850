import isEmpty from 'lodash/isEmpty';

const defaultState = {
  total: null,
  products: [],
  curratedProducts: [],
};

const productArrayUtil = (products, additionalProducts) => products.concat(additionalProducts);

export default (state = defaultState, action) => {
  const { prodId = '', catID = '' } = action;
  switch (action.type) {
    case `RESOLVED_ECM_QL_PRODUCT_INFO_${prodId}`: {
      if (isEmpty(state.products)) {
        return {
          ...state,
          total: action.payload.total,
          products: action.payload.products,
        };
      }
      const newProducts = productArrayUtil(state.products, action.payload.products);
      return {
        ...state,
        products: newProducts,
        total: newProducts.length,
      };
    }
    case `RESOLVED_ECM_CURRATED_PRODUCTS_${catID}`:
      return {
        ...state,
        curratedProducts: [
          ...state.curratedProducts,
          {
            catID,
            products: action.payload,
          },
        ],
      };
    default:
      return state;
  }
};
