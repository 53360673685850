import get from 'lodash/get';
import getSelectedBuckleFinishIndex from 'pdp/pages/ProductPage/selectors/getSelectedBuckleFinishIndex';
import {
  DISCONTINUED_CODES_YR,
  NON_DISCONTINUED_CODE,
} from 'pdp/constants';

export const ADD_TO_CART = 'ADD_TO_CART';
export const BOPS = 'BOPS';

export default (
  productState,
  selectedSku,
  vendorQtyRestrictions,
  type,
  customizationOptionTypePDP = false,
  isNewPDP = false,
  excludeBackOrderBOPSToggle = false,
) => {
  const {
    quantity = 0,
    departmentCode,
  } = productState;

  const selectedBuckleFinishIndex = getSelectedBuckleFinishIndex(productState);
  const selectedSizeIndex = get(productState, 'options.selectedSizeIndex', -1);
  const selectedFrequencyIndex = productState.replenishInterval === undefined
    ? -1
    : productState.replenishInterval;
  const restrictedQty = get(vendorQtyRestrictions, departmentCode);
  const isNotDiscontinued = () => (
    selectedSku.discontinuedCode === NON_DISCONTINUED_CODE
  );
  const inStock = () => (
    selectedSku.inStock && selectedSku.stockLevel >= quantity
  );
  const onOrder = () => (
    (selectedSku.preOrder || selectedSku.backOrder)
    && selectedSku.purchaseOrderQuantity >= quantity
  );

  const isValidBackOrder = () => (
    selectedSku.sellable
    && DISCONTINUED_CODES_YR.includes(selectedSku.discontinuedCode)
    && (productState.quantity <= (
      selectedSku.stockLevel + selectedSku.purchaseOrderQuantity
    ))
  );

  if (!selectedSku) {
    if (selectedSizeIndex === -1) {
      return 'Please select your size.';
    }

    return 'Please select a color, size, and/or monogram option.';
  }

  if (customizationOptionTypePDP && selectedBuckleFinishIndex === -1) {
    return 'Please select a buckle finish.';
  }

  if (isNewPDP
    && productState.replenishable
    && selectedFrequencyIndex === -1
  ) {
    return 'Please choose a frequency for delivery.';
  }

  if (quantity === 0) {
    return 'Please choose a quantity larger than 0.';
  }

  if (quantity > restrictedQty) {
    return `You may not purchase more than ${restrictedQty} of any one item from this brand at a time. Please update quantity to continue.`;
  }

  if (productState.perishable && !productState.deliveryDate) {
    return 'Please select a date to proceed';
  }

  if (type === BOPS && productState.isPersonalizationSelected) {
    return 'Personalized items are not available for in-store pickup.';
  }

  if (excludeBackOrderBOPSToggle && type === BOPS && selectedSku.backOrder) {
    return 'Items on back order are not available for in-store pickup.';
  }

  if (type !== ADD_TO_CART && type !== BOPS) {
    return false;
  }

  if (isNotDiscontinued()
    || inStock()
    || onOrder()
    || selectedSku.dropShip
    || isValidBackOrder()
  ) {
    return false;
  }

  return 'The quantity requested is not available. Please enter a lower quantity.';
};
