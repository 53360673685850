/* eslint-disable consistent-return */
import AWS from 'aws-sdk';
import log from 'server/utilities/logger';

function removeDraftsFromSubLayouts(layout, level = 0, isComponent) {
  if (level === 6 && !isComponent) { return; }

  if (!layout.fields) { return; }

  const subLayoutString = (level !== 1 ? `l${level + 1}Layouts` : `l${level + 1}Layout`);

  if (layout.fields[subLayoutString]) {
    const draftlessSubLayout = layout.fields[subLayoutString].map((subLayout) => {
      return removeDraftsFromSubLayouts(subLayout, level + 1);
    });
    // eslint-disable-next-line
    return {
      ...layout,
      fields: {
        ...layout.fields,
        [subLayoutString]: draftlessSubLayout,
      },
    };
  }

  if (layout.fields.components) {
    if (!isComponent) {
      const draftlessComponents = layout.fields.components.map((subComponent) => {
        return removeDraftsFromSubLayouts(subComponent, level + 1, true);
      });
      // eslint-disable-next-line
      return {
        ...layout,
        fields: {
          ...layout.fields,
          components: draftlessComponents,
        },
      };
    }
    // eslint-disable-next-line
    return {
      ...layout,
      fields: {
        ...layout.fields,
        components: layout.fields.components.filter((component) => component.fields),
      },
    };
  }

  // eslint-disable-next-line
  return layout;
}

export function removeDraftsFromLayout(contentLayout) {
  return contentLayout.map((item) => removeDraftsFromSubLayouts(item));
}

export const getStaticJsonData = async (brand, channel, isInternational, slugs, format) => {
  let data = {};

  try {
    const s3 = new AWS.S3();
    const region = isInternational ? 'International' : 'Domestic';
    const params = {
      Bucket: NMConfig.STATIC_CMS_JSON,
      Key: `${brand}/${channel}/${region}/${slugs}/${format}.json`,
    };
    const start = Date.now();
    const s3Response = await s3.getObject(params).promise();
    const elapsed = Date.now() - start;
    log.info(`Execution time to retrieve S3 content: ${elapsed} milliseconds`);
    data = JSON.parse(s3Response?.Body.toString());
  } catch (error) {
    log.error('Error fetching static json from S3 ', error);
  }
  return data;
};

export const productIdComparator = (productIdsString) => {
  const orderMap = productIdsString
    .reduce((currentOrderMap, productId, index) => ({
      ...currentOrderMap,
      [productId]: index,
    }), {});

  return (a, b) => orderMap[a.id] - orderMap[b.id];
};
