import { RESOLVED_BRAND_LINKS, REJECTED_BRAND_LINKS } from './sisterSites-actions';

export default (state = {}, action) => {
  switch (action.type) {
    case RESOLVED_BRAND_LINKS:
      return {
        ...action.payload,
      };
    case REJECTED_BRAND_LINKS:
      return state;
    default:
      return state;
  }
};
