import React from 'react';
import get from 'lodash/get';
import {
  startOfDay, endOfDay, isWithinInterval, parseISO,
} from 'date-fns';


import './bossMessage.scss';

function BossMessage({
  selectedSku,
  startDate,
  endDate,
  message,
}) {
  if (!startDate || !endDate) {
    return null;
  }
  const isTodayEligibleForBOSS = isWithinInterval(
    startOfDay(new Date()),
    {
      start: startOfDay(parseISO(startDate)),
      end: endOfDay(parseISO(endDate)),
    },
  );
  const isSkuEligibleForBOSS = get(selectedSku, 'sellable') && get(selectedSku, 'shipFromStore');


  if (isTodayEligibleForBOSS && isSkuEligibleForBOSS) {
    return (
      <div className="grid-75 tablet-grid-100 mobile-grid-100">
        <div className="bossMessage">{message}</div>
      </div>
    );
  }

  return null;
}

export default BossMessage;
