import {
  GET_STORES,
  CLEAR_STORES,
  GET_STORE_JSON,
  CLEAR_STORE_JSON,
} from '../actions';

const defaultState = {
  data: [],
  status: null,
  json: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_STORES:
      return {
        data: action.payload.stores,
        status: action.payload.status,
        json: state.json,
      };
    case CLEAR_STORES:
      return {
        data: defaultState.data,
        status: defaultState.status,
        json: state.json,
      };
    case GET_STORE_JSON:
      return {
        data: state.data,
        status: state.status,
        json: [
          ...state.json,
          {
            ...action.payload.data,
            distance: action.payload.distance,
          },
        ],
      };
    case CLEAR_STORE_JSON:
      return {
        data: state.data,
        status: state.status,
        json: defaultState.json,
      };
    default:
      return state;
  }
};
