import { getGuestTokens } from '@nmg/auth';
import { getAuthApiData } from '../../../utilities/amplifyUtils';

export const getRequestParams = async (isGuest) => {
  const { Sub: guestId } = getGuestTokens(['Sub']);
  const reqParams = {
    id: guestId,
    headers: {},
  };
  if (isGuest) return reqParams;
  const { headers, profileId } = await getAuthApiData();
  reqParams.id = profileId;
  reqParams.headers = headers;
  return reqParams;
};
