import isEmpty from 'lodash/isEmpty';


function beginsWithNumber(inputString) {
  return inputString.match('^[0-9]');
}

export function sortSelectedValuesForDesigner(validSelections) {
  if (isEmpty(validSelections)) {
    return [];
  }
  return validSelections.sort((filterOptionOne, filterOptionTwo) => {
    const lowerCaseFilterOptionOne = filterOptionOne.toLowerCase();
    const lowerCaseFilterOptionTwo = filterOptionTwo.toLowerCase();
    const filterOptionOneBeginsWithNumber = beginsWithNumber(lowerCaseFilterOptionOne);
    const filterOptionTwoBeginsWithNumber = beginsWithNumber(lowerCaseFilterOptionTwo);
    const oneOfTheFilterOptionBeginsWithANumber = (
      (filterOptionOneBeginsWithNumber && !filterOptionTwoBeginsWithNumber)
      || (filterOptionTwoBeginsWithNumber && !filterOptionOneBeginsWithNumber)
    );
    const lexicalSortResult = lowerCaseFilterOptionOne < lowerCaseFilterOptionTwo ? -1 : 1;
    return oneOfTheFilterOptionBeginsWithANumber ? -1 * lexicalSortResult : lexicalSortResult;
  });
}

export function sortSelectedValuesForGeneral(keys, validSelections) {
  if (isEmpty(validSelections)) {
    return [];
  }
  return validSelections.sort(
    (filterOptionOne, filterOptionTwo) => {
      return keys.indexOf(filterOptionOne) - keys.indexOf(filterOptionTwo);
    }
  );
}
