import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './ErrorBoundary.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
  errorBoundaryID: PropTypes.string.isRequired,
  error: PropTypes.instanceOf(Error),
  componentStack: PropTypes.string,
};

const defaultProps = {
  className: '',
  children: null,
  uiComponent: 'ErrorBoundaryFallback',
  testID: null,
  error: null,
  componentStack: '',
};

const ErrorBoundaryFallback = ({
  className,
  children,
  as: Element,
  uiComponent,
  testID,
  errorBoundaryID,
  error = null,
  componentStack = '',
  ...props
}) => {
  const classList = cx(['ErrorBoundary', className].join(' ').trim().replace(/\s+/g, ' '));
  if (!__PROD__) {
    return (
      <div
        className={classList}
        {...props}
        data-ui-component={uiComponent}
        data-tid={testID}
      >
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {`ErrorBoundaryID: ${errorBoundaryID}`}
          <br />
          {error && error.toString()}
          <br />
          {componentStack}
        </details>
      </div>
    );
  }
  if (__PROD__) {
    return (
      <div
        {...props}
        data-ui-component={uiComponent}
        data-tid={testID}
      />
    );
  }
  return null;
};

ErrorBoundaryFallback.displayName = 'ErrorBoundaryFallback';
ErrorBoundaryFallback.propTypes = propTypes;
ErrorBoundaryFallback.defaultProps = defaultProps;

export default ErrorBoundaryFallback;
