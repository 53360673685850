import {
  RESOLVED_CRP,
  RESOLVED_CRP_CMS_CONTENT,
  REJECTED_CRP,
} from 'shared/actions/actions-crp';

export const defaultState = {
  crpResponse: {},
  cacheKey: '',
  error: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_CRP: {
      return {
        ...state,
        crpResponse: action.status,
        cacheKey: action.payload.cacheKey,
      };
    }

    case REJECTED_CRP: {
      return {
        ...state,
        crpResponse: {},
        cacheKey: '',
        error: action.payload,
      };
    }

    case RESOLVED_CRP_CMS_CONTENT: {
      return {
        ...state,
        cmsContent: action.payload,
      };
    }

    default:
      return state;
  }
};
