import {
  APP_REQUEST_RECEIVED,
  PLP_SPA_PATH_UPDATE,
} from 'client/common/components/App/app-action-types';

const defaultState = '';

const isNavPathPresent = (action) => {
  return action.payload.request.query.navpath;
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_REQUEST_RECEIVED:
      if (isNavPathPresent(action)) {
        return action.payload.request.query.navpath;
      }
      return action.payload.request.query.xbcpath || defaultState;
    case PLP_SPA_PATH_UPDATE:
      return action.breadcrumbPath;
    default:
      return state;
  }
};
