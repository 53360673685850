import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Divider.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

const defaultProps = {
  className: '',
  children: null,
  uiComponent: 'Divider',
  testID: undefined,
  orientation: 'horizontal',
};

const Divider = ({
  className,
  children,
  uiComponent,
  testID,
  orientation,
  ...props
}) => {
  const classList = cx({
    Divider: true,
    'Divider--vertical': orientation === 'vertical',
  }, [className].join(' ').trim().replace(/\s+/g, ' '));
  return (
    <hr
      className={classList}
      aria-orientation={orientation}
      data-ui-component={uiComponent}
      data-tid={testID}
      {...props}
    />
  );
};

Divider.displayName = 'Divider';
Divider.propTypes = propTypes;
Divider.defaultProps = defaultProps;

export default Divider;
