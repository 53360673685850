import cookie from 'react-cookie';
import { GENDER_PLACEMENT } from 'universal/constants/genderPlacement';


export const setSelectedGender = (gender) => {
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  cookie.save('dt_gender', gender, { path: '/', expires: oneYearFromNow });
};

export const getValidGender = (gender) => (gender === 'M' ? 'M' : 'W');

export const getSelectedGender = () => {
  const dtSelectedGender = cookie.load('dt_gender') || 'W';
  return getValidGender(dtSelectedGender);
};

export const setGenderPlacementCookie = (genderPlacement) => {
  cookie.save('dt_gender_placement', genderPlacement, { path: '/' });
};

export const getGenderPlacement = () => cookie.load('dt_gender_placement') || GENDER_PLACEMENT.DEFAULT;

export const getGenderWithOverride = (state) => {
  if (state && state.session) {
    const { genderOverride } = state.session;
    const dtGender = state.session.dt_gender;
    if (genderOverride) {
      return 'M';
    }
    return dtGender || 'W';
  }
  return 'W';
};
