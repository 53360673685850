/* eslint-disable camelcase */
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { ABTEST_COLLAPSED_SEARCH_BAR, TYPEAHEAD_TEST } from 'client/common/actions/actions-page';

export const promoTitlePlaceHolders = {
  Mobile: {
    withOutLazyLoad: [8, 45, 60, 61],
    withLazyLoad: [8, 46, 77, 99],
  },
  Desktop: {
    withOutLazyLoad: [8, 46, 60, 61],
    withLazyLoad: [8, 46, 77, 101],
  },
  Tablet: {
    withOutLazyLoad: [8, 47, 62],
    withLazyLoad: [8, 46, 77],
  },
};

export const isGenderNavOn = (state) => {
  return state.toggles.SRP_GENDER;
};

export const isGenderOnHPandSRP = (state) => {
  const hideGenderSwitch = state.toggles.GENDERLESS_SRP;
  const genderNavToggleSRP = state.toggles.SRP_GENDER;
  const genderNavToggleHP = state.toggles.HP_GENDER;

  return (genderNavToggleSRP && genderNavToggleHP) && !hideGenderSwitch;
};


export const refreshResultsForGenderSwitch = (gender, router) => {
  // setSelectedGender(gender);
  const query = router?.location?.query;
  const {
    from,
    responsive,
    request_type,
    search_type,
    q,
    l,
    fl,
  } = query;
  const nextHref = `${'/s/'}?from=${from}&responsive=${responsive}&request_type=${request_type}&search_type=${search_type}&q=${q}&l=${l}&fl=${fl}`;
  window.location.href = nextHref;
};

export const isTypeAheadOn = (state) => {
  const isDomestic = get(state, 'locale.countryCode') === 'US';
  const toggle = state.toggles.TYPEAHEAD;
  const typeAheadAbTest = state.toggles.ABTEST_TYPEAHEAD;
  const isGenderTypeahead = state.toggles.GENDER_TYPEAHEAD;
  const testAssignment = get(state, `abTestsOpt.${TYPEAHEAD_TEST}.variation`) === 'b';
  const isTypeAheadExperience = (toggle
    || (!isGenderTypeahead && typeAheadAbTest && (testAssignment))) && isDomestic;
  return isTypeAheadExperience;
};

export const isCollapsedSearchBarOn = (state) => {
  const isDomestic = get(state, 'locale.countryCode', 'US') === 'US';
  const collapseSearchToggle = state.toggles.COLLAPSED_SEARCH_BAR;
  const collapseSearchAbTest = state.toggles.ABTEST_COLLAPSED_SEARCH_BAR
  && get(state, `abTestsOpt.${ABTEST_COLLAPSED_SEARCH_BAR}.variation`) === 'b';
  const isCollapsedSearchExperience = ((collapseSearchToggle
    || collapseSearchAbTest) && isDomestic);
  return isCollapsedSearchExperience;
};

export const isModalOpen = (state, modalType) => {
  const open = get(state, 'modal.open');
  const type = get(state, 'modal.type');
  return open && (type === modalType);
};

export const renderSelectedSRP = (selectedFilterOptions = {}, catSelected, genderNavExperience) => {
  let sum = 0;
  if (typeof selectedFilterOptions !== 'string') {
    Object.keys(selectedFilterOptions).forEach((option) => {
      if (!isEmpty(selectedFilterOptions[option]) && option !== 'In Store' && option !== 'Category') {
        sum += selectedFilterOptions[option].length;
      } else if (!isEmpty(selectedFilterOptions[option]) && option === 'In Store') {
        let inStoreSum = 0;
        const inStoreArray = selectedFilterOptions[option].slice(1);
        inStoreArray.forEach((deliveryOption) => {
          if (!isEmpty(deliveryOption)) {
            inStoreSum += 1;
          }
        });
        sum += inStoreSum;
      }
    });
  }
  if (!isEmpty(catSelected) && genderNavExperience) {
    const catCount = catSelected.length;
    sum += catCount;
  }
  return sum;
};

export const formatTopItems = (topProductsData) => {
  if (typeof topProductsData[0] === 'string') {
    return topProductsData;
  }
  if (!isEmpty(topProductsData)) {
    const topItems = topProductsData
      .reduce((filtered, product) => {
        if (product) {
          const { price } = product;
          let prices = [];
          const retailPrice = parseInt(price.retailPrice, 10);
          // eslint-disable-next-line no-prototype-builtins
          if (price.hasOwnProperty('adornments')) {
            prices = [
              ...price.adornments.map(({ price }) => parseInt(price, 10)),
              price.promotionalPrice,
              retailPrice,
            ].sort((a, b) => +a - +b).filter((e) => e !== undefined);
          } else {
            prices = [
              price.promotionalPrice,
              retailPrice,
            ].sort((a, b) => +a - +b);
          }
          const highPrice = prices[prices.length - 1];
          const lowPrice = prices[0];
          let onePrice = null;
          if (parseInt(highPrice, 10) === parseInt(lowPrice, 10)) {
            onePrice = lowPrice;
          }
          if (!highPrice) {
            onePrice = `${lowPrice}`;
          }

          const nextProduct = {
            title: product.designer.name,
            url: product.url,
            img: product.main,
            oldPrice: highPrice,
            price: lowPrice,
            onePrice,
          };
          filtered.push(nextProduct);
        }
        return filtered;
      }, []);
    return topItems;
  }
  return [];
};
