import { types as NavTypes } from '../actions-navigation';

const defaultState = {
  navBarLeft: 0,
  navBarRight: 0,
};

export default (state = defaultState, action) => {
  if (action.type === NavTypes.UPDATE_NAV_BAR) {
    const newLeft = action.navBar.getBoundingClientRect().left;
    const newRight = action.navBar.getBoundingClientRect().right;
    if (state.navBarLeft !== newLeft || state.navBarRight !== newRight) {
      return {
        ...state,
        navBarLeft: newLeft,
        navBarRight: newRight,
      };
    }
    return state;
  }
  return state;
};
