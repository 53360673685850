import get from 'lodash/get';
import {
  RESOLVED_CMS_ENTRIES,
  REJECTED_CMS_ENTRIES,
  RESOLVED_CMS_GLOBAL,
  REJECTED_CMS_GLOBAL,
  RESOLVED_CMS_STORIES,
  RESOLVED_EDITORIAL_STORIES,
  REJECTED_EDITORIAL_STORIES,
  SET_STORY_CLASSIFICATION,
  OPEN_POPOUT,
  CLOSE_POPOUT,
  SET_ACTIVE_ENTRY_ID,
  SET_RAIL_HOVERED,
  SET_RAIL_NOT_HOVERED,
  SET_RAIL_LINK_HOVERED,
  SET_RAIL_LINK_NOT_HOVERED,
} from '../actions';

const defaultState = {
  classification: '',
  stories: [],
  popout: { visible: false, productCarousel: {} },
  modal: [],
  dynamicRail: { hovered: false, hoveredLink: false },
};

const entriesReducer = (state = defaultState, action = {}) => {
  const { catID = '' } = action;
  const contentId = get(action, 'contentId', '');
  switch (action.type) {
    case RESOLVED_CMS_ENTRIES: {
      return {
        ...state,
        entries: [...action.payload],
      };
    }
    case REJECTED_CMS_ENTRIES:
      return {
        ...state,
        entries: [],
      };
    case RESOLVED_CMS_STORIES:
      return {
        ...state,
        stories: action.payload,
      };
    case RESOLVED_EDITORIAL_STORIES:
      return {
        ...state,
        stories: action.payload,
      };
    case REJECTED_EDITORIAL_STORIES:
      return {
        ...state,
        stories: [],
      };
    case SET_STORY_CLASSIFICATION:
      return {
        ...state,
        classification: action.payload,
      };
    case RESOLVED_CMS_GLOBAL: {
      return {
        ...state,
        global: action.payload,
      };
    }
    case REJECTED_CMS_GLOBAL:
      return {
        ...state,
        global: null,
      };
    case `RESOLVED_CMS_PRODUCTS_${contentId}`: {
      return {
        ...state,
        products: {
          ...state.products,
          [contentId]: action.payload,
        },
      };
    }
    case `RESOLVED_CMS_PRODUCT_CAROUSEL_${contentId}`: {
      return {
        ...state,
        popout: {
          ...state.popout,
          productCarousel: action.payload,
        },
      };
    }
    case `RESOLVED_CHILD_PRODUCTS_${contentId}`: {
      return {
        ...state,
        childProducts: {
          ...state.childProducts,
          [contentId]: action.payload,
        },
      };
    }
    case `RESOLVED_CMS_CURRATED_PRODUCTS_${catID}`:
      return {
        ...state,
        productsByCategory: {
          ...state.productsByCategory,
          [catID]: action.payload,
        },
      };
    case `RESOLVED_PERSONALIZED_PRODUCTS_${contentId}`:
      return {
        ...state,
        personalizedProductIds: {
          ...state.personalizedProductIds,
          [contentId]: action.payload,
        },
      };
    case OPEN_POPOUT:
      return {
        ...state,
        popout: {
          ...state.popout,
          visible: true,
        },
      };
    case CLOSE_POPOUT:
      return {
        ...state,
        popout: {
          ...state.popout,
          visible: false,
        },
      };

    case `RESOLVED_MODAL_CONTENT_${contentId}`: {
      return {
        ...state,
        modal: {
          ...state.modal,
          [contentId]: action.payload,
        },
      };
    }

    case SET_ACTIVE_ENTRY_ID: {
      return {
        ...state,
        activeEntryId: action.payload,
      };
    }

    case SET_RAIL_HOVERED: {
      return {
        ...state,
        dynamicRail: {
          ...state.dynamicRail,
          hovered: true,
        },
      };
    }

    case SET_RAIL_NOT_HOVERED: {
      return {
        ...state,
        dynamicRail: {
          ...state.dynamicRail,
          hovered: false,
        },
      };
    }

    case SET_RAIL_LINK_HOVERED: {
      return {
        ...state,
        dynamicRail: {
          ...state.dynamicRail,
          hoveredLink: true,
        },
      };
    }

    case SET_RAIL_LINK_NOT_HOVERED: {
      return {
        ...state,
        dynamicRail: {
          ...state.dynamicRail,
          hoveredLink: false,
        },
      };
    }

    default:
      return state;
  }
};

export default entriesReducer;
