import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import reject from 'lodash/reject';
import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import last from 'lodash/last';
import map from 'lodash/map';
import join from 'lodash/join';
import reactCookie from 'react-cookie';
import { X_ABTEST_INFO, X_DEVICE_TYPE } from 'universal/custom-http-headers';
import httpWithLogging from 'universal/http-client';
import logger from 'server/utilities/logger';
import { stringify } from 'query-string';
import { buildCookieString } from 'universal/utilities-cookies';
import { getOptyAssignmentValue } from 'client-utils/utilities-abtest';
import {
  MY_FAVORITES,
  FAVORITES_NOT_AVAILABLE_MESSAGE,
  IN_STORE,
  PRODUCT_LIST_FILTER_OPTIONS_EMPTY_MESSAGE,
  INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE,
} from 'plp/constants';
import {
  SET_CANONICAL_URL,
  getABTestAssignments,
  getABTests,
  SET_TEST_FROM_COOKIE,
} from 'shared/actions/actions-page';
import { types as NavTypes } from 'storefront/components/LeftNavigation/actions-leftnavigation';
import { setRecentlySearchedAnalytics } from 'client/storefront/components/Header/Search/search-actions';
import { showSpinner, hideSpinner } from 'shared/components/Spinner/spinner-actions';
import { openModal } from 'shared/components/Modal/actions';
import { LOADING_PRODUCT } from 'pdp/pages/ProductPage/actions';
import { isEmptyInStoreFilterValue } from 'plpFilters/components/InStoreFilter/inStoreFilter';
import { getSelectedGender } from 'client-utils/utilities-gender';

export const LOADING_PRODUCT_LIST = 'LOADING_PRODUCT_LIST';
export const LOADING_PRODUCT_LIST_METADATA = 'LOADING_PRODUCT_LIST_METADATA';
export const RESOLVED_PRODUCT_LIST = 'RESOLVED_PRODUCT_LIST';
export const REJECTED_PRODUCT_LIST = 'REJECTED_PRODUCT_LIST';
export const RESOLVED_PRODUCT_LIST_METADATA = 'RESOLVED_PRODUCT_LIST_METADATA';
export const RESOLVED_FAVORITE = 'RESOLVED_FAVORITE';
export const RESOLVED_FAVORITE_FLATTENED = 'RESOLVED_FAVORITE_FLATTENED';

export const RESOLVED_CATEGORY_NO_RESULTS_CONTENT = 'RESOLVED_CATEGORY_NO_RESULTS_CONTENT';
export const REJECTED_CATEGORY_NO_RESULTS_CONTENT = 'REJECTED_CATEGORY_NO_RESULTS_CONTENT';
export const SET_PRODUCT_LIST_DATA_TO_UTAG = 'SET_PRODUCT_LIST_DATA_TO_UTAG';
export const ADD_RECENT_SIZES_TO_UTAG_DATA = 'ADD_RECENT_SIZES_TO_UTAG_DATA';
export const SET_FAVORITE_UTAG_DATA = 'SET_FAVORITE_UTAG_DATA';
export const INVALID_CATEGORY = 'INVALID_CATEGORY';
export const VALID_CATEGORY = 'VALID_CATEGORY';

export const SOURCE_QUICK_LOOK = 'SOURCE_QUICK_LOOK';
export const RESET_QUICK_LOOK_PRODUCT = 'RESET_QUICK_LOOK_PRODUCT';
export const RESOLVED_QUICK_LOOK_PRODUCT = 'RESOLVED_QUICK_LOOK_PRODUCT';
export const NO_RESULTS_FOR_FILTER = 'NO_RESULTS_FOR_FILTER';

export const NO_RESULTS_FOR_FACET_FILTER = 'NO_RESULTS_FOR_FACET_FILTER';
export const INIT_SWATCH_VALUES = 'INIT_SWATCH_VALUES';
export const UPDATE_SWATCH_VALUE = 'UPDATE_SWATCH_VALUE';

export const UPDATE_CAT_ID = 'UPDATE_CAT_ID';

export const SET_SEO_FACETS_ORDER = 'SET_SEO_FACETS_ORDER';

export const types = {
  SET_CATEGORY: 'SET_CATEGORY',
  SET_SORT_BY: 'SET_SORT_BY',
  SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
};

export const breadcrumbsUtag = (breadcrumbs) => (isEmpty(breadcrumbs) ? null : {
  breadCrumb: map(breadcrumbs, 'name'),
  catId: map(breadcrumbs, 'id'),
});

export const formatAnalyticsPageName = (templateType, data) => {
  if (templateType === 'emag') {
    return 'Emag';
  }
  if (templateType === 'F') {
    return 'F0';
  }
  if (templateType === 'DesignerIndex') {
    data.push('Designers');
    return data.map((curr) => curr).join(':');
  }
  return data.map((curr) => curr.name).join(':');
};

export function addRecentSizesToUtagData(recentSizes) {
  return (dispatch) => {
    dispatch({ type: ADD_RECENT_SIZES_TO_UTAG_DATA, payload: { recentSizes } });
  };
}

export function getFetchSize(state) {
  const lazyLoadToggle = get(state, 'toggles.PLP_IMAGE_LAZY_LOAD', false);
  if (!lazyLoadToggle) {
    return get(state, 'lazyLoadDisabledConfig.pagination.fetchSize', 60);
  }
  if (get(state, 'session.deviceType.isMobile', false) && state.toggles.PLP_MOBILE_FETCH_SIZE && !get(state, 'session.deviceType.isTablet', false)) {
    return get(state, 'mobileConfig.pagination.fetchSize', null);
  }
  const abTestPlpFetchSizeExperience = get(state, 'abTestsOpt.v60_120.variation', 'view_120');
  if (abTestPlpFetchSizeExperience === 'view_60') {
    return get(state, 'mobileConfig.pagination.fetchSize', null);
  }
  return null;
}

export function getPlpCategory(requestOptions = {}) {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(getProductList(requestOptions))
      .then(resolve)
      .catch(() => {
        dispatch({ type: REJECTED_PRODUCT_LIST });
        dispatch({ type: SET_CANONICAL_URL, canonicalUrl: '' });
        reject();
      });
  });
}

export function getPlpCategoryWithSpecificRecs(requestOptions = {}, MBOX_IDS) {
  return (dispatch) => dispatch(getABTestAssignments(MBOX_IDS.join(','), true))
    .then(() => {
      dispatch(getPlpCategory(requestOptions));
    })
    .catch(() => {
      dispatch(getPlpCategory(requestOptions));
    });
}

export function getPlpCategoryWithTests(requestOptions = {}, TEST_IDS) {
  return (dispatch, getState) => {
    const testIdsForService = [];
    const state = getState();
    for (let i = 0; i < TEST_IDS.length; i++) {
      if (state.cookies[TEST_IDS[i].abTestCookieName]) {
        dispatch({
          type: SET_TEST_FROM_COOKIE,
          payload: {
            ...TEST_IDS[i],
            abTestCookieValue: state.cookies[TEST_IDS[i].abTestCookieName],
            abTestExperience: '',
          },
        });
      } else {
        testIdsForService.push(TEST_IDS[i]);
      }
    }
    if (testIdsForService.length > 0) {
      return dispatch(getABTests(testIdsForService))
        .then(() => {
          dispatch(getPlpCategory(requestOptions));
        })
        .catch(() => {
          dispatch(getPlpCategory(requestOptions));
        });
    }
    return dispatch(getPlpCategory(requestOptions));
  };
}

export function getPlpOptions(requestOptions = {}) {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(getProductList(requestOptions))
      .then(resolve)
      .catch((err) => {
        if (err.type === NO_RESULTS_FOR_FILTER) {
          dispatch(openModal({
            type: 'ModalWithoutHeader',
            message: err.message,
            className: 'modal-without-header',
          }));
        } else if (err.type === NO_RESULTS_FOR_FACET_FILTER) {
          dispatch(hideSpinner());
          dispatch(openModal({
            type: 'ModalWithoutHeader',
            message: err.message,
            className: 'modal-without-header',
          }));
        } else if (!err.isHandled) {
          dispatch(openModal({ type: 'ProductListErrorModal' }));
        }
        reject();
      });
  });
}

export function getUtagFiltersData(filterOptions) {
  const inStoreFilter = 'In Store';

  const renameKeyForUtagFilterAnalysis = (key) => {
    switch (key) {
      case 'level1':
        return 'Category';
      case 'level2':
        return 'Type';
      case 'level3':
        return 'Subcategory';
      case 'level4':
        return 'Subtype';
      case 'level5':
        return 'Product Type';
      case 'In Store':
        return 'Get It Fast';
      default:
        return key;
    }
  };

  const filterOmniSelection = (omniArray) => {
    const sddSelection = 'delivery today';
    const nddSelection = 'delivery tomorrow';
    const cspSelection = 'curbside today';

    if (!isEmpty(omniArray[2]) && !isEmpty(omniArray[3])) {
      return [sddSelection, nddSelection];
    } if (!isEmpty(omniArray[2])) {
      return [sddSelection];
    } if (!isEmpty(omniArray[3])) {
      return [nddSelection];
    } if (!isEmpty(omniArray[1])) {
      return [cspSelection];
    }
    return [];
  };

  const filterOptionsWithoutInstoreZipCode = {
    ...filterOptions,
    [inStoreFilter]: filterOmniSelection(get(filterOptions, inStoreFilter, [])),
  };

  return {
    filterSelection: flatten(
      reject(filterOptionsWithoutInstoreZipCode, isEmpty)
    ),
    filterType: flatMap(
      Object.keys(filterOptionsWithoutInstoreZipCode),
      (key) => Array(
        filterOptionsWithoutInstoreZipCode[key].length
      ).fill(renameKeyForUtagFilterAnalysis(key))
    ),
  };
}

export function getProductList(requestOptions = {}) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(showSpinner());
    dispatch({ type: LOADING_PRODUCT_LIST });

    const currentCategory = get(state, 'productListPage.currentCategory');
    const currentPage = get(state, 'productListPage.products.currentPage', 1);
    const selectedZipCodeCookie = reactCookie.load('dt_selected_zipcode') && reactCookie.load('dt_selected_zipcode').slice(1, -1);

    const {
      page = currentPage,
      categoryId = get(currentCategory, 'id', '').split('_')[0],
      parentCategoryId = '',
      siloCategoryId,
      sortBy = '',
      filterOptions,
      priorityProdId,
    } = requestOptions;
    const { user, session } = state;
    const context = state.api && state.api.requestContext;
    let deviceType;
    if (get(session, 'deviceType.isMobile', false) && !get(session, 'deviceType.isTablet', false)) {
      deviceType = 'M';
    } else if (get(session, 'deviceType.isTablet', false)) {
      deviceType = 'T';
    } else {
      deviceType = 'D';
    }
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        TLTSID: get(session, 'TLTSID', ''),
        ENABLE_STUB_SERVICE: get(state, 'cookies.enableStubService'),
        ENABLE_PROMO_PREVIEW: get(state, 'cookies.enablePromoPreview'),
        WID: get(state, 'utag.userSession.web_id', ''),
        ucid: get(user, 'ucid', ''),
      }),
      [X_ABTEST_INFO]: join(get(state, 'abTests.responses', []).map((i) => `${i.mboxId}:${get(i, 'assignment.value.experienceId', '')}`), '~'),
      [X_DEVICE_TYPE]: deviceType,
      ucid: get(user, 'ucid', ''),
      wid: get(state, 'utag.userSession.web_id', ''),
      pageurl: get(session, 'url', ''),
      br_uid: get(context, '_br_uid_2', ''),
    };

    headers.zip = selectedZipCodeCookie !== undefined
      ? selectedZipCodeCookie
      : get(context, 'akamaiEdgescape.zip', '');

    if (get(session, '_optuid')) headers.optuid = get(session, '_optuid');
    const requestApi = httpWithLogging(state, get(state, 'apiTimeouts.PRODUCT_LIST_API_TIMEOUT', 8000));

    function getProductListApiURL(
      categoryId,
      parentCategoryId,
      siloCategoryId,
      page,
      sortBy,
      filterOptions,
      personalizedPromos,
      priorityProdId
    ) {
      const sortByParams = sortBy ? `&sortBy=${sortBy}` : '';
      const personalizedPromosParams = personalizedPromos && personalizedPromos.length ? `&personalizedPromos=${personalizedPromos}` : '';
      const priorityProdIdParam = priorityProdId && priorityProdId.length ? `&priorityProdId=${priorityProdId}` : '';
      const tabsInSizeFacet = get(state, 'facetedLeftNav.tabsInSizeFacet', false);

      const defaultParams = {
        categoryId,
        parentCategoryId,
        siloCategoryId,
        page,
        filterOptions,
      };

      const queryBuilder = (fetchSize, tabsInSizeFacet) => {
        let params = {};

        if (fetchSize !== null || false) {
          params = { ...params, fetchSize };
        }

        if (tabsInSizeFacet) {
          params = { ...params, tabsInSizeFacet };
        }

        return params;
      };

      const generateURL = () => {
        const fetchSize = getFetchSize(state);
        const additionalParam = queryBuilder(
          fetchSize, tabsInSizeFacet
        );
        const VISUAL_NAV_TOGGLE = get(state, 'toggles.VISUAL_NAVIGATION', false);
        const VISUAL_NAV_ABTEST_TOGGLE = getOptyAssignmentValue(get(state, 'abTestsOpt'), 'visnav', '');
        const CRP_VISUAL_NAV_SPA = get(state, 'toggles.CRP_VISUAL_NAV_SPA', false);
        const navPath = get(state, 'routing.locationBeforeTransitions.query.navpath', '');
        let navPathURL = '';
        if (CRP_VISUAL_NAV_SPA) {
          const pathname = get(state, 'routing.locationBeforeTransitions.pathname', '');
          const categoryPathArray = pathname.split('-');
          const catId = last(categoryPathArray);
          navPathURL = `/dt/api/crp/productListing?catId=${catId}&navpath=${navPath}&spaAjax=true&force-opty=visnav:${VISUAL_NAV_ABTEST_TOGGLE}&${stringify({ ...defaultParams, ...additionalParam })}${sortByParams}${personalizedPromosParams}${priorityProdIdParam}`;
        } else {
          const resolvedVisualNavTest = VISUAL_NAV_TOGGLE ? 'b' : VISUAL_NAV_ABTEST_TOGGLE;
          const abtest = (VISUAL_NAV_TOGGLE || VISUAL_NAV_ABTEST_TOGGLE) ? `&navPath=${navPath}&abTestVisualNav=${resolvedVisualNavTest}` : '';
          navPathURL = `${NMConfig.API_PRODUCT_LIST}?${stringify({ ...defaultParams, ...additionalParam })}${sortByParams}${personalizedPromosParams}${priorityProdIdParam}${abtest}`;
        }
        return navPathURL;
      };
      return generateURL();
    }

    function shouldShowNoFavoriteMessage(products) {
      return state.productListPage.selectedSortBy !== MY_FAVORITES && sortBy === MY_FAVORITES
        && (isEmpty(page) || page === '1')
        && (products.length === 0 || ((state.page.pageId === 'PAGE_ID_PLP')
          ? !products[0].fav
          : !products[0].isFavorite));
    }

    function isSortOptionChanged(sortBy) {
      const previousSortOption = get(state, 'productListPage.products.selectedSortOption');
      return !isEmpty(sortBy)
        && !isEmpty(previousSortOption)
        && previousSortOption !== sortBy;
    }

    function isFilterOptionsChanged(selectedFilters) {
      const previousFilterOptions = get(state, 'productListPage.selectedFilterOptions');
      return !isEmpty(selectedFilters)
        && !isEmpty(previousFilterOptions)
        && !isEqual(previousFilterOptions, selectedFilters);
    }

    function shouldShowInStoreFilterMessage(products) {
      return (isEmpty(products)) && !isEmpty(filterOptions) && includes(filterOptions, IN_STORE)
        && !isEmptyInStoreFilterValue(JSON.parse(filterOptions)[IN_STORE]);
    }

    function shouldShowFilterMessage(products) {
      return isEmpty(products) && !isEmpty(filterOptions);
    }

    function getPlpType(displayAsGroupsFlag) {
      return displayAsGroupsFlag ? 'group' : 'non-group';
    }

    return new Promise((resolve, reject) => {
      const selectedFilters = isEmpty(filterOptions) ? {} : JSON.parse(filterOptions);
      const { session } = getState();
      const { promos } = JSON.parse(get(session, 'dt_personalize_data', '{}'));
      const crpUrl = getProductListApiURL(
        categoryId, parentCategoryId, siloCategoryId, page, sortBy, filterOptions, promos,
        priorityProdId
      );
      requestApi.get(crpUrl, { headers })
        .then((successResponse) => {
          const CRP_VISUAL_NAV_SPA = get(state, 'toggles.CRP_VISUAL_NAV_SPA', false);
          if (
            ((
              CRP_VISUAL_NAV_SPA
              && get(
                successResponse.data,
                'categoryTemplate.templateType',
                ''
              ).match(/P/, 'i') === null
            )
              || (
                !CRP_VISUAL_NAV_SPA
                && successResponse.data.templateType.match(/P/, 'i') === null
              )) && location) {
            location.reload();
            return resolve();
          }

          const productListDynamicContent = get(successResponse.data, 'categoryTemplate.templateAttributes.dynamicContent', {});
          const navigation = get(successResponse.data, 'categoryTemplate.templateAttributes.navigation', {});
          const { data: { products } } = successResponse;
          const rejectionFlag = CRP_VISUAL_NAV_SPA
            ? isEmpty(productListDynamicContent)
              || isEmpty(productListDynamicContent.productListing)
            : shouldShowFilterMessage(products);
          const { ecmContent } = CRP_VISUAL_NAV_SPA
            ? get(successResponse.data, 'categoryTemplate.templateAttributes', [])
            : {};
          const inStoreRejectionFlag = CRP_VISUAL_NAV_SPA
            ? shouldShowInStoreFilterMessage(productListDynamicContent.productListing)
            : shouldShowInStoreFilterMessage(successResponse.data.products);
          if (inStoreRejectionFlag) {
            return Promise.reject({
              type: NO_RESULTS_FOR_FACET_FILTER,
              message: INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE,
            });
          }

          if (rejectionFlag) {
            return Promise.reject({
              type: NO_RESULTS_FOR_FILTER,
              message: PRODUCT_LIST_FILTER_OPTIONS_EMPTY_MESSAGE,
            });
          }
          const productsToDispatch = CRP_VISUAL_NAV_SPA
            ? productListDynamicContent.productListing
            : successResponse.data;

          const driveToGroups = CRP_VISUAL_NAV_SPA
            ? get(successResponse.data, 'categoryTemplate.driveTosubCategory', false)
            : false;
          const boutiqueChild = CRP_VISUAL_NAV_SPA
            ? successResponse.data.categoryTemplate.boutiqueChild
            : false;

          dispatch({
            type: RESOLVED_PRODUCT_LIST,
            payload: {
              ...productsToDispatch,
              page,
              driveToGroups,
              boutiqueChild,
            },
            ftrTgls: {
              dprToggle: get(state, 'toggles.CLOUDINARY_DPR', false),
            },
          });
          const navivationFalg = CRP_VISUAL_NAV_SPA
            ? !isEmpty(navigation) && !isEmpty(navigation.hybridLeftNavigation)
            : successResponse.data.hybridLeftNavigation;
          if (getState().device && getState().device.isMobilePhone) {
            const navigationData = CRP_VISUAL_NAV_SPA
              ? navigation.hybridLeftNavigation
              : successResponse.data.hybridLeftNavigation;
            if (navivationFalg) {
              dispatch({
                type: NavTypes.RESOLVED_LEFTNAV_CONTENT,
                payload: navigationData,
              });
            }
          }

          const breadCrumbsFlag = CRP_VISUAL_NAV_SPA
            ? !isEmpty(navigation) && !isEmpty(navigation.breadcrumbs)
            : successResponse.data.breadcrumbs;
          const breadCrumbData = CRP_VISUAL_NAV_SPA
            ? navigation.breadcrumbs
            : get(successResponse, 'data.breadcrumbs.breadcrumbs', []);
          if (breadCrumbsFlag) {
            const breadcrumbPath = get(
              state,
              'routing.locationBeforeTransitions.query.navpath',
              ''
            );
            dispatch({
              type: 'RESOLVED_BREADCRUMB_CONTENT',
              payload: { breadcrumbs: breadCrumbData, breadcrumbPath },
            });
            dispatch({
              type: 'PLP_SPA_PATH_UPDATE',
              breadcrumbPath,
            });
            const templateType = CRP_VISUAL_NAV_SPA
              ? productListDynamicContent.productListing.templateType
              : successResponse.data.templateType;
            let pageName = formatAnalyticsPageName(
              templateType,
              breadCrumbData
            );
            const breadcrumbsUtagData = breadcrumbsUtag(breadCrumbData);
            let { breadCrumb } = breadcrumbsUtagData;
            const { catId } = breadcrumbsUtagData;
            if (reactCookie.load('dt_gender')) {
              const selectedGender = getSelectedGender() === 'W' ? 'Women' : 'Men';
              breadCrumb = [selectedGender, ...breadCrumb];
              pageName = `${selectedGender}:${pageName}`;
            }
            if (window.utag_data_dt) {
              window.utag_data_dt.cat_id = catId;
              window.utag_data_dt.bread_crumb = breadCrumb;
              window.utag_data_dt.page_name = pageName;
            }
          }

          dispatch(hideSpinner());
          dispatch({
            type: types.SET_FILTER_OPTIONS,
            selectedFilterOptions: isEmpty(filterOptions)
              ? 'No filter options selected' : JSON.parse(filterOptions),
          });
          let canonicalUrl = '';
          let sortByOption = '';
          if (CRP_VISUAL_NAV_SPA) {
            if (productListDynamicContent.productListing.seo.canonicalUrl) {
              canonicalUrl = productListDynamicContent.productListing.seo.canonicalUrl;
            }
            sortByOption = productListDynamicContent.productListing.selectedSortOption;
          } else {
            if (successResponse.data.seo.canonicalUrl) {
              canonicalUrl = successResponse.data.seo.canonicalUrl;
            }
            sortByOption = successResponse.data.selectedSortOption;
          }
          const seoFirstParam = state?.facetedLeftNav?.firstParam;
          const seoSecondParam = state?.facetedLeftNav?.secondParam;
          const blockCanonical = state?.facetedLeftNav?.blockCanonical;
          if (isEmpty(seoFirstParam) && isEmpty(seoSecondParam) && !blockCanonical) {
            dispatch({ type: SET_CANONICAL_URL, canonicalUrl });
          }
          dispatch({ type: types.SET_SORT_BY, sortBy: sortByOption });
          dispatch({ type: VALID_CATEGORY });

          dispatch({
            type: SET_PRODUCT_LIST_DATA_TO_UTAG,
            payload: {
              total: CRP_VISUAL_NAV_SPA
                ? productListDynamicContent.productListing.total
                : successResponse.data.total,
              templateType: CRP_VISUAL_NAV_SPA
                ? productListDynamicContent.productListing.templateType
                : successResponse.data.templateType,
              category: currentCategory,
              cmosCatalog: CRP_VISUAL_NAV_SPA
                ? map(productListDynamicContent.productListing.products, 'cmosCatalog')
                : map(successResponse.data.products, 'cmosCatalog'),
              cmosItem: CRP_VISUAL_NAV_SPA
                ? map(productListDynamicContent.productListing.products, 'cmosItem')
                : map(successResponse.data.products, 'cmosItem'),
              sortBy: CRP_VISUAL_NAV_SPA
                ? productListDynamicContent.productListing.selectedSortOption
                : successResponse.data.selectedSortOption,
              page,
              facetOptions: getUtagFiltersData(selectedFilters),
              previousViewFacetSubmit: isFilterOptionsChanged(selectedFilters),
              previousViewSortSubmit: isSortOptionChanged(sortBy),
              facetFlag: true,
              facetEligible: true,
              plpType: getPlpType(successResponse.data.displayAsGroups),
              pcsEnabled: CRP_VISUAL_NAV_SPA
                ? !get(successResponse.data, 'categoryTemplate.templateAttributes.dynamicContent.categoryFlags.excludeFromPCS', false)
                : '',
            },
          });

          const shouldShowFavMessage = CRP_VISUAL_NAV_SPA
            ? shouldShowNoFavoriteMessage(productListDynamicContent.productListing.products)
            : shouldShowNoFavoriteMessage(successResponse.data.products);
          if (shouldShowFavMessage) {
            dispatch(openModal({
              type: 'ModalWithoutHeader',
              message: FAVORITES_NOT_AVAILABLE_MESSAGE,
            }));
          }
          if (CRP_VISUAL_NAV_SPA) {
            const { templateType } = get(successResponse.data, 'categoryTemplate', '');
            let pageTemplate = templateType;
            let pageDefintionId = templateType;
            let pageType = !get(successResponse.data, 'categoryTemplate.boutiqueChild', false) ? 'category' : 'boutique';
            pageType = templateType === 'Silo' || templateType === 'DesignerIndex' ? 'Silo' : pageType;

            switch (templateType) {
              case 'DesignerIndex':
                pageTemplate = 'Silo';
                break;
              case 'F':
                pageDefintionId = 'F0';
                pageTemplate = 'F0';
                break;
              case 'Entry':
                pageTemplate = 'entry';
                break;
              default:
                break;
            }
            if (pageTemplate !== 'F0' || pageTemplate !== 'emag') {
              const pageName = breadCrumbData || [];
              const analyticsPageNameValue = formatAnalyticsPageName(templateType, pageName);
              dispatch({
                type: 'SET_CATEGORY_TEMPLATES_DATA_TO_UTAG',
                payload: {
                  page_definition_id: pageDefintionId,
                  page_name: analyticsPageNameValue,
                  page_template: pageTemplate,
                  page_type: pageType,
                },
              });
            }
          }
          const contentId = 'promotiles';
          if (!isEmpty(ecmContent)) {
            dispatch({
              type: `RESOLVED_ECM${contentId}`,
              payload: ecmContent,
              contentId,
              headers,
            });
          } else if (!isEmpty(getState().ecm)) {
            const tiles = Object.keys(getState().ecm.ecmContent).filter((key) => { return key.indexOf('PromoTile') !== -1; });
            tiles.forEach((tile) => {
              dispatch({
                type: `RESOLVED_ECM${tile}`,
                payload: {},
                contentId: tile,
                headers,
              });
            });
          }
          if (isEmpty(selectedFilters)) {
            dispatch({ type: 'CLEAR_ALL_FILTER_OPTION' });
          }

          const seoFacets = get(successResponse, 'data.seoFacets', []);
          if (!isEmpty(seoFacets)) {
            const lowerCaseFacets = seoFacets.map((facet) => facet.toLowerCase());
            dispatch({
              type: SET_SEO_FACETS_ORDER,
              payload: lowerCaseFacets,
            });
          }
          return resolve();
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            dispatch({ type: INVALID_CATEGORY });
          }
          dispatch(hideSpinner());
          if (err.response && err.response.status === 424) {
            logger.error(`API timed out while waiting to search for products for categoryId: ${categoryId}, HTTP Status: ${err.response.status}`);
          }
          reject(err);
        });
    });
  };
}

export function getProductListMetaData(productIds, customHeaders) {
  return (dispatch, getState) => {
    const state = getState();
    const { session } = state;
    const requestApi = httpWithLogging(
      state,
      get(state, 'apiTimeouts.PRODUCT_LIST_METADATA_API_TIMEOUT', 20000)
    );
    const combinedHeaders = {
      ...customHeaders,
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    dispatch({ type: LOADING_PRODUCT_LIST_METADATA });
    return new Promise((resolve) => {
      requestApi
        .post(`${NMConfig.API_PRODUCT_LIST_METADATA}`, productIds, { headers: combinedHeaders })
        .then((successResponse) => {
          dispatch({
            type: RESOLVED_PRODUCT_LIST_METADATA,
            payload: successResponse.data,
          });
          resolve();
        })
        .catch(() => { });
    });
  };
}

export function getCategoryNoResultsContent() {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);

    return requestApi.get(NMConfig.API_REFRESHABLE_CONTENT, {
      params: { refreshablePath: '/category/search/endeca/r_no_results.html' },
    }).then((response) => {
      dispatch({ type: RESOLVED_CATEGORY_NO_RESULTS_CONTENT, payload: response.data });
    }).catch(() => { dispatch({ type: REJECTED_CATEGORY_NO_RESULTS_CONTENT }); });
  };
}

export function addFavorite(
  productId, cmosCatalogId, cmosItem, isFavoritesPage, isDisplayAsGroups
) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    const query = stringify({
      productId, cmosCatalogId, cmosItem, isFavoritesPage,
    });
    return requestApi.post(`${NMConfig.API_ADD_FAVORITE}?${query}`)
      .then((successResponse) => {
        if (state.page.pageId === 'PAGE_ID_PLP') {
          dispatch({
            type: RESOLVED_FAVORITE_FLATTENED,
            data: successResponse.data,
          });
        } else {
          dispatch({
            type: RESOLVED_FAVORITE,
            data: successResponse.data,
          });
        }
        setRecentlySearchedAnalytics(false);
        dispatch({
          type: SET_FAVORITE_UTAG_DATA,
          payload: {
            cmosCatalogId,
            utagData: successResponse.data.utagData,
            displayAsGroups: isDisplayAsGroups,
          },
        });
        reactCookie.save('SPCR', 0, { path: '/' });
      })
      .catch(() => { });
  };
}
export function removeFavorite(
  productId, cmosCatalogId, cmosItem, isFavoritesPage, isDisplayAsGroups
) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    const query = stringify({
      productId, cmosCatalogId, cmosItem, isFavoritesPage,
    });
    return requestApi.post(`${NMConfig.API_REMOVE_FAVORITE}?${query}`)
      .then((successResponse) => {
        if (state.page.pageId === 'PAGE_ID_PLP') {
          dispatch({
            type: RESOLVED_FAVORITE_FLATTENED,
            data: successResponse.data,
          });
        } else {
          dispatch({
            type: RESOLVED_FAVORITE,
            data: successResponse.data,
          });
        }
        dispatch({
          type: SET_FAVORITE_UTAG_DATA,
          payload: {
            cmosCatalogId,
            utagData: successResponse.data.utagData,
            displayAsGroups: isDisplayAsGroups,
          },
        });
        reactCookie.save('SPCR', 0, { path: '/' });
      })
      .catch(() => { });
  };
}

export function showQLModalWindow(productId, productUrl, navPath, isDisplayAsGroups) {
  const data = {
    productId,
    productUrl,
    navPath,
  };

  return (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT });
    const state = getState();
    const { session } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const { promos } = JSON.parse(get(session, 'dt_personalize_data', '{}'));

    const requestURI = promos && promos.length
      ? `${NMConfig.API_PRODUCT_DETAIL_PDP}/${productId}?personalizedPromos=${promos}`
      : `${NMConfig.API_PRODUCT_DETAIL_PDP}/${productId}`;
    const requestApi = httpWithLogging(state);
    return requestApi.get(requestURI, { headers })
      .then((successResponse) => {
        dispatch({
          type: RESOLVED_QUICK_LOOK_PRODUCT,
          payload: {
            ...successResponse.data,
            displayAsGroups: isDisplayAsGroups,
          },
          ftrTgls: {
            dprToggle: get(state, 'toggles.CLOUDINARY_DPR', false),
          },
        });
        const product = successResponse.data || {};
        if (product.isGroup) {
          dispatch({ type: 'RESOLVED_GROUP', payload: successResponse.data });
        }
        dispatch(openModal({
          type: 'QLProductSummary',
          props: data,
          className: 'ql-product-summary',
        }));
      })
      .catch(() => {
        dispatch(openModal({ type: 'ProductListErrorModal' }));
      });
  };
}

export function sourceQuickLook(isEnabled) {
  if (isEnabled) setRecentlySearchedAnalytics(!isEnabled);
  return (dispatch) => dispatch({ type: SOURCE_QUICK_LOOK, payload: isEnabled });
}

export function resetQLProduct() {
  return (dispatch) => dispatch({ type: RESET_QUICK_LOOK_PRODUCT });
}

export function updateSwatchesValue(swatchIndex, productId) {
  return (dispatch) => dispatch({
    type: UPDATE_SWATCH_VALUE,
    payload: {
      swatchIndex,
      productId,
    },
  });
}

export function initializeSwatchValues() {
  return (dispatch) => dispatch({ type: INIT_SWATCH_VALUES });
}

export function updateCatId(catId) {
  return { type: UPDATE_CAT_ID, payload: { catId } };
}
