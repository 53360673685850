import curry from 'lodash/curry';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

export const sanitizeInput = (input) => (input.trim().toLowerCase().replace(/\s+/g, ' '));

export const indexOfSearchTerm = (searchTerm, name) => (
  name.toLowerCase().indexOf(searchTerm)
);

const findDesigners = (templates) => {
  return get(templates, 'templateDetails.templateAttributes.dynamicContent.designers.designersByIndex', []);
};


const buildMatch = (silo, matchIndex) => (
  { silo: { ...silo, categories: [] }, matchIndex, sortName: silo.name.toLowerCase() }
);

const findMatches = curry((searchTerm, designerSilo) => (
  map(designerSilo, (letterCategory) => (
    reduce(letterCategory.categories, (results, cat) => {
      const matchIndex = indexOfSearchTerm(searchTerm, cat.name);
      return matchIndex >= 0 ? [...results, buildMatch(cat, matchIndex)] : results;
    }, [])
  ))));

const sortMatches = (matches) => (
  sortBy(matches, ['matchIndex', 'sortName']).splice(0, 10)
);

const pluckSilo = (matches) => (
  map(matches, 'silo')
);

const designerIndexSearchDesigners = (searchInput, templates) => {
  const searchTerm = searchInput ? sanitizeInput(searchInput) : '';
  return flow(
    findDesigners,
    findMatches(searchTerm),
    flatten,
    sortMatches,
    pluckSilo
  )(templates);
};

export default designerIndexSearchDesigners;
