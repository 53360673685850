import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getColorOptions } from 'pdp/pages/ProductPage/selectors/getOptions';
import getSelectedColorIndex from 'pdp/pages/ProductPage/selectors/getSelectedColorIndex';
import getProduct from 'pdp/pages/ProductPage/selectors/getProduct';
import get from 'lodash/get';

class TrueFit extends Component {
  getColorName() {
    const colorOptions = getColorOptions(this.props.product);
    const selectedColorIndex = getSelectedColorIndex(this.props.product);
    let colorName;
    if (colorOptions.values && colorOptions.values.length > 0) {
      colorName = colorOptions.values[selectedColorIndex].name;
    }
    return colorName;
  }

  isRegisteredUser() {
    const { loggedInStatus, customerRegistered } = this.props;
    if (loggedInStatus === 'true' || customerRegistered === 'true') {
      return true;
    }
    return false;
  }

  render() {
    const {
      product,
      ucid,
      wid = '',
      trueFitIdToggle = false,
      showTrueFitGSAWidget,
      sourceIsQL = false,
    } = this.props;
    const colorName = this.getColorName();
    const isRegisteredUser = this.isRegisteredUser();
    let truefitClass = 'tfc-fitrec-product true-fit';
    if (showTrueFitGSAWidget) {
      truefitClass += ' gsa-enabled';
    } else {
      truefitClass += ' gsa-disabled';
    }
    if (sourceIsQL) {
      truefitClass += ' multi-size-true-fit';
    }
    const dataContextParam = !sourceIsQL ? {} : { 'data-context': 'quickview' };

    if (sourceIsQL && typeof window.tfcapi !== 'undefined') {
      window.tfcapi('calculate');
    }

    return (
      <Fragment>
        <div
          className={`${truefitClass}`}
          id={product.id}
          data-userid={trueFitIdToggle ? wid : ucid}
          data-registered={isRegisteredUser}
          data-colorid={colorName}
          {...dataContextParam}
          onClick={this.props.onClick}
        />
        { !sourceIsQL && <script src={`/assets/scripts/Truefit-${NMConfig.TRUEFIT_ENV}.js`} /> }
        { !sourceIsQL && (
          <script
            dangerouslySetInnerHTML={{
              __html: "tfcapi('event','tfc-fitrec-product','success',function tfSuccess() {nm.cookie.set('tf_ck', 'true', 0, '/');})",
            }}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  const loggedInStatus = get(state, 'utag.userSession.logged_in_status', '');
  const customerRegistered = get(state, 'utag.userSession.customer_registered', '');
  return {
    product: getProduct(state, props.productId),
    ucid: state.user.ucid,
    wid: state.session.WID,
    trueFitIdToggle: state.toggles.TRUEFIT_ID,
    loggedInStatus,
    customerRegistered,
    showTrueFitGSAWidget: get(state, 'toggles.TRUEFIT_GSA_OPTIMISELY', false),
  };
};

export default connect(mapStateToProps)(TrueFit);
