import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  toggle: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  toggle: true,
  onClick: () => {},
};

const TeaserStarsWrapper = ({
  toggle = true,
  onClick,
}) => {
  const turnToV4Selector = { className: 'TurnToReviewsTeaser' };
  const turnToV5Selector = { id: 'tt-teaser', className: 'TTteaser' };
  const props = toggle ? turnToV5Selector : turnToV4Selector;
  return <div role="presentation" {...props} onClick={onClick} />;
};

TeaserStarsWrapper.propTypes = propTypes;
TeaserStarsWrapper.defaultProps = defaultProps;

export default TeaserStarsWrapper;
