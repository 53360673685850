import { SEARCH_URL } from 'shared/constants';
import {
  RESOLVED_TH_HTML, REJECTED_TH_HTML, REJECTED_TOGGLE, REJECTED_TH_ERROR,
} from '../actions/actions-th';

const initialState = {
  thHtmlData: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESOLVED_TH_HTML: {
      return {
        ...state,
        thHtmlData: action.payload.data,
        thHeader: action.payload.header,
      };
    }
    case REJECTED_TOGGLE:
    case REJECTED_TH_ERROR:
    {
      return {
        ...state,
        redirectURL: `${NMConfig.ERROR_PAGE_URL}`,
      };
    }
    case REJECTED_TH_HTML: {
      const searchItem = action.payload.replace(/-/g, '+');
      return {
        ...state,
        redirectURL: `${SEARCH_URL}&l=${searchItem}&q=${searchItem}`,
      };
    }
    default:
      return state;
  }
};
