import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Tabs.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
  label: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
};

const defaultProps = {
  className: '',
  uiComponent: 'Tab',
  testID: undefined,
};

class Tab extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { label, onClick } = this.props;
    onClick(label);
  }

  render() {
    const {
      activeTab,
      className,
      children,
      label,
      uiComponent,
      testID,
    } = this.props;

    const isActive = activeTab === label;

    const classList = cx({
      Tabs__Tab: true,
      'Tabs__Tab--active': isActive,
    }, [className].join(' ').trim().replace(/\s+/g, ' '));

    return (
      <button
        className={classList}
        onClick={this.handleClick}
        label={label}
        role="tab"
        tabIndex={isActive ? 0 : -1}
        aria-selected={isActive}
        data-ui-component={uiComponent}
        data-tid={testID}
        type="button"
      >
        {children}
      </button>
    );
  }
}

Tab.displayName = 'Tab';
Tab.propTypes = propTypes;
Tab.defaultProps = defaultProps;

export default Tab;
