import {
  RESOLVED_PERSONALIZED_PROMOTIONS,
  NO_PERSONALIZED_PROMOTIONS_AVAILABLE,
  REJECTED_PERSONALIZED_PROMOTIONS,
} from 'client/profile/actions/actions';

const initialState = {
  event_name: 'pageLoad',
  page_definition_id: 'account',
  page_name: 'My Account:Special Offers',
  page_type: 'Account',
  page_template: 'Profile',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESOLVED_PERSONALIZED_PROMOTIONS:
    case NO_PERSONALIZED_PROMOTIONS_AVAILABLE:
    case REJECTED_PERSONALIZED_PROMOTIONS:
      return {
        page_definition_id: 'account',
        page_name: 'My Account:Special Offers',
        page_type: 'Account',
        page_template: 'Profile',
      };
    default:
      return state;
  }
};
