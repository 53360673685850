import get from 'lodash/get';

export default (options) => {
  const {
    useSkuAsset,
    cmosItem,
    previewShotType,
    skuColorKey,
    dynamicImageSkuColor,
    dropShip,
    vendorId,
    selectedMonoStyle,
    selectedThread,
    width = 400,
    height,
    text: [input1 = '', input2 = '', input3 = ''],
  } = options;

  const colorId = dynamicImageSkuColor ? `obj=base_color&src=NM${cmosItem}_${skuColorKey}&` : '';
  const monoStyleType = get(selectedMonoStyle, 'type');
  let sizeFormatQuality = `wid=${width}&resmode=sharp2&qlt=85,1`;
  if (height) {
    sizeFormatQuality = `${sizeFormatQuality}&height=${height}`;
  }

  let imageId = `NM${cmosItem}_${previewShotType}`;
  let specialInstructions;
  let monoStyle;
  let textColor;

  if (useSkuAsset) {
    imageId = `${imageId}_${skuColorKey}`;
  }

  if (dropShip) {
    monoStyle = `${vendorId}_${get(selectedMonoStyle, 'id')}`;
    textColor = `thread_${vendorId}_${get(selectedThread, 'id')}`;
  } else {
    monoStyle = get(selectedMonoStyle, 'assetName');
    textColor = `thread_${get(selectedThread, 'assetName')}`;
  }

  specialInstructions = `obj=imprint1&decal&src=is{NeimanMarcus/${monoStyle}`;

  switch (monoStyleType) {
    case 'NAME':
    case 'SINGLE_INIT':
      specialInstructions += `?$text1=${input1}`;
      break;
    case 'THREE_INIT':
    case 'THREE_INIT_FML':
      specialInstructions += `?$text1=${input1}&$text2=${input2}&$text3=${input3}`;
      break;
    default:
      // do nothing
  }

  specialInstructions += `&$swatch=${textColor}}&res=100&show&sharp=1`;

  return `${NMConfig.SCENE7_URL}is/image/NeimanMarcus?src=ir{NeimanMarcusRender/${imageId}?${colorId}${specialInstructions}}&${sizeFormatQuality}`;
};
