import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import classnames from 'classnames';
import { ENTER_KEYCODE } from 'client-utils/keyCodes';
import FilterButtons from '../FilterButtons/filterButtons';
import './inStoreFilter.scss';

export function inStoreFilterCount(value) {
  return isEmptyInStoreFilterValue(value) ? 0 : 1;
}

export function isEmptyInStoreFilterValue(value) {
  return isEmpty(value) || isEqual(value, ['', '']);
}

const onEventChange = (event) => event.stopPropagation();

export default class InStoreFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      freeFormAddress: props.selectedValues[0] || '',
      radius: props.selectedValues[1] || '100',
    };
    this.clearValues = this.clearValues.bind(this);
    this.onFreeFormAddressChanged = this.onFreeFormAddressChanged.bind(this);
    this.onRadiusChanged = this.onRadiusChanged.bind(this);
    this.getInStoreOptions = this.getInStoreOptions.bind(this);
    this.getSelectedOptions = this.getSelectedOptions.bind(this);
  }

  onFreeFormAddressChanged(value) {
    this.setState({ freeFormAddress: value });
  }

  onRadiusChanged(value) {
    this.setState({ radius: value });
  }

  getInStoreOptions() {
    if (isEmpty(this.props.selectedValues) && isEqual(this.getSelectedOptions(), ['', ''])) {
      return [];
    }
    return this.getSelectedOptions();
  }

  getSelectedOptions() {
    return [this.state.freeFormAddress, !isEmpty(this.state.freeFormAddress) ? this.state.radius : ''];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.selectedValues, nextProps.selectedValues)) {
      this.setState({ freeFormAddress: nextProps.selectedValues[0] || '', radius: nextProps.selectedValues[1] || '100' });
    }
  }

  clearValues() {
    this.setState({ freeFormAddress: '', radius: '100' });
    this.props.onClear();
  }

  render() {
    const { filterKey } = this.props;
    return (
      <div className={classnames('in-store-container', { 'instore-srp': true })}>
        <FilterButtons
          onSubmit={() => {
            this.props.onSubmit(this.getSelectedOptions());
          }}
          onClear={this.clearValues}
          filterKey={filterKey}
          selectedValues={['']}
        />
        <div className="freeFormAddress grid-70 tablet-grid-70 mobile-grid-55">
          <label htmlFor={`${filterKey}_freeFormAddress`}>
            City and State, or Zip
          </label>
          <input
            id={`${filterKey}_freeFormAddress`}
            type="text"
            className="freeFormText"
            value={this.state.freeFormAddress}
            onChange={(event) => this.onFreeFormAddressChanged(event.target.value)
            }
            onKeyDown={(event) => {
              if (event.keyCode === ENTER_KEYCODE) {
                event.preventDefault();
                this.props.onSubmit(this.getSelectedOptions());
              }
            }}
          />
        </div>
        <div className="radius grid-30 tablet-grid-30 mobile-grid-30" onMouseLeave={onEventChange}>
          <label htmlFor={`${filterKey}_radius`}>Radius</label>
          <select
            value={this.state.radius}
            id={`${filterKey}_radius`}
            className="dropdown radius-dropdown"
            onChange={(event) => this.onRadiusChanged(event.target.value)}
          >
            <option value="5">5 Miles</option>
            <option value="10">10 Miles</option>
            <option value="25">25 Miles</option>
            <option value="50">50 Miles</option>
            <option value="100">100 Miles</option>
          </select>
        </div>
      </div>
    );
  }
}
