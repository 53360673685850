export const HTTP_RESPONSE_CODE = {
  CONTINUE: 100,
  SWITCHING_PROTOCALS: 101,
  CHECKPOINT: 103,

  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,

  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  SWITCH_PROXY: 306,
  TEMPORARY_REDIRECT: 307,
  RESUME_INCOMPLETE: 308,

  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  REQUEST_ENTITY_TOO_LARGE: 413,
  REQUEST_URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  UNPROCESSABLE_ENTITY: 422,
  FAILED_DEPENDENCY: 424,

  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  NETWORK_AUTHENTICATION: 511,

  TOO_MANY_REQUESTS: 429,
};

export const PLP_AND_SRP_FILTERS_RECENT_SIZES = 'PLP_AND_SRP_FILTERS_RECENT_SIZES';
export const WOMEN = 'W';
export const MEN = 'M';
export const GENDER_CODE_MEN = 'Men';
export const GENDER_CODE_WOMEN = 'Women';

export const SEARCH_URL = '/s/?from=brSearch';

export const OUR_STORES = 'Our Stores';

export const LOCAL_STORAGE_RETRIEVE_KEYS = ['dt_selected_store', 'PLP_AND_SRP_FILTERS_RECENT_SIZES',
  '_kmsi', 'fpId', 'fpStoredDate', 'fingerprintExpiration', 'device_key', 'SRP_SEARCH_RECENT_KEYWORDS'];
export const LOCAL_STORAGE_RETRIEVE_KEYS_WITH_PREFIX = ['guestUser.', 'CognitoIdentityServiceProvider.', 'internationalCart_', 'recent_designer_'];
