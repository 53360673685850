import { combineReducers } from 'redux';
import searchReducersHc from 'clientHorchow/components/SearchBox/search-reducers';
import {
  emailSelectorReducer,
} from 'shared/components/App/app-reducers';
import searchReducer, {
  currentPage,
  selectedSortBy,
  selectedFilterOptions,
} from './searchReducer';

export default combineReducers({
  search: searchReducer,
  hcSearch: searchReducersHc,
  email: emailSelectorReducer,
  currentPage,
  selectedSortBy,
  selectedFilterOptions,
});
