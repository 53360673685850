import get from 'lodash/get';
import designerIndexSearchDesigners from 'templates/DesignerIndex/components/searchDesigners';
import { toggleSelected } from './siloToggler';
import searchDesigners from './DesignerMenu/searchDesigners';
import { types as NavTypes } from '../actions-navigation';
import {
  TOGGLE_SILO_SELECTED,
  DESIGNER_SEARCH_UPDATE,
  DESIGNER_INDEX_DESIGNER_SEARCH_UPDATE,
  TOGGLE_ACCOUNT_SELECTED,
} from './navSlider-actions';
import { DESIGNER_CAT_ID } from '../../constants';

const initialState = {
  sliderMenuVisible: false,
  silos: [],
  templates: {},
  searchTerm: '',
  foundDesigners: [],
  accountExpanded: false,
};

export const maintainSelected = (oldSilos, newSilos) => {
  const idOfSelected = oldSilos.reduce(
    (acc, silo) => (silo.selected ? [...acc, silo.id] : acc), []
  );

  return newSilos.reduce(
    (acc, silo) => [...acc, { ...silo, selected: idOfSelected.includes(silo.id) }], []
  );
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NavTypes.TOGGLE_NAV_SLIDER:
      return {
        ...state,
        sliderMenuVisible: !state.sliderMenuVisible,
        searchTerm: '',
        foundDesigners: [],
      };
    case NavTypes.REJECTED_NAVIGATION_MOBILE:
    case NavTypes.RESOLVED_NAVIGATION_MOBILE: {
      return {
        ...state,
        siloSource: 'API',
      };
    }
    case TOGGLE_SILO_SELECTED: {
      const searchState = action.catmanId === DESIGNER_CAT_ID
        ? { searchTerm: '', foundDesigners: [] }
        : {};

      return {
        ...state,
        silos: toggleSelected(action.id, state.silos),
        siloSource: 'MI',
        ...searchState,
      };
    }
    case DESIGNER_SEARCH_UPDATE: {
      return {
        ...state,
        searchTerm: action.searchTerm,
        foundDesigners: searchDesigners(action.searchTerm, state.silos),
      };
    }
    case DESIGNER_INDEX_DESIGNER_SEARCH_UPDATE: {
      return {
        ...state,
        designerIndexSearchTerm: action.payload.searchTerm,
        designerIndexFoundDesigners:
          designerIndexSearchDesigners(action.payload.searchTerm, action.payload.templates),
      };
    }
    case TOGGLE_ACCOUNT_SELECTED: {
      return {
        ...state,
        accountExpanded: action.accountExpanded,
      };
    }
    default:
      return state;
  }
};

export const getSliderMenuVisible = (state) => get(state, 'navigation.navSlider.sliderMenuVisible', {});
export const getNonDesignerSilos = (state) => get(state, 'navigation.navSlider.silos', []);
export const getDesignerSearchTerm = (state) => get(state, 'navigation.navSlider.searchTerm', '');
export const getDesignerIndexDesignerSearchTerm = (state) => get(state, 'navigation.navSlider.designerIndexSearchTerm', '');
export const getFoundDesigners = (state) => get(state, 'navigation.navSlider.foundDesigners', []);
export const getDesignerIndexFoundDesigners = (state) => get(state, 'navigation.navSlider.designerIndexFoundDesigners', []);
export const getAccountExpanded = (state) => get(state, 'navigation.navSlider.accountExpanded', false);
