import URL from 'url-parse';
import getUrl from 'url';

const urlDefaults = {
  protocol: 'https',
  slashes: true,
  hostname: 'www.neimanmarcus.com',
};

const hcUrlDefaults = { ...urlDefaults, hostname: 'www.horchow.com' };

// export function normalize(address, brand) {
//   if (!address) {
//     return '';
//   }

//   const defaultLocation = brand && brand === 'HC' ? hcUrlDefaults : urlDefaults;
//   const parsed = new URL(address, defaultLocation);
//   return parsed.toString();
// }
export function normalize(address, brand) {
  if (!address) {
    return '';
  }
  const trimmedAddress = address.trim();

  if (trimmedAddress.startsWith('//')) {
    return `https:${trimmedAddress}`;
  }

  if (/^https?:\/\//i.test(trimmedAddress)) {
    return trimmedAddress;
  }
  {
    const defaultLocation = brand && brand === 'HC' ? hcUrlDefaults : urlDefaults;
    const parsed = new URL(trimmedAddress, defaultLocation);
    return parsed.toString();
  }
}


export function getQueryParam(param, url) {
  const currentUrl = !url ? window.location.href : url;
  const parsed = getUrl.parse(currentUrl, true);
  return parsed.query[param];
}

export function hasProtocol(address) {
  const protocols = ['http:', 'https:'];
  return protocols.some((element) => address.startsWith(element));
}

export function prependSlashes(address) {
  return address.startsWith('//') ? address : `//${address}`;
}

export function parseUrlId(urlInfo) {
  const lastDashIndex = urlInfo.lastIndexOf('-');
  if (lastDashIndex === -1) {
    return urlInfo;
  }
  return urlInfo.slice(lastDashIndex + 1);
}

export function hasNoIndexParams(params) {
  const noIndexParams = [
    'ecid',
    'ECID',
    'icid',
    'utm_source',
    'sf_associd',
    'eVar4',
  ];
  const paramsKeys = Object.keys(params);
  return paramsKeys.some((param) => noIndexParams.includes(param));
}
