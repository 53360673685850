import { types } from './spinner-actions';

const defaultState = { active: false };

export default (state = defaultState, { type }) => {
  switch (type) {
    case types.SHOW_SPINNER:
      return { active: true };
    case types.HIDE_SPINNER:
      return { active: false };
    default:
      return state;
  }
};
