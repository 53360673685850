import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  testID: PropTypes.string,
};

const defaultProps = {
  testID: 'ShopRunnerProductDetail',
};

class ShopRunnerProductDetail extends Component {
  constructor(props) {
    super(props);
    this.srProductDetailRef = createRef();
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      testID,
    } = this.props;
    return (
      <div
        name="sr_productDetailDiv"
        data-tid={testID}
        ref={this.srProductDetailRef}
        suppressHydrationWarning
      />
    );
  }
}

ShopRunnerProductDetail.displayName = 'ShopRunnerProductDetail';
ShopRunnerProductDetail.propTypes = propTypes;
ShopRunnerProductDetail.defaultProps = defaultProps;

export default ShopRunnerProductDetail;
