import {
  LOADING_NEXT_CATEGORY,
  LOADING_PREVIOUS_CATEGORY,
  RESOLVED_NEXT_CATEGORY,
  RESOLVED_PREVIOUS_CATEGORY,
  APPLY_CATEGORY_TRANSITION,
  RESET_MENU,
  LOAD_CATEGORY,
  LOAD_ROOT_LEVEL_MENU,
} from './actions';
import { types as NavTypes } from '../actions-navigation';

export const defaultState = {
  silos: [],
  path: [],
  visibleCategories: [],
  loading: false,
  applyTransition: false,
  isNextClicked: false,
  isPrevClicked: false,
};

function displayableFields(categories) {
  return categories.map((category) => {
    return ({
      ...category,
      categories: [],
      children: category.categories && category.categories.length,
    });
  });
}

function getCategories(silos, path) {
  let categories = silos;

  if (path === undefined || path.length === 0) {
    return displayableFields(silos);
  }

  for (let i = 0; i < path.length; i++) {
    const index = path[i];
    categories = categories[index].categories;
  }

  return displayableFields(categories);
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case NavTypes.RESOLVED_NAVIGATION_MOBILE:
    case NavTypes.REJECTED_NAVIGATION_MOBILE: {
      return {
        ...state,
        silos: action.payload.serverNavigation
          ? action.payload.silos.map((silo) => { return { ...silo, categories: [] }; })
          : action.payload.silos,
        visibleCategories: [getCategories(action.payload.silos, action.payload.path)],
        path: action.payload.path || [],
      };
    }

    case 'SET_MOBILENAV_CONTEXT_PATH': {
      const path = action.payload;
      const categories = getCategories(state.silos, path);

      return {
        ...state,
        path: action.payload,
        visibleCategories: [categories],
      };
    }

    case LOAD_ROOT_LEVEL_MENU: {
      return {
        ...state,
        loading: true,
        isPrevClicked: true,
        path: [],
        visibleCategories: [...state.visibleCategories, getCategories(state.silos, 0)],
      };
    }

    case LOADING_NEXT_CATEGORY: {
      const index = action.payload;
      const path = [...state.path, index];
      const categories = getCategories(state.silos, path);
      return {
        ...state,
        loading: true,
        isNextClicked: true,
        path: [...path],
        visibleCategories: [...state.visibleCategories, categories],
      };
    }

    case LOADING_PREVIOUS_CATEGORY: {
      let categories = displayableFields(state.silos);
      let path = [];

      if (state.path.length > 1) {
        path = state.path.slice(0, state.path.length - 1);
        categories = getCategories(state.silos, path);
      }

      return {
        ...state,
        loading: true,
        isPrevClicked: true,
        path: [...path],
        visibleCategories: [...state.visibleCategories, categories],
      };
    }

    case LOAD_CATEGORY: {
      let categories = displayableFields(state.silos);
      let path = [];

      if (state.path.length > 1) {
        path = state.path.slice(0, action.idx + 1);
        categories = getCategories(state.silos, path);
      }

      return {
        ...state,
        loading: true,
        isPrevClicked: true,
        path: [...path],
        visibleCategories: [...state.visibleCategories, categories],
      };
    }

    case RESOLVED_NEXT_CATEGORY: {
      return {
        ...state,
        loading: false,
        applyTransition: false,
        isNextClicked: false,
        visibleCategories: [state.visibleCategories[1]],
      };
    }

    case RESOLVED_PREVIOUS_CATEGORY: {
      return {
        ...state,
        loading: false,
        applyTransition: false,
        isPrevClicked: false,
        visibleCategories: [state.visibleCategories[1]],
      };
    }

    case APPLY_CATEGORY_TRANSITION:
      return {
        ...state,
        applyTransition: true,
      };

    case RESET_MENU:
      return {
        ...state,
        path: [],
        visibleCategories: [displayableFields(state.silos)],
      };

    default:
      return state;
  }
};
