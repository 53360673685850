import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
  parent: PropTypes.bool,

  desktop: PropTypes.string,
  'desktop-hide': PropTypes.bool,
  'desktop-push': PropTypes.string,
  'desktop-pull': PropTypes.string,
  'desktop-prefix': PropTypes.string,
  'desktop-suffix': PropTypes.string,

  mobile: PropTypes.string,
  'mobile-hide': PropTypes.bool,
  'mobile-push': PropTypes.string,
  'mobile-pull': PropTypes.string,
  'mobile-prefix': PropTypes.string,
  'mobile-suffix': PropTypes.string,

  tablet: PropTypes.string,
  'tablet-hide': PropTypes.bool,
  'tablet-push': PropTypes.string,
  'tablet-pull': PropTypes.string,
  'tablet-prefix': PropTypes.string,
  'tablet-suffix': PropTypes.string,
};

const defaultProps = {
  children: null,
  className: undefined,
  uiComponent: 'FloatGrid',
  testID: null,
  parent: false,

  desktop: undefined,
  'desktop-hide': false,
  'desktop-push': undefined,
  'desktop-pull': undefined,
  'desktop-prefix': undefined,
  'desktop-suffix': undefined,

  mobile: undefined,
  'mobile-hide': false,
  'mobile-push': undefined,
  'mobile-pull': undefined,
  'mobile-prefix': undefined,
  'mobile-suffix': undefined,

  tablet: undefined,
  'tablet-hide': false,
  'tablet-push': undefined,
  'tablet-pull': undefined,
  'tablet-prefix': undefined,
  'tablet-suffix': undefined,
};

const FloatGrid = ({
  children,
  className,
  uiComponent,
  testID,
  parent,
  desktop,
  'desktop-hide': desktopHide,
  'desktop-push': desktopPush,
  'desktop-pull': desktopPull,
  'desktop-prefix': desktopPrefix,
  'desktop-suffix': desktopSuffix,
  mobile,
  'mobile-hide': mobileHide,
  'mobile-push': mobilePush,
  'mobile-pull': mobilePull,
  'mobile-prefix': mobilePrefix,
  'mobile-suffix': mobileSuffix,
  tablet,
  'tablet-hide': tabletHide,
  'tablet-push': tabletPush,
  'tablet-pull': tabletPull,
  'tablet-prefix': tabletPrefix,
  'tablet-suffix': tabletSuffix,
  ...props
}) => {
  let classList = [className];

  // Parent Class Names
  if (parent) {
    classList.push('grid-parent');
  }

  // Desktop Class Names
  if (desktop) {
    classList.push(`grid-${desktop}`);
  }

  if (desktopHide) {
    classList.push('hide-on-desktop');
  }

  if (desktopPush) {
    classList.push(`push-${desktopPush}`);
  }

  if (desktopPull) {
    classList.push(`pull-${desktopPull}`);
  }

  if (desktopPrefix) {
    classList.push(`prefix-${desktopPrefix}`);
  }

  if (desktopSuffix) {
    classList.push(`suffix-${desktopSuffix}`);
  }

  // Mobile Class Names
  if (mobile) {
    classList.push(`mobile-grid-${mobile}`);
  }

  if (mobileHide) {
    classList.push('hide-on-mobile');
  }

  if (mobilePush) {
    classList.push(`mobile-push-${mobilePush}`);
  }

  if (mobilePull) {
    classList.push(`mobile-pull-${mobilePull}`);
  }

  if (mobilePrefix) {
    classList.push(`mobile-prefix-${mobilePrefix}`);
  }

  if (mobileSuffix) {
    classList.push(`mobile-suffix-${mobileSuffix}`);
  }

  // Tablet Class Names
  if (tablet) {
    classList.push(`tablet-grid-${tablet}`);
  }

  if (tabletHide) {
    classList.push('hide-on-tablet');
  }

  if (tabletPush) {
    classList.push(`tablet-push-${tabletPush}`);
  }

  if (tabletPull) {
    classList.push(`tablet-pull-${tabletPull}`);
  }

  if (tabletPrefix) {
    classList.push(`tablet-prefix-${tabletPrefix}`);
  }

  if (tabletSuffix) {
    classList.push(`tablet-suffix-${tabletSuffix}`);
  }

  classList = (
    classList
      .join(' ')
      .trim()
      .replace(/\s+/g, ' ')
  );
  if (classList === '') {
    classList = null;
  }

  return (
    <div
      className={classList}
      {...props}
      data-ui-component={uiComponent}
      data-tid={testID}
    >
      {children}
    </div>
  );
};

FloatGrid.displayName = 'FloatGrid';
FloatGrid.propTypes = propTypes;
FloatGrid.defaultProps = defaultProps;

export default FloatGrid;
