import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './ListGroupItem.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  as: PropTypes.string,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
};

const defaultProps = {
  className: '',
  children: null,
  as: 'div',
  uiComponent: 'ListGroupItem',
  testID: undefined,
};

const ListGroupItem = ({
  className,
  children,
  as: Element,
  uiComponent,
  testID,
  flush,
  horizontal,
  ...props
}) => {
  const classList = cx({
    ListGroupItem: true,
  }, [className].join(' ').trim().replace(/\s+/g, ' '));
  return (
    <Element
      className={classList}
      data-ui-component={uiComponent}
      data-tid={testID}
      {...props}
    >
      {children}
    </Element>
  );
};

ListGroupItem.displayName = 'ListGroupItem';
ListGroupItem.propTypes = propTypes;
ListGroupItem.defaultProps = defaultProps;

export default ListGroupItem;
