/* eslint-disable no-param-reassign */
/**
 * Usage:
 *
 * import logger from 'server/utiliities/logger'
 *
 * logger.info('My message');
 *
 */
import { getLogger } from 'loglevel';

export const X_NMO_TRACE_ID_NAME = 'x-nmo-trace-id';

function trace() {
  if (!NMConfig?.TRACE || !Error.captureStackTrace) {
    // no-op
    return [];
  }

  const stub = {};
  if (Error.stackTraceLimit) {
    Error.stackTraceLimit = 50;
  }
  Error.captureStackTrace(stub);
  return stub.stack.replace(/^Error/, 'Stacktrace:');
}

function formatForHumans(message, loggerName, levelName, extras) {
  return [
    loggerName,
    `[${levelName.toUpperCase()}]`,
    message,
    ...extras,
    trace(),
  ];
}

/**
 * Allow nested objects to be logged as key-value pairs by flattening object and reindexing
 * with dots separating child keys.
 *
 * For example:
 * {a: {b: {c: 1}, d: 2}, e: 3} ==> {'a.b.c': 1, 'a.d': 2, e: 3}
 */
function flattenUsingDots(obj) {
  return Object.keys(obj).reduce((result, key) => {
    const child = obj[key];

    if (typeof child !== 'object') {
      result[key] = child;
      return result;
    }

    const flattenedChild = flattenUsingDots(child);

    Object.keys(flattenedChild).forEach((childKey) => {
      result[`${key}.${childKey}`] = flattenedChild[childKey];
    });

    return result;
  }, {});
}

function formatForMachines(message, loggerName, levelName, extras) {
  let additional = {};

  additional = flattenUsingDots(extras);

  const obj = {
    time: new Date(),
    ...additional,
    message,
    level: levelName.toUpperCase(),
  };

  return [
    Object.keys(obj).map((key) => `${key}=${JSON.stringify(obj[key])}`).join(', '),
  ];
}

function patchMethodFactory(logger, format, konsole) {
  // Inject custom format function - see README for loglevel package
  logger.methodFactory = function methodFactory(levelName,
    levelNumber, loggerName) {
    const rawLog = konsole[levelName];

    return (message, ...extras) => {
      try {
        const args = format(
          message,
          loggerName,
          levelName,
          extras,
        );

        rawLog(...args);
      } catch (e) {
        konsole.error(`Logging failed: ${e}`);
      }
    };
  };

  // Calling setLevel() applies new methodFactory
  logger.setLevel(NMConfig?.LOG_LEVEL || 'info');

  return logger;
}

export function loggerFactory(name) {
  const logger = getLogger(name);

  let format;
  let konsole;
  if (IS_CLIENT || NMConfig?.NODE_ENV === 'development') {
    // Developer-friendly messages
    format = formatForHumans;
    konsole = console;
  } else if (!IS_CLIENT) {
    // Machine-friendly messages
    format = formatForMachines;
    if (NMConfig?.LOG_TARGET === 'CLOUDWATCH') {
      konsole = console;
    } else {
      // Log to file using custom `console` and rotating file stream
      const logFilename = `nmo-ui.${NMConfig?.HOSTNAME || 'localhost'}.ecs.log`;
      // eslint-disable-next-line global-require
      const logStream = require('../rotatingLogStream')(logFilename);
      konsole = new console.Console(logStream); // eslint-disable-line no-console
    }
  }

  return patchMethodFactory(logger, format, konsole);
}

export default loggerFactory('NMOUILogger');
