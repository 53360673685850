import { ADD_TO_BAG_ERROR, BOPS_ERROR, CLEAR_ERROR } from '../actions';

const defaultState = {
  addToBagError: '',
  bopsError: '',
  bopsNoSelectionErrorForReplenishment: false,
  bopsErrorForReplenishment: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_TO_BAG_ERROR:
      return {
        ...state,
        ...defaultState,
        addToBagError: action.payload.errorMessage,
      };
    case BOPS_ERROR:
      return {
        ...state,
        ...defaultState,
        bopsError: action.payload.errorMessage,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultState,
      };
    default:
      return state;
  }
};
