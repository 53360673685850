import {
  RESOLVED_ABTESTOPT,
  REJECTED_ABTESTOPT,
} from 'shared/actions/actions-page';

export const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_ABTESTOPT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REJECTED_ABTESTOPT: {
      return defaultState;
    }
    default:
      return state;
  }
};
