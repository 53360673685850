import mcache from 'memory-cache';
import get from 'lodash/get';
import logger from 'server/utilities/logger';


export function logCache(key, state, message) {
  const JSESSIONID = get(state, 'session.JSESSIONID');
  const DYN_USER_ID = get(state, 'session.DYN_USER_ID');
  const TLTSID = get(state, 'session.TLTSID');
  logger.info(message, {
    key, JSESSIONID, DYN_USER_ID, TLTSID,
  });
}

export function getCache(key, state) {
  const getCacheData = mcache.get(key);
  if (getCacheData) {
    const message = 'Get Node Cache';
    logCache(key, state, message);
  }
  return getCacheData;
}

export function putCache(key, data, timeout, state) {
  const putCacheData = mcache.put(key, data, timeout);
  if (putCacheData) {
    const message = 'Put Node Cache';
    logCache(key, state, message);
  }
  return putCacheData;
}

export function exportCache() {
  return mcache.exportJson();
}

export function clearCache() {
  return mcache.clear();
}
