import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import he from 'he';
import { PAGE_ID_PDP } from 'shared/actions/actions-page';
import { normalize } from './utilities-url';
import { DISCONTINUED_CODE_V, DISCONTINUED_CODE_W, DISCONTINUED_CODE_Q } from '../pdp/constants';

export function stripHtml(string = '') {
  return string.toString().replace(/(<([^>]+)>)/ig, ' ').replace(/\s\s+/g, ' ').trim();
}

export function mapSkuAvailabilitySchema(stockStatusMessage) {
  switch (stockStatusMessage) {
    case 'Pre-Order':
      return 'PreOrder';
    case 'Item Not Available':
      return 'OutOfStock';
    default:
      return 'InStock';
  }
}

const isSkuEligibleForLinkedData = (skuObj) => (
  !(skuObj.discontinuedCode && (skuObj.discontinuedCode === DISCONTINUED_CODE_V
    || skuObj.discontinuedCode === DISCONTINUED_CODE_W
    || skuObj.discontinuedCode === DISCONTINUED_CODE_Q))
);

export function getLinkedDataPDP(product) {
  const name = get(product, 'name');
  const brand = get(product, 'designer.name');
  const image = get(product, 'media.main.medium.url');
  const description = get(product, 'details.longDesc');
  const url = get(product, 'details.canonicalUrl');
  const price = get(product, 'price.promotionalPrice', get(product, 'price.retailPrice'));
  const skus = get(product, 'skus', []);

  const offersArray = skus
    .filter(isSkuEligibleForLinkedData)
    .map((skuObj) => {
      const availability = mapSkuAvailabilitySchema(skuObj.stockStatusMessage);
      const sku = get(skuObj, 'id');
      const color = get(skuObj, 'color.name');
      const itemOffered = {};
      if (color) {
        itemOffered['@type'] = 'Product';
        if (color) itemOffered.color = color;
      }
      const offerObj = {
        '@type': 'Offer',
        priceCurrency: 'USD',
      };
      if (availability) offerObj.availability = availability;
      if (price) offerObj.price = price;
      if (sku) offerObj.sku = sku;
      if (!isEmpty(itemOffered)) offerObj.itemOffered = itemOffered;
      return offerObj;
    });

  const offers = offersArray.length ? {
    '@type': 'AggregateOffer',
    priceCurrency: 'USD',
    offers: offersArray,
    lowPrice: price,
    highPrice: price,
  } : undefined;

  const linkedData = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
  };

  if (name) linkedData.name = he.decode(name);
  if (brand) linkedData.brand = he.decode(brand);
  if (image) linkedData.image = normalize(image);
  if (description) linkedData.description = stripHtml(description);
  if (url) linkedData.url = normalize(url);
  if (offers) linkedData.offers = offers;

  return linkedData;
}

export function getLinkedDataForAllProductsAndSKUs(product) {
  const name = get(product, 'name');
  const brand = get(product, 'designer.name');
  const image = get(product, 'media.main.medium.url');
  const description = get(product, 'details.longDesc');
  const url = get(product, 'details.canonicalUrl');
  const price = get(product, 'price.promotionalPrice', get(product, 'price.retailPrice'));
  const skus = get(product, 'skus', []);
  const availability = skus.filter((sku) => sku.sellable).length === 0 ? 'OutOfStock' : 'InStock';

  const offersArray = skus
    .map((skuObj) => {
      const sku = get(skuObj, 'id');
      const color = get(skuObj, 'color.name');
      const offerObj = {
        '@type': 'Offer',
        priceCurrency: 'USD',
      };
      if (color) {
        offerObj.name = `${color} ${name}`;
      }
      if (price) offerObj.price = price;
      if (sku) offerObj.sku = sku;
      return offerObj;
    });

  const offers = offersArray.length ? {
    '@type': 'AggregateOffer',
    priceCurrency: 'USD',
    offers: offersArray,
    lowPrice: price,
    highPrice: price,
    availability,
  } : undefined;

  const linkedData = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
  };

  if (name) linkedData.name = he.decode(name);
  if (brand) linkedData.brand = he.decode(brand);
  if (image) linkedData.image = normalize(image);
  if (description) linkedData.description = stripHtml(description);
  if (url) linkedData.url = normalize(url);
  if (offers) linkedData.offers = offers;
  return linkedData;
}

export function computeRatingAndReviewForProductSchema(ratingAndReview) {
  const aggregateRating = {};
  if (ratingAndReview.averageRating && ratingAndReview.reviewCount) {
    aggregateRating['@type'] = 'AggregateRating';
    aggregateRating.ratingValue = ratingAndReview.averageRating;
    aggregateRating.reviewCount = ratingAndReview.reviewCount;
    // eslint-disable-next-line max-len
    aggregateRating.bestRating = (ratingAndReview.bestRating) ? ratingAndReview.bestRating : 5;
    // eslint-disable-next-line max-len
    aggregateRating.worstRating = (ratingAndReview.worstRating) ? ratingAndReview.worstRating : 1;
  }

  return { aggregateRating };
}

export function getGroupLinkData(productLinkData) {
  const linkData = {
    '@context': 'https://schema.org/',
    '@graph': [
      ...productLinkData,
    ],
  };

  return linkData;
}

export function getSiteNavigationElement(navigationSilos, pageId) {
  if (pageId === PAGE_ID_PDP) return {};

  const siteNavigationElements = [];

  navigationSilos.forEach((silo) => {
    const siteNavigationElement = {
      '@type': 'SiteNavigationElement',
      '@id': 'silo-nav',
    };
    siteNavigationElement.name = silo.name;
    siteNavigationElement.url = silo.url;
    siteNavigationElements.push(siteNavigationElement);
  });

  const linkedData = {
    '@context': 'http://schema.org',
    '@graph': [
      ...siteNavigationElements,
    ],
  };
  return linkedData;
}
