import axios from 'axios';

export const HC_SET_TYPEAHEAD_RESULT = 'HC_SET_TYPEAHEAD_RESULT';
export const HC_REJECTED_SEARCH = 'HC_REJECTED_SEARCH';

export function updateSearchTermResult(searchTerm, searchURL) {
  return (dispatch) => {
    return axios.get(`${searchURL}${searchTerm}`)
      .then((response) => {
        dispatch({
          type: HC_SET_TYPEAHEAD_RESULT,
          payload: response.data,
        });
      }).catch(() => dispatch({ type: HC_REJECTED_SEARCH }));
  };
}
