import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Box.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  as: PropTypes.string,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
};

const defaultProps = {
  className: '',
  children: null,
  as: 'div',
  uiComponent: 'Box',
  testID: undefined,
};

const Box = ({
  className,
  children,
  as: Element,
  uiComponent,
  testID,
  ...props
}) => {
  const classList = cx(['Box', className].join(' ').trim().replace(/\s+/g, ' '));
  return (
    <Element
      className={classList}
      {...props}
      data-ui-component={uiComponent}
      data-tid={testID}
    >
      {children}
    </Element>
  );
};

Box.displayName = 'Box';
Box.propTypes = propTypes;
Box.defaultProps = defaultProps;

export default Box;
