import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import {
  SET_HIDE_ON_DESKTOP,
  SET_HIDE_ON_TABLET,
  SET_HIDE_ON_MOBILE,
  SET_SELECTED_FACET,
  RESOLVED_SRP_ECM_CONTENT,
  REJECT_SRP_ECM_CONTENT,
} from '../actions/actions-ecmcontent';

export const defaultState = {};

const mergeContent = (component, ecmProps, targetProps) => {
  return {
    ...component,
    properties: {
      ...ecmProps,
      ...targetProps,
      images: {
        desktop: {
          ...ecmProps.images.desktop,
          ...targetProps.images.desktop,
        },
        tablet: {
          ...ecmProps.images.tablet,
          ...targetProps.images.tablet,
        },
        mobile: {
          ...ecmProps.images.mobile,
          ...targetProps.images.mobile,
        },
      },
    },
  };
};

export default (state = defaultState, action) => {
  const ecmContentSlot = isEmpty(action.contentId) ? {} : { [action.contentId]: {} };
  const contentId = get(action, 'contentId', '');
  switch (action.type) {
    case `RESOLVED_ECM${contentId}`:
      return { ...state, ...{ [contentId]: action.payload }, ...{ headers: action.headers } };
    case `REJECTED_ECM${contentId}`:
    case `RESET_ECM${contentId}`:
      return { ...state, ...ecmContentSlot, ...{ headers: {} } };
    case 'REJECTED_CP_PERSONALIZATION':
      return { ...state };
    case 'RESOLVED_CP_PERSONALIZATION': {
      if (action.cpToggle) {
        const personalizedContent = action.payload;
        const contentId = action.contentId;
        const slides = [];
        const promos = [];
        Object.keys(personalizedContent).forEach((key) => {
          personalizedContent[key].json_value = JSON.parse(personalizedContent[key].json_value);
        });

        if (action.cpBannerToggle) {
          const personalizedContentByType = groupBy(personalizedContent, 'layout');
          const banners = [];
          const normalizePersonalizedContent = (contentBlock, personalizedContentType) => {
            /* eslint-disable max-len */
            const personalizedObject = find(personalizedContentType, { content_block: contentBlock });
            if (personalizedObject) {
              if (!isEmpty(personalizedObject.json_value)) {
                personalizedObject.json_value = {
                  ...personalizedObject.json_value,
                  creative_id: personalizedObject.creative_id,
                };
                return personalizedObject.json_value;
              }
            }
            return null;
          };

          const assignPersonalizedContent = (layoutType, personalizedContentType) => {
            switch (layoutType) {
              case 'Row1_A': {
                slides.push(normalizePersonalizedContent('Row1_A', personalizedContentType));
                slides.push(null);
                slides.push(null);
                promos.push(null);
                promos.push(null);
                promos.push(null);
                break;
              }
              case 'Row1_BC': {
                slides.push(null);
                slides.push(normalizePersonalizedContent('Row1_B', personalizedContentType));
                slides.push(normalizePersonalizedContent('Row1_C', personalizedContentType));
                promos.push(null);
                promos.push(null);
                promos.push(null);
                break;
              }
              case 'Row1_ABC': {
                slides.push(normalizePersonalizedContent('Row1_A', personalizedContentType));
                slides.push(normalizePersonalizedContent('Row1_B', personalizedContentType));
                slides.push(normalizePersonalizedContent('Row1_C', personalizedContentType));
                promos.push(null);
                promos.push(null);
                promos.push(null);
                break;
              }
              case 'Row2_BC': {
                slides.push(null);
                slides.push(null);
                slides.push(null);
                promos.push(null);
                promos.push(normalizePersonalizedContent('Row2_B', personalizedContentType));
                promos.push(normalizePersonalizedContent('Row2_C', personalizedContentType));
                break;
              }
              case 'Row1_A_Row2_BC': {
                slides.push(normalizePersonalizedContent('Row1_A', personalizedContentType));
                slides.push(null);
                slides.push(null);
                promos.push(null);
                promos.push(normalizePersonalizedContent('Row2_B', personalizedContentType));
                promos.push(normalizePersonalizedContent('Row2_C', personalizedContentType));
                break;
              }
              case 'Row1_BC_Row2_BC': {
                slides.push(null);
                slides.push(normalizePersonalizedContent('Row1_B', personalizedContentType));
                slides.push(normalizePersonalizedContent('Row1_C', personalizedContentType));
                promos.push(null);
                promos.push(normalizePersonalizedContent('Row2_B', personalizedContentType));
                promos.push(normalizePersonalizedContent('Row2_C', personalizedContentType));
                break;
              }
              case 'Row1_ABC_Row2_BC': {
                slides.push(normalizePersonalizedContent('Row1_A', personalizedContentType));
                slides.push(normalizePersonalizedContent('Row1_B', personalizedContentType));
                slides.push(normalizePersonalizedContent('Row1_C', personalizedContentType));
                promos.push(null);
                promos.push(normalizePersonalizedContent('Row2_B', personalizedContentType));
                promos.push(normalizePersonalizedContent('Row2_C', personalizedContentType));
                break;
              }
              case 'Banner1': {
                banners.push(normalizePersonalizedContent('Banner1', personalizedContentType));
                banners.push(null);
                break;
              }
              case 'Banner2': {
                banners.push(null);
                banners.push(normalizePersonalizedContent('Banner2', personalizedContentType));
                break;
              }
              case 'Banner1_2': {
                banners.push(normalizePersonalizedContent('Banner1', personalizedContentType));
                banners.push(normalizePersonalizedContent('Banner2', personalizedContentType));
                break;
              }
              default:
                break;
            }
          };

          Object.keys(personalizedContentByType).forEach((layoutType) => {
            assignPersonalizedContent(layoutType, personalizedContentByType[layoutType]);
          });

          const hasSlides = !(isEmpty(slides[0]) && isEmpty(slides[1]) && isEmpty(slides[2]));
          const hasPromos = !(isEmpty(promos[0]) && isEmpty(promos[1]) && isEmpty(promos[2]));
          const hasBanners = !(isEmpty(banners[0]) && isEmpty(banners[1]));
          let cloneStateCP;
          let fLayoutReplaced = false;
          let cLayoutReplaced = false;
          let aLayoutCount = 0;
          if (state[contentId] && state[contentId].rows && (hasSlides || hasPromos || hasBanners)) {
            cloneStateCP = state[contentId].rows.map((row) => {
              if ((row.layout === 'F' || row.layout === 'FINMOBILE') && hasSlides && fLayoutReplaced === false) {
                const fCloneRow = cloneDeep(row);
                if (!isEmpty(fCloneRow.columns[0]) && !isEmpty(slides[0])) {
                  fCloneRow.columns[0] = slides[0];
                }
                if (fCloneRow.columns[1].rows) {
                  if (!isEmpty(fCloneRow.columns[1].rows[0]) && !isEmpty(slides[1])) {
                    fCloneRow.columns[1].rows[0] = slides[1];
                  }
                  if (!isEmpty(fCloneRow.columns[1].rows[1]) && !isEmpty(slides[2])) {
                    fCloneRow.columns[1].rows[1] = slides[2];
                  }
                }
                fLayoutReplaced = true;
                return { ...fCloneRow };
              }
              if ((row.layout === 'C' || row.layout === 'CINMOBILE') && hasPromos && cLayoutReplaced === false) {
                const cCloneRow = cloneDeep(row);
                if (!isEmpty(cCloneRow.columns[0]) && !isEmpty(promos[0])) {
                  cCloneRow.columns[0] = promos[0];
                }
                if (!isEmpty(cCloneRow.columns[1]) && !isEmpty(promos[1])) {
                  cCloneRow.columns[1] = promos[1];
                }
                if (!isEmpty(cCloneRow.columns[2]) && !isEmpty(promos[2])) {
                  cCloneRow.columns[2] = promos[2];
                }
                cLayoutReplaced = true;
                return { ...cCloneRow };
              }
              if ((row.layout === 'A' && hasBanners && aLayoutCount < 2)) {
                const aCloneRow = cloneDeep(row);
                if (!isEmpty(aCloneRow.columns[0]) && !isEmpty(banners[aLayoutCount])) {
                  aCloneRow.columns[0] = banners[aLayoutCount];
                }
                aLayoutCount += 1;
                return { ...aCloneRow };
              }
              return row;
            });
            return { ...state, [contentId]: { ...state[contentId], rows: cloneStateCP } };
          }
        }

        const cpTemplateType = get(personalizedContent, '[0].layout') ? get(personalizedContent, '[0].layout') : null;
        const normalizePersonalizedContent = (recNum) => {
          const personalizedObject = find(personalizedContent, { rec_num: recNum });
          if (personalizedObject) {
            if (!isEmpty(personalizedObject.json_value)) {
              personalizedObject.json_value = {
                ...personalizedObject.json_value,
                creative_id: personalizedObject.creative_id,
              };
              return personalizedObject.json_value;
            }
          }
          return null;
        };

        switch (cpTemplateType) {
          case 'Row1_A': {
            slides.push(normalizePersonalizedContent(1));
            slides.push(normalizePersonalizedContent(0));
            slides.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(0));
            break;
          }
          case 'Row1_BC': {
            slides.push(normalizePersonalizedContent(0));
            slides.push(normalizePersonalizedContent(1));
            slides.push(normalizePersonalizedContent(2));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(0));
            break;
          }
          case 'Row1_ABC': {
            slides.push(normalizePersonalizedContent(1));
            slides.push(normalizePersonalizedContent(2));
            slides.push(normalizePersonalizedContent(3));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(0));
            break;
          }
          case 'Row2_BC': {
            slides.push(normalizePersonalizedContent(0));
            slides.push(normalizePersonalizedContent(0));
            slides.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(1));
            promos.push(normalizePersonalizedContent(2));
            break;
          }
          case 'Row1_A_Row2_BC': {
            slides.push(normalizePersonalizedContent(1));
            slides.push(normalizePersonalizedContent(0));
            slides.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(2));
            promos.push(normalizePersonalizedContent(3));
            break;
          }
          case 'Row1_BC_Row2_BC': {
            slides.push(normalizePersonalizedContent(0));
            slides.push(normalizePersonalizedContent(1));
            slides.push(normalizePersonalizedContent(2));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(3));
            promos.push(normalizePersonalizedContent(4));
            break;
          }
          case 'Row1_ABC_Row2_BC': {
            slides.push(normalizePersonalizedContent(1));
            slides.push(normalizePersonalizedContent(2));
            slides.push(normalizePersonalizedContent(3));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(4));
            promos.push(normalizePersonalizedContent(5));
            break;
          }
          default:
            break;
        }
        const hasSlides = !(isEmpty(slides[0]) && isEmpty(slides[1]) && isEmpty(slides[2]));
        const hasPromos = !(isEmpty(promos[0]) && isEmpty(promos[1]) && isEmpty(promos[2]));
        let cloneStateCP;
        let fLayoutReplaced = false;
        let cLayoutReplaced = false;
        if (state[contentId] && state[contentId].rows && (hasSlides || hasPromos)) {
          cloneStateCP = state[contentId].rows.map((row) => {
            if ((row.layout === 'F' || row.layout === 'FINMOBILE') && hasSlides && fLayoutReplaced === false) {
              const fCloneRow = cloneDeep(row);
              if (!isEmpty(fCloneRow.columns[0]) && !isEmpty(slides[0])) {
                fCloneRow.columns[0] = slides[0];
              }
              if (fCloneRow.columns[1].rows) {
                if (!isEmpty(fCloneRow.columns[1].rows[0]) && !isEmpty(slides[1])) {
                  fCloneRow.columns[1].rows[0] = slides[1];
                }
                if (!isEmpty(fCloneRow.columns[1].rows[1]) && !isEmpty(slides[2])) {
                  fCloneRow.columns[1].rows[1] = slides[2];
                }
              }
              fLayoutReplaced = true;
              return { ...fCloneRow };
            }
            if ((row.layout === 'C' || row.layout === 'CINMOBILE') && hasPromos && cLayoutReplaced === false) {
              const cCloneRow = cloneDeep(row);
              if (!isEmpty(cCloneRow.columns[0]) && !isEmpty(promos[0])) {
                cCloneRow.columns[0] = promos[0];
              }
              if (!isEmpty(cCloneRow.columns[1]) && !isEmpty(promos[1])) {
                cCloneRow.columns[1] = promos[1];
              }
              if (!isEmpty(cCloneRow.columns[2]) && !isEmpty(promos[2])) {
                cCloneRow.columns[2] = promos[2];
              }
              cLayoutReplaced = true;
              return { ...cCloneRow };
            }
            return row;
          });
          return { ...state, [contentId]: { ...state[contentId], rows: cloneStateCP } };
        }
      } else {
        const personalizedContent = action.payload;
        const contentId = action.contentId;
        const cpTemplateType = get(personalizedContent, '[0].layout') ? get(personalizedContent, '[0].layout') : get(personalizedContent, '[3].layout', 'both');
        const slides = [];
        const promos = [];
        const normalizePersonalizedContent = (recNum) => {
          const personalizedObject = find(personalizedContent, { rec_num: recNum });
          if (personalizedObject) {
            if (!isEmpty(personalizedObject.mobile_image_url)
              || !isEmpty(personalizedObject.tablet_image_url)
              || !isEmpty(personalizedObject.desktop_image_url)) {
              const returnObject = {
                images: {
                  tablet: { imagesrc: personalizedObject.tablet_image_url },
                  mobile: { imagesrc: personalizedObject.mobile_image_url },
                  desktop: { imagesrc: personalizedObject.desktop_image_url },
                },
              };
              if (!isEmpty(personalizedObject.link_url)) {
                returnObject.linkurl = personalizedObject.link_url;
              }
              if (!isEmpty(personalizedObject.alt_text)) {
                returnObject.alttext = personalizedObject.alt_text;
                returnObject.title = personalizedObject.alt_text;
              }
              return returnObject;
            }
          }
          return null;
        };
        switch (cpTemplateType) {
          case 'both': {
            slides.push(normalizePersonalizedContent(1));
            slides.push(normalizePersonalizedContent(2));
            slides.push(normalizePersonalizedContent(3));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(4));
            promos.push(normalizePersonalizedContent(5));
            break;
          }
          case 'carousel only': {
            slides.push(normalizePersonalizedContent(1));
            slides.push(normalizePersonalizedContent(2));
            slides.push(normalizePersonalizedContent(3));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(0));
            break;
          }
          case 'third_width only': {
            slides.push(normalizePersonalizedContent(0));
            slides.push(normalizePersonalizedContent(0));
            slides.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(0));
            promos.push(normalizePersonalizedContent(1));
            promos.push(normalizePersonalizedContent(2));
            break;
          }
          default:
            break;
        }

        const hasSlides = !(isEmpty(slides[0]) && isEmpty(slides[1]) && isEmpty(slides[2]));
        const hasPromos = !(isEmpty(promos[0]) && isEmpty(promos[1]) && isEmpty(promos[2]));

        if (state[contentId] && state[contentId].rows && (hasSlides || hasPromos)) {
          let processedPromos = false;
          let processedSlides = false;
          const cloneRows = state[contentId].rows.map((row) => {
            if (get(row, 'layout', '').toLowerCase() === 'c' && hasPromos && !processedPromos) {
              processedPromos = true;
              const cloneColumns = get(row, 'columns', []).map((column, index) => {
                if (column.componentType === 'adaptive-image' && promos[index]) {
                  const promoProperties = get(column, 'properties', {});
                  return mergeContent(column,
                    promoProperties,
                    promos[index]);
                }
                return column;
              });
              return { ...row, columns: cloneColumns };
            }
            if (get(row, 'layout', '').toLowerCase() === 'a' && hasSlides && !processedSlides) {
              const cloneColumns = get(row, 'columns', []).map((column) => {
                if (column.componentType === 'carousel' && get(column, 'slides', []).length > 0) {
                  processedSlides = true;
                  const cloneSlides = column.slides.map((slide, index) => {
                    if (slide.componentType === 'adaptive-image' && slides[index]) {
                      const slideProperties = get(slide, 'properties', {});
                      return mergeContent(slide,
                        slideProperties,
                        slides[index]);
                    }
                    return slide;
                  });
                  return { ...column, slides: cloneSlides };
                }
                return column;
              });
              return { ...row, columns: cloneColumns };
            }
            return row;
          });
          const contentRef = state[contentId];
          const cloneContent = { ...contentRef, rows: cloneRows };
          return {
            ...state,
            [contentId]: cloneContent,
          };
        }
      }
      return { ...state };
    }

    case SET_HIDE_ON_DESKTOP: {
      const content = { ...state[contentId], hideOnDesktop: true };
      return { ...state, [contentId]: content };
    }
    case SET_HIDE_ON_TABLET: {
      const content = { ...state[contentId], hideOnTablet: true };
      return { ...state, [contentId]: content };
    }
    case SET_HIDE_ON_MOBILE: {
      const content = { ...state[contentId], hideOnMobile: true };
      return { ...state, [contentId]: content };
    }
    case `RESOLVED_ECM${contentId}_MAGAZINE_LOAD_MORE`: {
      const slotInState = state[contentId] || {};
      const rows = slotInState.rows || [];
      const storyConfig = slotInState.storyConfig || {};
      const newRows = action.payload.rows || [];
      const newStoryConfig = action.payload.storyConfig || {};
      const clonedStoryConfig = { ...storyConfig };
      clonedStoryConfig.lastServedStoryId = newStoryConfig.lastServedStoryId;
      clonedStoryConfig.loadMoreFlagEnabled = newStoryConfig.loadMoreFlagEnabled;
      const clonedRows = [...rows, ...newRows];
      const clonedSlotInState = { ...slotInState };
      clonedSlotInState.rows = clonedRows;
      clonedSlotInState.storyConfig = clonedStoryConfig;
      return { ...state, ...{ [contentId]: clonedSlotInState }, ...{ headers: action.headers } };
    }
    case `RESOLVED_ECM${contentId}_MAGAZINE_FACET`: {
      const slotInState = state[contentId] || {};
      const storyConfig = slotInState.storyConfig || {};
      const newStoryConfig = action.payload.storyConfig || {};
      const clonedStoryConfig = { ...storyConfig };
      clonedStoryConfig.lastServedStoryId = newStoryConfig.lastServedStoryId;
      clonedStoryConfig.loadMoreFlagEnabled = newStoryConfig.loadMoreFlagEnabled;
      const payload = action.payload;
      payload.storyConfig = clonedStoryConfig;
      payload.selectedFacet = state[contentId].selectedFacet;
      return { ...state, ...{ [contentId]: payload } };
    }
    case SET_SELECTED_FACET: {
      const content = { ...state[contentId], selectedFacet: action.payload };
      return { ...state, [contentId]: content };
    }
    case RESOLVED_SRP_ECM_CONTENT: {
      const { payload } = action;
      return {
        ...state,
        ...payload.srpEmcContent,
        srpPromoTileSize: payload.srpPromoTileSize,
      };
    }
    case REJECT_SRP_ECM_CONTENT: {
      return {
        ...state,
        srpPromoTitles: {},
      };
    }
    default:
      return state;
  }
};
