export const LOADING_NEXT_CATEGORY = 'LOADING_NEXT_CATEGORY';
export const LOADING_PREVIOUS_CATEGORY = 'LOADING_PREVIOUS_CATEGORY';
export const RESOLVED_NEXT_CATEGORY = 'RESOLVED_NEXT_CATEGORY';
export const RESOLVED_PREVIOUS_CATEGORY = 'RESOLVED_PREVIOUS_CATEGORY';
export const APPLY_CATEGORY_TRANSITION = 'APPLY_CATEGORY_TRANSITION';
export const TOGGLE_NAV_SLIDER = 'TOGGLE_NAV_SLIDER';
export const RESET_MENU = 'RESET_MENU';
export const LOAD_CATEGORY = 'LOAD_CATEGORY';
export const LOAD_ROOT_LEVEL_MENU = 'LOAD_ROOT_LEVEL_MENU';

export function loadNextCategory(categoryIndex) {
  return (dispatch) => {
    dispatch({ type: LOADING_NEXT_CATEGORY, payload: categoryIndex });
    setTimeout(() => {
      dispatch({ type: APPLY_CATEGORY_TRANSITION });
    }, 10);
    setTimeout(() => {
      dispatch({ type: RESOLVED_NEXT_CATEGORY });
    }, 220);
  };
}

export function loadPreviousCategory() {
  return (dispatch) => {
    dispatch({ type: LOADING_PREVIOUS_CATEGORY });
    setTimeout(() => {
      dispatch({ type: APPLY_CATEGORY_TRANSITION });
    }, 10);
    setTimeout(() => {
      dispatch({ type: RESOLVED_PREVIOUS_CATEGORY });
    }, 220);
  };
}

export function loadCategoryMenu(path, idx = 0) {
  return (dispatch) => {
    if (path.length === 0) {
      dispatch({ type: LOAD_ROOT_LEVEL_MENU });
    } else {
      dispatch({ type: LOAD_CATEGORY, path, idx });
    }

    setTimeout(() => {
      dispatch({ type: APPLY_CATEGORY_TRANSITION });
    }, 10);

    setTimeout(() => {
      dispatch({ type: RESOLVED_PREVIOUS_CATEGORY });
    }, 220);
  };
}

export function closeMenu() {
  return (dispatch) => {
    dispatch({ type: TOGGLE_NAV_SLIDER });
    dispatch({ type: RESET_MENU });
  };
}

export function closeMenuNew() {
  return (dispatch) => {
    dispatch({ type: TOGGLE_NAV_SLIDER });
  };
}
