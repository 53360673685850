import { combineReducers } from 'redux';
import productListReducer, {
  currentCategory,
  selectedSortBy,
  categoryNoResultsContent,
  seo,
  isValidCategory,
  selectedFilterOptions,
  productListMetadata,
} from './reducers/productListReducer';

export default combineReducers({
  products: productListReducer,
  currentCategory,
  selectedSortBy,
  categoryNoResultsContent,
  seo,
  isValidCategory,
  selectedFilterOptions,
  productListMetadata,
});
