import {
  SET_SELECTED_SIZE_INDEX,
  SET_SELECTED_COLOR_INDEX,
  SET_HOVERED_SIZE_INDEX,
  SET_HOVERED_COLOR_INDEX,
  SET_SELECTED_COLOR_CODE,
  SET_HOVERED_BUCKLE_FINISH_INDEX,
  SET_SELECTED_BUCKLE_FINISH_INDEX,
} from '../actions';
import { getActiveMediaIndex } from '../selectors/getMedia';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_SELECTED_COLOR_CODE:
      return {
        ...state,
        options: {
          ...state.options,
          selectedColorCode: action.payload,
        },
      };
    case SET_SELECTED_SIZE_INDEX:
      return {
        ...state,
        options: {
          ...state.options,
          selectedSizeIndex: action.payload,
          lastClickedOption: 'size',
        },
      };
    case SET_SELECTED_COLOR_INDEX: {
      const newState = {
        ...state,
        options: {
          ...state.options,
          selectedColorIndex: action.payload,
        },
      };

      return {
        ...newState,
        activeMediaIndex: getActiveMediaIndex(newState, true),
      };
    }
    case SET_SELECTED_BUCKLE_FINISH_INDEX: {
      return {
        ...state,
        options: {
          ...state.options,
          selectedBuckleFinishIndex: action.payload,
        },
      };
    }
    case SET_HOVERED_SIZE_INDEX: {
      return {
        ...state,
        options: {
          ...state.options,
          hoveredSizeIndex: action.payload,
          hoveredColorIndex: -1,
        },
      };
    }
    case SET_HOVERED_COLOR_INDEX: {
      const newState = {
        ...state,
        options: {
          ...state.options,
          hoveredColorIndex: action.payload,
          hoveredSizeIndex: -1,
        },
      };

      return {
        ...newState,
        activeMediaIndex: getActiveMediaIndex(newState, true),
      };
    }
    case SET_HOVERED_BUCKLE_FINISH_INDEX: {
      return {
        ...state,
        options: {
          ...state.options,
          hoveredBuckleFinishIndex: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
