import reactCookie from 'react-cookie';

export const updateUcaProfileCountCartItems = (cartItemCount = 0) => {
  const ucaProfileDataCookie = reactCookie.load('ucaProfileData');
  const expiresAt = Date.now() + 2592000000; // 30 days
  const expirationDate = new Date(expiresAt);
  reactCookie.save(
    'ucaProfileData',
    JSON.stringify({ ...ucaProfileDataCookie, cartItemCount }),
    {
      path: '/',
      expires: expirationDate,
      secure: true,
    }
  );
};
