import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  uiComponent: PropTypes.string,
  id: PropTypes.string.isRequired,
  fallback: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
    PropTypes.func,
  ]),
  onError: PropTypes.func,
};

const defaultProps = {
  className: '',
  children: null,
  uiComponent: 'ErrorBoundary',
  fallback: ErrorBoundaryFallback,
  onError: () => {},
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    const { id, onError } = this.props;

    this.setState({
      error,
      errorInfo,
      hasError: true,
    });

    if (typeof onError === 'function') {
      try {
        onError.call(this, error, errorInfo ? errorInfo.componentStack : '');
      } catch (ignoredError) {
        /* Do Nothing */
      }
    }

    // log error messages to dynatrace RUM
    try {
      if (window && typeof window.dtrum !== 'undefined') {
        window.dtrum.reportError({
          message: `ErrorBoundary: ${id}. ${error.toString()}`,
          file: null,
          line: null,
          column: null,
          stack: errorInfo ? errorInfo.componentStack : '',
        });
      }
    } catch (ignoredError) {
      /* Do Nothing */
    }
  }

  render() {
    const {
      className,
      children = null,
      uiComponent,
      id,
      fallback: FallbackComponent,
      ...props
    } = this.props;
    const {
      error = null,
      errorInfo,
      hasError = false,
    } = this.state;

    if (hasError) {
      return (
        <FallbackComponent
          className={className}
          componentStack={
            errorInfo ? errorInfo.componentStack : ''
          }
          error={error}
          errorBoundaryID={id}
          {...props}
        />
      );
    }
    return children;
  }
}

ErrorBoundary.displayName = 'ErrorBoundary';
ErrorBoundary.propTypes = propTypes;
ErrorBoundary.defaultProps = defaultProps;

export default ErrorBoundary;
