import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Tabs.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  as: PropTypes.string,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
  label: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
};

const defaultProps = {
  className: '',
  children: null,
  as: 'div',
  uiComponent: 'TabPanel',
  testID: undefined,
};

const TabPanel = ({
  activeTab,
  className,
  children,
  as: Element,
  uiComponent,
  testID,
  label,
  ...props
}) => {
  const isActive = activeTab === label;

  const classList = cx(['Tabs__TabPanel', className].join(' ').trim().replace(/\s+/g, ' '));
  return (
    <Element
      className={classList}
      data-ui-component={uiComponent}
      data-tid={testID}
      label={label}
      role="tabpanel"
      tabIndex={-1}
      hidden={!isActive}
      {...props}
    >
      {children}
    </Element>
  );
};

TabPanel.displayName = 'TabPanel';
TabPanel.propTypes = propTypes;
TabPanel.defaultProps = defaultProps;

export default TabPanel;
