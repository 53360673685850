import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import countries from './countries.json';
import styles from './FlagIcon.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
  countryCode: PropTypes.oneOf([
    ...Object.keys(countries),
  ]).isRequired,
};

const defaultProps = {
  className: '',
  uiComponent: 'FlagIcon',
  testID: undefined,
};

const FlagIcon = ({
  className,
  uiComponent,
  testID,
  countryCode = 'US',
  ...props
}) => {
  let src = null;
  const classList = cx(['FlagIcon', className].join(' ').trim().replace(/\s+/g, ' '));
  const countryName = countries[countryCode];

  if (!countryName) {
    return null;
  }
  // eslint-disable-next-line global-require
  src = require(`./icons/${countryCode.toLowerCase()}.svg`);

  return (
    <span
      {...props}
      className={classList}
      data-ui-component={uiComponent}
      data-tid={testID}
    >
      <img
        alt={`${countryName} flag`}
        src={src}
      />
    </span>
  );
};

FlagIcon.displayName = 'FlagIcon';
FlagIcon.propTypes = propTypes;
FlagIcon.defaultProps = defaultProps;

export default FlagIcon;
