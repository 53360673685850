import httpWithLogging from 'universal/http-client';

export const RESOLVED_REFRESHABLE_CONTENT = 'RESOLVE_REFRESHABLE_CONTENT';
export const REJECTED_REFRESHABLE_CONTENT = 'REJECT_REFRESHABLE_CONTENT';

export function getRefreshableHtmlContent(refreshablePath, categoryId) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    return requestApi.get(NMConfig.API_REFRESHABLE_CONTENT, { params: { refreshablePath } })
      .then((successResponse) => dispatch({
        type: RESOLVED_REFRESHABLE_CONTENT,
        payload: successResponse.data,
        categoryId,
      }))
      .catch(() => dispatch({ type: REJECTED_REFRESHABLE_CONTENT }));
  };
}
