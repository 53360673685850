// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { isTouchDevice } from 'client-utils/utilities-page';

class WithTappable extends Component {
  touchOrMouse() {
    const { mouseOver, mouseOut } = this.props;
    if (!isTouchDevice()) {
      return { onMouseOver: mouseOver, onMouseOut: mouseOut };
    }
    return {};
  }

  render() {
    return this.props.children(this.touchOrMouse());
  }
}

export default WithTappable;
