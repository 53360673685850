export default (state) => {
  const products = (state.isGroup) ? state.childProducts : [state];
  return {
    page: 'product-details',
    details: {
      products: products.map((product) => ({
        id: product.id,
        skus: product.skus.map((sku) => ({
          id: sku.id,
          preOrder: sku.preOrder,
          backOrder: sku.backOrder,
          inStock: sku.inStock,
          dropShip: sku.dropShip,
          discontinuedCode: sku.discontinuedCode,
          cmosSkuId: sku.metadata.cmosSkuId,
          pimSkuId: sku.metadata.pimSkuId,
          color: sku.color && sku.color.name,
          size: sku.size && sku.size.name,
          stockStatusMessage: sku.stockStatusMessage,
          poQuantity: sku.poQuantity,
          shipFromStore: sku.shipFromStore,
          stockLevel: sku.stockLevel,
          purchaseOrderQuantity: sku.purchaseOrderQuantity,
          sellable: sku.sellable,
        })),
      })),
    },
  };
};
