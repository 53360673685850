import { SET_P13N_PXL } from '../actions/actions-ecmcontent';

export const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_P13N_PXL:
      return { pxlParams: action.payload };
    default:
      return state;
  }
};
