import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

export default (productState, selectedSizeIndex) => {
  let sizeIndex = selectedSizeIndex;
  const sizeOptions = find(
    get(productState, 'options.productOptions', []),
    ['label', 'size']
  ) || { values: [] };
  const skus = get(productState, 'skus');
  const skuMatrix = get(productState, 'options.optionMatrix', []);

  if (sizeOptions.values.length === 0) {
    // there're no size options meaning there is a single array of colors to size in skuMatrix
    sizeIndex = 0;
  }

  if (isEmpty(skuMatrix) || sizeIndex < 0 || isEmpty(skuMatrix[sizeIndex])) {
    return [];
  }

  return skuMatrix[sizeIndex].map((skuId) => find(skus, ['id', skuId]));
};
