import { types as NavTypes } from '../actions-navigation';

const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case NavTypes.REJECTED_BREADCRUMB_CONTENT:
      return state;
    case NavTypes.RESOLVED_BREADCRUMB_CONTENT:
      return action.payload.breadcrumbs || null;
    default:
      return state;
  }
};
