import httpWithLogging from 'universal/http-client';
import { isMobile } from 'client-utils/utilities-page';

export const types = {
  LOADING_PROMO_CAROUSEL_CONTENT: 'LOADING_PROMO_CAROUSEL_CONTENT',
  RESOLVED_PROMO_CAROUSEL_CONTENT: 'RESOLVE_PROMO_CAROUSEL_CONTENT',
  REJECTED_PROMO_CAROUSEL_CONTENT: 'REJECTED_PROMO_CAROUSEL_CONTENT',
};

const getRefreshablePath = (countryCode) => {
  let allRefreshablePath = {
    US: '/category/cat000000/r_header_promo.html',
  };

  if (isMobile()) {
    allRefreshablePath = {
      ...allRefreshablePath,
      CA: '/category/cat000000/r_mobile_header_promo_CA.html',
      RoW: '/category/cat000000/r_mobile_header_promo_INTL.html',
    };
  } else {
    allRefreshablePath = {
      ...allRefreshablePath,
      CA: '/category/cat000000/r_header_promo_CA.html',
      RoW: '/category/cat000000/r_header_promo_INTL.html',
    };
  }

  return allRefreshablePath[countryCode] || allRefreshablePath.RoW;
};

const fetchPromoCarouselData = (dispatch, state, countryCode) => {
  const refreshablePath = getRefreshablePath(countryCode);
  const requestApi = httpWithLogging(state);
  return requestApi.get(NMConfig.API_REFRESHABLE_CONTENT, { params: { refreshablePath } })
    .then((successResponse) => dispatch({
      type: types.RESOLVED_PROMO_CAROUSEL_CONTENT,
      payload: successResponse.data,
    }))
    .catch(() => dispatch({ type: types.REJECTED_PROMO_CAROUSEL_CONTENT }));
};

export function getPromoCarouselContent() {
  return (dispatch, getState) => {
    const state = getState();
    const countryCode = state.locale.countryCode;
    return fetchPromoCarouselData(dispatch, state, countryCode);
  };
}
