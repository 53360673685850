import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
  'desktop-hide': PropTypes.bool,
  'mobile-hide': PropTypes.bool,
  'tablet-hide': PropTypes.bool,
};

const defaultProps = {
  className: '',
  uiComponent: 'FloatGridClear',
  testID: null,
  'desktop-hide': false,
  'mobile-hide': false,
  'tablet-hide': false,
};

const FloatGridClear = ({
  children,
  className = '',
  uiComponent,
  testID,
  'desktop-hide': desktopHide,
  'mobile-hide': mobileHide,
  'tablet-hide': tabletHide,
  ...props
}) => {
  let classList = ['clear', className];

  // Desktop Class Names
  if (desktopHide) {
    classList.push('hide-on-desktop');
  }

  // Mobile Class Names
  if (mobileHide) {
    classList.push('hide-on-mobile');
  }

  // Tablet Class Names
  if (tabletHide) {
    classList.push('hide-on-tablet');
  }

  classList = (
    classList
      .join(' ')
      .trim()
      .replace(/\s+/g, ' ')
  );

  return (
    <span
      className={classList}
      {...props}
      data-ui-component={uiComponent}
      data-tid={testID}
    />
  );
};

FloatGridClear.displayName = 'FloatGridClear';
FloatGridClear.propTypes = propTypes;
FloatGridClear.defaultProps = defaultProps;

export default FloatGridClear;
