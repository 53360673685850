/* eslint-disable import/order */
import isEmpty from 'lodash/isEmpty';
import httpWithLogging from 'universal/http-client';
import { setPlaceholderValue, setSearchTermToEmpty } from 'storefront/components/Header/Search/search-actions';
import { getCloudinaryImageWithFixedWidth } from 'client/utilities/utilities-cloudinary';

export const LOADING_SEARCH_LIST = 'LOADING_SEARCH_LIST';
export const RESOLVED_SEARCH_LIST = 'RESOLVED_SEARCH_LIST';
export const REJECTED_SEARCH_LIST = 'REJECTED_SEARCH_LIST';
export const SET_SEARCH_LIST_DATA_TO_UTAG = 'SET_SEARCH_LIST_DATA_TO_UTAG';
export const SET_SEARCH_CATEGORY_CRUMB = 'SET_SEARCH_CATEGORY_CRUMB';
export const LOADING_NULL_SEARCH_LIST = 'LOADING_NULL_SEARCH_LIST';
export const RESOLVED_NULL_SEARCH_LIST = 'RESOLVED_NULL_SEARCH_LIST';
export const REJECTED_NULL_SEARCH_LIST = 'REJECTED_NULL_SEARCH_LIST';
export const LOADING_NULL_SEARCH_FOOTER = 'LOADING_NULL_SEARCH_FOOTER';
export const RESOLVED_NULL_SEARCH_FOOTER = 'RESOLVED_NULL_SEARCH_FOOTER';
export const REJECTED_NULL_SEARCH_FOOTER = 'REJECTED_NULL_SEARCH_FOOTER';
export const LOADING_SEARCH_GRAPHIC_HEADER = 'LOADING_SEARCH_GRAPHIC_HEADER';
export const RESOLVED_SEARCH_GRAPHIC_HEADER = 'RESOLVED_SEARCH_GRAPHIC_HEADER';
export const REJECTED_SEARCH_GRAPHIC_HEADER = 'REJECTED_SEARCH_GRAPHIC_HEADER';
export const SET_LEFT_NAV_ANALYTICS_FLAG = 'SET_LEFT_NAV_ANALYTICS_FLAG';
export const CLEAR_QR_FROM_UTAG = 'CLEAR_QR_FROM_UTAG';
export const SELECT_FILTER_OPTION_SRP = 'SELECT_FILTER_OPTION_SRP';
export const CLEAR_ALL_FILTER_OPTION = 'CLEAR_ALL_FILTER_OPTION';
export const SELECT_FILTER_BY_ROUTE_PARAM_SRP = 'SELECT_FILTER_BY_ROUTE_PARAM_SRP';
export const LOADING_TOP_PRODUCTS = 'LOADING_TOP_PRODUCTS';
export const RESOLVED_TOP_PRODUCTS = 'RESOLVED_TOP_PRODUCTS';
export const REJECTED_TOP_PRODUCTS = 'REJECTED_TOP_PRODUCTS';
export const TYPEAHEAD_IMAGE_WIDTH = 155;
export const SET_GENDER_TA = 'SET_GENDER_TA';

export const types = {
  SET_SRP_PAGE: 'SET_SRP_PAGE',
  SET_SRP_SORT_BY: 'SET_SRP_SORT_BY',
  SET_SRP_FILTER_OPTIONS: 'SET_SRP_FILTER_OPTIONS',
};

export function setPlaceholderToTextBoxValueAndClearTextBox(searchTerm) {
  return (dispatch) => {
    setPlaceholderValue(searchTerm)(dispatch);
    setSearchTermToEmpty()(dispatch);
  };
}

export function clearQRFromUtag() {
  return (dispatch) => {
    dispatch({ type: CLEAR_QR_FROM_UTAG });
  };
}

export function updateTopProducts(body, cacheKey) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_TOP_PRODUCTS });
    const state = getState();
    const requestApi = httpWithLogging(state);
    const dprToggle = state?.toggles?.CLOUDINARY_DPR || false;
    return requestApi
      .post(`${NMConfig.API_TOP_PRODUCTS}?cacheKey=${cacheKey}`, body)
      .then(((response) => {
        let products = response.data.products
          .reduce((filtered, product) => {
            if (product.displayable === true) {
              const nextProduct = {
                id: product.id,
                designer: product.designer,
                main: getCloudinaryImageWithFixedWidth(product.main, TYPEAHEAD_IMAGE_WIDTH, '', dprToggle),
                price: product.price,
                url: product.details.canonicalUrl,
              };
              filtered.push(nextProduct);
            }
            return filtered;
          }, []);
        if (isEmpty(products)) {
          products = ['NO PRODUCTS AVAILABLE'];
        }
        dispatch({
          type: RESOLVED_TOP_PRODUCTS,
          payload: products,
        });
      }))
      .catch(() => {
        dispatch({
          type: REJECTED_TOP_PRODUCTS,
          payload: ['NO PRODUCTS AVAILABLE'],
        });
      });
  };
}

export const setGenderTA = (gender, withUtagAnalytics = false) => {
  return {
    type: SET_GENDER_TA,
    payload: { gender, withUtagAnalytics },
  };
};
