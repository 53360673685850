import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  testID: PropTypes.string,
  isSellableSku: PropTypes.bool,
};

const defaultProps = {
  testID: 'ShopRunnerNotifyMe',
  isSellableSku: false,
};

class ShopRunnerNotifyMe extends Component {
  constructor(props) {
    super(props);
    this.srNotifyMeRef = createRef();
  }

  shouldComponentUpdate(nextProps) {
    const { isSellableSku } = this.props;
    return !(isSellableSku === nextProps.isSellableSku);
  }

  render() {
    const {
      testID,
      isSellableSku,
    } = this.props;
    return (
      <div
        id="notifyMeBIS"
        data-tid={testID}
        data-oos-selected={!isSellableSku}
        ref={this.srNotifyMeRef}
        suppressHydrationWarning
      />
    );
  }
}

ShopRunnerNotifyMe.displayName = 'ShopRunnerNotifyMe';
ShopRunnerNotifyMe.propTypes = propTypes;
ShopRunnerNotifyMe.defaultProps = defaultProps;

export default ShopRunnerNotifyMe;
