export const NMMobileFallback = [
  {
    metadata: {
      tags: [],
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: '2kqx4czmzra1',
        },
      },
      id: 'rpP1jhX8FOqVoi5hDMX5D',
      type: 'Entry',
      createdAt: '2022-07-27T15:27:35.803Z',
      updatedAt: '2022-07-27T15:27:35.803Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment',
        },
      },
      revision: 1,
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'frame',
        },
      },
      locale: 'en-US',
    },
    fields: {
      name: "Women's Mobile Homepage: Fallback",
      metaData: {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: '7cJqVhvKU7spWzaox3VdpW',
        },
        contentType: 'metaData',
        fields: {
          name: 'Homepage: Fallback',
          slugs: [
            '/womens-evergreen',
            '/editorial/fallback-test',
          ],
        },
      },
      brand: 'NM',
      channel: [
        'Mobile',
      ],
      l1Layouts: [
        {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: '7fKuRYULSGs96aBSEzZ6f3',
          },
          contentType: 'l1Layout',
          fields: {
            name: "Women's Mobile Homepage: Fallback",
            placement: 'Main',
            l2Layout: [
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '3YAz2dh7sCdLxa7sYsxoCq',
                },
                contentType: 'l2Layout',
                fields: {
                  name: "Women's Mobile Homepage: Fallback Row 1 & 2",
                  l3Layout: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '2gssp2Ef7F6n9AGN4TWLhs',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage: Fallback Row 1',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4EGYiuAtYDzAcWJ8CXQZQI',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'Homepage: Fallback Row 1a',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1661798229/content/marketing/r_HP_Fallback_New_Arrivals_083122.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 135967,
                                  width: 1056,
                                  format: 'jpg',
                                  height: 528,
                                  context: {
                                    custom: {
                                      alt: 'Grand Entrance - Shop New Arrivals',
                                      caption: 'New Arrivals',
                                    },
                                  },
                                  version: 1661798229,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      'o6ubpongsfhteehpxwlf',
                                    ],
                                    channels: [
                                      'zux26btuerj5pm8cd76l',
                                    ],
                                    asset_type: 'jgjeeyocn9sjhgzofsvg',
                                    categories: [
                                      '0001',
                                    ],
                                    star_rating: '0001',
                                    campaign_types: [
                                      'lwlvaocntzgohrkdjuuf',
                                    ],
                                    retouch_status: '0001',
                                  },
                                  public_id: 'content/marketing/r_HP_Fallback_New_Arrivals_083122',
                                  created_at: '2022-08-29T18:37:09Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1661798229/content/marketing/r_HP_Fallback_New_Arrivals_083122.jpg',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1661798229/content/marketing/r_HP_Fallback_New_Arrivals_083122.jpg',
                                  resource_type: 'image',
                                  raw_transformation: 'f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1661798229/content/marketing/r_HP_Fallback_New_Arrivals_083122.jpg',
                                },
                              ],
                              mobileImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_3800,w_5792,y_3200/c_fill,dpr_2.0,g_xy_center,h_331,w_414,x_3795/f_auto/q_auto:good/v1660690183/content/creative/NUE_NODEPIC_060322_0007.tiff',
                                  tags: [
                                    'ALTERNATIVE EVENING 2023',
                                  ],
                                  type: 'upload',
                                  bytes: 92221960,
                                  width: 5792,
                                  format: 'tiff',
                                  height: 8688,
                                  context: {
                                    custom: {
                                      Vendors: 'Philosophy',
                                      caption: 'FALL 23 WAVE 1| ALTERNATIVE EVENING',
                                      'Art Director': 'Pamela Libonati',
                                      'Digital Tech': 'Guy Aroch',
                                      Photographer: 'Tom Schirmacher',
                                    },
                                  },
                                  version: 1660690183,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      'o6ubpongsfhteehpxwlf',
                                    ],
                                    asset_type: 'uymo684qvl8awfg52vni',
                                    categories: [
                                      '0001',
                                    ],
                                    shoot_date: '2022-06-03',
                                    composition: '0001',
                                    star_rating: 'wkkermich0rjr7exzmar',
                                    campaign_types: [
                                      'lwlvaocntzgohrkdjuuf',
                                    ],
                                    retouch_status: 'we2rathfmx9tfp0tefy6',
                                    expiration_date: '2023-06-03',
                                  },
                                  public_id: 'content/creative/NUE_NODEPIC_060322_0007',
                                  created_at: '2022-06-08T14:20:35Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_3800,w_5792,y_3200/c_fill,dpr_2.0,g_xy_center,h_331,w_414,x_3795/f_auto/q_auto:good/v1660690183/content/creative/NUE_NODEPIC_060322_0007.tiff',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1660690183/content/creative/NUE_NODEPIC_060322_0007.tiff',
                                  resource_type: 'image',
                                  raw_transformation: 'c_crop,g_xy_center,h_3800,w_5792,y_3200/c_fill,dpr_2.0,g_xy_center,h_331,w_414,x_3795/f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1660690183/content/creative/NUE_NODEPIC_060322_0007.tiff',
                                },
                              ],
                              linkTo: 'https://www.neimanmarcus.com/c/new-now-all-new-arrivals-cat84680809',
                              trackingTags: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '1K6PzWcZsmI2HqZQ8MtyX3',
                                  },
                                  contentType: 'trackingTags',
                                  fields: {
                                    name: 'New Arrivals',
                                    code: 'NA',
                                    type: 'Category',
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '57mSuMFejxVMLw9r5obk7e',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: 'Homepage: Fallback Row 1a',
                              desktopText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'heading-4',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'GRAND ENTRANCE',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'Let your inner confidence shine with the latest arrivals, exclusives & more',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.neimanmarcus.com/c/new-now-all-new-arrivals-cat84680809',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'SHOP NEW ARRIVALS',
                                            marks: [],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              buttonStyle: 'underlined',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '1HtMv1GP8Ti01N3yUKr7ey',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: "Women's Mobile Homepage: Fallback Row 2",
                        mobileWidth: '96',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '5Mt73YAPeBaXMEXiX3sHGh',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'Homepage: Fallback Row 1b',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1661798714/content/marketing/r_HP_Fallback_Designer_Handbags_083122.jpg',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 86918,
                                  width: 512,
                                  format: 'jpg',
                                  height: 528,
                                  context: {
                                    custom: {
                                      alt: 'Just-Landed Bags - Shop Designer Handbags',
                                      caption: 'Designer Handbags',
                                    },
                                  },
                                  version: 1661798714,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      'o6ubpongsfhteehpxwlf',
                                    ],
                                    channels: [
                                      'zux26btuerj5pm8cd76l',
                                    ],
                                    asset_type: 'jgjeeyocn9sjhgzofsvg',
                                    categories: [
                                      'r2byaqh6ppzzhofx3apm',
                                    ],
                                    star_rating: '0001',
                                    campaign_types: [
                                      'lwlvaocntzgohrkdjuuf',
                                    ],
                                    retouch_status: '0001',
                                  },
                                  public_id: 'content/marketing/r_HP_Fallback_Designer_Handbags_083122',
                                  created_at: '2022-08-29T18:45:14Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/f_auto/q_auto:good/v1661798714/content/marketing/r_HP_Fallback_Designer_Handbags_083122.jpg',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1661798714/content/marketing/r_HP_Fallback_Designer_Handbags_083122.jpg',
                                  resource_type: 'image',
                                  raw_transformation: 'f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1661798714/content/marketing/r_HP_Fallback_Designer_Handbags_083122.jpg',
                                },
                              ],
                              mobileImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_south,h_3800,w_3389/c_fill,dpr_2.0,h_465,w_372/f_auto/q_auto:good/v1659400183/content/creative/GAIA_2_NODEPIC_061022-001.tiff',
                                  tags: [
                                    'ALTERNATIVE EVENING 2023',
                                  ],
                                  type: 'upload',
                                  bytes: 49633676,
                                  width: 3389,
                                  format: 'tiff',
                                  height: 4236,
                                  context: {
                                    custom: {
                                      alt: 'Banu Mini Embellished Fringe Clutch',
                                      Vendors: 'Cult Gaia',
                                      caption: 'FALL 23 WAVE 1| ALTERNATIVE EVENING',
                                      'PIM Style': '4112790',
                                      Depictions: 'V54VU',
                                      'Art Director': 'Malcolm Hall',
                                      'Digital Tech': 'David Nix',
                                      Photographer: 'Jeff Junkin',
                                    },
                                  },
                                  version: 1659400183,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      'o6ubpongsfhteehpxwlf',
                                    ],
                                    channels: [
                                      'c6gkcb2srckettgdzfc8',
                                    ],
                                    asset_type: 'uymo684qvl8awfg52vni',
                                    categories: [
                                      'r2byaqh6ppzzhofx3apm',
                                    ],
                                    shoot_date: '2022-06-10',
                                    composition: 'jkvyc553vnyzofslenmd',
                                    star_rating: 'wkkermich0rjr7exzmar',
                                    campaign_types: [
                                      'lwlvaocntzgohrkdjuuf',
                                    ],
                                    retouch_status: 'we2rathfmx9tfp0tefy6',
                                    expiration_date: '2023-06-10',
                                  },
                                  public_id: 'content/creative/GAIA_2_NODEPIC_061022-001',
                                  created_at: '2022-06-10T20:38:44Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_south,h_3800,w_3389/c_fill,dpr_2.0,h_465,w_372/f_auto/q_auto:good/v1659400183/content/creative/GAIA_2_NODEPIC_061022-001.tiff',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1659400183/content/creative/GAIA_2_NODEPIC_061022-001.tiff',
                                  resource_type: 'image',
                                  raw_transformation: 'c_crop,g_south,h_3800,w_3389/c_fill,dpr_2.0,h_465,w_372/f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1659400183/content/creative/GAIA_2_NODEPIC_061022-001.tiff',
                                },
                              ],
                              linkTo: 'https://www.neimanmarcus.com/c/handbags-premier-designer-cat40720741',
                              trackingTags: [
                                {
                                  sys: {
                                    type: 'Link',
                                    linkType: 'Entry',
                                    id: '5xq10TSpy5Fx3E21MqpEDQ',
                                  },
                                  contentType: 'trackingTags',
                                  fields: {
                                    name: 'Handbags',
                                    code: 'HB',
                                    type: 'Category',
                                  },
                                },
                              ],
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '562Lz6X3bp7Z7rfSy332xd',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: "Women's Mobile Homepage: Fallback Row 2",
                              desktopText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'heading-4',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'JUST-LANDED BAGS',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.neimanmarcus.com/c/handbags-premier-designer-cat40720741',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'SHOP DESIGNER HANDBAGS',
                                            marks: [],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              buttonStyle: 'underlined',
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '25HsNx0ovdu8XGZ0zZPfWr',
                },
                contentType: 'l2Layout',
                fields: {
                  name: "Women's Mobile Homepage: Fallback Row 2a-c",
                  mobileWidth: '96',
                  l3Layout: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '5PTcPnVKcgUuVdlKpgXkL6',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage: Fallback Row 2a',
                        desktopWidth: '33',
                        mobileWidth: '96',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '7orFgMZ2kajvez2yMUSOjm',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'Homepage: Fallback Row 2a',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_north,h_6800,w_5891/c_fill,h_617,w_512/f_auto/q_auto:good/v1659462808/content/creative/SIMKHAI_NODEPIC_060122_0243.tiff',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 155780800,
                                  width: 5891,
                                  format: 'tiff',
                                  height: 8836,
                                  context: {
                                    custom: {
                                      caption: 'ALTERNATIVE EVENING',
                                      'Art Director': 'Pamela Libonati',
                                      Photographer: 'GUY AROCH',
                                    },
                                  },
                                  version: 1659462808,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      'o6ubpongsfhteehpxwlf',
                                    ],
                                    asset_type: 'uymo684qvl8awfg52vni',
                                    categories: [
                                      '0001',
                                    ],
                                    shoot_date: '2022-06-01',
                                    composition: '0001',
                                    star_rating: 'wkkermich0rjr7exzmar',
                                    campaign_types: [
                                      'lwlvaocntzgohrkdjuuf',
                                    ],
                                    retouch_status: 'we2rathfmx9tfp0tefy6',
                                    expiration_date: '2023-06-01',
                                  },
                                  public_id: 'content/creative/SIMKHAI_NODEPIC_060122_0243',
                                  created_at: '2022-06-08T14:32:23Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_north,h_6800,w_5891/c_fill,h_617,w_512/f_auto/q_auto:good/v1659462808/content/creative/SIMKHAI_NODEPIC_060122_0243.tiff',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1659462808/content/creative/SIMKHAI_NODEPIC_060122_0243.tiff',
                                  resource_type: 'image',
                                  raw_transformation: 'c_crop,g_north,h_6800,w_5891/c_fill,h_617,w_512/f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1659462808/content/creative/SIMKHAI_NODEPIC_060122_0243.tiff',
                                },
                              ],
                              mobileImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_north,h_7500,w_5891/c_fill,dpr_2.0,h_465,w_372/f_auto/q_auto:good/v1659462808/content/creative/SIMKHAI_NODEPIC_060122_0243.tiff',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 155780800,
                                  width: 5891,
                                  format: 'tiff',
                                  height: 8836,
                                  version: 1659462808,
                                  duration: null,
                                  metadata: {
                                    expiration_date: '2023-06-01',
                                    shoot_date: '2022-06-01',
                                    asset_type: 'uymo684qvl8awfg52vni',
                                    composition: '0001',
                                    retouch_status: 'we2rathfmx9tfp0tefy6',
                                    star_rating: 'wkkermich0rjr7exzmar',
                                    brands: [
                                      'o6ubpongsfhteehpxwlf',
                                    ],
                                    campaign_types: [
                                      'lwlvaocntzgohrkdjuuf',
                                    ],
                                    categories: [
                                      '0001',
                                    ],
                                  },
                                  context: {
                                    custom: {
                                      'Art Director': 'Pamela Libonati',
                                      caption: 'ALTERNATIVE EVENING',
                                      Photographer: 'GUY AROCH',
                                    },
                                  },
                                  public_id: 'content/creative/SIMKHAI_NODEPIC_060122_0243',
                                  created_at: '2022-06-08T14:32:23Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_north,h_7500,w_5891/c_fill,dpr_2.0,h_465,w_372/f_auto/q_auto:good/v1659462808/content/creative/SIMKHAI_NODEPIC_060122_0243.tiff',
                                  resource_type: 'image',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1659462808/content/creative/SIMKHAI_NODEPIC_060122_0243.tiff',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1659462808/content/creative/SIMKHAI_NODEPIC_060122_0243.tiff',
                                  raw_transformation: 'c_crop,g_north,h_7500,w_5891/c_fill,dpr_2.0,h_465,w_372/f_auto/q_auto:good',
                                },
                              ],
                              linkTo: 'https://www.neimanmarcus.com/c/womens-clothing-dresses-cat43810733',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '4oO91NfDaT2ZdR6GGpvnjN',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: 'Homepage: Fallback Row 2a',
                              desktopText: {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: 'DRESS TO EXPRESS',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'heading-5',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: 'Striking designs set these styles apart & secure your status as one-of-a-kind',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                      {
                                        data: {
                                          uri: 'https://www.neimanmarcus.com/c/womens-clothing-dresses-cat43810733',
                                        },
                                        content: [
                                          {
                                            data: {},
                                            marks: [],
                                            value: 'SHOP DRESSES',
                                            nodeType: 'text',
                                          },
                                        ],
                                        nodeType: 'hyperlink',
                                      },
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                ],
                                nodeType: 'document',
                              },
                              mobileText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'heading-4',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'DRESS TO EXPRESS',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.neimanmarcus.com/c/womens-clothing-dresses-cat43810733',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'SHOP DRESSES',
                                            marks: [],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              buttonStyle: 'underlined',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '678Xqha8Dq2K0MgE3lwpYs',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage: Fallback Row 2b',
                        desktopWidth: '33',
                        mobileWidth: '96',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '2chdxnEGp4EZFuLQbfzNCm',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'Homepage: Fallback Row 2b',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_4000,w_5316,y_3100/c_fill,g_xy_center,h_617,w_512,x_2300/f_auto/q_auto:good/v1659420014/content/creative/DACCORI_2_NODEPIC_060822_4932.tiff',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 121813052,
                                  width: 5316,
                                  format: 'tiff',
                                  height: 6645,
                                  context: {
                                    custom: {
                                      caption: 'FALL 2020 PLATFORMS ALT EVENING',
                                      'Art Director': 'Larry Oliver',
                                    },
                                  },
                                  version: 1659420014,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      'o6ubpongsfhteehpxwlf',
                                    ],
                                    asset_type: 'uymo684qvl8awfg52vni',
                                    categories: [
                                      'rho185vmgblznspgvdsa',
                                    ],
                                    shoot_date: '2022-06-08',
                                    composition: 'vcovhktak3itubr6zjpb',
                                    star_rating: 'wkkermich0rjr7exzmar',
                                    campaign_types: [
                                      'lwlvaocntzgohrkdjuuf',
                                    ],
                                    retouch_status: 'we2rathfmx9tfp0tefy6',
                                    expiration_date: '2023-06-08',
                                  },
                                  public_id: 'content/creative/DACCORI_2_NODEPIC_060822_4932',
                                  created_at: '2022-06-09T16:49:21Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_4000,w_5316,y_3100/c_fill,g_xy_center,h_617,w_512,x_2300/f_auto/q_auto:good/v1659420014/content/creative/DACCORI_2_NODEPIC_060822_4932.tiff',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1659420014/content/creative/DACCORI_2_NODEPIC_060822_4932.tiff',
                                  resource_type: 'image',
                                  raw_transformation: 'c_crop,g_xy_center,h_4000,w_5316,y_3100/c_fill,g_xy_center,h_617,w_512,x_2300/f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1659420014/content/creative/DACCORI_2_NODEPIC_060822_4932.tiff',
                                },
                              ],
                              mobileImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_4000,w_5316,y_3000/c_fill,dpr_2.0,g_xy_center,h_465,w_372,x_2261/f_auto/q_auto:good/v1659420014/content/creative/DACCORI_2_NODEPIC_060822_4932.tiff',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 121813052,
                                  width: 5316,
                                  format: 'tiff',
                                  height: 6645,
                                  version: 1659420014,
                                  duration: null,
                                  metadata: {
                                    expiration_date: '2023-06-08',
                                    shoot_date: '2022-06-08',
                                    asset_type: 'uymo684qvl8awfg52vni',
                                    composition: 'vcovhktak3itubr6zjpb',
                                    retouch_status: 'we2rathfmx9tfp0tefy6',
                                    star_rating: 'wkkermich0rjr7exzmar',
                                    brands: [
                                      'o6ubpongsfhteehpxwlf',
                                    ],
                                    campaign_types: [
                                      'lwlvaocntzgohrkdjuuf',
                                    ],
                                    categories: [
                                      'rho185vmgblznspgvdsa',
                                    ],
                                  },
                                  context: {
                                    custom: {
                                      'Art Director': 'Larry Oliver',
                                      caption: 'FALL 2020 PLATFORMS ALT EVENING',
                                    },
                                  },
                                  public_id: 'content/creative/DACCORI_2_NODEPIC_060822_4932',
                                  created_at: '2022-06-09T16:49:21Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_crop,g_xy_center,h_4000,w_5316,y_3000/c_fill,dpr_2.0,g_xy_center,h_465,w_372,x_2261/f_auto/q_auto:good/v1659420014/content/creative/DACCORI_2_NODEPIC_060822_4932.tiff',
                                  resource_type: 'image',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1659420014/content/creative/DACCORI_2_NODEPIC_060822_4932.tiff',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1659420014/content/creative/DACCORI_2_NODEPIC_060822_4932.tiff',
                                  raw_transformation: 'c_crop,g_xy_center,h_4000,w_5316,y_3000/c_fill,dpr_2.0,g_xy_center,h_465,w_372,x_2261/f_auto/q_auto:good',
                                },
                              ],
                              linkTo: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-pumps-heels-cat68670755',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '11OjAAh67nv9LC8OnkjVtv',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: 'Homepage: Fallback Row 2b',
                              desktopText: {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: 'ON THE RISE',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'heading-5',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: 'Sky-high platforms take your look to new heights, featuring Miu Miu',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                      {
                                        data: {
                                          uri: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-pumps-heels-cat68670755',
                                        },
                                        content: [
                                          {
                                            data: {},
                                            marks: [],
                                            value: 'SHOP HEELS',
                                            nodeType: 'text',
                                          },
                                        ],
                                        nodeType: 'hyperlink',
                                      },
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                ],
                                nodeType: 'document',
                              },
                              mobileText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'heading-4',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'ON THE RISE',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.neimanmarcus.com/c/shoes-all-designer-shoes-pumps-heels-cat68670755',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'SHOP HEELS',
                                            marks: [],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              buttonStyle: 'underlined',
                            },
                          },
                        ],
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: 'JNOW7hJ2x98k2RXg8zt9e',
                      },
                      contentType: 'l3Layout',
                      fields: {
                        name: 'Homepage: Fallback Row 2c',
                        desktopWidth: '33',
                        components: [
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '6jEn4TkkbAaGGpjqQatbdU',
                            },
                            contentType: 'imageContentAsset',
                            fields: {
                              name: 'Homepage: Fallback Row 2c',
                              parallaxShiftZoomBehavior: false,
                              desktopImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,g_north,h_617,w_512/f_auto/q_auto:good/v1659416209/content/creative/KORS_KHAITE_NODEPIC_060122_0203.tiff',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 167220736,
                                  width: 5873,
                                  format: 'tiff',
                                  height: 8804,
                                  context: {
                                    custom: {
                                      caption: 'FALL 2022 ALTERNATIVE EVENING',
                                      'Art Director': 'Pamela Libonati',
                                      Photographer: 'GUY AROCH',
                                    },
                                  },
                                  version: 1659416209,
                                  duration: null,
                                  metadata: {
                                    brands: [
                                      'o6ubpongsfhteehpxwlf',
                                    ],
                                    asset_type: 'uymo684qvl8awfg52vni',
                                    categories: [
                                      '0001',
                                    ],
                                    shoot_date: '2022-06-01',
                                    composition: '0001',
                                    star_rating: 'wkkermich0rjr7exzmar',
                                    campaign_types: [
                                      'lwlvaocntzgohrkdjuuf',
                                    ],
                                    retouch_status: 'we2rathfmx9tfp0tefy6',
                                    expiration_date: '2023-06-01',
                                  },
                                  public_id: 'content/creative/KORS_KHAITE_NODEPIC_060122_0203',
                                  created_at: '2022-06-08T13:55:52Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,g_north,h_617,w_512/f_auto/q_auto:good/v1659416209/content/creative/KORS_KHAITE_NODEPIC_060122_0203.tiff',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1659416209/content/creative/KORS_KHAITE_NODEPIC_060122_0203.tiff',
                                  resource_type: 'image',
                                  raw_transformation: 'c_fill,g_north,h_617,w_512/f_auto/q_auto:good',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1659416209/content/creative/KORS_KHAITE_NODEPIC_060122_0203.tiff',
                                },
                              ],
                              mobileImage: [
                                {
                                  url: 'http://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,g_north,h_465,w_372/f_auto/q_auto:good/v1659416209/content/creative/KORS_KHAITE_NODEPIC_060122_0203.tiff',
                                  tags: [],
                                  type: 'upload',
                                  bytes: 167220736,
                                  width: 5873,
                                  format: 'tiff',
                                  height: 8804,
                                  version: 1659416209,
                                  duration: null,
                                  metadata: {
                                    expiration_date: '2023-06-01',
                                    shoot_date: '2022-06-01',
                                    asset_type: 'uymo684qvl8awfg52vni',
                                    composition: '0001',
                                    retouch_status: 'we2rathfmx9tfp0tefy6',
                                    star_rating: 'wkkermich0rjr7exzmar',
                                    brands: [
                                      'o6ubpongsfhteehpxwlf',
                                    ],
                                    campaign_types: [
                                      'lwlvaocntzgohrkdjuuf',
                                    ],
                                    categories: [
                                      '0001',
                                    ],
                                  },
                                  context: {
                                    custom: {
                                      'Art Director': 'Pamela Libonati',
                                      caption: 'FALL 2022 ALTERNATIVE EVENING',
                                      Photographer: 'GUY AROCH',
                                    },
                                  },
                                  public_id: 'content/creative/KORS_KHAITE_NODEPIC_060122_0203',
                                  created_at: '2022-06-08T13:55:52Z',
                                  secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/c_fill,dpr_2.0,g_north,h_465,w_372/f_auto/q_auto:good/v1659416209/content/creative/KORS_KHAITE_NODEPIC_060122_0203.tiff',
                                  resource_type: 'image',
                                  original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1659416209/content/creative/KORS_KHAITE_NODEPIC_060122_0203.tiff',
                                  original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1659416209/content/creative/KORS_KHAITE_NODEPIC_060122_0203.tiff',
                                  raw_transformation: 'c_fill,dpr_2.0,g_north,h_465,w_372/f_auto/q_auto:good',
                                },
                              ],
                              linkTo: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-evening-wear-cat48730734',
                            },
                          },
                          {
                            sys: {
                              type: 'Link',
                              linkType: 'Entry',
                              id: '26N0H6us9V7SiEqlgt7b9p',
                            },
                            contentType: 'richTextContentAsset',
                            fields: {
                              name: 'Homepage: Fallback Row 2c',
                              desktopText: {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: 'ALL THAT GLITTERS',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'heading-5',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: 'Glam sequins, modern silhouettes & more for stepping into the spotlight',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                  {
                                    data: {},
                                    content: [
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                      {
                                        data: {
                                          uri: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-evening-wear-cat48730734',
                                        },
                                        content: [
                                          {
                                            data: {},
                                            marks: [],
                                            value: 'SHOP EVENING',
                                            nodeType: 'text',
                                          },
                                        ],
                                        nodeType: 'hyperlink',
                                      },
                                      {
                                        data: {},
                                        marks: [],
                                        value: '',
                                        nodeType: 'text',
                                      },
                                    ],
                                    nodeType: 'paragraph',
                                  },
                                ],
                                nodeType: 'document',
                              },
                              mobileText: {
                                nodeType: 'document',
                                data: {},
                                content: [
                                  {
                                    nodeType: 'heading-4',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: 'ALL THAT GLITTERS',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                  {
                                    nodeType: 'paragraph',
                                    data: {},
                                    content: [
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                      {
                                        nodeType: 'hyperlink',
                                        data: {
                                          uri: 'https://www.neimanmarcus.com/c/womens-clothing-clothing-evening-wear-cat48730734',
                                        },
                                        content: [
                                          {
                                            nodeType: 'text',
                                            value: 'SHOP EVENING',
                                            marks: [],
                                            data: {},
                                          },
                                        ],
                                      },
                                      {
                                        nodeType: 'text',
                                        value: '',
                                        marks: [],
                                        data: {},
                                      },
                                    ],
                                  },
                                ],
                              },
                              textJustification: 'Center',
                              buttonStyle: 'underlined',
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: '3Gtt4xnBFqu52LXlQD7oc5',
                },
                contentType: 'l2Layout',
                fields: {
                  name: 'Homepage: InCircle',
                  desktopWidth: '96',
                  mobileWidth: '100',
                  components: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '3bGAp8xR3nqcYOSwttc8Tp',
                      },
                      contentType: 'imageContentAsset',
                      fields: {
                        name: 'InCircle: Homepage',
                        desktopImage: [
                          {
                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/f_auto,q_auto/v1608744244/content/marketing/r_HP_InCircle_031620.png',
                            tags: [],
                            type: 'upload',
                            bytes: 10110,
                            width: 1540,
                            format: 'png',
                            height: 190,
                            context: {
                              custom: {
                                alt: 'InCircle the ultimate insider access. Get rewarded with the Neiman Marcus Credit Card',
                              },
                            },
                            version: 1608744244,
                            duration: null,
                            metadata: {
                              brands: [
                                'o6ubpongsfhteehpxwlf',
                              ],
                              channels: [
                                'zux26btuerj5pm8cd76l',
                              ],
                              asset_type: 'jgjeeyocn9sjhgzofsvg',
                              launch_date: '2020-03-16',
                              star_rating: '0001',
                              campaign_types: [
                                '0001',
                              ],
                              retouch_status: '0001',
                            },
                            public_id: 'content/marketing/r_HP_InCircle_031620',
                            created_at: '2020-12-23T17:24:04Z',
                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/f_auto,q_auto/v1608744244/content/marketing/r_HP_InCircle_031620.png',
                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1608744244/content/marketing/r_HP_InCircle_031620.png',
                            resource_type: 'image',
                            raw_transformation: 'f_auto,q_auto',
                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1608744244/content/marketing/r_HP_InCircle_031620.png',
                          },
                        ],
                        mobileImage: [
                          {
                            url: 'http://res.cloudinary.com/nmg-prod/image/upload/f_auto,q_auto/v1608744715/content/marketing/m_HP_InCircle_031620.png',
                            tags: [],
                            type: 'upload',
                            bytes: 10005,
                            width: 414,
                            format: 'png',
                            height: 279,
                            context: {
                              custom: {
                                alt: 'InCircle the ultimate insider access. Get rewarded with the Neiman Marcus Credit Card',
                              },
                            },
                            version: 1608744715,
                            duration: null,
                            metadata: {
                              brands: [
                                'o6ubpongsfhteehpxwlf',
                              ],
                              channels: [
                                'zux26btuerj5pm8cd76l',
                              ],
                              asset_type: 'jgjeeyocn9sjhgzofsvg',
                              launch_date: '2020-03-16',
                              star_rating: '0001',
                              campaign_types: [
                                '0001',
                              ],
                              retouch_status: '0001',
                            },
                            public_id: 'content/marketing/m_HP_InCircle_031620',
                            created_at: '2020-12-23T17:31:55Z',
                            secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/f_auto,q_auto/v1608744715/content/marketing/m_HP_InCircle_031620.png',
                            original_url: 'http://res.cloudinary.com/nmg-prod/image/upload/v1608744715/content/marketing/m_HP_InCircle_031620.png',
                            resource_type: 'image',
                            raw_transformation: 'f_auto,q_auto',
                            original_secure_url: 'https://res.cloudinary.com/nmg-prod/image/upload/v1608744715/content/marketing/m_HP_InCircle_031620.png',
                          },
                        ],
                        linkTo: 'https://www.incircle.com',
                      },
                    },
                  ],
                  verticalSpacingDesktop: '24',
                  trackingTags: [
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '6FpIIo6MNdebUezurO7Bx6',
                      },
                      contentType: 'trackingTags',
                      fields: {
                        name: 'Row 5',
                        code: 'R5',
                        type: '3rd Group',
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '3ebxn1L4ZRzd8jZ8VjFjY3',
                      },
                      contentType: 'trackingTags',
                      fields: {
                        name: 'Evergreen',
                        code: 'EG',
                        type: 'Campaign Type',
                      },
                    },
                    {
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: '7fRUUsfnbwcqU0YGwD6md9',
                      },
                      contentType: 'trackingTags',
                      fields: {
                        name: 'InCircle 080921',
                        code: 'InCircle_080921',
                        type: 'Campaign Name',
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
    contentType: 'frame',
  },
];
