export const DEFAULT_PAGE = 1;
export const DEFAULT_SORT_OPTION = 'NEWEST_FIRST';
export const BEST_MATCH = 'BEST_MATCH';
export const DEFAULT_REQUEST_OPTIONS = {
  page: DEFAULT_PAGE,
  sortBy: '',
};
export const PAGE_SIZE = 120;
export const SWATCHES_LIMIT_MOBILE = 3;
export const SWATCHES_LIMIT_TABLET = 4;
export const SWATCHES_LIMIT_DESKTOP = 7;
export const SWATCHES_LIMIT_DESKTOP_MODERN = 8;
export const SHOW_MORE_COLORS_AVAILABLE_TEXT = 'More Colors Available';
export const ONLY_AT_NM_TEXT = 'Exclusive';
export const MONOGRAM_TEXT = 'Monogram';
export const SOLD_OUT_TEXT = 'SOLD OUT';
export const BRAND_CODE = 'nm';
export const MY_FAVORITES = 'MY_FAVORITES';
export const FAVORITES_NOT_AVAILABLE_MESSAGE = "Sorry, you don't have any favorite items here";
export const PRODUCT_LIST_FILTER_OPTIONS_EMPTY_MESSAGE = 'Sorry, your last search produced no results. Please adjust your selections and try again.';
export const INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE = 'We currently have no results for this search. Try changing the city, state or zip.';
export const PROMOTION_TYPE_113 = '113';
export const IN_STORE = 'In Store';
export const DESIGNER = 'Designer';
export const SIZE = 'Size';
export const CATEGORY = 'Category';
export const COLOR = 'Color';
