/* eslint-disable no-param-reassign */
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';

export function getParams(location) {
  return location ? qs.parse(location.search) : '';
}

export function updateParams(router, params) {
  const location = router.getCurrentLocation();
  const search = qs.parse(location.search);
  return router.push({
    pathname: location.pathname,
    search: `?${qs.stringify({ ...search, ...params })}`,
  });
}

function cleanParams(param, search) {
  const savedFacet = Object.keys(param)[0];
  const searchKeys = Object.keys(search);
  searchKeys.forEach((key) => {
    if (key === savedFacet) {
      delete search[key];
    }
  });
}

export function updateParamsWithSEO(
  router,
  firstParam,
  secondParam,
  params,
  savedFirstParam,
  savedSecondParam,
  isDeselectFirst,
  isDeselectSecond
) {
  const location = router.getCurrentLocation();
  const searchString = location.search;
  const search = qs.parse(searchString);
  let firstParamString = `${qs.stringify({ ...savedFirstParam })}`;
  let secondParamString = `${qs.stringify({ ...savedSecondParam })}`;
  const isDeselect = isDeselectFirst || isDeselectSecond;
  let forcedParams = '';


  if (!isEmpty(savedFirstParam) && !isEmpty(savedSecondParam) && !isDeselect) {
    forcedParams = `${firstParamString}&${secondParamString}&`;
    cleanParams(savedFirstParam, search);
    cleanParams(savedSecondParam, search);
  } else {
    if (isDeselectFirst) {
      cleanParams(firstParam, search);
      cleanParams(savedSecondParam, search);
      if (isEmpty(savedSecondParam)) {
        forcedParams = '';
      } else {
        forcedParams = `${secondParamString}&`;
      }
    } else if (!isEmpty(savedFirstParam)) {
      cleanParams(savedFirstParam, search);
      forcedParams += `${firstParamString}&`;
    } else {
      firstParamString = `${qs.stringify({ ...firstParam })}`;
      if (!isEmpty(firstParamString)) {
        forcedParams += `${firstParamString}&`;
      }
    }
    if (isDeselectSecond) {
      cleanParams(secondParam, search);
      cleanParams(savedFirstParam, search);
      if (isEmpty(savedFirstParam)) {
        forcedParams = '';
      } else {
        forcedParams = `${firstParamString}&`;
      }
    } else if (!isEmpty(savedSecondParam)) {
      cleanParams(savedSecondParam, search);
      forcedParams += `${secondParamString}&`;
    } else {
      secondParamString = `${qs.stringify({ ...secondParam })}`;
      if (!isEmpty(secondParamString)) {
        forcedParams += `${secondParamString}&`;
      }
    }
  }

  return router.push({
    pathname: location.pathname,
    search: `?${
      forcedParams
      + qs.stringify({
        ...search,
        ...params,
      })}`,
  });
}

export function clearAllParams(router, firstParamKey, secondParamKey) {
  const location = router.getCurrentLocation();
  const params = { filterOptions: null, page: 1 };
  const search = qs.parse(location.search);
  delete search[firstParamKey];
  delete search[secondParamKey];

  return router.push({
    pathname: location.pathname,
    search: `?${
      qs.stringify({
        ...search,
        ...params,
      })}`,
  });
}

export function clearParamsForMultiSelect(router, paramKey, params) {
  const location = router.getCurrentLocation();
  const search = qs.parse(location.search);
  delete search[paramKey];
  return router.push({
    pathname: location.pathname,
    search: `?${qs.stringify({ ...search, ...params })}`,
  });
}

export function updateParamsWithStoreName(router, params, storeName) {
  const location = router.getCurrentLocation();
  const search = qs.parse(location.search);
  return router.push({
    pathname: location.pathname,
    search: `?${qs.stringify({ ...search, ...params, storeName })}`,
  });
}

export function setParams(router, params) {
  const location = router.getCurrentLocation();
  return router.push({
    pathname: location.pathname,
    search: `?${qs.stringify({ ...params })}`,
  });
}
