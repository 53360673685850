import get from 'lodash/get';

export const CLOUDINARY_IMG_NOT_AVAILABLE = 'https://media.neimanmarcus.com/image/upload/f_auto,q_auto:low,ar_4:5,c_fill,dpr_2.0,w_500/v1598369085/NM-no-image-vnt_error';
export const HC_CLOUDINARY_IMG_NOT_AVAILABLE = 'https://media.horchow.com/image/upload/f_auto,q_auto:low,ar_4:5,c_fill,dpr_2.0,w_500/v1598369085/HC-no-image-vnt_error';
export const NO_IMAGE_AVAILABLE_CDN = 'https://neimanmarcus.scene7.com/is/image/NeimanMarcus/vnt_error?&wid=456&height=570';
export const HC_NO_IMAGE_AVAILABLE_LARGE_CDN = 'https://media.horchow.com/image/upload/f_auto,q_auto:low,ar_4:5,c_fill,dpr_2.0,w_500/v1598369085/HC-no-image-vnt_error';
export const HC_NO_IMAGE_AVAILABLE_CDN = 'https://media.horchow.com/image/upload/f_auto,q_auto:low,ar_4:5,c_fill,dpr_2.0,w_500/v1598369085/HC-no-image-vnt_error';
export const NO_IMAGE_AVAILABLE_LARGE_CDN = 'https://neimanmarcus.scene7.com/is/image/NeimanMarcus/vnt_error?&wid=700&height=800';

// eslint-disable-next-line
export const getNotFoundImageLarge = (brand) => {
  if (brand === 'HC') {
    return HC_CLOUDINARY_IMG_NOT_AVAILABLE;
  }
  return CLOUDINARY_IMG_NOT_AVAILABLE;
};

// eslint-disable-next-line
export const getNotFoundImage = (brand) => {
  if (brand === 'HC') {
    return HC_CLOUDINARY_IMG_NOT_AVAILABLE;
  }
  return CLOUDINARY_IMG_NOT_AVAILABLE;
};

// eslint-disable-next-line
export const shouldUseCloudinaryImage = (imageSet) => {
  return get(imageSet, 'dynamic.url', '')?.length > 0;
};

export const getCloudinaryImageWithFixedWidth = (
  url,
  width,
  defaultUrl,
) => {
  const dprValue = '2';
  if (url && url.indexOf('/f_auto,q_auto') !== -1) {
    const urlWidth = url.indexOf(',w_') !== -1;
    const urlDPR = url.indexOf(',dpr_') !== -1;
    if (urlWidth) {
      if (urlDPR) {
        return url.replace(/,w_\d+/g, `,w_${width}`)
          .replace(/,dpr_\d+/g, `,dpr_${dprValue}`);
      }
      return url.replace(/,w_\d+/g, `,w_${width}`);
    }
    return url.replace('/f_auto,q_auto',
      `/f_auto,q_auto:low,ar_4:5,c_fill,dpr_${dprValue}.0,w_${width}`);
  }
  return defaultUrl || (url || '');
};

const ImageConfig = {
  thumbnail: { width: 75 },
  medium: { width: 456 },
  large: { width: 1200 },
};

export const getResizedCloudinaryImage = (imageSet, size) => {
  // eslint-disable-next-line
  const width = ImageConfig[size] ? ImageConfig[size].width : ImageConfig.medium.width;
  const { url } = imageSet.dynamic;
  return getCloudinaryImageWithFixedWidth(url, width);
};

export const getCloudinaryImageSetIfEnabled = (imageSet) => {
  if (shouldUseCloudinaryImage(imageSet)) {
    const { url } = imageSet.dynamic;

    return {
      ...imageSet,
      thumbnail: {
        tag: imageSet?.thumbnail?.tag || 'x0',
        // eslint-disable-next-line
        url: getCloudinaryImageWithFixedWidth(url, ImageConfig.thumbnail.width, imageSet?.thumbnail?.url),
      },
      medium: {
        tag: imageSet?.medium?.tag || 'y0',
        // eslint-disable-next-line
        url: getCloudinaryImageWithFixedWidth(url, ImageConfig.medium.width, imageSet?.medium?.url),
      },
      large: {
        tag: imageSet?.large?.tag || 'z0',
        // eslint-disable-next-line
        url: getCloudinaryImageWithFixedWidth(url, ImageConfig.large.width, imageSet?.large?.url),
      },
    };
  }
  return imageSet;
};
