import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

export default (productState, selectedColorIndex) => {
  const skuMatrix = get(productState, 'options.optionMatrix', []);
  const skus = get(productState, 'skus');

  if (isEmpty(skuMatrix) || selectedColorIndex < 0) {
    return [];
  }

  return skuMatrix.map((skuArr) => (
    find(skus, ['id', skuArr[selectedColorIndex]])
  ));
};
