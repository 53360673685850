import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  testID: PropTypes.string,
};

const defaultProps = {
  testID: 'ShopRunnerBackInStock',
};

class ShopRunnerBackInStock extends Component {
  constructor(props) {
    super(props);
    this.srBackInStockRef = createRef();
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      testID,
    } = this.props;
    return (
      <div
        id="shopRunnerBISTest"
        ref={this.srBackInStockRef}
        suppressHydrationWarning
        data-tid={testID}
      />
    );
  }
}

ShopRunnerBackInStock.displayName = 'ShopRunnerBackInStock';
ShopRunnerBackInStock.propTypes = propTypes;
ShopRunnerBackInStock.defaultProps = defaultProps;

export default ShopRunnerBackInStock;
