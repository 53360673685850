import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import { TOGGLE_FAVORITE } from 'pdp/pages/ProductPage/actions';
import { RESOLVED_FAVORITE } from 'plp/components/ProductListPage/actions';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  types as SrpTypes,
  RESOLVED_SEARCH_LIST,
  REJECTED_SEARCH_LIST,
  SET_SEARCH_CATEGORY_CRUMB,
  RESOLVED_NULL_SEARCH_LIST,
  REJECTED_NULL_SEARCH_LIST,
  RESOLVED_NULL_SEARCH_FOOTER,
  REJECTED_NULL_SEARCH_FOOTER,
  RESOLVED_SEARCH_GRAPHIC_HEADER,
  REJECTED_SEARCH_GRAPHIC_HEADER,
  SELECT_FILTER_OPTION_SRP,
  CLEAR_ALL_FILTER_OPTION,
  SELECT_FILTER_BY_ROUTE_PARAM_SRP,
  RESOLVED_TOP_PRODUCTS,
  REJECTED_TOP_PRODUCTS,
  SET_GENDER_TA,
} from 'srp/actions/actions';
import { getValidGender } from 'client-utils/utilities-gender';

const STATUS_FAVORITE = 'favorite';
export const defaultState = {
  list: [],
  gender: 'W',
  suggestOtherGender: false,
  total: 0,
  sortOptions: [],
  facetedFiltersList: {},
  selectedSortOption: '',
  keywordRedirect: {},
  currentCategoryCrumb: '',
  nullSearchResult: [],
  topProducts: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_SEARCH_LIST: {
      const total = state.total ? state.total : action.payload.total;
      const assetUrl = get(action.payload, 'graphicHeader.assetUrl', '');
      const graphicHeader = {
        ...state.graphicHeader,
        assetUrl,
      };

      let filterOptionsParsed;
      let categorySelected;

      if (action.params.filterOptions) {
        filterOptionsParsed = JSON.parse(action.params.filterOptions);
      }

      if (filterOptionsParsed) {
        categorySelected = get(filterOptionsParsed, 'Category');
      }

      const facetedFiltersListInit = {
        ...categorySelected && { Category: categorySelected },
      };

      action.payload.applicableFilters
      && action.payload.applicableFilters.forEach((facetOption) => {
        const { facetsOrderList, facets } = facetOption;

        let validFacetsList = [];

        facetedFiltersListInit[facetOption.filterKey] = {};
        validFacetsList = filter(facetsOrderList, (facetKey) => !isNil(facets[facetKey]));

        if (facetOption.filterKey === 'In Store') {
          validFacetsList = ['sdd', 'ndd', 'csp'];
        }

        validFacetsList.forEach((facet) => {
          facetedFiltersListInit[facetOption.filterKey][facet] = false;

          if (state.facetedFiltersList[facetOption.filterKey]
            && state.facetedFiltersList[facetOption.filterKey][facet] === true) {
            facetedFiltersListInit[facetOption.filterKey][facet] = true;
          }
        });

        return true;
      });

      return {
        ...state,
        list: action.payload.products,
        gender: action.payload.gender,
        suggestOtherGender: action.payload.suggestOtherGender,
        total,
        filteredTotal: action.payload.total,
        params: action.params,
        href: action.href,
        facetedFiltersList: facetedFiltersListInit,
        applicableFilters: action.payload.applicableFilters,
        leftNav: action.payload.leftNav,
        mobileNav: action.payload.mobileNav,
        sortOptions: action.payload.sortOptions,
        selectedSortOption: action.payload.selectedSortOption,
        keywordRedirect: action.payload.keywordRedirect,
        graphicHeader,
        auto: action.payload.auto,
        rlx: action.payload.rlx,
      };
    }

    case REJECTED_SEARCH_LIST:
      return {
        ...defaultState,
        results: action.payload,
        params: action.params,
        href: action.href,
      };

    case SET_SEARCH_CATEGORY_CRUMB: {
      return {
        ...state,
        currentCategoryCrumb: action.payload,
      };
    }

    case RESOLVED_FAVORITE: {
      const index = findIndex(state.list, { id: action.data.productId });
      const productList = state.list;
      const favoriteProduct = productList[index];
      productList[index] = {
        ...favoriteProduct,
        isFavorite: action.data.utagData.favoriteItemStatus === STATUS_FAVORITE,
      };
      return {
        ...state,
        list: [...productList],
      };
    }

    case TOGGLE_FAVORITE: {
      const index = findIndex(state.list, { id: action.productId });
      const productList = state.list;
      const favoriteProduct = productList[index];
      productList[index] = {
        ...favoriteProduct,
        isFavorite: action.payload === STATUS_FAVORITE,
      };
      return {
        ...state,
        list: [...productList],
      };
    }

    case RESOLVED_NULL_SEARCH_LIST: {
      return {
        ...state,
        nullSearchResult: action.payload,
      };
    }

    case REJECTED_NULL_SEARCH_LIST:
      return { ...state, nullSearchResult: [] };

    case RESOLVED_NULL_SEARCH_FOOTER: {
      return {
        ...state,
        nullSearchFooter: action.payload,
      };
    }

    case REJECTED_NULL_SEARCH_FOOTER: {
      return {
        ...state,
        nullSearchFooter: '',
      };
    }

    case RESOLVED_SEARCH_GRAPHIC_HEADER: {
      const graphicHeader = { ...state.graphicHeader, graphicHeaderContent: action.payload };
      return {
        ...state,
        graphicHeader,
      };
    }

    case REJECTED_SEARCH_GRAPHIC_HEADER: {
      const graphicHeader = { ...state.graphicHeader, graphicHeaderContent: '' };
      return {
        ...state,
        graphicHeader,
      };
    }

    case RESOLVED_TOP_PRODUCTS: {
      return {
        ...state,
        topProducts: action.payload,
      };
    }

    case REJECTED_TOP_PRODUCTS: {
      return {
        ...state,
        topProducts: action.payload,
      };
    }

    case SELECT_FILTER_OPTION_SRP: {
      if (!state.facetedFiltersList) return state;
      const { selectedFilterKey, selectedFilterOptionKey } = action.payload;
      const IN_STORE = 'In Store';

      if (selectedFilterKey === IN_STORE) {
        if (selectedFilterOptionKey === 'csp') {
          const facetedFilterClone = {
            ...state.facetedFiltersList[IN_STORE],
            sdd: false,
            ndd: false,
            csp: !state.facetedFiltersList[IN_STORE].csp,
          };

          return {
            ...state,
            storeChange: false,
            facetedFiltersList: {
              ...state.facetedFiltersList,
              [IN_STORE]: facetedFilterClone,
            },
          };
        }

        if (selectedFilterOptionKey === 'sdd') {
          const facetedFilterClone = {
            ...state.facetedFiltersList[IN_STORE],
            sdd: !state.facetedFiltersList[IN_STORE].sdd,
            csp: false,
          };

          return {
            ...state,
            storeChange: false,
            facetedFiltersList: {
              ...state.facetedFiltersList,
              [IN_STORE]: facetedFilterClone,
            },
          };
        }

        if (selectedFilterOptionKey === 'ndd') {
          const facetedFilterClone = {
            ...state.facetedFiltersList[IN_STORE],
            ndd: !state.facetedFiltersList[IN_STORE].ndd,
            csp: false,
          };

          return {
            ...state,
            storeChange: false,
            facetedFiltersList: {
              ...state.facetedFiltersList,
              [IN_STORE]: facetedFilterClone,
            },
          };
        }

        if (selectedFilterOptionKey === 'zipChange') {
          const facetedFilterClone = {
            ...state.facetedFiltersList[IN_STORE],
            ndd: false,
            sdd: false,
            csp: false,
          };

          return {
            ...state,
            storeChange: false,
            facetedFiltersList: {
              ...state.facetedFiltersList,
              [IN_STORE]: facetedFilterClone,
            },
          };
        }

        if (selectedFilterOptionKey === 'storeChange') {
          const facetedFilterClone = {
            ...state.facetedFiltersList[IN_STORE],
            ndd: false,
            sdd: false,
            csp: true,
          };

          return {
            ...state,
            storeChange: true,
            facetedFiltersList: {
              ...state.facetedFiltersList,
              [IN_STORE]: facetedFilterClone,
            },
          };
        }
      }

      const facetedFilterClone = {
        ...state.facetedFiltersList[selectedFilterKey],
        [selectedFilterOptionKey]:
        !state.facetedFiltersList[selectedFilterKey][selectedFilterOptionKey],
      };

      const facetedFiltersListClone = {
        ...state.facetedFiltersList,
        [selectedFilterKey]: facetedFilterClone,
      };

      return {
        ...state,
        designerSearchText: '',
        facetedFiltersList: facetedFiltersListClone,
      };
    }

    case CLEAR_ALL_FILTER_OPTION: {
      if (!state.facetedFiltersList) return state;
      const facetedFiltersListClone = {};

      Object.keys(state.facetedFiltersList).forEach((facetKey) => {
        facetedFiltersListClone[facetKey] = mapValues(
          facetedFiltersListClone[facetKey], () => false
        );
      });

      return {
        ...state,
        selectedFilterKey: '',
        designerSearchText: '',
        facetedFiltersList: facetedFiltersListClone,
      };
    }

    case SET_GENDER_TA: {
      return {
        ...state,
        ta_gender: getValidGender(action.payload.gender),
      };
    }

    case LOCATION_CHANGE:
    case SELECT_FILTER_BY_ROUTE_PARAM_SRP: {
      if (!state.facetedFiltersList) return state;
      let filterOptions = {};

      if (action.routeParam && action.routeParam.filterOptions) {
        filterOptions = JSON.parse(action.routeParam.filterOptions);
      } else if (action.payload) {
        filterOptions = get(action, 'payload.query.filterOptions', '');
        if (filterOptions !== '') {
          filterOptions = JSON.parse(filterOptions);
        } else { filterOptions = {}; }
      }

      if (isEmpty(filterOptions)) {
        return {
          ...state,
          facetedFiltersList: {
            ...state.facetedFiltersList,
          },
        };
      }

      const facetedFilterClone = { ...state.facetedFiltersList };
      const filterClone = {};
      const address = state.address;
      const radius = state.radius;

      Object.keys(filterOptions).forEach((key) => {
        if (filterOptions[key].length > 0) {
          const selectedFilters = {};
          filterOptions[key].forEach((filterKey) => {
            if (facetedFilterClone[key]
                && facetedFilterClone[key][filterKey] !== undefined) {
              selectedFilters[filterKey] = true;
            }
          });
          filterClone[key] = { ...facetedFilterClone[key], ...selectedFilters };
        }
      });

      return {
        ...state,
        facetedFiltersList: { ...state.facetedFiltersList, ...filterClone },
        address: (state.inStoreFilterError) ? state.address : address,
        radius: (state.inStoreFilterError) ? state.radius : radius,
      };
    }

    default:
      return state;
  }
};


export const currentPage = (selectedPage = 1, { type, page }) => {
  return type === SrpTypes.SET_SRP_PAGE
    ? (parseInt(page, 10) || 1)
    : (parseInt(selectedPage, 10) || 1);
};

export const selectedSortBy = (selectedSortBy = '', { type, sortBy = '' }) => {
  return type === SrpTypes.SET_SRP_SORT_BY ? sortBy : selectedSortBy;
};

export const selectedFilterOptions = (selectedFilterOptions = {}, action) => {
  return action.type === SrpTypes.SET_SRP_FILTER_OPTIONS
    ? action.selectedFilterOptions
    : selectedFilterOptions;
};
