const getLinkDomain = () => {
  if (typeof window !== 'undefined') {
    if (
      window.location.hostname.includes('localhost')
        || window.location.hostname.includes('dev-int-stores')
    ) {
      return 'https://devint.neimanmarcus.com';
    }
    if (window.location.hostname.includes('dev-int2-stores')) {
      return 'https://devint2.neimanmarcus.com';
    }
    const hostarray = window.location.hostname.split('.');
    if (hostarray.length > 2) {
      return `https://${hostarray[0]}.neimanmarcus.com`;
    }
    return 'www.neimanmarcus.com';
  }
  return '';
};

export default getLinkDomain;
