import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import React from 'react';
import { ECMParams } from 'ecm/constants';

export function ecmHasPadding(objPadding) {
  const arrPadding = [get(objPadding, 'padding-top') === null || isEmpty(get(objPadding, 'padding-top')) ? 0 : get(objPadding, 'padding-top'),
    get(objPadding, 'padding-right') === null || isEmpty(get(objPadding, 'padding-right')) ? 0 : get(objPadding, 'padding-right'),
    get(objPadding, 'padding-bottom') === null || isEmpty(get(objPadding, 'padding-bottom')) ? 0 : get(objPadding, 'padding-bottom'),
    get(objPadding, 'padding-left') === null || isEmpty(get(objPadding, 'padding-left')) ? 0 : get(objPadding, 'padding-left')];
  return arrPadding.reduce((a, b) => a + b) > 0;
}

export function ecmParsePadding(objPadding) {
  const arrPadding = [];
  arrPadding.push(`padding-top:${get(objPadding, 'padding-top') === null || isEmpty(get(objPadding, 'padding-top')) ? 0 : get(objPadding, 'padding-top')}px !important;`);
  arrPadding.push(`padding-right:${get(objPadding, 'padding-right') === null || isEmpty(get(objPadding, 'padding-right')) ? 0 : get(objPadding, 'padding-right')}px !important;`);
  arrPadding.push(`padding-bottom:${get(objPadding, 'padding-bottom') === null || isEmpty(get(objPadding, 'padding-bottom')) ? 0 : get(objPadding, 'padding-bottom')}px !important;`);
  arrPadding.push(`padding-left:${get(objPadding, 'padding-left') === null || isEmpty(get(objPadding, 'padding-left')) ? 0 : get(objPadding, 'padding-left')}px !important;`);
  return arrPadding.join('');
}

export function ecmHasMargin(objMargin) {
  const arrMargin = [get(objMargin, 'margin-top') === null || isEmpty(get(objMargin, 'margin-top')) ? 0 : get(objMargin, 'margin-top'),
    get(objMargin, 'margin-right') === null || isEmpty(get(objMargin, 'margin-right')) ? 0 : get(objMargin, 'margin-right'),
    get(objMargin, 'margin-bottom') === null || isEmpty(get(objMargin, 'margin-bottom')) ? 0 : get(objMargin, 'margin-bottom'),
    get(objMargin, 'margin-left') === null || isEmpty(get(objMargin, 'margin-left')) ? 0 : get(objMargin, 'margin-left')];
  return arrMargin.reduce((a, b) => a + b) > 0;
}

export function ecmParseMargin(objMargin) {
  const arrMargin = [];
  arrMargin.push(`margin-top:${get(objMargin, 'margin-top') === null || isEmpty(get(objMargin, 'margin-top')) ? 0 : get(objMargin, 'margin-top')}px;`);
  arrMargin.push(`margin-right:${get(objMargin, 'margin-right') === null || isEmpty(get(objMargin, 'margin-right')) ? 0 : get(objMargin, 'margin-right')}px;`);
  arrMargin.push(`margin-bottom:${get(objMargin, 'margin-bottom') === null || isEmpty(get(objMargin, 'margin-bottom')) ? 0 : get(objMargin, 'margin-bottom')}px;`);
  arrMargin.push(`margin-left:${get(objMargin, 'margin-left') === null || isEmpty(get(objMargin, 'margin-left')) ? 0 : get(objMargin, 'margin-left')}px;`);
  return arrMargin.join('');
}

export function ecmHasBorder(objBorder) {
  const arrBorder = [get(objBorder, 'border-top-width') === null || isEmpty(get(objBorder, 'border-top-width')) ? 0 : get(objBorder, 'border-top-width'),
    get(objBorder, 'border-right-width') === null || isEmpty(get(objBorder, 'border-right-width')) ? 0 : get(objBorder, 'border-right-width'),
    get(objBorder, 'border-bottom-width') === null || isEmpty(get(objBorder, 'border-bottom-width')) ? 0 : get(objBorder, 'border-bottom-width'),
    get(objBorder, 'border-left-width') === null || isEmpty(get(objBorder, 'border-left-width')) ? 0 : get(objBorder, 'border-left-width')];
  return ((arrBorder.reduce((a, b) => a + b) > 0) || !(get(objBorder, 'border-color') === null || isEmpty(get(objBorder, 'border-color'))));
}

export function ecmParseBorder(objBorder) {
  const arrBorder = [];
  arrBorder.push(`border-top-width:${get(objBorder, 'border-top-width') === null || isEmpty(get(objBorder, 'border-top-width')) ? 0 : get(objBorder, 'border-top-width')}px;`);
  arrBorder.push(`border-right-width:${get(objBorder, 'border-right-width') === null || isEmpty(get(objBorder, 'border-right-width')) ? 0 : get(objBorder, 'border-right-width')}px;`);
  arrBorder.push(`border-bottom-width:${get(objBorder, 'border-bottom-width') === null || isEmpty(get(objBorder, 'border-bottom-width')) ? 0 : get(objBorder, 'border-bottom-width')}px;`);
  arrBorder.push(`border-left-width:${get(objBorder, 'border-left-width') === null || isEmpty(get(objBorder, 'border-left-width')) ? 0 : get(objBorder, 'border-left-width')}px;`);
  arrBorder.push(get(objBorder, 'border-color') === null || isEmpty(get(objBorder, 'border-color')) ? 'border-color:#000000;' : `border-color:${get(objBorder, 'border-color')};`);
  arrBorder.push('border-style:solid;');
  return arrBorder.join('');
}

export function ecmIsHidden(objHidden) {
  return (get(objHidden, 'hide', false));
}

export function ecmGetAdaptiveStyleBlock(objData, ecmElementClass) {
  if (ecmHasPadding(get(objData, 'desktop', {})) || ecmHasPadding(get(objData, 'tablet', {})) || ecmHasPadding(get(objData, 'mobile', {}))
    || ecmHasMargin(get(objData, 'desktop', {})) || ecmHasMargin(get(objData, 'tablet', {})) || ecmHasMargin(get(objData, 'mobile', {}))
    || ecmHasBorder(get(objData, 'desktop', {})) || ecmHasBorder(get(objData, 'tablet', {})) || ecmHasBorder(get(objData, 'mobile', {}))
    || ecmIsHidden(get(objData, 'desktop', {})) || ecmIsHidden(get(objData, 'tablet', {})) || ecmIsHidden(get(objData, 'mobile', {}))) {
    const desktopPadding = ecmParsePadding(get(objData, 'desktop', {}));
    const tabletPadding = ecmParsePadding(get(objData, 'tablet', {}));
    const mobilePadding = ecmParsePadding(get(objData, 'mobile', {}));
    const desktopMargin = ecmParseMargin(get(objData, 'desktop', {}));
    const tabletMargin = ecmParseMargin(get(objData, 'tablet', {}));
    const mobileMargin = ecmParseMargin(get(objData, 'mobile', {}));
    const desktopBorder = ecmParseBorder(get(objData, 'desktop', {}));
    const tabletBorder = ecmParseBorder(get(objData, 'tablet', {}));
    const mobileBorder = ecmParseBorder(get(objData, 'mobile', {}));
    const desktopHidden = ecmIsHidden(get(objData, 'desktop', {})) ? 'display:none;' : '';
    const tabletHidden = ecmIsHidden(get(objData, 'tablet', {})) ? 'display:none;' : '';
    const mobileHidden = ecmIsHidden(get(objData, 'mobile', {})) ? 'display:none;' : '';
    const adaptiveStyle = `
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .${ecmElementClass} { ${mobileHidden}${mobilePadding}${mobileMargin}${mobileBorder} }
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .${ecmElementClass} { ${tabletHidden}${tabletPadding}${tabletMargin}${tabletBorder} }
      }
      @media screen and (min-width: 1025px) {
        .${ecmElementClass} { ${desktopHidden}${desktopPadding}${desktopMargin}${desktopBorder} }
      }`;
    return (<style dangerouslySetInnerHTML={{ __html: adaptiveStyle }} />);
  }
  return ('');
}

export function ecmGetImageWidthStyleBlock(objData, ecmElementClass, fullDataObj) {
  if (objData.desktop.imageWidth !== 50
    || objData.tablet.imageWidth !== 50
    || objData.mobile.imageWidth !== 50) {
    const tabletText = get(fullDataObj, 'text.properties.tabletText') === null || isEmpty(get(fullDataObj, 'text.properties.tabletText')) ? '' : get(fullDataObj, 'text.properties.tabletText');
    const mobileText = get(fullDataObj, 'text.properties.mobileText') === null || isEmpty(get(fullDataObj, 'text.properties.mobileText')) ? '' : get(fullDataObj, 'text.properties.mobileText');
    const desktopTextWidth = 100 - objData.desktop.imageWidth;
    const tabletTextWidth = 100 - objData.tablet.imageWidth;
    const mobileTextWidth = 100 - objData.mobile.imageWidth;
    let adaptiveStyle = '';
    if (isEmpty(tabletText) && isEmpty(mobileText)) {
      adaptiveStyle = `
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .${ecmElementClass} .mobile-text-position-left .textwithimage-image,
        .${ecmElementClass} .mobile-text-position-right .textwithimage-image {width:${objData.mobile.imageWidth}% !important;}
        .${ecmElementClass} .mobile-text-position-left .textwithimage-text-before,
        .${ecmElementClass} .mobile-text-position-right .textwithimage-text-before,
        .${ecmElementClass} .mobile-text-position-left .textwithimage-text-after,
        .${ecmElementClass} .mobile-text-position-right .textwithimage-text-after {width:${mobileTextWidth}% !important;}
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .${ecmElementClass} .tablet-text-position-left .textwithimage-image,
        .${ecmElementClass} .tablet-text-position-right .textwithimage-image {width:${objData.tablet.imageWidth}% !important;}
        .${ecmElementClass} .tablet-text-position-left .textwithimage-text-before,
        .${ecmElementClass} .tablet-text-position-right .textwithimage-text-before,
        .${ecmElementClass} .tablet-text-position-left .textwithimage-text-after,
        .${ecmElementClass} .tablet-text-position-right .textwithimage-text-after {width:${tabletTextWidth}% !important;}
      }
      @media screen and (min-width: 1025px) {
        .${ecmElementClass} .desktop-text-position-left .textwithimage-image,
        .${ecmElementClass} .desktop-text-position-right .textwithimage-image {width:${objData.desktop.imageWidth}% !important;}
        .${ecmElementClass} .desktop-text-position-left .textwithimage-text-before,
        .${ecmElementClass} .desktop-text-position-right .textwithimage-text-before,
        .${ecmElementClass} .desktop-text-position-left .textwithimage-text-after,
        .${ecmElementClass} .desktop-text-position-right .textwithimage-text-after {width:${desktopTextWidth}% !important;}
      }`;
    } else {
      adaptiveStyle = `
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .${ecmElementClass} .mobile-text-position-left .textwithimage-image,
        .${ecmElementClass} .mobile-text-position-right .textwithimage-image {width:${objData.mobile.imageWidth}% !important;}
        .${ecmElementClass} .mobile-text-position-left .textwithimage-mobile-text-before,
        .${ecmElementClass} .mobile-text-position-right .textwithimage-mobile-text-before,
        .${ecmElementClass} .mobile-text-position-left .textwithimage-mobile-text-after,
        .${ecmElementClass} .mobile-text-position-right .textwithimage-mobile-text-after {width:${mobileTextWidth}% !important;}
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .${ecmElementClass} .tablet-text-position-left .textwithimage-image,
        .${ecmElementClass} .tablet-text-position-right .textwithimage-image {width:${objData.tablet.imageWidth}% !important;}
        .${ecmElementClass} .tablet-text-position-left .textwithimage-tablet-text-before,
        .${ecmElementClass} .tablet-text-position-right .textwithimage-tablet-text-before,
        .${ecmElementClass} .tablet-text-position-left .textwithimage-tablet-text-after,
        .${ecmElementClass} .tablet-text-position-right .textwithimage-tablet-text-after {width:${tabletTextWidth}% !important;}
      }
      @media screen and (min-width: 1025px) {
        .${ecmElementClass} .desktop-text-position-left .textwithimage-image,
        .${ecmElementClass} .desktop-text-position-right .textwithimage-image {width:${objData.desktop.imageWidth}% !important;}
        .${ecmElementClass} .desktop-text-position-left .textwithimage-desktop-text-before,
        .${ecmElementClass} .desktop-text-position-right .textwithimage-desktop-text-before,
        .${ecmElementClass} .desktop-text-position-left .textwithimage-desktop-text-after,
        .${ecmElementClass} .desktop-text-position-right .textwithimage-desktop-text-after {width:${desktopTextWidth}% !important;}
      }`;
    }
    return (<style dangerouslySetInnerHTML={{ __html: adaptiveStyle }} />);
  }
  return ('');
}

export function ecmGetTextOverlayStyles(objData) {
  const bgColor = get(objData, 'bgcolor') === null || isEmpty(get(objData, 'bgcolor')) ? '' : `background-color:${get(objData, 'bgcolor')}!important;`;
  if (ecmHasPadding(objData) || ecmHasMargin(objData) || ecmHasBorder(objData) || bgColor !== '') {
    const padding = ecmParsePadding(objData);
    const margin = ecmParseMargin(objData);
    const border = ecmParseBorder(objData);
    const bgColor = get(objData, 'bgcolor') === null || isEmpty(get(objData, 'bgcolor')) ? '' : `background-color:${get(objData, 'bgcolor')}!important;`;
    const styleBlock = `.${objData.componentId} { ${padding}${margin}${border}${bgColor} }`;
    return (<style dangerouslySetInnerHTML={{ __html: styleBlock }} />);
  }
  return ('');
}

export function ecmTextHasAnchor(objText) {
  let textHasAnchor = false;
  const textProperties = get(objText, 'properties');
  if (textProperties) {
    const desktopText = (get(textProperties, 'text') === null || isEmpty(get(textProperties, 'text', ''))) ? '' : get(textProperties, 'text');
    const tabletText = (get(textProperties, 'tabletText') === null || isEmpty(get(textProperties, 'tabletText', ''))) ? '' : get(textProperties, 'tabletText');
    const mobileText = (get(textProperties, 'mobileText') === null || isEmpty(get(textProperties, 'mobileText', ''))) ? '' : get(textProperties, 'mobileText');
    textHasAnchor = desktopText.indexOf('<a ') >= 0
      || tabletText.indexOf('<a ') >= 0
      || mobileText.indexOf('<a ') >= 0;
  }
  return (textHasAnchor);
}

export function ecmImageHasImageMap(objImage) {
  let imageHasImageMap = false;
  const imageImages = get(objImage, 'properties.images');
  if (imageImages) {
    const desktopMap = get(imageImages, 'desktop.map', []);
    const tabletMap = get(imageImages, 'tablet.map', []);
    const mobileMap = get(imageImages, 'mobile.map', []);
    imageHasImageMap = desktopMap.length > 0
      || tabletMap.length > 0
      || mobileMap.length > 0;
  }
  return (imageHasImageMap);
}

export function ecmCanPersonalize(actionPayload) {
  const { cookies, headers, query } = actionPayload.request;
  let checkIp = false;
  if (query[ECMParams.PARAM_LAUNCH] || cookies[ECMParams.PARAM_LAUNCH]) {
    checkIp = true;
  } else if (!isEmpty(query[ECMParams.PARAM_PERSONALIZE])) {
    if (query[ECMParams.PARAM_PERSONALIZE] === 'true'
      || cookies[`ECM${ECMParams.PARAM_PERSONALIZE}`] === 'true') {
      checkIp = true;
    }
  } else if (cookies[`ECM${ECMParams.PARAM_PERSONALIZE}`] === 'true') {
    checkIp = true;
  }

  let personalize = false;
  if (checkIp) {
    const trueClientIp = headers['true-client-ip'] || '';
    const trueClientIpMask = trueClientIp.substr(0, trueClientIp.lastIndexOf('.'));
    const trueClientIpNode = trueClientIp.substr(trueClientIp.lastIndexOf('.') + 1, trueClientIp.length - trueClientIp.lastIndexOf('.'));
    for (let i = 0; i < actionPayload.internalIps.length; i++) {
      if (trueClientIpMask === actionPayload.internalIps[i].mask
        && (parseInt(actionPayload.internalIps[i].start, 10) <= parseInt(trueClientIpNode, 10)
          && parseInt(trueClientIpNode, 10) <= parseInt(actionPayload.internalIps[i].end, 10))) {
        personalize = true;
        break;
      }
    }
  }
  return personalize;
}

export function ecmNormalizeFormFields(session, locale, abTestsOpt, aemOptId) {
  const defaultState = session.dt_personalize_data
    ? { ...JSON.parse(session.dt_personalize_data) }
    : {
      bestCustomer: 'n',
      customerJourneySegment: '0',
      customerScore: '0',
      emailSubscriber: 'n',
      inCircleLevel: '0',
      plcc: 'n',
    };
  let defaultCountryCode = locale.countryCode ? locale.countryCode : 'US';
  if (defaultCountryCode !== 'US'
    && defaultCountryCode !== 'AU'
    && defaultCountryCode !== 'CA'
    && defaultCountryCode !== 'CN'
    && defaultCountryCode !== 'AE') {
    defaultCountryCode = 'ROW';
  }
  const defaultAemAbTestUsergroup = !isEmpty(get(abTestsOpt, `${aemOptId}`))
    ? get(abTestsOpt, `${aemOptId}.variation`, '')
    : '';
  const dtPersonalizePreview = session.dt_personalize_preview
    ? { ...JSON.parse(session.dt_personalize_preview) } : defaultState;
  dtPersonalizePreview.bestCustomer = dtPersonalizePreview.bestCustomer === 'y';
  dtPersonalizePreview.emailSubscriber = dtPersonalizePreview.emailSubscriber === 'y';
  dtPersonalizePreview.plcc = dtPersonalizePreview.plcc === 'y';
  dtPersonalizePreview.countryCode = dtPersonalizePreview.countryCode
    ? dtPersonalizePreview.countryCode : defaultCountryCode;
  dtPersonalizePreview.aemAbTestUsergroup = dtPersonalizePreview.aemAbTestUsergroup
    ? dtPersonalizePreview.aemAbTestUsergroup : defaultAemAbTestUsergroup;
  return dtPersonalizePreview;
}

export function ecmGetOverlayClasses(dataObj) {
  const hasDesktopOverlays = get(dataObj, 'desktop', []).length > 0;
  const hasTabletOverlays = get(dataObj, 'tablet', []).length > 0;
  const hasMobileOverlays = get(dataObj, 'mobile', []).length > 0;
  let desktopOverlayClasses = '';
  let tabletOverlayClasses = '';
  let mobileOverlayClasses = '';
  desktopOverlayClasses = hasTabletOverlays ? 'hide-on-tablet' : '';
  desktopOverlayClasses = hasMobileOverlays ? `${desktopOverlayClasses} hide-on-mobile` : desktopOverlayClasses;
  tabletOverlayClasses = hasDesktopOverlays ? 'hide-on-desktop' : '';
  tabletOverlayClasses = hasMobileOverlays ? `${tabletOverlayClasses} hide-on-mobile` : tabletOverlayClasses;
  mobileOverlayClasses = hasDesktopOverlays ? 'hide-on-desktop' : '';
  mobileOverlayClasses = hasTabletOverlays ? `${mobileOverlayClasses} hide-on-tablet` : mobileOverlayClasses;
  return { desktopOverlayClasses, tabletOverlayClasses, mobileOverlayClasses };
}
export function ecmGetOptimizedPadding(objPadding) {
  const paddingArr = [];
  get(objPadding, 'padding-top') > 0 && paddingArr.push(`padding-top:${get(objPadding, 'padding-top')}px;`);
  get(objPadding, 'padding-right') > 0 && paddingArr.push(`padding-right:${get(objPadding, 'padding-right')}px;`);
  get(objPadding, 'padding-bottom') > 0 && paddingArr.push(`padding-bottom:${get(objPadding, 'padding-bottom')}px;`);
  get(objPadding, 'padding-left') > 0 && paddingArr.push(`padding-left:${get(objPadding, 'padding-left')}px;`);
  return paddingArr.join('');
}
export function ecmGetOptimizedMargin(objMargin) {
  const marginArr = [];
  get(objMargin, 'margin-top') > 0 && marginArr.push(`margin-top:${get(objMargin, 'margin-top')}px;`);
  get(objMargin, 'margin-right') > 0 && marginArr.push(`margin-right:${get(objMargin, 'margin-right')}px;`);
  get(objMargin, 'margin-bottom') > 0 && marginArr.push(`margin-bottom:${get(objMargin, 'margin-bottom')}px;`);
  get(objMargin, 'margin-left') > 0 && marginArr.push(`margin-left:${get(objMargin, 'margin-left')}px;`);
  return marginArr.join('');
}
export function ecmGetOptimizedBorder(objBorder) {
  const borderArr = [];
  get(objBorder, 'border-top-width') > 0 && borderArr.push(`border-top-width:${get(objBorder, 'border-top-width')}px;`);
  get(objBorder, 'border-right-width') > 0 && borderArr.push(`border-right-width:${get(objBorder, 'border-right-width')}px;`);
  get(objBorder, 'border-bottom-width') > 0 && borderArr.push(`border-bottom-width:${get(objBorder, 'border-bottom-width')}px;`);
  get(objBorder, 'border-left-width') > 0 && borderArr.push(`border-left-width:${get(objBorder, 'border-left-width')}px;`);
  borderArr.length > 0 && borderArr.push(get(objBorder, 'border-color') === null || isEmpty(get(objBorder, 'border-color')) ? 'border-color:#000000;' : `border-color:${get(objBorder, 'border-color')};`);
  return borderArr.join('');
}

export function ecmGetOptimizedAdaptiveStyleBlock(objData, ecmElementClass) {
  const desktopPadding = ecmHasPadding(get(objData, 'desktop', {})) ? ecmGetOptimizedPadding(get(objData, 'desktop', {})) : '';
  const tabletPadding = ecmHasPadding(get(objData, 'tablet', {})) ? ecmGetOptimizedPadding(get(objData, 'tablet', {})) : '';
  const mobilePadding = ecmHasPadding(get(objData, 'mobile', {})) ? ecmGetOptimizedPadding(get(objData, 'mobile', {})) : '';
  const desktopMargin = ecmHasMargin(get(objData, 'desktop', {})) ? ecmGetOptimizedMargin(get(objData, 'desktop', {})) : '';
  const tabletMargin = ecmHasMargin(get(objData, 'tablet', {})) ? ecmGetOptimizedMargin(get(objData, 'tablet', {})) : '';
  const mobileMargin = ecmHasMargin(get(objData, 'mobile', {})) ? ecmGetOptimizedMargin(get(objData, 'mobile', {})) : '';
  const desktopBorder = ecmHasBorder(get(objData, 'desktop', {})) ? ecmGetOptimizedBorder(get(objData, 'desktop', {})) : '';
  const tabletBorder = ecmHasBorder(get(objData, 'tablet', {})) ? ecmGetOptimizedBorder(get(objData, 'tablet', {})) : '';
  const mobileBorder = ecmHasBorder(get(objData, 'mobile', {})) ? ecmGetOptimizedBorder(get(objData, 'mobile', {})) : '';
  const desktopHidden = ecmIsHidden(get(objData, 'desktop', {})) ? 'display:none;' : '';
  const tabletHidden = ecmIsHidden(get(objData, 'tablet', {})) ? 'display:none;' : '';
  const mobileHidden = ecmIsHidden(get(objData, 'mobile', {})) ? 'display:none;' : '';

  const mobileDisplay = mobilePadding || mobileMargin || mobileBorder ? `@media screen and (min-width: 1px) and (max-width: 767px) {
    .optst.${ecmElementClass} { ${mobileHidden}${mobilePadding}${mobileMargin}${mobileBorder} }
  }` : '';
  const tabletDisplay = tabletPadding || tabletMargin || tabletBorder ? `@media screen and (min-width: 768px) and (max-width: 1024px) {
    .optst.${ecmElementClass} { ${tabletHidden}${tabletPadding}${tabletMargin}${tabletBorder} }
  }` : '';
  const desktopDisplay = desktopPadding || desktopMargin || desktopBorder ? `@media screen and (min-width: 1025px) {
    .optst.${ecmElementClass} { ${desktopHidden}${desktopPadding}${desktopMargin}${desktopBorder} }
  }` : '';
  const adaptiveStyle = `${mobileDisplay} ${tabletDisplay} ${desktopDisplay}`;
  if (adaptiveStyle) {
    return (<style dangerouslySetInnerHTML={{ __html: adaptiveStyle }} />);
  }
  return ('');
}

export function dynamicGridLayout(obj) {
  const grid = get(obj, 'grid', []);
  return grid.map((g) => {
    const desktop = g.desktop || '100';
    const tablet = g.tablet || '100';
    const mobile = g.mobile || '100';
    return `grid-${desktop} tablet-grid-${tablet} mobile-grid-${mobile} grid-parent`;
  });
}

export function ecmGetPositionedTextStyleMap() {
  return {
    topLeft: { top: 0, left: 0 },
    topCenter: { top: 0, left: '50%', transform: 'translateX(-50%)' },
    topRight: { top: 0, right: 0 },
    midLeft: { top: '50%', left: 0 },
    midCenter: { top: '50%', left: '50%', transform: 'translateX(-50%)' },
    midRight: { top: '50%', right: 0 },
    bottomLeft: { bottom: 0, left: 0 },
    bottomCenter: { bottom: 0, left: '50%', transform: 'translateX(-50%)' },
    bottomRight: { bottom: 0, right: 0 },
  };
}

export function isOverlayPositioned(textOverlayElements) {
  return ['desktop', 'tablet', 'mobile'].some((breakpoint) => {
    return get(textOverlayElements, breakpoint, []).some((textOverlay) => {
      return textOverlay.textPosition;
    });
  });
}
