import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import curry from 'lodash/curry';
import { getColorOptions } from './getOptions';

export default (productState) => {
  // if state has a selectedColorIndex, then use it
  const chosenIndex = get(productState, 'options.selectedColorIndex');
  if (chosenIndex !== undefined) {
    return chosenIndex;
  }

  const selectedColorCode = get(productState, 'options.selectedColorCode');
  const colorOptions = getColorOptions(productState);

  const arity = 2;
  const findColorIndex = curry(findIndex, arity)(colorOptions.values);

  let selectedColorIndex;
  selectedColorIndex = findColorIndex(({ defaultColor }) => defaultColor);

  if (selectedColorCode) {
    selectedColorIndex = findColorIndex(({ key }) => (
      key === selectedColorCode
    ));
  }

  return selectedColorIndex === -1 ? 0 : selectedColorIndex;
};
