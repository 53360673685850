import {
  NO_ORDERHISTORY_DATA_AVAILABLE,
  RESOLVED_COMBINED_ORDER_HISTORY,
  MAP_OH_ITEM_ADDRESSES,
  LOADING_ORDER_HISTORY_DATA,
  SET_FETCHED_ALL_OH,
  LOADING_CANCELLED_ORDERS,
  LOADING_ONLINE_ORDERS,
  LOADING_STORE_ORDERS,
  SET_CANCELLED_ORDER_HISTORY,
  SET_ONLINE_ORDER_HISTORY,
  SET_STORE_ORDER_HISTORY,
  REJECTED_ORDER_HISTORY,
  REJECTED_CANCELLED_ORDER_HISTORY,
  REJECTED_ONLINE_ORDER_HISTORY,
  REJECTED_STORE_ORDER_HISTORY,
  SET_OH_SEARCH_TEXT,
  START_SEARCHING_OH,
  RESOLVED_STYLED_FOR_YOU_DATA,
  REJECTED_STYLED_FOR_YOU_DATA,
  SET_FETCHED_ALL_ONLINE,
  SET_FETCHED_ALL_STORE,
  SUBMIT_CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILED,
  LOADING_GUEST_ORDERS,
  RESOLVED_GUEST_ORDERS,
  REJECTED_GUEST_ORDERS,
} from 'profile/actions/v3/actions';

const defaultState = {
  orderHistoryDetails: {
    isOrderHistoryAvail: false,
  },
  guest: {
    orders: [],
    details: {},
    error: false,
    loading: false,
  },
  combinedOrderHistory: {},
  hasCancelled: false,
  pagination: {
    tabs: {
      all: { next: {} },
      online: { next: {} },
      store: { next: {} },
    },
    loadingOrderHistory: false,
    fetchedAll: false,
    error: false,
    searchText: '',
    fetchedAllOnline: false,
    fetchedAllStore: false,
  },
  cancelledOrders: [],
  onlineOrders: [],
  storeOrders: [],
  loadingOnline: false,
  loadingStore: false,
  loadingCancelled: false,
  cancelledOrdersError: false,
  onlineOrdersError: false,
  storeOrdersError: false,
  styledForYou: [],
  cancellation: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOADING_GUEST_ORDERS: {
      return {
        ...state,
        ...{
          guest: {
            ...state.guest,
            loading: true,
            error: false,
          },
        },
      };
    }
    case RESOLVED_GUEST_ORDERS: {
      return {
        ...state,
        ...{
          guest: {
            ...defaultState.guest,
            orders: action.payload.orders,
            details: action.payload.details,
          },
        },
      };
    }
    case REJECTED_GUEST_ORDERS: {
      return {
        ...state,
        ...{
          guest: {
            ...defaultState.guest,
            error: true,
          },
        },
      };
    }
    case SUBMIT_CANCEL_ORDER:
    case CANCEL_ORDER_FAILED:
      return {
        ...state,
        cancellation: {
          ...state.cancellation,
          ...action.payload,
        },
      };
    case CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        cancellation: {
          ...state.cancellation,
          ...action.payload,
        },
        ...action.orders,
      };
    case NO_ORDERHISTORY_DATA_AVAILABLE:
      return {
        ...state,
        orderHistoryDetails: {
          ...state.orderHistoryDetails,
          isOrderHistoryAvail: false,
        },
      };

    case RESOLVED_COMBINED_ORDER_HISTORY: {
      return {
        ...state,
        combinedOrderHistory: {
          allOrders: [
            ...(state.combinedOrderHistory?.allOrders || []),
            ...action.payload.orders,
          ],
        },
        pagination: {
          ...state.pagination,
          ...{ tabs: { ...state.pagination.tabs, ...action.payload.pagination.tabs } },
          loadingOrderHistory: false,
        },
      };
    }
    case MAP_OH_ITEM_ADDRESSES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case LOADING_ORDER_HISTORY_DATA: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          loadingOrderHistory: action.payload,
        },
      };
    }
    case SET_FETCHED_ALL_OH: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          fetchedAll: action.payload,
        },
      };
    }
    case SET_FETCHED_ALL_ONLINE: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          fetchedAllOnline: action.payload,
        },
      };
    }
    case SET_FETCHED_ALL_STORE: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          fetchedAllStore: action.payload,
        },
      };
    }

    case LOADING_CANCELLED_ORDERS: {
      return {
        ...state,
        loadingCancelled: action.payload,
      };
    }
    case LOADING_ONLINE_ORDERS: {
      return {
        ...state,
        loadingOnline: action.payload,
      };
    }
    case LOADING_STORE_ORDERS: {
      return {
        ...state,
        loadingStore: action.payload,
      };
    }
    case SET_CANCELLED_ORDER_HISTORY: {
      return {
        ...state,
        cancelledOrders: action.payload,
        pagination: {
          ...state.pagination,
          ...action.pagination,
        },
        loadingCancelled: false,
      };
    }
    case SET_ONLINE_ORDER_HISTORY: {
      return {
        ...state,
        onlineOrders: [
          ...(state.onlineOrders || []),
          ...action.payload.orders,
        ],
        pagination: {
          ...state.pagination,
          ...{ tabs: { ...state.pagination.tabs, ...action.payload.pagination.tabs } },
        },
        loadingOnline: false,
      };
    }
    case SET_STORE_ORDER_HISTORY: {
      return {
        ...state,
        storeOrders: [
          ...(state.storeOrders || []),
          ...action.payload.orders,
        ],
        pagination: {
          ...state.pagination,
          ...{ tabs: { ...state.pagination.tabs, ...action.payload.pagination.tabs } },
        },
        loadingStore: false,
      };
    }
    case REJECTED_ORDER_HISTORY: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          loadingOrderHistory: false,
          error: true,
          fetchedAll: true,
        },
      };
    }
    case REJECTED_CANCELLED_ORDER_HISTORY: {
      return {
        ...state,
        loadingCancelled: false,
        cancelledOrdersError: true,
      };
    }
    case REJECTED_ONLINE_ORDER_HISTORY: {
      return {
        ...state,
        loadingOnline: false,
        onlineOrdersError: true,
      };
    }
    case REJECTED_STORE_ORDER_HISTORY: {
      return {
        ...state,
        loadingStore: false,
        storeOrdersError: true,
      };
    }
    case SET_OH_SEARCH_TEXT: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          searchText: action.payload,
        },
      };
    }
    case START_SEARCHING_OH: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...{
            tabs: {
              all: { next: {} },
              online: { next: {} },
              store: { next: {} },
            },
          },
          fetchedAll: false,
          error: false,
        },
        combinedOrderHistory: {},
      };
    }
    case RESOLVED_STYLED_FOR_YOU_DATA: {
      return {
        ...state,
        styledForYou: { ...state.styledForYou, ...action.payload },
      };
    }
    case REJECTED_STYLED_FOR_YOU_DATA: {
      return {
        ...state,
        styledForYou: { ...state.styledForYou, ...action.payload },
      };
    }
    default: {
      return state;
    }
  }
};
