import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

const closeChildren = (silo) => {
  const newCategories = map(silo.categories, ((cat) => { return closeChildren(cat); }));
  return {
    ...silo,
    selected: false,
    latestSelected: false,
    categories: newCategories,
  };
};

export const closeAll = (silos) => map(silos, closeChildren);

export const toggleSelected = (selectedId, silos) => {
  return map(silos, ((silo) => {
    if (silo.id === selectedId) {
      return silo.selected
        ? closeChildren(silo)
        : { ...silo, selected: true, latestSelected: true };
    }
    silo.latestSelected = false;
    return isEmpty(silo.categories)
      ? silo
      : { ...silo, categories: toggleSelected(selectedId, silo.categories) };
  }));
};

export default toggleSelected;
