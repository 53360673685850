import { types as NavTypes } from '../actions-navigation';

const defaultState = null;

export default (state = defaultState, action) => {
  switch (action.type) {
    case NavTypes.SET_VISUAL_NAV:
      return action.payload.visualNav || null;
    default:
      return state;
  }
};
