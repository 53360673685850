import reactCookie from 'react-cookie';
import get from 'lodash/get';
import { createSelector } from 'reselect';

export function getActiveAbTest(abTestName = {}, abTestIds = {}) {
  const abTest = abTestIds[abTestName];
  if (abTest) {
    for (const key of Object.keys(abTest)) {
      if (abTest[key].active) {
        return ({
          abTestName,
          abTestId: key,
          abTestCookieName: abTest[key].cookieName,
        });
      }
    }
  }
  return null;
}

export function getActiveAbTests(abTestIds = {}) {
  const activeAbTests = [];
  for (const name of Object.keys(abTestIds)) {
    const abTest = getActiveAbTest(name, abTestIds);
    if (abTest) activeAbTests.push(abTest);
  }
  return activeAbTests;
}

export function parseAbTestValue(abTestIds, responseData) {
  for (let i = 0; i < abTestIds.length; i++) {
    let experience = null;
    let value = null;
    for (let j = 0; j < responseData.length; j++) {
      if (!value) {
        if (responseData[j][abTestIds[i].abTestName]) {
          // eslint-disable-next-line max-len
          const testIdObj = responseData[j][abTestIds[i].abTestName][abTestIds[i].abTestId];
          for (const key of Object.keys(testIdObj)) {
            experience = key;
            value = testIdObj[key].expType;
          }
        }
      }
    }
    if (value) {
      // eslint-disable-next-line no-param-reassign
      abTestIds[i].abTestExperience = experience;
      // eslint-disable-next-line no-param-reassign
      abTestIds[i].abTestCookieValue = value;
    }
  }
}

export function createCookiesFromAbTests(abTests) {
  const nowPlus30Days = new Date(Date.now() + (30 * 24 * 60 * 60 * 1000));
  for (const name of Object.keys(abTests)) {
    reactCookie.save(abTests[name].abTestCookieName, abTests[name].abTestCookieValue, { path: '/', expires: nowPlus30Days });
  }
}

export function shouldLoadPage(state, abTestOptApi) {
  if (state.callOptimizelyAbTestService) {
    return abTestOptApi.resolved || abTestOptApi.rejected;
  }
  return true;
}

const selectOptyTestData = (abTestsOpt, testId, defaultValue) => get(abTestsOpt, `${testId}.variation`, defaultValue);

export const getOptyAssignmentValue = createSelector(
  selectOptyTestData,
  (value) => {
    if (value) {
      switch (value.toLowerCase().trim()) {
        case 'true': return true;
        case 'false': return false;
        default: return value;
      }
    }
    return value;
  }
);
