import get from 'lodash/get';
import find from 'lodash/find';

export default (product, legacyType) => {
  const {
    customization = {},
  } = product;
  const customizationOptions = get(customization, 'customizationOptions', []);
  return find(customizationOptions, { legacyType }) || {};
};
