import React, { Component } from 'react';
import { connect } from 'react-redux';
import requiredCheckmark from 'assets/images/checkmark.svg';
import { formatMoney } from 'accounting';
import ErrorMessage from 'pdp/components/ErrorMessage/errorMessage';
import {
  openMonogram as openMonogramAction,
  setPersonalization as setPersonalizationAction,
  setSelectedCustomizations as setSelectedCustomizationsAction,
  // eslint-disable-next-line max-len
  initializeSelectedCustomizationsAllTypes as initializeSelectedCustomizationsAllTypesAction,
} from 'pdp/pages/ProductPage/actions';
import {
  SUPPRESSED_MONOGRAM, FREE_MONOGRAM, REQUIRED_MONOGRAM, OOS_ERROR_MESSAGE,
} from 'pdp/constants';
import './personalization.scss';
import { SelectedPersonalizations } from './components/selectedPersonalization/selectedPersonalizations';
import PersonalizationCTA from './components/personalizationCTA/personalizationCTA';

export class DumbPersonalization extends Component {
  constructor() {
    super();
    this.state = { showErrorMessage: false };
    this.getAdditionalCharge = this.getAdditionalCharge.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.editCustomizations = this.editCustomizations.bind(this);
    this.removeCustomizations = this.removeCustomizations.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  getAdditionalCharge(behaviorCode, additionalCharge) {
    if (behaviorCode === FREE_MONOGRAM || additionalCharge === 0.0) {
      return 'FREE';
    }
    return `+${formatMoney(additionalCharge, '$', 2)}`;
  }

  handleClick() {
    const {
      product,
      selectedSku,
      openMonogram,
      setPersonalization,
    } = this.props;

    if (!product.isPersonalizationSelected
        && selectedSku && !selectedSku.sellable) {
      this.setState({ showErrorMessage: true });
      return;
    }
    if (product && !product.isPersonalizationSelected) {
      openMonogram(product);
      this.setState({ showErrorMessage: false });
    } else {
      setPersonalization(false);
    }
  }

  removeCustomizations() {
    const {
      initializeSelectedCustomizationsAllTypes,
      setSelectedCustomizations,
      setPersonalization,
    } = this.props;

    setPersonalization(false);
    setSelectedCustomizations([]);
    initializeSelectedCustomizationsAllTypes();
  }

  editCustomizations() {
    const {
      product,
      selectedSku,
      openMonogram,
    } = this.props;

    if (selectedSku && !selectedSku.sellable) {
      this.setState({ showErrorMessage: true });
      return;
    }
    this.setState({ showErrorMessage: false });
    openMonogram(product);
  }

  render() {
    const { product = {} } = this.props;
    const { customization = {}, isPersonalizationSelected } = product;
    const { behaviorCode, additionalCharge = 0.0 } = customization;
    const { showErrorMessage } = this.state;
    const additionalChargeElement = (
      <span id="customization-charge" className="personalization__details__text__charge">
        (
        { this.getAdditionalCharge(behaviorCode, additionalCharge) }
        )
      </span>
    );

    const isRequireMonogramProduct = () => (behaviorCode !== REQUIRED_MONOGRAM);
    const personalizationSection = (
      <div className="grid-100 tablet-grid-100 mobile-grid-100 personalization">
        <span className="personalization__title">Personalize:</span>
        {!product.productFlags.previewSupported
          && product.isPersonalizationSelected && (
          <span>No Preview Available</span>
        )}
        <div className={isRequireMonogramProduct() ? 'personalization__details optional' : 'personalization__details required'}>
          {showErrorMessage && <ErrorMessage message={OOS_ERROR_MESSAGE} />}
          {isRequireMonogramProduct()
            ? (
              <button
                type="button"
                aria-pressed={isPersonalizationSelected}
                aria-label="Personalize"
                aria-describedby="customization-text"
                className={
                  isPersonalizationSelected
                    ? 'personalization__details__button personalization__details__button__checked'
                    : 'personalization__details__button personalization__details__button__unchecked'
                }
                onClick={this.handleClick}
              />
            )
            : (
              <img
                src={requiredCheckmark}
                alt="Added"
              />
            )}
          {isPersonalizationSelected
            ? (
              <div id="customization-text" className="personalization__details__text">
                <span className="personalization__details__text__title">Added</span>
                { isRequireMonogramProduct() && additionalChargeElement }
                <PersonalizationCTA
                  editCustomizations={this.editCustomizations}
                  removeCustomizations={this.removeCustomizations}
                  behaviorCode={behaviorCode}
                />
              </div>
            )
            : (
              <div id="customization-text" className="personalization__details__text">
                <span className="personalization__details__text__title">Add</span>
                { isRequireMonogramProduct() && additionalChargeElement }
              </div>
            )
          }
        </div>
        {(isPersonalizationSelected
          && (product.skus && product.skus[0].dropShip === false))
          && <SelectedPersonalizations product={product} />
        }
      </div>
    );

    if (behaviorCode === SUPPRESSED_MONOGRAM) {
      return false;
    }
    return personalizationSection;
  }
}

const mapDispatchToProps = (dispatch, { product: { id: productId } }) => ({
  openMonogram: (...args) => dispatch(openMonogramAction(...args)),
  setPersonalization: (...args) => (
    dispatch(setPersonalizationAction(productId, ...args))
  ),
  setSelectedCustomizations: (...args) => (
    dispatch(setSelectedCustomizationsAction(productId, ...args))
  ),
  initializeSelectedCustomizationsAllTypes: (...args) => (
    dispatch(initializeSelectedCustomizationsAllTypesAction(...args))
  ),
});

export default connect(undefined, mapDispatchToProps)(DumbPersonalization);
