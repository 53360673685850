import {
  RESOLVED_RELATED_ITEMS,
  RESET_RELATED_ITEMS,
  REJECTED_RELATED_ITEMS,
} from '../actions';

export const defaultState = {
  relatedItemsHtml: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_RELATED_ITEMS:
      return {
        ...state,
        relatedItemsHtml: encodeURIComponent(action.payload),
      };
    case RESET_RELATED_ITEMS:
      // falls through
    case REJECTED_RELATED_ITEMS:
      return defaultState;
    default:
      return state;
  }
};
