export const APP_SET_USER = 'SET_USER';
export const APP_SET_SESSION = 'SET_SESSION';
export const APP_LOSE_FOCUS = 'LOSE_FOCUS';
export const APP_PUT_IN_FOCUS = 'PUT_IN_FOCUS';
export const APP_SET_LOCALE = 'SET_LOCALE';
export const APP_REQUEST_RECEIVED = 'REQUEST_RECEIVED';
export const APP_SET_REVISIT_USER = 'SET_REVISIT_USER';
export const APP_PLA_COOKIE_RECEIVED = 'PLA_COOKIE_RECEIVED';
export const APP_COOKIES_RECEIVED = 'COOKIES_RECEIVED';
export const APP_PARSE_PERSONALIZE = 'PARSE_PERSONALIZE';
export const APP_AB_TEST_SESSION_DATA_RECEIVED = 'AB_TEST_SESSION_DATA_RECEIVED';
export const APP_SET_HEADER_STATE = 'APP_SET_HEADER_STATE';
export const PLP_SPA_PATH_UPDATE = 'PLP_SPA_PATH_UPDATE';
export const SET_GENDER = 'SET_GENDER';
export const SET_TYPEAHEAD_UTAG = 'SET_TYPEAHEAD_UTAG';
