/* eslint no-console: 0 */
import NodeCache from 'node-cache';
import log from 'server/utilities/logger';

const nodeCache = new NodeCache();

export const setCache = (key, value, ttl) => {
  try {
    nodeCache.set(key, value, ttl);
    log.info('Cache updated for key : ', key);
  } catch (error) {
    log.error('Set cache failed');
  }
};

export const getCache = (key) => {
  try {
    return nodeCache.get(key);
  } catch (error) {
    log.error(`Get cache failed: ${error}`);
    return undefined;
  }
};

export const flushAllCache = () => {
  try {
    nodeCache.flushAll();
  } catch (error) {
    log.error(`Flush cache failed: ${error}`);
  }
};

export default nodeCache;
