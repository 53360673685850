import React from 'react';
import './bopsErrorForReplenishment.scss';

export const BOPS_ERR_MSG_FOR_REPLENISHMENT = 'Sorry, Auto-Replenishment Services are not available for in-store pickup.';

const BopsErrorForReplenishment = ({
  isError,
  ...props
}) => {
  if (isError) {
    return (
      <div className="bopsErrorForReplenishment" {...props}>
        <p>{BOPS_ERR_MSG_FOR_REPLENISHMENT}</p>
      </div>
    );
  }
  return null;
};

export default BopsErrorForReplenishment;
