import get from 'lodash/get';
import { createSelector } from 'reselect';
import {
  UPDATE_SEARCH,
  UPDATE_TYPEAHEAD_SUGGESTIONS,
  TOGGLE_MOBILE_SEARCH_BAR,
  SET_RECENTLY_SEARCHED_ANALYTICS,
  LOAD_TYPEAHEAD_UI_CONFIG,
  types as SearchTypes,
  TOGGLE_IS_TYPEAHEAD_SUGGESTIONS_EMPTY,
  SET_COLLAPSIBLE_SEARCH_MODAL,
} from './search-actions';

const defaultState = {
  searchTerm: '',
  isVisible: false,
  placeholderValue: '',
  mobileSearchTerm: '',
  recentlySearchedAnalytics: false,
  typeaheadPayload: {
    suggestions: [],
    narrowedWithin: [],
    serverQuery: '',
    rid: '',
  },
  isSuggestionsEmpty: false,
  noResults: false,
  isSearchModalOpen: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH: {
      return {
        ...state,
        searchTerm: action.searchTerm,
        noResults: false,
      };
    }
    case UPDATE_TYPEAHEAD_SUGGESTIONS: {
      return {
        ...state,
        typeaheadPayload: action.payload,
      };
    }
    case TOGGLE_MOBILE_SEARCH_BAR: {
      return { ...state, isVisible: !state.isVisible };
    }
    case SearchTypes.SET_PLACEHOLDER_VALUE: {
      return {
        ...state,
        placeholderValue: action.placeholderValue,
      };
    }
    case SearchTypes.SET_MOBILE_SEARCH_TERM: {
      return {
        ...state,
        mobileSearchTerm: action.mobileSearchTerm,
      };
    }
    case SET_RECENTLY_SEARCHED_ANALYTICS: {
      return {
        ...state,
        recentlySearchedAnalytics: true,
      };
    }
    case LOAD_TYPEAHEAD_UI_CONFIG: {
      return {
        ...state,
        typeaheadUIConf: action.payload,
      };
    }
    case TOGGLE_IS_TYPEAHEAD_SUGGESTIONS_EMPTY: {
      return {
        ...state,
        isTypeaheadSuggestionEmpty: action.payload,
      };
    }
    case SET_COLLAPSIBLE_SEARCH_MODAL: {
      return {
        ...state,
        isSearchModalOpen: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const getSearchTerm = (state) => get(state, 'search.searchTerm', {});
export const typeaheadSuggestionPayload = (state) => get(state, 'search.typeaheadPayload', {});
export const getIsTypeaheadSuggestionEmpty = createSelector(
  [
    typeaheadSuggestionPayload,
    (state) => get(state, 'toggles.GENDER_TYPEAHEAD', false),
    (state) => get(state, 'srp.search.ta_gender', 'W'),
  ],
  (typeaheadPayload, isGenderTypeahead, selectedGender) => {
    const gender = selectedGender === 'W' ? 'women' : 'men';
    if (isGenderTypeahead) {
      return typeaheadPayload?.[gender]?.suggestions?.length === 0;
    }

    if (typeaheadPayload?.[gender]) {
      return typeaheadPayload?.[gender]?.suggestions?.length === 0;
    }

    return typeaheadPayload?.suggestions?.length === 0;
  }
);
export const getSearchVisible = (state) => get(state, 'search.isVisible', {});
export const getPlaceholderValue = (state) => get(state, 'search.placeholderValue', 'Search');
export const getMobileSearchTerm = (state) => get(state, 'search.mobileSearchTerm', {});
export const getTypeaheadUIConf = (state) => get(state, 'search.typeaheadUIConf', {});
