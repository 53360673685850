import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';

const ReviewRatings = Loadable({
  loader: () => import(/* webpackChunkName: 'review-ratings' */ '../ReviewRatings/ReviewRatings'),
  loading: () => false,
});

const ReviewRatingsV5 = Loadable({
  loader: () => import(/* webpackChunkName: 'review-ratings-V5' */ '../ReviewRatingsV5/ReviewRatingsV5'),
  loading: () => false,
});

const propTypes = {
  productId: PropTypes.string,
  toggle: PropTypes.bool,
};

const defaultProps = {
  productId: undefined,
  toggle: true,
};

const ReviewRatingsWrapper = ({
  productId,
  toggle = true,
}) => {
  const component = toggle
    ? <ReviewRatingsV5 productId={productId} />
    : <ReviewRatings productId={productId} />;

  return component;
};

ReviewRatingsWrapper.propTypes = propTypes;
ReviewRatingsWrapper.defaultProps = defaultProps;

export default ReviewRatingsWrapper;
