import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  uiComponent: PropTypes.string,
  testID: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  className: '',
  uiComponent: 'FloatGridContainer',
  testID: null,
};

const FloatGridContainer = ({
  children,
  className,
  uiComponent,
  testID,
  ...props
}) => {
  const classList = ['grid-container', className].join(' ').trim().replace(/\s+/g, ' ');
  return (
    <div
      className={classList}
      {...props}
      data-ui-component={uiComponent}
      data-tid={testID}
    >
      {children}
    </div>
  );
};

FloatGridContainer.displayName = 'FloatGridContainer';
FloatGridContainer.propTypes = propTypes;
FloatGridContainer.defaultProps = defaultProps;

export default FloatGridContainer;
