import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import chanelLogoNew from './chanel_logo.png';
import chanelLogoOld from './chanel_logo.svg';
import styles from './ChanelBanner.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: PropTypes.string,
  uiComponent: PropTypes.string,
};

const defaultProps = {
  className: '',
  uiComponent: 'ChanelBanner',
};

const ChanelBanner = ({
  className,
  uiComponent,
  newChanelToggle,
  ...props
}) => {
  const classList = cx(
    [newChanelToggle ? 'ChanelBannerNew' : 'ChanelBanner', className]
      .join(' ')
      .trim()
      .replace(/\s+/g, ' '),
  );

  return (
    <div {...props} className={classList} data-ui-component={uiComponent}>
      <img alt="CHANEL" src={newChanelToggle ? chanelLogoNew : chanelLogoOld} />
    </div>
  );
};

ChanelBanner.displayName = 'ChanelBanner';
ChanelBanner.propTypes = propTypes;
ChanelBanner.defaultProps = defaultProps;

export default ChanelBanner;
