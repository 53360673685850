const screenReaderAnnouncer = (message) => {
  return (dispatch) => {
    dispatch({
      type: 'SCREEN_READER_MESSAGE',
      payload: { message },
    });
  };
};

export default screenReaderAnnouncer;
