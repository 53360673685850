import { combineReducers } from 'redux';
import siloReducers from './reducers-silos';
import positionReducers from './reducers-position';
import navSliderReducer from '../NavSlider/navSlider-reducer';
import breadcrumbReducer from './reducers-breadcrumb';
import breadcrumbPathReducer from '../Breadcrumb/breadcrumbPath-reducer';
import visualNavReducer from './visualNavReducer';

export default combineReducers({
  silos: siloReducers,
  position: positionReducers,
  navSlider: navSliderReducer,
  breadcrumbs: breadcrumbReducer,
  breadcrumbPath: breadcrumbPathReducer,
  visualNav: visualNavReducer,
  siloDrawerHoverIntent: (state = {}) => state,
});
