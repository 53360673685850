import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { DISCONTINUED_CODES_YR, NON_DISCONTINUED_CODE } from 'pdp/constants';

const BACK_ORDER = 'Back Order';
const IN_STOCK = 'In Stock';
const MERCHANDISE_TYPE_OTHERS = 'OTHERS';
const onlyXLeft = (quantity) => `Only ${quantity} Left`;
const shouldShowOnlyXLeft = (sku, threshold) => (
  sku.stockLevel
  && sku.stockLevel <= threshold
  && !sku.dropShip
  && !sku.backOrder
  && !sku.preOrder
);

const updateStockStatusMessageForXLeft = (sku, preciousJewelryItem, isChanel, threshold) => (
  (shouldShowOnlyXLeft(sku, threshold) && !preciousJewelryItem && !isChanel)
  && `${sku.stockStatusMessage}, ${onlyXLeft(sku.stockLevel)}`
);

const updateStockStatusMessageForBackOrder = (sku, selectedSkuQuantity) => {
  if (sku.inStock && selectedSkuQuantity > sku.stockLevel) {
    if (sku.discontinuedCode === NON_DISCONTINUED_CODE) return BACK_ORDER;
    if (DISCONTINUED_CODES_YR.includes(sku.discontinuedCode) && sku.poQuantity) {
      return BACK_ORDER;
    }
  }
  return '';
};

const SkuStatusMessages = (props) => {
  const {
    selectedSku = {},
    perishable,
    selectedSkuQuantity,
    onlyXLeftThreshold,
    preciousJewelryItem,
    isChanel,
    merchandiseType = '',
  } = props;
  const shippingStatus = selectedSku.shippingStatusMessage;
  const merchandiseTypeValidation = (merchandiseType === MERCHANDISE_TYPE_OTHERS
      && selectedSku.dropShip === true && selectedSku.sellable === true);
  const stockStatus = (merchandiseTypeValidation)
    ? IN_STOCK
    : selectedSku.stockStatusMessage;
  const messages = [];

  if (!merchandiseTypeValidation
    && shippingStatus && !perishable) {
    messages.push(shippingStatus);
  }

  if (stockStatus) {
    const backOrderMessage = updateStockStatusMessageForBackOrder(selectedSku, selectedSkuQuantity);
    const onlyXLeftMessage = updateStockStatusMessageForXLeft(
      selectedSku,
      preciousJewelryItem,
      isChanel,
      onlyXLeftThreshold
    );

    if (backOrderMessage) {
      messages.push(backOrderMessage);
    } else if (onlyXLeftMessage) {
      messages.push(onlyXLeftMessage);
    } else {
      messages.push(stockStatus);
    }
  }

  if (!messages.length) {
    return false;
  }

  return (
    <div className="sku-status-messages">
      {messages.map((message, index) => (
        <p key={index} className="message">
          {message}
        </p>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  const siloCategory = get(state.navigation, 'breadcrumbs[0].id', '');
  const onlyXLeftThresholds = state.onlyXLeftThresholds;
  const onlyXLeftThreshold = get(
    onlyXLeftThresholds,
    siloCategory,
    onlyXLeftThresholds.defaultThreshold,
  );
  return { onlyXLeftThreshold };
};

export default connect(mapStateToProps)(SkuStatusMessages);
