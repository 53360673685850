import { RESOLVED_GROUP } from 'pdp/pages/ProductPage/actions';


const initialState = {};
const initialStatusState = { loading: false, resolved: false, rejected: false };

const getResourceFromType = (type) => type.split('_').slice(1).join('_').toLowerCase();

export default (state = initialState, action) => {
  switch (true) {
    case /LOADING_/.test(action.type):
      return {
        ...state,
        [getResourceFromType(action.type)]: { ...initialStatusState, loading: true },
      };
    case /REJECTED_/.test(action.type):
      return {
        ...state,
        [getResourceFromType(action.type)]: { ...initialStatusState, rejected: true },
      };
    case /RESOLVED_/.test(action.type):
      return {
        ...state,
        [getResourceFromType(action.type)]: { ...initialStatusState, resolved: true },
      };
    case RESOLVED_GROUP === action.type:
      return {
        ...state,
        product: { ...initialStatusState, resolved: true },
      };
    case /RESET_/.test(action.type):
      return {
        ...state,
        [getResourceFromType(action.type)]: initialStatusState,
      };
    default:
      return state;
  }
};
