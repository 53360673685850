import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import styleAdvisor from 'assets/images/style_advisor.svg';
import { fetchListOfStoreByAddress } from 'plp/components/ProductListPage/components/FacetedLeftNav/actions';
import {
  openAppointments,
  getStoreJSON,
  getAppointments,
} from 'pdp/pages/ProductPage/actions';
import { APPOINTMENT_RADIUS } from 'pdp/constants';
import './Appointments.scss';

export class DumbAppointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeName: '',
      storeURL: '',
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleStoreChange = this.handleStoreChange.bind(this);
  }

  componentDidMount() {
    const { zip, fetchListOfStoreByAddress } = this.props;
    const storeSet = cookie.load('appointmentsStore');

    storeSet && this.setState({
      storeNumber: storeSet.number,
      storeName: storeSet.name,
      storeURL: storeSet.url,
    });
    zip && fetchListOfStoreByAddress(zip, APPOINTMENT_RADIUS);
  }

  componentDidUpdate(prevProps) {
    const {
      zip,
      storeList,
      storeJSON,
      getStoreJSON,
      isDomLoadComplete,
      getAppointments,
    } = this.props;

    const { storeName } = this.state;

    if (isDomLoadComplete !== prevProps.isDomLoadComplete) {
      const date = new Date();

      getAppointments(zip, date.getDay(), date.getHours());
      storeList.forEach((store) => {
        getStoreJSON(store.storeNumber, store.distance);
      });
    }

    if (!storeName.length && storeJSON.length === storeList.length) {
      const sortedJSON = storeJSON.sort(
        (a, b) => parseFloat(a.distance) - parseFloat(b.distance),
      );
      const nearestStore = sortedJSON.find((store) => {
        return get(
          store,
          'storeServices.onlineAptBooking.isOpen',
          false,
        ) === true;
      });

      this.handleStoreChange(nearestStore);
    }
  }

  handleStoreChange(store) {
    const storeBookingURL = 'https://stores.neimanmarcus.com/bookings';
    if (!isEmpty(store)) {
      const name = get(store, 'name', '');
      const url = get(store, 'storeServices.onlineAptBooking.link', storeBookingURL);
      const number = get(store, 'storeNumber', '');
      const cookieData = JSON.stringify({ number, name, url });

      this.setState({
        storeName: name,
        storeURL: url,
        storeNumber: number,
      });
      cookie.save('appointmentsStore', cookieData, { path: '/' });
    }
  }

  handleClick() {
    const { openAppointments } = this.props;
    openAppointments(this.handleStoreChange);
  }

  ctaRender() {
    const { storeName, storeURL, storeNumber } = this.state;
    const { appointments = [] } = this.props;
    const storeBookingURL = 'https://stores.neimanmarcus.com/bookings';

    const storeInfo = appointments.find((store) => store.storeNumber === storeNumber);
    const storeStaffAvailability = get(storeInfo, 'availability', false);

    return storeName.length ? (
      <Fragment>
        <img
          alt="Stlye Advisor Icon"
          aria-hidden="true"
          className="appointments__icon"
          src={styleAdvisor}
        />
        <div className="appointments__cta">
          <a href={storeURL} rel="noopener noreferrer" target="_blank">
            <span>Book An Appointment With A Style Advisor</span>
            <br />
            <span>{`At ${storeName.toLowerCase()} `}</span>
          </a>
          <a href="#appointments-modal" onClick={this.handleClick}>
            Change
          </a>
          <br />
          {storeStaffAvailability && (
            <span className="appointments__cta-appointment">
              Appointments available today
            </span>
          )}
        </div>
      </Fragment>
    ) : (
      <a href={storeBookingURL} rel="noopener noreferrer" target="_blank">
        <img
          alt="Stlye Advisor Icon"
          aria-hidden="true"
          className="appointments__icon"
          src={styleAdvisor}
        />
        <div className="appointments__cta">
          <span>Book An Appointment With A Style Advisor</span>
        </div>
      </a>
    );
  }

  render() {
    return (
      <div className={`appointments ${this.props.fullWidth && 'appointments__full-width'}`}>
        {this.ctaRender()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  zip: get(state, 'api.requestContext.akamaiEdgescape.zip', ''),
  storeList: get(state, 'facetedLeftNav.storeListPayload', []),
  storeJSON: get(state, 'productCatalog.stores.json', []),
  isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
  appointments: get(state, 'productCatalog.appointments.stores', []),
});

const mapDispatchToProps = {
  getStoreJSON,
  openAppointments,
  fetchListOfStoreByAddress,
  getAppointments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbAppointments);
