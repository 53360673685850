import {
  RESOLVED_ECMRECOMMENDATIONS,
  RESET_ECMRECOMMENDATIONS,
  SET_SELECTED_ITEM_INDEX,
  RESOLVE_BV_RECOMMENDATIONS,
  REJECT_BV_RECOMMENDATIONS,
}
  from '../actions/actions-ecmrecommendations';

export const defaultState = {
  bvRecommendations: {
    bvCategories: [],
    errorText: '',
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_ECMRECOMMENDATIONS:
      return {
        ...state,
        products: action.payload,
      };
    case RESET_ECMRECOMMENDATIONS:
      return defaultState;
    case SET_SELECTED_ITEM_INDEX:
      return {
        ...state,
        selectedItemIndex: action.payload,
      };
    case RESOLVE_BV_RECOMMENDATIONS:
      return {
        ...state,
        bvRecommendations: {
          bvCategories: action.payload,
        },
      };
    case REJECT_BV_RECOMMENDATIONS:
      return {
        ...state,
        bvRecommendations: {
          errorText: action.payload,
        },
      };
    default:
      return state;
  }
};
