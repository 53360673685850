const get = require('lodash/get');
const themes = require('./themes');

const theme = themes.nm;
const themeName = process.env.NMO_THEME || 'nm';
const themeClass = `theme-${themeName}`;

exports.themes = themes;
exports.theme = themes.nm;
exports.themeName = themeName;
exports.themeClass = themeClass;

exports.themeGet = (path, defaultValue) => get(theme, path, defaultValue);
