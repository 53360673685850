import { types as NavTypes } from './actions-leftnavigation';

const defaultState = [];
const STATUS_FAVORITE = 'favorite';
const STATUS_UNFAVORITE = 'unfavorite';


export default (state = defaultState, action) => {
  switch (action.type) {
    case NavTypes.REJECTED_LEFTNAV_CONTENT:
      return state;
    case NavTypes.RESOLVED_LEFTNAV_CONTENT:
      return action.payload;
    case NavTypes.RESOLVED_ADD_FAVORITE_CATEGORY:
      return {
        ...state,
        isFavorite: action.data.utagData.favoriteItemStatus === STATUS_FAVORITE,
      };
    case NavTypes.RESOLVED_REMOVE_FAVORITE_CATEGORY:
      return {
        ...state,
        isFavorite: action.data.utagData.favoriteItemStatus !== STATUS_UNFAVORITE,
      };
    case NavTypes.RESOLVED_GET_FAVORITE_CATEGORY_STATUS:
      return {
        ...state,
        isFavorite: action.payload.favoriteIds.indexOf(action.categoryId) >= 0,
      };
    default:
      return state;
  }
};
