import {
  OPEN_MODAL, QL_MODAL, CLOSE_MODAL, SET_OVERFLOWED,
} from 'shared/components/Modal/actions';

const initialState = { open: false, overflowed: false, content: '' };

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...initialState,
        ...action.payload,
        open: true,
      };
    case QL_MODAL:
      return {
        ...initialState,
        ...action.payload,
        open: false,
      };
    case CLOSE_MODAL:
      return {
        ...initialState,
        open: false,
        content: '',
        prevOpen: action.payload,
      };
    case SET_OVERFLOWED:
      return {
        ...state,
        overflowed: action.payload,
      };
    default:
      return state;
  }
};
