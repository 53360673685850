import get from 'lodash/get';
import httpWithLogging from 'universal/http-client';
import {
  RESOLVED_PRODUCT_PLA,
  REJECTED_PRODUCT_PLA,
  LOADING_PRODUCT_PLA,
} from 'pdp/pages/ProductPage/actions';
import { buildCookieString } from 'universal/utilities-cookies';

export const RESOLVED_PLA_RECOMMENDATIONS = 'RESOLVED_PLA_RECOMMENDATIONS';
export const REJECTED_PLA_RECOMMENDATIONS = 'REJECTED_PLA_RECOMMENDATIONS';

export function getProduct(id) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT_PLA });
    const state = getState();
    const { user, session } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    let requestURI = `${NMConfig.API_PRODUCT_LISTING_AD}/${id}`;
    const currencyCode = get(state, 'locale.currencyCode', 'USD');
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      if (currencyCode !== 'USD') {
        let currencyQuery = `?currency=${currencyCode}`;
        if (requestURI.indexOf('?') > -1 || requestURI.indexOf('&') > -1) {
          currencyQuery = `&currency=${currencyCode}`;
        }
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }
    const requestApi = httpWithLogging(state, 10000);
    return requestApi
      .get(requestURI, { headers })
      .then((successResponse) => {
        return Promise.all([
          dispatch({
            type: RESOLVED_PRODUCT_PLA,
            payload: {
              ...successResponse.data.product,
              displayOutfitting: true,
            },
          }),
          dispatch({
            type: RESOLVED_PLA_RECOMMENDATIONS,
            payload: successResponse.data.recommendedProducts || [],
          }),
        ]);
      })
      .catch(() => {
        dispatch({ type: REJECTED_PRODUCT_PLA });
        dispatch({ type: REJECTED_PLA_RECOMMENDATIONS });
      });
  };
}
