import React from 'react';
import { PERISHABLE_MESSAGE } from 'pdp/constants';
import './perishableMessage.scss';

const PerishableMessage = ({ perishable, pdpLayoutTest }) => {
  if (!perishable) {
    return null;
  }

  const clsName = (pdpLayoutTest === 'pdp_layout_on') ? '__optimized' : '';

  const productMessage = (
    <div className={`perishable-message${clsName}`}>
      <p className="message">
        {PERISHABLE_MESSAGE}
      </p>
    </div>
  );

  return productMessage;
};

export default PerishableMessage;
